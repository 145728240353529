import { Route, Routes } from 'react-router-dom'

import { RedirectHome } from 'components'
import { pages } from 'constant'
import {
  BankingPage,
  CardPage,
  ConfirmationCodePage,
  DeleteAccountPage,
  EarnPage,
  ForgotPasswordPage,
  IndividualLaunchpadPage,
  IndividualTokenPage,
  KYCPage,
  LaunchpadPage,
  PortfolioPage,
  SettingsPage,
  SigninPage,
  SignupPage,
  SupportPage,
  TransactionsHistoryPage,
  TwoFactorAuthenticationPage,
} from 'pages'

export function Router() {
  return (
    <Routes>
      <Route path={pages.Base.path} element={<PortfolioPage />} />
      <Route path={pages.PORTFOLIO.path} element={<PortfolioPage />} />
      <Route path={pages.EARN.path} element={<EarnPage />} />
      <Route path={pages.CARD.path} element={<CardPage />} />
      <Route path={pages.CARD.path} />
      <Route path={pages.BANKING.path} element={<BankingPage />} />
      <Route path={pages.BANKING.path} />
      <Route path={pages.ASSET.path}>
        <Route path=':assetId' element={<IndividualTokenPage />} />
      </Route>
      <Route path={pages.EARN.path} />
      {/*<Route path={pages.TRANSACTIONS.path} />*/}
      <Route path={pages.SUPPORT.path} element={<SupportPage />} />
      <Route path={pages.DELETE_ACCOUNT.path} element={<DeleteAccountPage />} />
      <Route path={pages.SETTINGS.path} element={<SettingsPage />} />
      <Route path={pages.SETTINGS.path} />
      <Route path={pages.SignIn.path} element={<SigninPage />} />
      <Route path={pages.SignUp.path} element={<SignupPage />} />
      <Route path={pages.ForgotPassword.path} element={<ForgotPasswordPage />} />
      <Route path={pages.ConfirmationCode.path} element={<ConfirmationCodePage />} />
      <Route path={pages.TwoFactorAuthentication.path} element={<TwoFactorAuthenticationPage />} />
      <Route path={pages.TRANSACTIONS_HISTORY.path} element={<TransactionsHistoryPage />} />
      <Route path={pages.KYC.path} element={<KYCPage />} />
      <Route path={pages.LAUNCHPAD.path} element={<LaunchpadPage />} />
      <Route path={pages.INDIVIDUAL_LAUNCHPAD.path}>
        <Route path=':launchpadId' element={<IndividualLaunchpadPage />} />
      </Route>
      <Route path='*' element={<RedirectHome />} />
    </Routes>
  )
}
