import { Modal } from 'components'
import { pages } from 'constant'
import { ChangePasswordModal } from 'features/modals'

import styles from './styles.module.scss'

export function ChangePassword() {
  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>Change Password</div>
        </div>

        <div className={styles.settingsItemDescription}>
          You can update your account password anytime. To change password follow our instructions.
        </div>

        <div
          onClick={() =>
            Modal.open(<ChangePasswordModal />, {
              title: pages.SETTINGS.name,
              isFullScreen: true,
            })
          }
          className={styles.twoFaBtn}
        >
          Change Password
        </div>
      </div>
    </div>
  )
}
