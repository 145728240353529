import styles from './styles.module.scss'

export function Spinner() {
  return (
    <div className={styles.spinner}>
      <div />
      <div />
    </div>
  )
}
