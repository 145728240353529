import { ReactElement, RefObject, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { CommonDropdown, CompleteIconBlur, Modal } from 'components'
import { pages } from 'constant'
import { StepControllerComponent } from 'features/step-controller'
import { getBalanceString, getToken, parseJwt } from 'utils'
import {
  AuthResponse,
  AuthServiceV4,
  BankAddressResponse,
  FiatService,
  MFAAddAuthResponse,
  StepUpAuthResponse,
} from 'wip/services'
import { DangerIcon } from 'icons'
import { theme } from 'config'
import { $isMobile } from 'model'

import styles from './styles.module.scss'

type Inputs = {
  amount: string
}

const defaultValues = {
  amount: '',
}

export interface WithdrawalFiat {
  bankAddresses: BankAddressResponse[]
  containerRef?: RefObject<HTMLDivElement>
}

export function WithdrawalFiat({ bankAddresses, containerRef }: WithdrawalFiat) {
  const isMobile = useStore($isMobile)

  const navigate = useNavigate()

  const storageToken = getToken()
  const parsedToken = parseJwt(storageToken)
  const scope = parsedToken?.scope || []

  const twoFaStatus: boolean = scope.includes('MFA')

  const methods = useForm<Inputs>({ defaultValues })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods

  const watchAmount = watch('amount').replace(',', '.')

  const [selectedAddress, setSelectedAddress] = useState(bankAddresses[0])
  const [isSummary, setIsSummary] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [requestError, setRequestError] = useState('')
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [response, setResponse] = useState<AuthResponse | StepUpAuthResponse | MFAAddAuthResponse>()

  const itemComponentAddresses = (selectedItemAddress: BankAddressResponse): ReactElement => {
    const trimmedStr = selectedItemAddress?.iban.replace(/\s/g, '')
    const iban = `Account Ending in ****${trimmedStr?.substring(trimmedStr.length - 4)}`
    return (
      <div>
        <div className={styles.bankName}>{selectedItemAddress?.name || '123'}</div>
        <div className={styles.bankIban}>{iban}</div>
      </div>
    )
  }

  const itemComponentCurrency = (currency: string): ReactElement => {
    return <div className={styles.bankName}>{currency}</div>
  }

  const summaryName = (): string => {
    const trimmedStr = selectedAddress?.iban.replace(/\s/g, '')
    return `${selectedAddress?.name} ****${trimmedStr?.substring(trimmedStr.length - 4)}`
  }

  const handleFinalAction = async (responseData: AuthResponse | StepUpAuthResponse | MFAAddAuthResponse) => {
    try {
      setIsLoading(true)

      if ((responseData as StepUpAuthResponse).oneTimeAccessToken && isSummary) {
        await FiatService.fiatWithdrawal(
          {
            assetId: 'EUR',
            amount: +watchAmount,
            depositAddressId: selectedAddress.addressUuid,
          },
          (responseData as StepUpAuthResponse).oneTimeAccessToken
        )

        setIsSuccessful(true)
      }
    } catch (error: any) {
      setRequestError(error.code)
      console.log('handleFinalAction-ERROR', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFiatWithdraw = async () => {
    setIsLoading(true)
    try {
      if (isSummary) {
        const stepUpRes = await AuthServiceV4.stepUp({ scope: 'STEP_UP_FIAT_WITHDRAWAL' })

        setResponse(stepUpRes)
      } else {
        setIsSummary(true)
      }
    } catch (error: any) {
      setRequestError(error.code)
      console.log('handleFiatWithdraw-ERROR', error)
    } finally {
      setIsLoading(false)
    }
  }

  const validateInput = (data: string): boolean => {
    return Number(data.replace(',', '.')) >= 50
  }

  if (!twoFaStatus) {
    return (
      <div>
        <div className={styles.twoFaTitle}>Two Factor Authentication</div>
        <div className={styles.twoFaDescription} style={{ maxWidth: 440 }}>
          For security reasons, a 2FA setup is required. Please follow the instructions.
        </div>
        <button
          onClick={() => {
            navigate(pages.SETTINGS.path)
            Modal.close()
          }}
          className='btn btn-primary'
          style={{ maxWidth: 440 }}
        >
          Go to Settings
        </button>
      </div>
    )
  }

  if (isSuccessful) {
    return (
      <div data-theme={theme}>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            padding: '0 32px',
          }}
        >
          <div className={styles.transfer}>Withdrawal Requested</div>
          <div className={styles.transferDescription} style={{ fontSize: 12 }}>
            Please allow 24-72 hours for the withdrawal request to be processed.
          </div>
          <div style={{}}>
            <CompleteIconBlur isMobile={isMobile} />
          </div>
          <button onClick={() => Modal.close()} className={clsx('btn', 'btn-primary', styles.btnMobile)}>
            Close and Return
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      {!response ? (
        <div className={styles.containerAvailable}>
          <FormProvider {...methods}>
            <form style={{ position: 'relative', margin: '0 32px' }} onSubmit={handleSubmit(handleFiatWithdraw)}>
              <div style={{ height: 103 }} />
              <div className={styles.transfer}>{isSummary ? 'Withdrawal Summary' : 'Withdraw'}</div>
              <div className={styles.transferDescription}>
                {isSummary
                  ? 'Please review the information below and confirm.'
                  : 'Please fill in the necessary information to withdraw.'}
              </div>
              <div className={styles.transferDescription}>
                The bank account from which you are withdrawing must be under your full name. The minimum withdrawal
                amount is €50 or it will not be processed. Please note that SWIFT withdrawals incur a €25 fee.
              </div>
              <div className={styles.transferDescription2} />
              <div className={styles.enterAmount}>Bank Account</div>
              {isSummary ? (
                <div className={styles.bankNameResult}>{summaryName()}</div>
              ) : (
                <CommonDropdown
                  containerRef={containerRef}
                  data={bankAddresses}
                  itemComponent={itemComponentAddresses}
                  setSelectedData={setSelectedAddress}
                  selectedData={selectedAddress}
                />
              )}

              <div style={{ height: 24 }} />
              <div className={styles.enterAmount}>Currency</div>
              {isSummary ? (
                <div className={styles.bankNameResult}>Euro</div>
              ) : (
                <CommonDropdown
                  containerRef={containerRef}
                  data={['Euro']}
                  itemComponent={itemComponentCurrency}
                  selectedData='Euro'
                />
              )}
              <div style={{ height: 24 }} />
              <div className={styles.enterAmount} style={errors.amount ? { color: 'red' } : {}}>
                {errors.amount?.type === 'validate' ? 'Minimal withdrawal amount is 50 EUR' : 'Amount'}
              </div>
              {isSummary ? (
                <div className={styles.bankNameResult}>{getBalanceString(Number(watchAmount || '0'))} EUR</div>
              ) : (
                <div className={styles.inputBlock} style={errors.amount ? { border: '1px solid red' } : {}}>
                  <input
                    placeholder='00.00'
                    className={styles.input}
                    {...register('amount', { required: true, validate: validateInput })}
                  />
                  <div style={{ marginRight: 17 }}>EUR</div>
                </div>
              )}

              <button type='submit' className='btn btn-primary' style={{ marginTop: 64 }}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isLoading ? <span className='spinner-border' /> : isSummary ? 'Confirm Withdraw Request' : 'Continue'}
              </button>

              {isSummary && (
                <button
                  className={clsx('btn', 'btn-primary', styles.backBtn)}
                  onClick={e => {
                    e.preventDefault()
                    setIsSummary(false)
                  }}
                >
                  Back
                </button>
              )}

              {isSummary ? null : (
                <div className={styles.transferDescription} style={{ fontSize: 12, marginTop: 36 }}>
                  Please allow 24-72 hours for the withdrawal request to be processed.
                </div>
              )}

              {requestError ? (
                <div
                  style={{
                    height: 78,
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    margin: '24px 0',
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DangerIcon style={{ height: 24, margin: '0 24px' }} />
                  <div style={{ marginRight: 24 }}>{requestError}</div>
                </div>
              ) : (
                <div style={{ height: 78, margin: '24px 0' }} />
              )}
            </form>
          </FormProvider>
        </div>
      ) : null}

      {response && (
        <div className={styles.stepControllerWrap}>
          <div className={clsx(styles.title, styles.titleMainMobile)}>Withdraw</div>

          <StepControllerComponent nextStepResponse={response} finalAction={handleFinalAction} />
        </div>
      )}
    </>
  )
}
