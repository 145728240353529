import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import { ErrorView } from './error-view'
import { ResendView } from './resend-view'
import styles from './styles.module.scss'

type Inputs = {
  code: string
}
const defaultValues = {
  code: '',
}

type Props = {
  action: (confirmationCode: string) => Promise<void>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  handleResend: () => Promise<void>
  email?: string
}

export const NewEmailVerifyView = ({
  action,
  errorMessage,
  setErrorMessage,
  isLoading,
  handleResend,
  email,
}: Props) => {
  const { handleSubmit, watch, register, reset } = useForm<Inputs>({ defaultValues })

  const inputValue = watch('code')

  useEffect(() => {
    !!errorMessage && setErrorMessage('')
  }, [inputValue])

  const handleAction = (inputData: Inputs) => {
    action(inputData.code)
  }

  const handleOnResend = () => {
    handleResend()
    reset()
  }

  return (
    <form onSubmit={handleSubmit(handleAction)} className={styles.formWrap}>
      <div>
        <div className={styles.description}>
          We have sent a code to your email <span className={styles.boldText}>{email ? email : null}</span>
          <br />
          Please enter the code below to continue.
        </div>
      </div>
      <div style={{ width: '100%' }} className='input-item-wrap'>
        <label htmlFor='code' className={'input-label'}>
          The email code
        </label>
        <input
          type='text'
          className='input-form'
          style={errorMessage ? { outline: '1px solid red', marginBottom: '0.7rem' } : { marginBottom: '0.7rem' }}
          placeholder='Enter your code here'
          {...register('code', { required: true })}
        />

        <ErrorView errorMessage={errorMessage} />

        <ResendView action={handleOnResend} />
      </div>
      <div className={styles.button}>
        <div></div>
        <button
          type='submit'
          className={clsx('btn btn-primary', !inputValue ? styles.buttonDisable : {})}
          disabled={isLoading || !inputValue}
        >
          {isLoading ? <span className='spinner-border' /> : 'Next'}
        </button>
      </div>
    </form>
  )
}
