import { useEffect, useState } from 'react'
import { useStore } from 'effector-react'

import { AuthResponse, MFAAddAuthResponse, StepController, StepUpAuthResponse } from 'wip/services'
import { $userEmail } from 'model/user-email'
import { $userPhone } from 'model/user-phone'

import { EmailVerifyAndTotpView } from './email-verify-and-totp-view'
import { MfaConfirmView } from './mfa-confirm-view'
import { MfaVerifyView } from './mfa-verify-view'
import { NewEmailVerifyView } from './new-email-verify-view'
import { NewPhoneVerifyView } from './new-phone-verify-view'
import { PasswordVerify } from './password-verify'
import { PhoneVerifyAndTotpView } from './phone-verify-and-totp-view'

export type StepControllerData = {
  email?: string
  phone?: string
}

type Props = {
  nextStepResponse: AuthResponse | StepUpAuthResponse | MFAAddAuthResponse
  finalAction: (response: AuthResponse | StepUpAuthResponse | MFAAddAuthResponse) => void
  dataProps?: StepControllerData
}

const STEPS = {
  PHONE_TOTP: 'PHONE_TOTP_VERIFY',
  EMAIL_TOTP: 'EMAIL_TOTP_VERIFY',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  VERIFY_PHONE: 'VERIFY_PHONE',
  MFA_VERIFY: 'MFA_VERIFY',
  MFA_ADD: 'MFA_ADD',
  MFA_RESET: 'MFA_RESET',
  MFA_CONFIRM: 'MFA_CONFIRM',
  VERIFY_NEW_EMAIL: 'VERIFY_NEW_EMAIL',
  VERIFY_NEW_PHONE: 'VERIFY_NEW_PHONE',
  PASSWORD_VERIFY: 'PASSWORD_VERIFY',
}

export const StepControllerComponent = ({ nextStepResponse, finalAction, dataProps }: Props) => {
  const userEmail = useStore($userEmail)
  const userPhone = useStore($userPhone)

  const [response, setResponse] = useState<AuthResponse | StepUpAuthResponse | MFAAddAuthResponse>(nextStepResponse)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const resetMfa = async () => {
    try {
      const resetMfaRes = await StepController.resetMfa(response.sessionToken)
      setResponse(resetMfaRes)
    } catch (error: any) {
      setErrorMessage(error.code)
      console.log('ERROR-resetMfa ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const addMfa = async () => {
    try {
      const addMfaRes = await StepController.addMfa(response.sessionToken)

      setResponse(addMfaRes)
    } catch (error: any) {
      setErrorMessage(error.code)
      console.log('ERROR-addMfa ', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if ([null, 'STEP_UP'].includes(response.nextStep)) {
      finalAction(response)
    } else if (response.nextStep === STEPS.MFA_RESET) {
      resetMfa()
    } else if (response.nextStep === STEPS.MFA_ADD) {
      addMfa()
    }
  }, [response])

  const onClickSubmit = async (confirmationCode: string) => {
    if (!response?.sessionToken) {
      setErrorMessage('Error - no session token')
      return
    }

    setIsLoading(true)
    try {
      if (response.nextStep === STEPS.VERIFY_EMAIL) {
        const verifyEmailRes = await StepController.verifyEmail({ code: confirmationCode }, response.sessionToken)

        setResponse(verifyEmailRes)
      } else if (response.nextStep === STEPS.VERIFY_NEW_EMAIL) {
        const verifyNewEmailRes = await StepController.verifyNewEmail({ code: confirmationCode }, response.sessionToken)

        setResponse(verifyNewEmailRes)
      } else if (response.nextStep === STEPS.VERIFY_PHONE) {
        const verifyPhoneRes = await StepController.verifyPhone({ code: confirmationCode }, response.sessionToken)

        setResponse(verifyPhoneRes)
      } else if (response.nextStep === STEPS.VERIFY_NEW_PHONE) {
        const verifyPhoneRes = await StepController.verifyNewPhone({ code: confirmationCode }, response.sessionToken)

        setResponse(verifyPhoneRes)
      } else if (response.nextStep === STEPS.MFA_VERIFY) {
        const verifyMfaRes = await StepController.verifyMfa({ totp: confirmationCode }, response.sessionToken)

        setResponse(verifyMfaRes)
      } else if (response.nextStep === STEPS.PASSWORD_VERIFY) {
        const verifyPassRes = await StepController.verifyPass({ password: confirmationCode }, response.sessionToken)

        setResponse(verifyPassRes)
      } else if (response.nextStep === STEPS.EMAIL_TOTP) {
        const verifyEmailTotpRes = await StepController.verifyEmailTotp(
          { totp: confirmationCode },
          response.sessionToken
        )

        setResponse(verifyEmailTotpRes)
      } else if (response.nextStep === STEPS.PHONE_TOTP) {
        const verifyPhoneTotpRes = await StepController.verifyPhoneTotp(
          { totp: confirmationCode },
          response.sessionToken
        )

        setResponse(verifyPhoneTotpRes)
      } else if (response.nextStep === STEPS.MFA_CONFIRM) {
        const confirmMfaRes = await StepController.confirmMfa({ totp: confirmationCode, token: response.sessionToken })

        setResponse(confirmMfaRes)
      } else {
        setErrorMessage(`ERROR - UNKNOWN STEP - ${response.nextStep}`)
      }
    } catch (error: any) {
      setErrorMessage(error.code)
      console.log('ERROR-StepController ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleResend = async () => {
    if (!response?.sessionToken) {
      setErrorMessage('Error - no session token')
      return
    }

    setIsLoading(true)

    try {
      if (response.nextStep === STEPS.VERIFY_PHONE) await StepController.phoneSessionCodeResend(response.sessionToken)
      if (response.nextStep === STEPS.VERIFY_EMAIL) await StepController.emailSessionCodeResend(response.sessionToken)
      if (response.nextStep === STEPS.EMAIL_TOTP) await StepController.emailTotpResend(response.sessionToken)
      if (response.nextStep === STEPS.PHONE_TOTP) await StepController.phoneTotpResend(response.sessionToken)

      if (response.nextStep === STEPS.VERIFY_NEW_PHONE)
        await StepController.newPhoneSessionCodeResend(response.sessionToken)
      if (response.nextStep === STEPS.VERIFY_NEW_EMAIL)
        await StepController.newEmailSessionCodeResend(response.sessionToken)
    } catch (error: any) {
      console.log('handleResend-ERROR', error)
      setErrorMessage(error.code)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {response.nextStep === STEPS.MFA_VERIFY && (
        <MfaVerifyView
          action={onClickSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isLoading={isLoading}
        />
      )}

      {response.nextStep === STEPS.PASSWORD_VERIFY && (
        <PasswordVerify
          action={onClickSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isLoading={isLoading}
        />
      )}

      {response.nextStep === STEPS.MFA_CONFIRM && (
        <MfaConfirmView
          action={onClickSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isLoading={isLoading}
          response={response}
        />
      )}

      {[STEPS.EMAIL_TOTP, STEPS.VERIFY_EMAIL].includes(response.nextStep) && (
        <EmailVerifyAndTotpView
          action={onClickSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isLoading={isLoading}
          handleResend={handleResend}
          email={[STEPS.VERIFY_EMAIL].includes(response.nextStep) ? dataProps?.email : userEmail}
        />
      )}

      {[STEPS.VERIFY_NEW_EMAIL].includes(response.nextStep) && (
        <NewEmailVerifyView
          action={onClickSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isLoading={isLoading}
          handleResend={handleResend}
          email={dataProps?.email}
        />
      )}

      {[STEPS.PHONE_TOTP, STEPS.VERIFY_PHONE].includes(response.nextStep) && (
        <PhoneVerifyAndTotpView
          action={onClickSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isLoading={isLoading}
          handleResend={handleResend}
          phone={[STEPS.VERIFY_PHONE].includes(response.nextStep) ? dataProps?.phone : userPhone}
        />
      )}

      {[STEPS.VERIFY_NEW_PHONE].includes(response.nextStep) && (
        <NewPhoneVerifyView
          action={onClickSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isLoading={isLoading}
          handleResend={handleResend}
          phone={dataProps?.phone}
        />
      )}
    </>
  )
}
