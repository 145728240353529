import { useStore } from 'effector-react'

import { getBalanceString } from 'utils'

import { $currency } from '../../model/currency'
import styles from './styles.module.scss'

export interface SummaryBlockStake {
  amount: any
  assetRate: any
  targetPlan: any
  currentStakingApyPercent: any
  maxOtherApiPercent: any
  isCurrency?: boolean
}
export function SummaryBlockStake({
  amount,
  assetRate,
  targetPlan,
  currentStakingApyPercent,
  maxOtherApiPercent,
  isCurrency,
}: SummaryBlockStake) {
  const currency = useStore($currency)

  return (
    <>
      <div className={styles.summaryBlockTitle}>Amount</div>
      <div className={styles.cryptoAmount}>
        {assetRate.fromAssetId}{' '}
        {isCurrency ? getBalanceString(+amount / (assetRate?.data.currentPrice || 1), 8) : amount}
      </div>
      <div className={styles.currencyAmount}>
        {currency.symbol} {isCurrency ? amount : getBalanceString(+amount * (assetRate?.data.currentPrice || 1), 2)}
      </div>
      <div className={styles.conversion}>
        Conversion Rate: 1 {assetRate.fromAssetId} = {currency.symbol} {assetRate?.data.currentPrice}
      </div>
      <div className={styles.divider} />
      <div className={styles.summaryBlockTitle}>Duration</div>
      <div className={styles.cryptoAmount}>{targetPlan?.stakingPeriod || '184'} Days</div>
      <div className={styles.divider} />
      <div className={styles.summaryBlockTitle}>Tier</div>
      <div className={styles.cryptoAmount}>
        <div>
          {Number(targetPlan?.stakingApyPercent || currentStakingApyPercent)}% APY {assetRate.fromAssetId}
        </div>
        {assetRate.fromAssetId === 'FI' && (
          <>
            <div className={styles.dot2} />
            <div>{maxOtherApiPercent}% APY Other</div>
          </>
        )}
      </div>
    </>
  )
}
