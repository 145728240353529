/* eslint-disable max-len */
import * as React from 'react'

type Props = {
  fill: string
}

const SvgComponent: React.FC<Props> = ({ fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='62' height='62' fill='none' viewBox='0 0 62 62'>
    <rect width='62' height='62' fill={fill || '#3D5CF5'} fillOpacity='0.1' rx='31' />
    <path
      fill={fill || '#3D5CF5'}
      d='M38.186 44.987H23.144a9.38 9.38 0 01-6.718-2.353 8.878 8.878 0 01-2.414-6.55V23.903a8.861 8.861 0 012.412-6.543 9.394 9.394 0 016.72-2.346H38.18a9.39 9.39 0 016.717 2.346 8.86 8.86 0 012.418 6.543v.062H40.26a5.895 5.895 0 00-5.945 5.798 5.697 5.697 0 001.739 4.108 5.995 5.995 0 004.208 1.705h7.055v.51a8.88 8.88 0 01-2.413 6.55 9.379 9.379 0 01-6.717 2.352zM21.903 21.466a1.264 1.264 0 00-1.267 1.239 1.218 1.218 0 00.37.879c.24.236.562.368.899.368h9.408a1.28 1.28 0 001.174-.77 1.22 1.22 0 00.095-.479 1.264 1.264 0 00-1.277-1.237h-9.402z'
      opacity='0.4'
    />
    <path
      fill={fill || '#3D5CF5'}
      d='M37.39 30.497a3.36 3.36 0 003.33 2.664h5.413a1.209 1.209 0 001.187-1.222v-4.217a1.212 1.212 0 00-1.194-1.222h-5.54a3.305 3.305 0 00-3.258 3.347c0 .218.02.436.062.65z'
    />
  </svg>
)
export default SvgComponent
