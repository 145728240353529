/* eslint-disable max-len */
import { SVGProps } from 'react'

export function LaunchpadIcon({ fill = '#9C88FD', stroke, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.4'
        d='M20 10C20 15.515 15.514 20 10 20C4.486 20 0 15.515 0 10C0 4.486 4.486 0 10 0C15.514 0 20 4.486 20 10Z'
        fill='#445374'
      />
      <path
        d='M5.77795 12.0446C5.77795 11.8536 5.85095 11.6616 5.99695 11.5156L9.46695 8.02857C9.60795 7.88757 9.79895 7.80857 9.99895 7.80857C10.198 7.80857 10.389 7.88757 10.53 8.02857L14.002 11.5156C14.294 11.8086 14.294 12.2826 14 12.5756C13.706 12.8676 13.231 12.8666 12.939 12.5736L9.99895 9.62057L7.05895 12.5736C6.76695 12.8666 6.29295 12.8676 5.99895 12.5756C5.85095 12.4296 5.77795 12.2366 5.77795 12.0446Z'
        fill='#445374'
      />
    </svg>
  )
}
