import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Modal } from 'components'
import { pages } from 'constant'
import { AddOrChangePhoneModal } from 'features/modals/add-or-change-phone'
import { $userPhone } from 'model/user-phone'
import editSvg from 'assets/icons/edit.svg'

import styles from './styles.module.scss'

export function UserPhone() {
  const userPhone = useStore($userPhone)

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>Phone Number</div>
        </div>
        <div className={styles.settingsItemDescription}>
          {userPhone ? (
            'Your account is registered with the following phone number. You can update your phone number.'
          ) : (
            <p className={styles.fixOneRowText}>You can add your phone number.</p>
          )}
        </div>
        <div
          onClick={() =>
            Modal.open(<AddOrChangePhoneModal />, {
              title: pages.SETTINGS.name,
              isFullScreen: true,
            })
          }
          className={clsx(styles.twoFaBtn, styles.copyBtn)}
          style={{ color: '#445374' }}
        >
          {userPhone ? userPhone : 'Add phone number'}
          {userPhone ? (
            <div className={styles.btnIcon}>
              <img src={editSvg} alt='Edit' />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
