import { useEffect, useState } from 'react'

import { Modal, Spinner } from 'components'
import { HistoryResponseError } from 'components/history-response-error'
import { NoHistoryPlaceholder } from 'components/no-history-placeholder'
import { NoResultsFilter } from 'components/no-results-filter'
import { FilterOptionsType, TransactionHistoryFilter } from 'features/modals/transaction-filter'
import { FilterBar } from 'features/modals/transaction-filter/filter-bar'
import { SearchBar } from 'features/modals/transaction-filter/search-bar'
import { getToken } from 'utils'
import { CardHistoryQueryParams } from 'wip/services'
import { ExtendedAccountStatementRecord, getCardHistoryByFilterFx, queryParamsDefault } from 'model/card-history'
// import downloadIcon from 'assets/icons/history/download-icon.svg'
import filterIcon from 'assets/icons/history/filter-icon.svg'

import { TYPE_TXN_HISTORY } from './constant-type-transaction-history'
import { GroupedSectionListForCard } from './grouped-section-list-for-card'
import styles from './styles.module.scss'

export function TransactionHistoryCard() {
  const storeToken = getToken()

  const [historyData, setHistoryData] = useState<ExtendedAccountStatementRecord[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [responseError, setResponseError] = useState('')
  const [queryParams, setQueryParams] = useState<CardHistoryQueryParams>(queryParamsDefault)

  const fetchInitialData = async (params = queryParams) => {
    setIsLoading(true)
    setResponseError('')

    if (storeToken) {
      try {
        const response = await getCardHistoryByFilterFx(params)
        response && setHistoryData(response)
      } catch (error) {
        setResponseError('error')
        console.log('ERROR - getCardHistoryByFilterFx')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const filterAndSearch = (filterOptionsData: FilterOptionsType[], searchValueParams: string) => {
    const newQueryParams = { ...queryParamsDefault }

    let hasTransactionTypeFilter = false
    const activemerchantCategoryTypes: string[] = []
    const activeTransactionTypes: Set<string> = new Set()

    filterOptionsData.forEach(option => {
      const field = option.field.toLowerCase()

      if (field === 'time') {
        const [start, end] = option.value.split(' - ')

        newQueryParams.fromDate = start
        newQueryParams.toDate = end
      } else if (field === 'merchant_type') {
        if (!newQueryParams.merchantCategoryTypes) {
          newQueryParams.merchantCategoryTypes = []
        }
        newQueryParams.merchantCategoryTypes.push(option.value)
      } else if (field === 'transaction_type') {
        hasTransactionTypeFilter = true
        activeTransactionTypes.add(option.value)
      }
    })

    if (hasTransactionTypeFilter) {
      newQueryParams.includeAuthorizations = activeTransactionTypes.has('Card Transaction')
      newQueryParams.includeAccountAdjustments = activeTransactionTypes.has('Top Up')
      newQueryParams.includeFees = activeTransactionTypes.has('Fee')
    } else {
      newQueryParams.includeAuthorizations = true
      newQueryParams.includeAccountAdjustments = true
      newQueryParams.includeFees = true
    }

    if (searchValueParams) {
      newQueryParams.searchKeyword = searchValueParams
    }

    setQueryParams(newQueryParams)
    fetchInitialData(newQueryParams)
  }

  useEffect(() => {
    filterAndSearch(filterOptions, searchValue)
  }, [filterOptions, searchValue])

  const handleCancelFilter = (value: string) => {
    if (value === 'cancelAllFilter') {
      setFilterOptions([])
      return
    }
    const data = filterOptions.filter(option => option.value !== value)

    setFilterOptions(data)
  }

  const handleOpenFilter = () => {
    Modal.open(
      <TransactionHistoryFilter
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        transactionType={TYPE_TXN_HISTORY.CARD}
      />,
      { title: '', variant: 'right', isFullScreen: true }
    )
  }

  const iconGroup = () => {
    return (
      <div className={styles.btnGroupWrap}>
        <div className={styles.btnGroupButton} onClick={handleOpenFilter}>
          <div className={styles.btnGroupButtonText}>Filters</div>
          <img className={styles.btnGroupIcon} alt='icon' src={filterIcon} />
        </div>

        {/* <div className={styles.btnGroupButton} onClick={() => {}}> //Get CSV
          <div className={styles.btnGroupButtonText}>Get CSV</div>
          <img className={styles.btnGroupIcon} alt='icon' src={downloadIcon} />
        </div> */}
      </div>
    )
  }

  return (
    <div className={styles.containerWrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>Card Transactions</div>
          {(!responseError && historyData?.length) || filterOptions.length ? iconGroup() : null}
        </div>

        <div className={styles.horizontalLine}></div>

        <div className={styles.searchBarWrap}>
          <SearchBar onChange={setSearchValue} />
        </div>

        {filterOptions.length ? (
          <FilterBar filterOptions={filterOptions} handleCancelFilter={handleCancelFilter} />
        ) : null}

        {isLoading ? (
          <div className={styles.loadingWrap}>
            <Spinner />
          </div>
        ) : null}

        {!isLoading && !responseError && historyData?.length ? (
          <GroupedSectionListForCard data={historyData} queryParams={queryParams} setResponseError={setResponseError} />
        ) : null}

        {!isLoading && !responseError && !historyData?.length && !filterOptions.length ? (
          <NoHistoryPlaceholder />
        ) : null}

        {!isLoading && !responseError && !historyData?.length && filterOptions.length ? <NoResultsFilter /> : null}
        {!isLoading && responseError ? <HistoryResponseError /> : null}
      </div>
    </div>
  )
}
