import React, { useState } from 'react'
import { useStore } from 'effector-react'

import { ErrorRedBlock } from 'components'
import { roundingBalance } from 'utils'
import { CardService } from 'wip/services'
import { Currencies } from 'wip/stores'
import { $cardStatus, getCardStatusFx } from 'model/cefi-banking'

import { $assetEurData } from '../../model/cefi-combain-assets-data'
import styles from './styles.module.scss'

type Props = {
  goToTopUp: () => void
}

export function StepDeposit({ goToTopUp }: Props) {
  const cardStatus = useStore($cardStatus)

  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState('')

  const fiatAsset = useStore($assetEurData)

  return (
    <div className={styles.stepContentContainer}>
      <div className={styles.freeHistoryDescription} style={{ textAlign: 'center', marginTop: 90 }}>
        To continue with the application please complete the €50 deposit to your card balance.
      </div>
      <div className={styles.enterAmount}>Deposit Amount</div>
      <div className={styles.inputBlock}>
        <input
          placeholder='00.00'
          className={styles.input}
          value={cardStatus?.additionalInfo?.minimalAmount || 50}
          disabled
        />
        <div style={{ marginRight: 17 }}>{cardStatus?.additionalInfo?.assetId || 'EUR'}</div>
      </div>
      <div className={styles.fiatBalanceAmount}>
        EUR Balance{' '}
        <span
          style={{
            color:
              Number(fiatAsset?.availableBalance || 0) >= (cardStatus?.additionalInfo?.minimalAmount || 50)
                ? '#2B9A18'
                : 'red',
          }}
        >
          {Currencies.EUR}
          {roundingBalance(fiatAsset?.availableBalance?.toString() || '0', 2)}
        </span>{' '}
      </div>

      {Number(fiatAsset?.availableBalance || 0) >= 50 ? (
        <div className={styles.freeHistoryDescription} style={{ marginLeft: '.5rem', marginTop: 0, marginBottom: 64 }}>
          Deposit will be deducted from your fiat balance.
        </div>
      ) : (
        <div
          className={styles.freeHistoryDescription}
          style={{ marginLeft: '.5rem', marginTop: 0, marginBottom: 64, color: 'red', fontWeight: 500 }}
        >
          Not enough fiat balance. Top up your balance to be able to buy.
        </div>
      )}

      <button
        onClick={async e => {
          e.preventDefault()
          setLoading(true)
          try {
            if (Number(fiatAsset?.availableBalance || 0) >= (cardStatus?.additionalInfo?.minimalAmount || 50)) {
              const statusData = await CardService.submitOrderStatus({
                step: cardStatus.nextStep,
                data: {
                  amount: (cardStatus?.additionalInfo?.minimalAmount || 50).toString(),
                },
              })
              await getCardStatusFx()
            } else {
              goToTopUp()
            }
          } catch (error: any) {
            setRequestError(error.code || 'Deposit Error')
            console.log('submitOrderStatus(DEPOSIT)-ERROR', error)
          }
          setLoading(false)
        }}
        className='btn btn-primary'
        style={
          Number(fiatAsset?.availableBalance || 0) >= (cardStatus?.additionalInfo?.minimalAmount || 50)
            ? {}
            : { backgroundColor: 'red' }
        }
      >
        {loading ? (
          <span className='spinner-border' />
        ) : (
          <div className={styles.btnTitle}>
            {Number(fiatAsset?.availableBalance || 0) >= (cardStatus?.additionalInfo?.minimalAmount || 50)
              ? 'Deposit & Order Card'
              : 'Top Up Fiat Balance'}
          </div>
        )}
      </button>

      {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
    </div>
  )
}
