import React from 'react'

import { TransactionHistoryCashback } from 'features/transactions-history/transaction-history-cashback'

import styles from './styles.module.scss'

const Cashback: React.FC = () => {
  return (
    <div className={styles.cashbackWrap}>
      <TransactionHistoryCashback />
    </div>
  )
}

export default Cashback
