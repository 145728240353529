import styles from './styles.module.scss'

export const NoResultsFilter = () => {
  return (
    <div className={styles.container}>
      <div className={styles.emoji}>😢</div>
      <div className={styles.title}>Ooops.. No results found</div>
      <div className={styles.subTitle}>{`We're sorry, but we couldn't find any matches for your search.`}</div>
    </div>
  )
}
