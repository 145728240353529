import React, { useMemo } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Modal } from 'components'
import { MODAL_TYPES, pages } from 'constant'
import { GetUDModal, ListUDModal } from 'features/modals'
import {
  BankingIcon,
  CloseIcon,
  EarnIcon,
  LaunchpadIcon,
  LogoOutIcon,
  PortfolioIcon,
  SettingsIcon,
  SupportIcon,
  TransactionsIcon,
} from 'icons'
import { theme, themeLogo } from 'config'
import { $isMobile, $isMobileNavOpen, setIsMobileNavOpenEV } from 'model'

import { CardIcon } from '../../../../icons/card'
import { $cardStatus } from '../../../../model/cefi-banking'
import { getToken } from '../../../../utils'
import { NavItem } from '../nav-item'
import styles from './styles.module.scss'

const navItemsStartObject: Record<string, NavItemType> = {
  [pages.PORTFOLIO.name]: {
    path: pages.PORTFOLIO.path,
    title: pages.PORTFOLIO.name,
    icon: PortfolioIcon,
  },
  [pages.EARN.name]: {
    path: pages.EARN.path,
    title: pages.EARN.name,
    icon: EarnIcon,
    isSoon: theme === 'fideum',
  },
  [pages.BANKING.name]: {
    path: pages.BANKING.path,
    title: pages.BANKING.name,
    icon: BankingIcon,
  },
  [pages.CARD.name]: {
    path: pages.CARD.path,
    title: pages.CARD.name,
    icon: CardIcon,
  },
  [pages.OTC.name]: {
    // OTC
    path: pages.OTC.path,
    title: pages.OTC.name,
    icon: BankingIcon,
    isSoon: true,
  },
  [pages.STAKING.name]: {
    // STAKING
    path: pages.EARN.path,
    title: pages.STAKING.name,
    icon: BankingIcon,
    // isSoon: true,
  },
  // TODO: off launchpad
  [pages.LAUNCHPAD.name]: {
    path: pages.LAUNCHPAD.path,
    title: pages.LAUNCHPAD.name,
    icon: LaunchpadIcon,
    isSoon: true,
  },
  [pages.SUPPORT.name]: {
    path: pages.SUPPORT.path,
    title: pages.SUPPORT.name,
    icon: SupportIcon,
  },
  [pages.TRANSACTIONS_HISTORY.name]: {
    path: pages.TRANSACTIONS_HISTORY.path,
    title: pages.TRANSACTIONS_HISTORY.name,
    icon: TransactionsIcon,
  },
}

const BlockBankNavState = [
  pages.PORTFOLIO.name,
  pages.EARN.name,
  pages.BANKING.name,
  pages.CARD.name,
  pages.LAUNCHPAD.name,
  pages.SUPPORT.name,
]
const FideumNavState = [pages.PORTFOLIO.name, pages.EARN.name, pages.BANKING.name, pages.OTC.name, pages.SUPPORT.name]
const KaizenNavState = [pages.PORTFOLIO.name, pages.BANKING.name, pages.SUPPORT.name]

const navItemsEnd = [
  {
    path: pages.SETTINGS.path,
    title: pages.SETTINGS.name,
    icon: SettingsIcon,
  },
  {
    path: pages.SignIn.path,
    title: pages.LOGOUT.name,
    icon: LogoOutIcon,
  },
]

interface NavItemType {
  path: string
  title: string
  icon: React.FC
  isSoon?: boolean
}

export function NavPanel() {
  const isMobileNavOpen = useStore($isMobileNavOpen)
  const isMobile = useStore($isMobile)
  const cardStatus = useStore($cardStatus)

  const token = getToken()

  const navItemsStart = useMemo(() => {
    if (!token) {
      const navItemsNoToken: NavItemType[] = [
        {
          path: pages.Base.path,
          title: 'Assets',
          icon: PortfolioIcon,
        },
        {
          path: pages.LAUNCHPAD.path,
          title: pages.LAUNCHPAD.name,
          icon: LaunchpadIcon,
          isSoon: true,
        },
      ]
      return navItemsNoToken
    }

    let navState: string[]

    switch (theme) {
      case 'kaizen':
        navState = KaizenNavState
        break
      case 'fideum':
        navState = FideumNavState
        break
      case 'main':
        navState = BlockBankNavState
        break
      default:
        navState = []
    }

    const navItems = navState.map(item => {
      return navItemsStartObject[item]
    })

    return isMobile ? [...navItems, navItemsStartObject[pages.TRANSACTIONS_HISTORY.name]] : navItems
  }, [theme, isMobile])

  const handleCloseMenu = () => {
    setIsMobileNavOpenEV(!isMobileNavOpen)
  }

  const goToModal = (type: string) => {
    if (type === MODAL_TYPES.LIST_UD_MODAL) {
      Modal.open(<ListUDModal />, { title: pages.PORTFOLIO.name, isFullScreen: true })
    }

    if (type === MODAL_TYPES.GET_UD_MODAL) {
      Modal.open(<GetUDModal />, { title: pages.PORTFOLIO.name, isFullScreen: true })
    }
  }

  return (
    <section className={clsx(styles.mainNavPanel, !isMobileNavOpen && styles.deActivePanel)}>
      <div className={styles.closeNav} onClick={handleCloseMenu}>
        <CloseIcon />
      </div>
      <div className={clsx(styles.navLinksContainer, styles.mobSecondContainer)}>
        <img src={themeLogo()} alt='' className={styles.bbLogo} />
        {navItemsStart
          .filter(({ title }) => {
            if (cardStatus?.currentStep === 'PROCESSED') {
              return true
            }
            return title !== 'Card'
          })
          .map(({ path, title, icon, isSoon }) => {
            return <NavItem key={title} path={path} title={title} Icon={icon} isSoon={isSoon} />
          })}
      </div>
      <div className={styles.navLinksContainer}>
        {/*{theme ? null : (*/}
        {/*  <div className={styles.udLink}>*/}
        {/*    <div className={styles.udLinkContent}>*/}
        {/*      <div className={styles.udLinkTitle}>*/}
        {/*        {unstoppableDomains ? 'WEB3 Domain Details' : 'Get a Free WEB3 Domain'}*/}
        {/*      </div>*/}
        {/*      <div className={styles.udLinkDescription}>*/}
        {/*        {unstoppableDomains*/}
        {/*          ? 'View your WEB3 domain settings and supported networks here.'*/}
        {/*          : 'In partnership with Unstoppable Domains we offer a free WEB3 domain.'}*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        onClick={() => {*/}
        {/*          goToModal(unstoppableDomains ? MODAL_TYPES.LIST_UD_MODAL : MODAL_TYPES.GET_UD_MODAL)*/}
        {/*          handleCloseMenu()*/}
        {/*        }}*/}
        {/*        className={styles.udLinkBtn}*/}
        {/*      >*/}
        {/*        {unstoppableDomains ? 'View Details' : 'Get Free WEB3 Domain'}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

        {token
          ? navItemsEnd.map(({ path, title, icon }) => {
              return <NavItem key={title} path={path} title={title} Icon={icon} />
            })
          : null}
      </div>
    </section>
  )
}
