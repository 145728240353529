import { LogoFiSvg } from './logo-fi'
import styles from './styles.module.scss'

type Props = {
  description: string
}

export function NothingLaunchpad({ description }: Props) {
  return (
    <div
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        // margin: '0 24px',
        padding: '24px',
        borderRadius: '15px',
        minHeight: '70vh',
      }}
    >
      <div style={{ marginBottom: 42, textAlign: 'center' }}>
        <div className={styles.titleText}>Oops! Nothing yet.</div>
        <div className={styles.descriptionText} style={{ color: '#585562', marginTop: 4 }}>
          {description}
        </div>
      </div>
      <div style={{ display: 'flex', gap: 12 }}>
        <div style={{ opacity: 0.2 }}>
          <LogoFiSvg />
        </div>
        <div style={{ opacity: 0.4 }}>
          <LogoFiSvg />
        </div>
        <LogoFiSvg />
        <div style={{ opacity: 0.4 }}>
          <LogoFiSvg />
        </div>
        <div style={{ opacity: 0.2 }}>
          <LogoFiSvg />
        </div>
      </div>
    </div>
  )
}
