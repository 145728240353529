import { ReactElement, RefObject, useEffect, useState } from 'react'
import clsx from 'clsx'

import { TriangleIcon } from 'icons'

import styles from './styles.module.scss'

export interface CommonDropDown {
  data: Array<unknown>
  containerRef?: RefObject<HTMLDivElement>
  setSelectedData?: React.Dispatch<any>
  selectedData: any
  itemComponent: (arg0: any) => ReactElement
}

export function CommonDropdown({ data, containerRef, itemComponent, setSelectedData, selectedData }: CommonDropDown) {
  const [isShowContent, setIsShowContent] = useState(false)

  const hideContent = (event: MouseEvent): void => {
    if (containerRef && containerRef.current?.id === (event?.target as Element)?.id) {
      setIsShowContent(false)
    }
  }

  useEffect(() => {
    if (isShowContent) {
      containerRef?.current?.addEventListener('click', hideContent, true)
    }
    return containerRef?.current?.removeEventListener('click', hideContent)
  }, [isShowContent])

  return (
    <>
      <div
        className={styles.networksDropdownMain}
        style={isShowContent ? { borderColor: 'var(--mainBlue)' } : {}} // height: 62 ??
        onClick={() => data.length > 1 && setIsShowContent(prev => !prev)}
      >
        <div className={styles.dropDownSelectedText}>{itemComponent(selectedData)}</div>
        {data.length > 1 ? (
          <div
            style={{
              width: 17,
              height: 20,
              marginRight: 12,
              marginLeft: 20,
              transform: `rotate(${isShowContent ? '+90' : '-90'}deg)`,
            }}
          >
            <TriangleIcon fill='var(--mainBlue)' />
          </div>
        ) : null}
      </div>
      {isShowContent && (
        <div className={styles.networksDropdownContainer}>
          {data.map((item, index) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => {
                  if (setSelectedData) {
                    setSelectedData(item)
                  }
                  setIsShowContent(false)
                }}
                className={clsx(styles.dropDownSelectedText, styles.networksDropdownItem)}
              >
                {itemComponent(item)}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
