import { useCallback, useRef, useState } from 'react'

import searchIconSvg from 'assets/icons/history/search-icon.svg'

import styles from './styles.module.scss'

type Props = {
  onChange: (text: string) => void
}

export const SearchBar = ({ onChange }: Props) => {
  const textInputRef = useRef<HTMLInputElement>(null)
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [value, setValue] = useState<string>('')

  const handleFocus = () => {
    if (textInputRef.current) {
      textInputRef.current.focus()
    }
  }

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      setValue(newValue)

      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current)
      }

      debounceTimeoutRef.current = setTimeout(() => {
        onChange(newValue)
      }, 700)
    },
    [onChange]
  )

  return (
    <div className={styles.searchBar} onClick={handleFocus}>
      <div className={styles.searchBarIcon}>
        <img alt='Search icon' src={searchIconSvg} />
      </div>
      <input
        ref={textInputRef}
        className={styles.searchBarInput}
        placeholder='Search'
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}
