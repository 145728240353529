/* eslint-disable max-len */
import * as React from 'react'

type Props = {
  fill: string
}

const SvgComponent: React.FC<Props> = ({ fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={62} height={62} fill='none'>
    <rect width={62} height={62} fill={fill || '#3D5CF5'} fillOpacity={0.1} rx={31} />
    <path
      fill={fill || '#3D5CF5'}
      d='M19.098 23.32A2.113 2.113 0 0 0 17 25.441v17.432a2.108 2.108 0 0 0 4.216 0V25.44a2.119 2.119 0 0 0-2.118-2.12ZM30.934 15a2.114 2.114 0 0 0-2.098 2.12v25.754a2.109 2.109 0 1 0 4.216 0V17.12A2.117 2.117 0 0 0 30.934 15ZM42.617 32.54a2.118 2.118 0 0 0-2.12 2.12v8.23a2.108 2.108 0 0 0 4.216 0v-8.23a2.113 2.113 0 0 0-2.096-2.12Z'
    />
  </svg>
)
export default SvgComponent
