import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { AuthSnackbar, BackButton } from 'components'
import I18n from 'components/i18n'
import i18n from 'components/i18n/localize'
import { pages } from 'constant'
import AuthLayout from 'features/auth-new/auth-layout'
import AuthTitle from 'features/auth-new/auth-title'
import { errorDescriptionHandler, parseJwt, saveRefreshToken, saveToken } from 'utils'
import { StepController } from 'wip/services'
import { initApp } from 'wip/stores'
import { setTwoFaStatusEv } from 'model/two-fa'

import { snackComponentChangedEv } from '../../../model/snackComponent'
import styles from '../styles.module.scss'

type Inputs = {
  code: string
}

const defaultValues = {
  code: '',
}

// TODO the same component in settings ??
export function TwoFactorAuthentication() {
  const methods = useForm<Inputs>({ defaultValues })
  const { t } = i18n
  const navigate = useNavigate()
  const location = useLocation()

  const [loading, setLoading] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true)
    try {
      const res = await StepController.verifyMfa({ totp: data.code }, location.state?.sessionToken)

      if (res.nextStep === null) {
        saveToken(res.accessToken)
        saveRefreshToken(res.refreshToken)
        const parsedToken = parseJwt(res.accessToken || '')
        const scope = parsedToken?.scope || []
        setTwoFaStatusEv(scope.includes('MFA'))
        await initApp()

        navigate(pages.Base.path)
      }

      // if (temporaryPassword) {
      //   // TODO: temporaryPassword
      // } else {
      //   setIsBlockUpdate(false)
      //   saveToken(token)
      //   //TODO 2fa refactor
      //   // const userSelf = await AuthService.getSelf()
      //   // setUser(userSelf)
      //   await initApp()
      //
      //   navigate(pages.Base.path)
      // }
    } catch (error: any) {
      console.log('ERROR-twoFASignIn', error)
      // TODO: title ?
      snackComponentChangedEv(
        <AuthSnackbar title='Code is Incorrect.' description={errorDescriptionHandler(error.code || '')} />
      )
    }
    setLoading(false)
  }

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackButton />

        <div className={styles.formWrap}>
          <AuthTitle title='twoFactorAuthentication.title' description='twoFactorAuthentication.description' />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='input-item-wrap'>
                <label htmlFor='code' className={`input-label ${errors.code ? 'text-error' : ''}`}>
                  {t('twoFactorAuthentication.code')}{' '}
                  {errors.code && errors.code.type === 'required' ? t('inputError.required') : ''}
                </label>
                <input
                  style={errors.code ? { outline: '1px solid red' } : {}}
                  id='code'
                  type='text'
                  aria-invalid={!!errors.code}
                  className='input-form'
                  placeholder={t('twoFactorAuthentication.placeholder') || ''}
                  {...register('code', {
                    required: true,
                  })}
                />
              </div>

              <button type='submit' className='btn btn-primary' disabled={loading}>
                {loading ? <span className='spinner-border' /> : <I18n tKey='signIn.title' />}
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  )
}
