import { useEffect, useRef } from 'react'
import { useStore } from 'effector-react'
import moment from 'moment'
import Chart from 'chart.js/auto'

import { themeGlobalColor } from 'config'

import { $currency } from '../../model/currency'

export function PriceChart({ data }: any) {
  const chartRef = useRef<any>(null)
  const chartInstanceRef = useRef<Chart | null>(null)
  const currency = useStore($currency)

  const isMobile = window.matchMedia('(max-width: 767px)').matches

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d')

      // Уничтожаем предыдущий график, если он существует
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      let formatDate = 'YYYY-MM-DD'
      const startDate = moment.unix(data[0][0] / 1000)
      const endDate = moment.unix(data[data.length - 1][0] / 1000)
      const duration = moment.duration(endDate.diff(startDate))
      const days = duration.asDays()

      if (days <= 1) formatDate = 'HH:mm:ss'

      chartInstanceRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map((item: any[]) => moment.unix(item[0] / 1000).format(formatDate)),
          datasets: [
            {
              label: `${currency.symbol} price`,
              data: data.map((item: any[]) => item[1]),
              borderColor: themeGlobalColor(),
              borderWidth: 3,
              parsing: {
                yAxisKey: 'price',
              },
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              fill: 'start',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: !isMobile,
              grid: {
                display: false,
              },
            },
            x: {
              display: false,
              grid: {
                display: false,
              },
            },
          },
          elements: {
            point: {
              radius: 1,
            },
          },
        },
      })
    }
  }, [data, currency.symbol, isMobile])

  return <canvas width='900' ref={chartRef} />
}
