import { API_BASE_AUTH_URL } from 'config'

import { AuthResponse } from './auth-v4'
import { request } from './base'

export interface VerifyEmailRequest {
  code: string
}

export interface MFAVerifyRequest {
  totp: string
}

export interface VerifyPhoneRequest {
  code: string
}

export interface MFAAddAuthResponse extends AuthResponse {
  secret: string
}

export interface PasswordVerifyRequest {
  password: string
}

const verifyEmail = (data: VerifyEmailRequest, token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-email', baseURL: API_BASE_AUTH_URL, data, token })

const verifyEmailTotp = (data: MFAVerifyRequest, token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-email-totp', baseURL: API_BASE_AUTH_URL, data, token })

const emailTotpResend = (token: string): Promise<void> =>
  request({ url: '/public/auth/v4/step/email-totp-resend', baseURL: API_BASE_AUTH_URL, token })

const verifyMfa = (data: MFAVerifyRequest, token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-mfa', baseURL: API_BASE_AUTH_URL, data, token })

const addMfa = (token: string): Promise<MFAAddAuthResponse> =>
  request({ url: '/public/auth/v4/step/add-mfa', baseURL: API_BASE_AUTH_URL, token })

const confirmMfa = ({ token, totp }: { token: string; totp: string }): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/confirm-mfa', baseURL: API_BASE_AUTH_URL, token, data: { totp } })

const verifyPhone = (data: VerifyPhoneRequest, sessionToken: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-phone', baseURL: API_BASE_AUTH_URL, data, token: sessionToken })

const verifyPhoneTotp = (data: MFAVerifyRequest, token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-phone-totp', baseURL: API_BASE_AUTH_URL, data, token })

const phoneTotpResend = (token: string): Promise<void> =>
  request({ url: '/public/auth/v4/step/phone-totp-resend', baseURL: API_BASE_AUTH_URL, token })

const resetMfa = (token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/reset-mfa', baseURL: API_BASE_AUTH_URL, token })

// const stepUp = (data: StepUp, token: string): Promise<StepUpAuthResponse> =>
//   request({ url: '/public/auth/v4/step/step-up', baseURL: API_BASE_AUTH_URL, data, token })

const emailSessionCodeResend = (token: string): Promise<void> =>
  request({ url: '/public/auth/v4/step/email-session-code-resend', baseURL: API_BASE_AUTH_URL, token })

const newEmailSessionCodeResend = (token: string): Promise<void> =>
  request({ url: '/public/auth/v4/step/new-email-session-code-resend', baseURL: API_BASE_AUTH_URL, token })

const phoneSessionCodeResend = (token: string): Promise<void> =>
  request({ url: '/public/auth/v4/step/phone-session-code-resend', baseURL: API_BASE_AUTH_URL, token })

const newPhoneSessionCodeResend = (token: string): Promise<void> =>
  request({ url: '/public/auth/v4/step/new-phone-session-code-resend', baseURL: API_BASE_AUTH_URL, token })

const verifyNewEmail = (data: VerifyEmailRequest, token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-new-email', baseURL: API_BASE_AUTH_URL, data, token })

const verifyNewPhone = (data: VerifyPhoneRequest, token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-new-phone', baseURL: API_BASE_AUTH_URL, data, token })

const verifyPass = (data: PasswordVerifyRequest, token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/step/verify-password', baseURL: API_BASE_AUTH_URL, data, token })

export const StepController = {
  verifyEmail,
  verifyEmailTotp,
  emailTotpResend,
  verifyMfa,
  addMfa,
  confirmMfa,
  verifyPhone,
  phoneTotpResend,
  verifyPhoneTotp,
  resetMfa,
  emailSessionCodeResend,
  phoneSessionCodeResend,
  verifyNewEmail,
  verifyNewPhone,
  newPhoneSessionCodeResend,
  newEmailSessionCodeResend,
  verifyPass,
}
