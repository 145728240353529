/* eslint-disable max-len */
import { SVGProps } from 'react'

export function SettingsIcon({ fill = '#fff', stroke, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='20' fill='none' viewBox='0 0 19 20' {...props}>
      <path fill={fill} d='M9.511 12.83a2.852 2.852 0 01-2.9-2.82 2.86 2.86 0 012.9-2.83 2.825 2.825 0 110 5.65z' />
      <path
        fill={fill}
        d='M18.73 12.37a2.3 2.3 0 00-.827-.79 1.547 1.547 0 01-.634-.64 1.822 1.822 0 01.654-2.5 2.027 2.027 0 00.756-2.83l-.685-1.18a2.112 2.112 0 00-2.872-.76 1.973 1.973 0 01-2.575-.69 1.546 1.546 0 01-.235-.88 1.777 1.777 0 00-.276-1.06A2.151 2.151 0 0010.218 0H8.777a2.195 2.195 0 00-1.81 1.04 1.785 1.785 0 00-.285 1.06c.012.31-.07.617-.235.88a1.963 1.963 0 01-2.565.69A2.124 2.124 0 001 4.43L.315 5.61a2.044 2.044 0 00.756 2.83 1.834 1.834 0 01.664 2.5c-.15.27-.373.492-.644.64-.34.184-.623.457-.818.79a2 2 0 00.02 2.05L1 15.62a2.134 2.134 0 001.819 1.04 2.16 2.16 0 001.083-.3c.27-.165.584-.245.9-.23a1.892 1.892 0 011.88 1.82A2.07 2.07 0 008.808 20H10.2a2.067 2.067 0 002.115-2.05 1.908 1.908 0 011.885-1.82 1.63 1.63 0 01.9.23 2.1 2.1 0 002.892-.74l.715-1.2a2.018 2.018 0 00.02-2.05'
        opacity='0.4'
      />
    </svg>
  )
}
