import moment from 'moment'
import clsx from 'clsx'

import { Modal } from 'components'
import { TYPE_TXN_HISTORY } from 'features/transactions-history/constant-type-transaction-history'
import { addCommasToDisplayValue } from 'utils/add-commas-to-display-value'
import { TITLE_TXN_HISTORY } from 'model/cefi-transactions-history'

import { groupNameMapping } from './card-history-groupMapping'
import styles from './styles.module.scss'

type Props = {
  data: any
}

export function TransactionHistoryDetailModal({ data }: Props) {
  const time = data.time || data.rewardTime || data.transactionTime || data.purchaseDate || ''

  const formatTime = moment(time).format('MMMM D, YYYY hh:mm A')
  const formatAndCapitalize = (sentence: string) => {
    if (!sentence) return sentence

    sentence = sentence.replace(/_/g, ' ')

    let words = sentence.split(' ')
    words = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    return words.join(' ')
  }

  const getGroupFormatName = (group: string): string | undefined => {
    for (const [key, value] of Object.entries(groupNameMapping)) {
      if (Array.isArray(value)) {
        if (value.includes(group)) {
          return key
        }
      } else {
        if (value === group) {
          return key
        }
      }
    }
    return formatAndCapitalize(group)
  }

  return (
    <>
      <div className={styles.main}>
        {[TYPE_TXN_HISTORY.CRYPTO, TYPE_TXN_HISTORY.FIAT].includes(data.transactionType) ? (
          <>
            <div className={styles.title}>Transaction Details</div>
            <div className={styles.header}>
              <img className={styles.icon} alt='icon' src={data.icon} />
              <div className={styles.headerTitleWrap}>
                <div className={styles.headerTitle}>{data.title}</div>
                <div className={styles.headerSubTitle}>{formatTime}</div>
              </div>
            </div>

            <div className={styles.content}>
              <div
                className={clsx(
                  styles.amountText,
                  ['Earning Reward', 'Deposit', 'Top Up', 'Cashback'].includes(data.title || '')
                    ? styles.greenColor
                    : '',
                  data.status === 'PENDING' ? styles.pendingStyle : ''
                )}
              >
                {data.title === 'Withdrawal' ? `-${addCommasToDisplayValue(data.amount || '', 5)} ${data.assetId}` : ''}
                {data.title === 'Exchange'
                  ? `+${addCommasToDisplayValue(data.toAmount || '', 5)} ${data.toAssetId}`
                  : ''}
                {data.title === 'Earning Reward'
                  ? `+${addCommasToDisplayValue(data.amount || '', 5)} ${data.assetId}`
                  : ''}
                {data.title === 'Earning Claimed'
                  ? `+${addCommasToDisplayValue(data.amount || '', 5)} ${data.assetId}`
                  : ''}
                {data.title === 'Cashback' ? `+${addCommasToDisplayValue(data.amount || '', 5)} ${data.assetId}` : ''}
                {data.title === 'Deposit' ? `+${addCommasToDisplayValue(data.amount || '', 5)} ${data.assetId}` : ''}
                {data.title === 'Top Up' ? `+${addCommasToDisplayValue(data.amount || '', 5)} ${data.assetId}` : ''}
                {data.title === TITLE_TXN_HISTORY.REFERRAL_BONUS
                  ? `+${addCommasToDisplayValue(data.amount || '', 5)} ${data.assetId}`
                  : ''}
              </div>

              <div className={styles.rowWrap}>
                {data.iban ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>IBAN:</div>
                    <div className={styles.rowSubText}>{data.iban}</div>
                  </div>
                ) : null}

                {data.senderName ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Bank:</div>
                    <div className={styles.rowSubText}>{data.senderName}</div>
                  </div>
                ) : null}

                {data.status ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Status:</div>
                    <div className={styles.rowSubText}>{data.status}</div>
                  </div>
                ) : null}

                {data.fromAmount ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>From:</div>
                    <div className={styles.rowSubText}>
                      {addCommasToDisplayValue(data.fromAmount || '', 5)} {data.fromAssetId}
                    </div>
                  </div>
                ) : null}

                {data.toAmount ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>To:</div>
                    <div className={styles.rowSubText}>
                      {addCommasToDisplayValue(data.toAmount || '', 5)} {data.toAssetId}
                    </div>
                  </div>
                ) : null}

                {data.rate ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Rate:</div>
                    <div className={styles.rowSubText}>
                      1 {data.fromAssetId} = {addCommasToDisplayValue(data.rate || '', 5)} {data.toAssetId}{' '}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : null}

        {data.transactionType === TYPE_TXN_HISTORY.CARD ? (
          <>
            <div className={styles.title}>Transaction Details</div>
            <div className={styles.header}>
              <img className={styles.icon} alt='icon' src={data.icon} />
              <div className={styles.headerTitleWrap}>
                <div className={styles.headerTitle}>
                  {data.group === 'WITHDRAW' ? 'Cash Withdrawal' : formatAndCapitalize(data?.merchantName)}
                </div>
                <div className={styles.headerSubTitle}>{formatTime}</div>
              </div>
            </div>

            <div className={styles.content}>
              <div className={clsx(styles.amountText, +data?.transactionAmount > 0 ? styles.listGreenColor : '')}>
                {+data?.transactionAmount > 0 ? '+' : ''}
                {addCommasToDisplayValue(data?.transactionAmount || '', 5)} {data?.transactionCurrencyCode}
              </div>

              <div className={styles.rowWrap}>
                {data.maskedCardNumber ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Card:</div>
                    <div className={styles.rowSubText}>{data.maskedCardNumber}</div>
                  </div>
                ) : null}

                {data.group === 'WITHDRAW' ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>ATM:</div>
                    <div className={styles.rowSubText}>
                      {data.merchantName}, {data.merchantId}, {data.merchantCity}, {data.merchantCountryCode}
                    </div>
                  </div>
                ) : null}

                {data.group !== 'WITHDRAW' && !data.group && data.description ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Group:</div>
                    <div className={styles.rowSubText}>{getGroupFormatName(data.description)}</div>
                  </div>
                ) : null}

                {data.group !== 'WITHDRAW' && data.group ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Group:</div>
                    <div className={styles.rowSubText}>{getGroupFormatName(data.group)}</div>
                  </div>
                ) : null}

                {data.status ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Status:</div>
                    <div className={styles.rowSubText}>{formatAndCapitalize(data.status)}</div>
                  </div>
                ) : null}

                {data.exchangeRate ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>VISA exchange rate:</div>
                    <div className={styles.rowSubText}>
                      1 EUR = {addCommasToDisplayValue(data.exchangeRate, 5)} {data.transactionCurrencyCode}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : null}

        {[TYPE_TXN_HISTORY.CASHBACK].includes(data.transactionType) ? (
          <>
            <div className={styles.title}>Cashback Details</div>

            <div className={styles.content}>
              <div className={styles.rowWrap}>
                {data.amount ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Amount:</div>
                    <div className={styles.rowSubText}>
                      +{addCommasToDisplayValue(data.amount, 5)} {data.assetId} /{' '}
                      {addCommasToDisplayValue((data.amount / data.exchangeRate || '').toString(), 2)} EUR
                    </div>
                  </div>
                ) : null}

                {data.rewardTime ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Date:</div>
                    <div className={styles.rowSubText}>{moment(data.rewardTime).format('MMMM D, YYYY hh:mm A')}</div>
                  </div>
                ) : null}

                {data.exchangeRate ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Exchange rate:</div>
                    <div className={styles.rowSubText}>
                      1 EUR = {addCommasToDisplayValue(data.exchangeRate, 5)} {data.assetId}
                    </div>
                  </div>
                ) : null}

                {data.transactionAmount ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Spent:</div>
                    <div className={styles.rowSubText}>
                      -{addCommasToDisplayValue(data.transactionAmount || '', 2)} EUR
                    </div>
                  </div>
                ) : null}

                {data.merchantName ? (
                  <div className={styles.row}>
                    <div className={styles.rowText}>Merchant:</div>
                    <div className={styles.rowSubText}>{data.merchantName}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : null}

        <div className={styles.flexGrow1}></div>

        <button className={clsx('btn', 'btn-primary', styles.button)} onClick={() => Modal.close()}>
          Looks Good!
        </button>
      </div>
    </>
  )
}
