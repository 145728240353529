import React from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { $isMobile } from 'model'
import { $cardsData } from 'model/cefi-banking'

import styles from './styles.module.scss'

type Props = {
  cardUuid: string
}

export function CardLimits({ cardUuid }: Props) {
  const isMobileScreens = useStore($isMobile)
  const cards = useStore($cardsData)
  const limits = cards.find(card => card.cardUuid === cardUuid)?.limits

  const CardLimitsFields = [
    {
      title: 'Daily Purchase Limit',
      value: limits?.dailyPurchaseAvailable || 0,
    },
    {
      title: 'Daily Contactless Purchase Limit',
      value: limits?.dailyContactlessPurchaseAvailable || 0,
    },
    {
      title: 'Monthly Purchase Limit',
      value: limits?.monthlyPurchaseAvailable || 0,
    },
    {
      title: 'Monthly Withdrawal Limit',
      value: limits?.monthlyWithdrawalAvailable || 0,
    },
  ]

  return (
    <>
      <div className={styles.title}>
        <div>Card Limits</div>
      </div>

      {CardLimitsFields.map(limitField => {
        return (
          <div key={limitField.title} className={clsx('input-item-wrap', styles.limitInput)}>
            <label htmlFor='amount' className='input-label'>
              {limitField.title}
            </label>
            <input
              id='amount'
              type='text'
              className='input-form'
              value={`${limitField.value} EUR`}
              style={{ fontWeight: 700, fontSize: isMobileScreens ? 14 : 18, fontFamily: 'Lexend' }}
              disabled
            />
          </div>
        )
      })}

      <div style={{ height: 77 }} />
    </>
  )
}

export default CardLimits
