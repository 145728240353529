import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { AssetsDropdown, RequestError } from 'components'
import { getBalanceString, roundingBalance } from 'utils'
import { AssetsServices, EVENT_NAMES, ExchangeInfo, useAnalytics } from 'wip/services'
import { initApp } from 'wip/stores'
import ChangeIcon from 'assets/icons/ChangeIcon'

import { $assetsCefiExchangeRates } from '../../../model/cef-rates-exchange'
import { $assetEurData, $assetsListData, CombainedObject } from '../../../model/cefi-combain-assets-data'
import { $currency, LowercaseCurrencyType } from '../../../model/currency'
import { AmountInput } from './ammount-input'
import styles from './styles.module.scss'
import { Success } from './success'
import { TradeSummaryInfo } from './trade-summary-info'

const defaultValues = {
  fromAmountCrypto: '',
  fromAmountCurrency: '',
  toAmountCrypto: '',
  toAmountCurrency: '',
}

export type ExchangeInputs = {
  fromAmountCrypto: string
  fromAmountCurrency: string
  toAmountCrypto: string
  toAmountCurrency: string
}

type Props = {
  asset: CombainedObject
}

export function Exchange({ asset }: Props) {
  const ratesCeFi = useStore($assetsCefiExchangeRates)
  const assets = useStore($assetsListData)
  const { myLogEvent } = useAnalytics()

  const currency = useStore($currency)
  const currencyType: 'eur' | 'usd' = (currency?.type?.toLowerCase() as LowercaseCurrencyType) || 'eur'

  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm<ExchangeInputs>({ defaultValues })
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
  } = methods

  const watchFromAmountCrypto = watch('fromAmountCrypto')
  const watchFromAmountCurrency = watch('fromAmountCurrency')
  const watchToAmountCrypto = watch('toAmountCrypto')
  const watchToAmountCurrency = watch('toAmountCurrency')

  const assetsToList = (selectedFromAsset: any): string[] => {
    return ratesCeFi
      .filter(assetRate => assetRate.fromAssetId === selectedFromAsset?.assetId)
      .map(assetRate => assetRate.toAssetId)
  }
  const assetsFromList = (selectedFromAsset: any): string[] => {
    return ratesCeFi
      .filter(assetRate => assetRate.toAssetId === selectedFromAsset.assetId)
      .map(assetRate => assetRate.fromAssetId)
  }

  const [selectedFromAsset, setSelectedFromAsset] = useState<CombainedObject | null | undefined>(null)
  const [selectedToAsset, setSelectedToAsset] = useState<CombainedObject | null | undefined>(null)
  const [isCurrency, setIsCurrency] = useState(false)
  const [focusName, setFocusName] = useState('')
  const [exchangeInfo, setExchangeInfo] = useState<ExchangeInfo | null>(null)

  const [requestError, setRequestError] = useState('')
  const [isSuccessfully, setIsSuccessfully] = useState(false)

  const fromToRate = ratesCeFi.find(
    rateCeFi => rateCeFi.fromAssetId === selectedFromAsset?.assetId && rateCeFi.toAssetId === selectedToAsset?.assetId
  )

  const toFromRate = ratesCeFi.find(
    rateCeFi => rateCeFi.fromAssetId === selectedToAsset?.assetId && rateCeFi.toAssetId === selectedFromAsset?.assetId
  )

  useEffect(() => {
    if (!assetsToList(asset)?.length) {
      setSelectedFromAsset(assets.find(assetItem => assetsFromList(asset).includes(assetItem.assetId)))
      setSelectedToAsset(asset)
    } else {
      setSelectedFromAsset(asset)
      setSelectedToAsset(
        assets.find(assetItem => assetItem.assetId !== asset.assetId && assetsToList(asset).includes(assetItem.assetId))
      )
    }
  }, [asset])

  useEffect(() => {
    reset()
    setIsCurrency(false)
  }, [selectedFromAsset, selectedToAsset])

  useEffect(() => {
    if (focusName === 'fromAmountCrypto') {
      setValue(
        'fromAmountCurrency',
        roundingBalance((+watchFromAmountCrypto * (selectedFromAsset?.[currencyType]?.price || 0)).toString(), 2)
      )
      setValue(
        'toAmountCurrency',
        roundingBalance((+watchFromAmountCrypto * (selectedFromAsset?.[currencyType]?.price || 0)).toString(), 2)
      )
      setValue('toAmountCrypto', roundingBalance((+watchFromAmountCrypto * (fromToRate?.rate || 0)).toString(), 8))
    }
    if (focusName === 'fromAmountCurrency') {
      setValue(
        'fromAmountCrypto',
        roundingBalance((+watchFromAmountCurrency / (selectedFromAsset?.[currencyType]?.price || 0)).toString(), 8)
      )
      setValue('toAmountCurrency', roundingBalance(watchFromAmountCurrency, 2))
      setValue('toAmountCrypto', roundingBalance((+watchFromAmountCrypto * (fromToRate?.rate || 0)).toString(), 8))
    }
    if (focusName === 'toAmountCrypto') {
      setValue(
        'toAmountCurrency',
        roundingBalance((+watchToAmountCrypto * (selectedToAsset?.[currencyType]?.price || 0)).toString(), 2)
      )
      setValue(
        'fromAmountCurrency',
        roundingBalance((+watchToAmountCrypto * (selectedToAsset?.[currencyType]?.price || 0)).toString(), 2)
      )
      setValue('fromAmountCrypto', roundingBalance((+watchToAmountCrypto * (toFromRate?.rate || 0)).toString(), 2))
    }
    if (focusName === 'toAmountCurrency') {
      setValue(
        'toAmountCrypto',
        roundingBalance((+watchToAmountCurrency / (selectedToAsset?.[currencyType]?.price || 0)).toString(), 8)
      )
      setValue('fromAmountCurrency', roundingBalance(watchToAmountCurrency, 2))
      setValue('fromAmountCrypto', roundingBalance((+watchToAmountCrypto * (toFromRate?.rate || 0)).toString(), 8))
    }

    clearErrors()
    setRequestError('')
  }, [watchFromAmountCurrency, watchFromAmountCrypto, watchToAmountCurrency, watchToAmountCrypto])

  const changeAmountCurrency = (): void => {
    setIsCurrency(prev => !prev)
  }

  const handleMax = (): void => {
    if (isCurrency) {
      setValue(
        'fromAmountCurrency',
        roundingBalance(
          ((selectedFromAsset?.availableBalance || 0) * (selectedFromAsset?.[currencyType]?.price || 0)).toString(),
          2
        )
      )
      setValue('fromAmountCrypto', roundingBalance(selectedFromAsset?.availableBalance.toString(), 8))
      setValue(
        'toAmountCurrency',
        roundingBalance(
          ((selectedFromAsset?.availableBalance || 0) * (selectedFromAsset?.[currencyType]?.price || 0)).toString(),
          2
        )
      )
      setValue('toAmountCrypto', ((selectedFromAsset?.availableBalance || 0) * (fromToRate?.rate || 0)).toString())
    } else {
      setValue('fromAmountCrypto', roundingBalance(selectedFromAsset?.availableBalance?.toString() || '0', 8))
      setValue(
        'fromAmountCurrency',
        roundingBalance(
          ((selectedFromAsset?.availableBalance || 0) * (selectedFromAsset?.[currencyType]?.price || 0)).toString(),
          2
        )
      )
      setValue(
        'toAmountCurrency',
        roundingBalance(
          ((selectedFromAsset?.availableBalance || 0) * (selectedFromAsset?.[currencyType]?.price || 0)).toString(),
          2
        )
      )
      setValue(
        'toAmountCrypto',
        roundingBalance(((selectedFromAsset?.availableBalance || 0) * (fromToRate?.rate || 0)).toString(), 8)
      )
    }
  }

  const handleChange = (): void => {
    if (!assetsToList(selectedToAsset).includes(selectedFromAsset?.assetId || '')) return

    reset()
    setSelectedToAsset(selectedFromAsset)
    setSelectedFromAsset(selectedToAsset)
  }

  const changeFromAsset = (assetItem: any): void => {
    if (!assetsToList(assetItem).includes(selectedToAsset?.assetId || '')) {
      setSelectedToAsset(assets.find(assetOrigin => assetsToList(assetItem).includes(assetOrigin.assetId)))
    }
    setSelectedFromAsset(assetItem)
  }

  const handleExchange = async (): Promise<void> => {
    setIsLoading(true)

    try {
      if (exchangeInfo) {
        await AssetsServices.exchangeAsset({
          amount: +watchFromAmountCrypto,
          info: exchangeInfo as ExchangeInfo,
        })

        myLogEvent(EVENT_NAMES.WEB_EXCHANGE, { ...exchangeInfo })

        await initApp()

        setIsSuccessfully(true)
      } else {
        const exchangeInfoData = await AssetsServices.exchangeInfoAsset({
          amount: +watchFromAmountCrypto,
          from: selectedFromAsset?.assetId || '',
          to: selectedToAsset?.assetId || '',
        })
        setExchangeInfo(exchangeInfoData)
      }
    } catch (e: any) {
      console.log('ERROR-handleExchange', e)
      setRequestError(e.code)
    }
    setIsLoading(false)
  }

  const inputErrorHandler = (): string => {
    if (+watchFromAmountCrypto > +(selectedFromAsset?.availableBalance || 0)) {
      return 'Not Enough Balance'
    }
    if (Object.keys(errors).some(error => ['fromAmountCrypto', 'fromAmountCurrency'].includes(error))) {
      return 'Required'
    }
    return ''
  }

  if (isSuccessfully) {
    return <Success />
  }

  if (!selectedFromAsset) return null

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleExchange)}>
        {exchangeInfo && fromToRate ? (
          <TradeSummaryInfo
            exchangeInfo={exchangeInfo}
            fromToRate={fromToRate}
            selectedFromAsset={selectedFromAsset}
            selectedToAsset={selectedToAsset}
            watchFromAmountCrypto={watchFromAmountCrypto}
          />
        ) : (
          <>
            <div className={styles.title}>Exchange</div>
            <div className={styles.description}>
              Please enter the details for the <br />
              transaction to proceed.
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '50%', position: 'relative' }}>
                <div className={styles.enterAmount}>From</div>
                <AssetsDropdown
                  assets={assets.filter(item => {
                    return item.assetId !== selectedFromAsset?.assetId && assetsFromList(asset).includes(item.assetId)
                  })}
                  selectedData={selectedFromAsset}
                  setSelectedData={changeFromAsset}
                />
              </div>
              <div style={{ width: 5 }} />
              <div style={{ width: '50%' }}>
                <div className={styles.enterAmount} style={inputErrorHandler() ? { color: 'red' } : {}}>
                  {inputErrorHandler() || 'Amount'}
                </div>
                <AmountInput
                  currencyAmount={watchFromAmountCurrency}
                  cryptoAmount={watchFromAmountCrypto}
                  asset={selectedFromAsset}
                  changeAmountCurrency={changeAmountCurrency}
                  isCurrency={isCurrency}
                  direction='from'
                  setFocusName={setFocusName}
                  methods={methods}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', height: 88 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 13 }}>
                  <div className={styles.balance}>
                    Balance: {getBalanceString(+selectedFromAsset.availableBalance, 8)} {selectedFromAsset.symbol}
                  </div>
                  <div onClick={handleMax} className={styles.max}>
                    MAX
                  </div>
                </div>
                <div className={styles.enterAmount}>To</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 36,
                }}
              >
                <div style={{ flexGrow: 1, borderLeft: '1px solid var(--mainBlue)', width: 1 }} />
                <div
                  onClick={handleChange}
                  style={{
                    border: '1px solid var(--mainBlue)',
                    borderRadius: '100%',
                    height: 26,
                    width: 26,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    opacity: assetsToList(selectedToAsset).includes(selectedFromAsset.assetId) ? 1 : 0.2,
                  }}
                >
                  <ChangeIcon fill='var(--mainBlue)' />
                </div>
                <div style={{ flexGrow: 1, borderRight: '1px solid var(--mainBlue)', width: 1 }} />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '50%', position: 'relative' }}>
                <AssetsDropdown
                  assets={assets.filter((assetTo: any) => {
                    return (
                      assetTo.assetId !== selectedToAsset?.assetId &&
                      assetsToList(selectedFromAsset).includes(assetTo.assetId)
                    )
                  })}
                  selectedData={selectedToAsset}
                  setSelectedData={setSelectedToAsset}
                />
              </div>
              <div style={{ width: 5 }} />
              <div style={{ width: '50%' }}>
                <AmountInput
                  currencyAmount={watchToAmountCurrency}
                  cryptoAmount={watchToAmountCrypto}
                  asset={selectedToAsset}
                  changeAmountCurrency={changeAmountCurrency}
                  isCurrency={isCurrency}
                  direction='to'
                  setFocusName={setFocusName}
                  methods={methods}
                />
              </div>
            </div>
            <div style={{ marginTop: 15 }} className={styles.balance}>
              Balance: {getBalanceString(selectedToAsset ? +selectedToAsset.availableBalance : 0, 8)}{' '}
              {selectedToAsset?.symbol || ''}
            </div>

            <div style={{ width: '100%', borderTop: '1px solid rgba(38, 40, 50, 0.1)', margin: '25px 0' }} />
            <div className={styles.conversionRate}>
              Conversion Rate: 1 {selectedFromAsset.assetId} = {getBalanceString(Number(fromToRate?.rate ?? 0), 8)}{' '}
              {selectedToAsset?.assetId || ''}
            </div>
          </>
        )}

        <div style={{ height: 30 }} />
        <button type='submit' className='btn btn-primary' disabled={isLoading || !!inputErrorHandler()}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? <span className='spinner-border' /> : exchangeInfo ? 'Confirm Exchange' : 'Exchange'}
        </button>

        {exchangeInfo && fromToRate && (
          <button
            className={clsx('btn', 'btn-primary', styles.backBtn)}
            onClick={e => {
              e.preventDefault()
              setExchangeInfo(null)
              setRequestError('')
            }}
          >
            Back
          </button>
        )}

        {requestError ? <RequestError requestError={requestError} /> : null}
      </form>
    </FormProvider>
  )
}
