/* eslint-disable max-len */
import { SVGProps } from 'react'

export function CloseIcon({ fill = '#262832', stroke, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg width='32' height='31' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.647 8.455l1.527-1.527L24.48 22.233l-1.528 1.527L7.647 8.455zm-.16 13.842l15.37-15.37 1.59 1.592L9.08 23.887l-1.591-1.59z'
        fill={fill}
      />
    </svg>
  )
}
