import { HTMLAttributes } from 'react'

import styles from './styles.module.scss'

export interface ProgressBar extends HTMLAttributes<HTMLDivElement> {
  value: number
  isModal?: boolean
  customColor?: string
}

export function ProgressBar({ value, isModal, customColor, ...props }: ProgressBar) {
  return (
    <div
      className={styles.progressBar}
      style={{
        ...(isModal ? { height: 8 } : {}),
        ...(customColor ? { backgroundColor: customColor } : {}),
      }}
      {...props}
    >
      <div className={styles.progressBarFill} style={{ width: `${value}%` }} />
    </div>
  )
}
