import { TYPE_TXN_HISTORY } from './constant-type-transaction-history'
import styles from './styles.module.scss'
import { TransactionHistoryCard } from './transaction-history-card'
import { TransactionHistoryCrypto } from './transaction-history-crypto'
import { TransactionHistoryFiat } from './transaction-history-fiat'

type Props = {
  transactionType?: string // crypto, fiat, card
}

export function TransactionsHistory({ transactionType = TYPE_TXN_HISTORY.CRYPTO }: Props) {
  return (
    <div className={styles.page}>
      <div className={styles.containerPage}>
        {transactionType === TYPE_TXN_HISTORY.CRYPTO ? <TransactionHistoryCrypto /> : null}
        {transactionType === TYPE_TXN_HISTORY.FIAT ? <TransactionHistoryFiat /> : null}
        {transactionType === TYPE_TXN_HISTORY.CARD ? <TransactionHistoryCard /> : null}
      </div>
    </div>
  )
}
