import { HTMLAttributes } from 'react'

import { DangerIcon } from 'icons'

import styles from './styles.module.scss'

export interface ErrorRedBlock extends HTMLAttributes<HTMLDivElement> {
  requestError: string
}

type Props = {
  isCountryBlock?: string
}

export function ErrorCardBlock({ isCountryBlock }: Props) {
  return (
    <>
      <div className={styles.containerErrorCard}>
        <header className={styles.header}>
          <DangerIcon />
          <p className={styles.title}>Card Order Error</p>
        </header>
        <p className={styles.description}>
          {isCountryBlock
            ? isCountryBlock
            : 'Uh-oh! It seems there’s an issue with your card order. For more information and assistance, please reach out\n' +
              ' to out to our customer support. We appreciate your understanding and apologize for any inconvenience caused'}
        </p>
      </div>
    </>
  )
}
