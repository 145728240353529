import { useState } from 'react'

type Props = {
  action: () => void
}

export const ResendView = ({ action }: Props) => {
  const [isResend, setIsResend] = useState(false) //TODO add resend text

  const handleAction = () => {
    setIsResend(true)
    action()
  }
  return (
    <div className='input-item-wrap'>
      <p className='input-label'>
        Haven’t received the code?
        <span
          onClick={async () => {
            handleAction()
          }}
          className='input-label form-link'
        >
          Send again
        </span>
      </p>
    </div>
  )
}
