import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'

import { Modal, RequestError, Spinner } from 'components'
import { AuthResponse, AuthServiceV4, CardService, MFAAddAuthResponse, StepUpAuthResponse } from 'wip/services'
import { $isMobile } from 'model'
import { $cardsData } from 'model/cefi-banking'
import copied from 'assets/icons/copy.svg'
import CopySvgComponent from 'assets/icons/copy-svg-component'

import { pages } from '../../../constant'
import { getToken, parseJwt } from '../../../utils'
import { StepControllerComponent } from '../../step-controller'
import { decryptedData, generateKeyPair, getPublicKeyPem, preparedEncrypt } from './cryptoHelpers'
import styles from './styles.module.scss'

type Props = {
  cardUuid: string
}

export function CardViewDetails({ cardUuid }: Props) {
  const cards = useStore($cardsData)
  const isMobile = useStore($isMobile)
  const expiryDate = cards.find(card => card.cardUuid === cardUuid)?.expiryDate

  const navigate = useNavigate()

  const storageToken = getToken()
  const parsedToken = parseJwt(storageToken)
  const scope = parsedToken?.scope || []

  const twoFaStatus: boolean = scope.includes('MFA')

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
    const year = date.getUTCFullYear() % 100
    return `${month}/${year}`
  }

  const [cardData, setCardData] = useState({
    cardNumber: '',
    cvv2: '',
  })
  const [requestError, setRequestError] = useState('')
  const [isCardNumberCopied, setIsCardNumberCopied] = useState(false)
  const [isExpiryDateCopied, setIsExpiryDateCopied] = useState(false)
  const [isCodeCopied, setIsCodeCopied] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [response, setResponse] = useState<AuthResponse | MFAAddAuthResponse | StepUpAuthResponse | null>(null)
  const getCardsData = async (responseData: AuthResponse | MFAAddAuthResponse | StepUpAuthResponse): Promise<void> => {
    setIsLoading(true)
    try {
      const { privateKey, publicKey } = await generateKeyPair()

      const publicKeyPem = await getPublicKeyPem(publicKey)
      const base64PublicKey = window.btoa(publicKeyPem)

      const cardDetails = await CardService.cardDetails({
        cardUuid,
        publicKey: base64PublicKey,
        token: (responseData as StepUpAuthResponse)?.oneTimeAccessToken,
      })

      const encryptedCardNumber = preparedEncrypt(cardDetails.encryptedCardNumber)
      const encryptedCvv2 = preparedEncrypt(cardDetails.encryptedCvv2)

      const cardNumber = await decryptedData(privateKey, encryptedCardNumber, 'CardNumber')
      const cvv2 = await decryptedData(privateKey, encryptedCvv2, 'CVV2')
      setResponse(null)

      setCardData({ cardNumber, cvv2 })
    } catch (e: any) {
      console.log('ERROR-getCardsData', e)
      setRequestError(e.code)
    } finally {
      setIsLoading(false)
    }
  }

  const startViewDetails = async () => {
    try {
      const stepUpRes = await AuthServiceV4.stepUp({ scope: 'STEP_UP_CARD_DATA' })
      setResponse(stepUpRes)
    } catch (error: any) {
      console.log('startViewDetails-ERROR', error)
      setRequestError(error.code)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    startViewDetails().catch(e => {
      console.log('ERROR-cryptoFlow', e)
    })
  }, [])

  const handleCopied = (data: string, fn: React.Dispatch<React.SetStateAction<boolean>>): void => {
    const setStateCopied = fn

    navigator.clipboard.writeText(data).then(() => {
      setStateCopied(true)
      setTimeout(() => {
        setStateCopied(false)
      }, 2000)
    })
  }

  if (isLoading) {
    return (
      <div className={styles.cardViewWrap}>
        <div className={styles.contentWrap}>
          <Spinner />
        </div>
      </div>
    )
  }

  if (!twoFaStatus) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <div className={styles.title}>Two Factor Authentication</div>
        <div style={{ flexGrow: 1 }} />
        <div className={styles.description} style={{ maxWidth: 440, textAlign: 'center' }}>
          For security reasons, a 2FA setup is required. Please follow the instructions.
        </div>
        <div style={{ flexGrow: 1 }} />
        <button
          onClick={() => {
            navigate(pages.SETTINGS.path)
            Modal.close()
          }}
          className='btn btn-primary'
          style={{ maxWidth: 440 }}
        >
          Go to Settings
        </button>
      </div>
    )
  }

  return (
    <div className={styles.cardViewWrap}>
      <div className={styles.contentWrap}>
        <div className={styles.title}>
          <div>Your Card Details</div>
        </div>
        {!response && (
          <>
            <div className={styles.viewDetailsItemWrap}>
              <div>
                <div className={styles.viewDetailsTitle}>Card Number</div>
                <div className={styles.viewDetailsDescription}>
                  {(cardData?.cardNumber || '').replace(/(\d{4})/g, '$1 ')}
                </div>
              </div>
              <div
                onClick={() => handleCopied(cardData.cardNumber, setIsCardNumberCopied)}
                style={{ position: 'relative', cursor: 'pointer' }}
              >
                {isMobile ? null : <CopySvgComponent fill='var(--mainBlue)' />}

                {/* eslint-disable-next-line no-nested-ternary */}
                {isCardNumberCopied ? (
                  <div className={styles.copiedWrap}>
                    <img src={copied} alt='' />
                  </div>
                ) : isMobile ? (
                  <CopySvgComponent fill='var(--mainBlue)' />
                ) : null}
              </div>
            </div>

            <div style={{ display: 'flex', width: '100%', maxWidth: 320, marginTop: 15, marginBottom: 69 }}>
              <div className={styles.viewDetailsItemWrap}>
                <div>
                  <div className={styles.viewDetailsTitle}>Expiry Date</div>
                  <div className={styles.viewDetailsDescription}>{expiryDate ? formatDate(expiryDate) : '--/--'}</div>
                </div>
                <div
                  onClick={() => handleCopied(formatDate(expiryDate || ''), setIsExpiryDateCopied)}
                  style={{ position: 'relative', cursor: 'pointer' }}
                >
                  {isMobile ? null : <CopySvgComponent fill='var(--mainBlue)' />}

                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isExpiryDateCopied ? (
                    <div className={styles.copiedWrap}>
                      <img src={copied} alt='' />
                    </div>
                  ) : isMobile ? (
                    <CopySvgComponent fill='var(--mainBlue)' />
                  ) : null}
                </div>
              </div>
              <div style={{ width: 74 }} />
              <div className={styles.viewDetailsItemWrap}>
                <div>
                  <div className={styles.viewDetailsTitle}>Security Code</div>
                  <div className={styles.viewDetailsDescription}>{cardData.cvv2}</div>
                </div>
                <div
                  onClick={() => handleCopied(cardData.cvv2, setIsCodeCopied)}
                  style={{ position: 'relative', cursor: 'pointer' }}
                >
                  {isMobile ? null : <CopySvgComponent fill='var(--mainBlue)' />}

                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isCodeCopied ? (
                    <div className={styles.copiedWrap}>
                      <img src={copied} alt='' />
                    </div>
                  ) : isMobile ? (
                    <CopySvgComponent fill='var(--mainBlue)' />
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}

        {response && <StepControllerComponent nextStepResponse={response} finalAction={getCardsData} />}
      </div>
      {requestError ? <RequestError requestError={requestError} /> : null}
    </div>
  )
}
