import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { MainLoader, Modal } from 'components'
import { pages } from 'constant'
import { CardSettingsModal } from 'features/modals/card-settings-modal'
import { CardtTracking } from 'features/modals/card-tracking'
import { CardViewDetails } from 'features/modals/card-view-details'
import { FancyPhysicalCard } from 'features/modals/fancy-physical-card'
import { TopUpCard } from 'features/modals/top-up-card'
import { CardService } from 'wip/services'
import { Currencies } from 'wip/stores'
import { $isMobile } from 'model'
import { $cardsBalance, $cardsData, $physicalCardRequested, getCardsDataFx } from 'model/cefi-banking'

import { $tierLevel } from '../../model/cefi-stacking'
import styles from './styles.module.scss'
import CardBodySvg from './SVG/CardBodySvg'
import CardBodySvgMobile from './SVG/CardBodySvgMobile'
import lockIcon from './SVG/Lock.svg'
import settingIcon from './SVG/Setting.svg'
import showIcon from './SVG/Show.svg'
import unLockIcon from './SVG/UnLock.svg'

export function ManageCard() {
  const cardsData = useStore($cardsData)
  const physicalCardRequested = useStore($physicalCardRequested)
  const tierLevel = useStore($tierLevel)
  const isMobile = useStore($isMobile)
  const cardsBalance = useStore($cardsBalance)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isVirtual, setIsVirtual] = useState(true)
  const [flipped, setFlipped] = useState(false)

  const cardData = cardsData.find(card => card.type === (isVirtual ? 'VIRTUAL' : 'CHIP_AND_PIN'))
  // const hasPhysicalCard = cardsData.find(card => card.type === 'CHIP_AND_PIN')
  const hasActiveCard = cardsData.find(card => card.status === 'ACTIVE')
  const cardUuid = cardData?.cardUuid || ''
  const isCardBlocked = cardData?.status === 'BLOCKED'

  const goToTopUpCard = () => {
    Modal.open(<TopUpCard />, { title: pages.EARN.name, isFullScreen: true })
  }

  const handleBlockCard = async (): Promise<void> => {
    if (cardUuid) {
      const blockService = isCardBlocked ? CardService.unBlockCard : CardService.blockCard
      setIsLoading(true)
      try {
        await blockService(cardUuid)
        await getCardsDataFx()
      } catch (e) {
        console.log('ERROR-handleBlockCard', e)
      }
      setIsLoading(false)
    }
  }

  const handleActivateCard = async (): Promise<void> => {
    setIsLoading(true)
    try {
      if (cardUuid) {
        await CardService.activateCard(cardUuid)
        await getCardsDataFx()
      }
    } catch (e) {
      console.log('ERROR-handleActivateCard', e)
    }
    setIsLoading(false)
  }

  const handleTracking = () => {
    Modal.open(<CardtTracking cardData={cardData} />, {
      isFullScreen: true,
    })
  }

  const handleChangeCard = (): void => {
    setIsVirtual(!isVirtual)
    // if (hasPhysicalCard) setIsVirtual(!isVirtual)
    //
    // if (!hasPhysicalCard && !physicalCardRequested) {
    //   Modal.open(<FancyPhysicalCard />, { title: pages.PORTFOLIO.name, isFullScreen: true })
    // }
    setFlipped(!flipped)
    setTimeout(() => {
      setFlipped(false)
    }, 500)
  }

  const isShowActivateCard = (): boolean => {
    return cardData?.status === 'DISPATCHED'
  }

  const isBlockIconBtn = (): boolean => {
    if (cardData?.blockType === 'BLOCKED_BY_CARDHOLDER') return false
    return cardData?.status !== 'ACTIVE'
  }

  const goToEarn = () => {
    navigate(pages.EARN.path)
  }

  const getPhysicalCard = () => {
    Modal.open(<FancyPhysicalCard />, { title: pages.PORTFOLIO.name, isFullScreen: true })
  }

  return (
    <div className={styles.manageCardWrap}>
      <div className={styles.manageCardHeader}>
        <div className={styles.manageCardTitle}>Manage Card</div>
        <div className={styles.manageCardSwitchBlock}>
          <div
            onClick={handleChangeCard}
            className={clsx(styles.manageCardBtn, !isVirtual ? styles.manageCardBtnActive : '')}
          >
            Physical
          </div>
          <div
            onClick={handleChangeCard}
            className={clsx(styles.manageCardBtn, isVirtual ? styles.manageCardBtnActive : '')}
          >
            Virtual
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      {cardData ? (
        <div className={styles.cardBody}>
          <div
            style={{
              position: 'relative',
              filter: isCardBlocked ? 'blur(2px)' : '',
              transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
              transition: '0.6s',
              margin: '0 auto',
            }}
          >
            {isMobile ? (
              <CardBodySvgMobile fill={isCardBlocked ? 'var(--mainBlue)' : '#000'} />
            ) : (
              <CardBodySvg fill={isCardBlocked ? 'var(--mainBlue)' : '#000'} />
            )}
            <div className={styles.cardNumber}>
              <p className={styles.cardName}>
                {cardData?.embossingName
                  ? cardData.embossingName.split(' ')[0] + ' ' + cardData.embossingName.split(' ')[1][0]
                  : ''}
              </p>
              {cardData?.maskedCardNumber || '--'}
            </div>
          </div>
          <div className={styles.balanceCardWrap}>
            <div style={{ flexGrow: 1 }} />
            <div className={styles.balanceCardTitle}>Card Balance</div>
            <div className={styles.balanceCardAmount}>
              {Currencies.EUR}
              {cardsBalance || ''}
            </div>
            <div className={styles.btnGroup}>
              <button
                disabled={!hasActiveCard}
                onClick={() => goToTopUpCard()}
                className={clsx('btn btn-primary', styles.topUpBtn)}
                style={{
                  ...(isShowActivateCard()
                    ? { backgroundColor: '#f5f4fa', color: '#9C88FD', border: '1px solid #9C88FD' }
                    : {}),
                  ...(hasActiveCard ? {} : { opacity: 0.2 }),
                }}
              >
                <div className={styles.btnTitle}>Top Up</div>
              </button>
              {isShowActivateCard() ? (
                <button onClick={() => handleActivateCard()} className={clsx('btn btn-primary', styles.topUpBtn)}>
                  {isLoading ? (
                    <span className='spinner-border' />
                  ) : (
                    <div className={styles.btnTitle}>Activate Card</div>
                  )}
                </button>
              ) : null}
            </div>
            {cardData?.status !== 'ACTIVE' ? (
              <div className={styles.trackingLinkText} onClick={handleTracking}>
                Track your card
              </div>
            ) : (
              <div style={{ flexGrow: 1 }} />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.cardBody} style={{ background: 'inherit' }}>
          <div
            style={{
              position: 'relative',
              filter: isCardBlocked ? 'blur(2px)' : '',
              transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
              transition: '0.6s',
              margin: '0 auto',
            }}
          >
            {isMobile ? (
              <CardBodySvgMobile fill={isCardBlocked ? 'var(--mainBlue)' : '#000'} />
            ) : (
              <CardBodySvg fill={isCardBlocked ? 'var(--mainBlue)' : '#000'} />
            )}
          </div>
        </div>
      )}

      {cardData ? (
        <div className={styles.iconsWrap} style={isCardBlocked ? { justifyContent: 'center' } : {}}>
          <div style={isBlockIconBtn() ? { opacity: 0.2 } : {}}>
            <div
              onClick={() => (!isBlockIconBtn() ? handleBlockCard() : null)}
              className={styles.iconWrap}
              style={isCardBlocked ? { backgroundColor: 'var(--mainBlue)' } : {}}
            >
              {isLoading ? <MainLoader /> : <img src={isCardBlocked ? unLockIcon : lockIcon} alt='' />}
            </div>
            <div className={styles.iconTitle}>{isCardBlocked ? 'Unfreeze' : 'Freeze'}</div>
          </div>

          {isCardBlocked ? null : (
            <div style={isBlockIconBtn() ? { opacity: 0.2 } : {}}>
              <div
                onClick={() =>
                  !isBlockIconBtn()
                    ? Modal.open(<CardViewDetails cardUuid={cardUuid} />, {
                        title: pages.PORTFOLIO.name,
                        isFullScreen: true,
                      })
                    : null
                }
                className={styles.iconWrap}
              >
                <img src={showIcon} alt='' />
              </div>
              <div className={styles.iconTitle}>View Details</div>
            </div>
          )}

          {isCardBlocked ? null : (
            <div style={isBlockIconBtn() ? { opacity: 0.2 } : {}}>
              <div
                onClick={() =>
                  !isBlockIconBtn()
                    ? Modal.open(<CardSettingsModal cardUuid={cardUuid} />, {
                        title: pages.PORTFOLIO.name,
                        isFullScreen: true,
                      })
                    : null
                }
                className={styles.iconWrap}
              >
                <img src={settingIcon} alt='' />
              </div>
              <div className={styles.iconTitle}>Settings</div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={styles.iconsWrap}
          style={{
            alignItems: 'center',
            flexDirection: 'column',
            margin: '0 auto 46px',
          }}
        >
          {tierLevel < 2 ? (
            <>
              <div className={styles.availableTier}>Available from Tier 2</div>
              <div style={{ height: 18 }} />
              <div className={styles.getCardTitle}>Physical Card</div>
              <div style={{ height: 8 }} />
              <div className={styles.getCardDescription}>
                You can start using the virtual card right away, before your physical one arrives.
              </div>
              <div style={{ height: 28 }} />
              <div onClick={goToEarn} className={styles.goUpgradeText}>
                Go to “Earn” to upgrade to Tier 2
              </div>
            </>
          ) : (
            <>
              <div className={styles.getCardTitle}>Get Physical Card</div>
              <div style={{ height: 8 }} />
              <div className={styles.getCardDescription}>
                You can start using the virtual card right away, before your physical one arrives.
              </div>
              <div style={{ height: 31 }} />
              <button onClick={getPhysicalCard} className='btn btn-primary'>
                Get Physical Card
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}
