import moment from 'moment'
import clsx from 'clsx'

import { Modal } from 'components'
import { TransactionHistoryDetailModal } from 'features/modals/transaction-history-detail'
import { addCommasToDisplayValue } from 'utils/add-commas-to-display-value'
import { CryptoAndFiatHistoryType } from 'model/cefi-transactions-history'

import { TYPE_TXN_HISTORY } from './constant-type-transaction-history'
import styles from './styles.module.scss'

interface GroupedData {
  [key: string]: CryptoAndFiatHistoryType[]
}

type Section = {
  title: string
  data: CryptoAndFiatHistoryType[]
}

type RenderItemType = {
  item: CryptoAndFiatHistoryType
}

const processAndGroupData = (data: CryptoAndFiatHistoryType[], currentMonth: string) => {
  if (!data.length) return []

  const transactionType: string = data[0].transactionType

  const sections: Section[] = []

  const currentMonthData: CryptoAndFiatHistoryType[] /* CryptoAndFiatHistoryType[] */ = []
  const otherGroupedData: GroupedData = {}

  if ([TYPE_TXN_HISTORY.CRYPTO, TYPE_TXN_HISTORY.FIAT].includes(transactionType)) {
    data.forEach(item => {
      const itemDate = moment(item.time || item.rewardTime || item.transactionTime)
      const monthYear = itemDate.format('MMMM YYYY')

      if (monthYear === currentMonth) {
        currentMonthData.push(item)
      } else {
        if (!otherGroupedData[monthYear]) {
          otherGroupedData[monthYear] = []
        }
        otherGroupedData[monthYear].push(item)
      }
    })

    currentMonthData.sort((a, b) =>
      moment(b.time || b.rewardTime || b.transactionTime).diff(moment(a.time || a.rewardTime || a.transactionTime))
    )

    if (currentMonthData.length > 0) {
      const groupedByDay = {} as GroupedData

      currentMonthData.forEach(item => {
        const day = moment(item?.time || item?.rewardTime || item?.transactionTime).format('DD MMMM')
        if (!groupedByDay[day]) {
          groupedByDay[day] = []
        }
        groupedByDay[day].push(item)
      })

      Object.keys(groupedByDay).forEach(day => {
        sections.push({
          title: day,
          data: groupedByDay[day],
        })
      })
    }

    const sortedOtherGroupedData: GroupedData = {}
    Object.keys(otherGroupedData)
      .sort((a, b) => moment(b, 'MMMM YYYY').diff(moment(a, 'MMMM YYYY')))
      .forEach(key => {
        sortedOtherGroupedData[key] = otherGroupedData[key].sort((a, b) =>
          moment(b.time || b.rewardTime || b.transactionTime).diff(moment(a.time || a.rewardTime || a.transactionTime))
        )
      })

    Object.keys(sortedOtherGroupedData).forEach(monthYear => {
      sections.push({
        title: monthYear?.split(' ')[0],
        data: sortedOtherGroupedData[monthYear],
      })
    })

    return sections
  } else if ([TYPE_TXN_HISTORY.CASHBACK].includes(transactionType)) {
    data.forEach(item => {
      const itemDate = moment(item.transactionDate)
      const monthYear = itemDate.format('MMMM YYYY')

      if (monthYear === currentMonth) {
        currentMonthData.push(item)
      } else {
        if (!otherGroupedData[monthYear]) {
          otherGroupedData[monthYear] = []
        }
        otherGroupedData[monthYear].push(item)
      }
    })

    currentMonthData.sort((a, b) => moment(b.transactionDate).diff(moment(a.transactionDate)))

    if (currentMonthData.length > 0) {
      const groupedByDay = {} as GroupedData

      currentMonthData.forEach(item => {
        const day = moment(item?.transactionDate).format('DD MMMM')
        if (!groupedByDay[day]) {
          groupedByDay[day] = []
        }
        groupedByDay[day].push(item)
      })

      Object.keys(groupedByDay).forEach(day => {
        sections.push({
          title: day,
          data: groupedByDay[day],
        })
      })
    }

    const sortedOtherGroupedData: GroupedData = {}
    Object.keys(otherGroupedData)
      .sort((a, b) => moment(b, 'MMMM YYYY').diff(moment(a, 'MMMM YYYY')))
      .forEach(key => {
        sortedOtherGroupedData[key] = otherGroupedData[key].sort((a, b) =>
          moment(b.transactionDate).diff(moment(a.transactionDate))
        )
      })

    Object.keys(sortedOtherGroupedData).forEach(monthYear => {
      sections.push({
        title: monthYear?.split(' ')[0],
        data: sortedOtherGroupedData[monthYear],
      })
    })

    return sections
  } else {
    return []
  }
}

type Props = {
  data: any[] /*  CryptoAndFiatHistoryType[] */
}

export const GroupedSectionList = ({ data }: Props) => {
  const currentMonth = moment().format('MMMM YYYY')

  const handelSelectRow = (rowValue: any /* CryptoAndFiatHistoryType */) => {
    Modal.open(<TransactionHistoryDetailModal data={rowValue} />, { title: '', isFullScreen: true })
  }

  //TODO разобраться с типами
  const renderFormatAmount = (item: any | undefined) => {
    if (!item) return <div className={styles.listTextAmount}>no data</div>

    return (
      <>
        {[TYPE_TXN_HISTORY.CRYPTO, TYPE_TXN_HISTORY.FIAT].includes(item.transactionType) ? (
          <>
            <div
              className={clsx(
                styles.listTextAmount,
                ['Earning Reward', 'Deposit', 'Top Up', 'Cashback'].includes(item.title || '')
                  ? styles.listGreenColor
                  : '',
                item.status === 'PENDING' ? styles.listPendingStyle : ''
              )}
            >
              {item.title === 'Withdrawal' ? `-${addCommasToDisplayValue(item.amount || '', 3)} ${item.assetId}` : ''}
              {item.title === 'Exchange' ? `+${addCommasToDisplayValue(item.toAmount || '', 3)} ${item.toAssetId}` : ''}
              {item.title === 'Earning Reward'
                ? `+${addCommasToDisplayValue(item.amount || '', 3)} ${item.assetId}`
                : ''}
              {item.title === 'Earning Claimed'
                ? `+${addCommasToDisplayValue(item.amount || '', 3)} ${item.assetId}`
                : ''}
              {item.title === 'Cashback' ? `+${addCommasToDisplayValue(item.amount || '', 3)} ${item.assetId}` : ''}
              {item.title === 'Campaign Reward'
                ? `+${addCommasToDisplayValue(item.amount || '', 3)} ${item.assetId}`
                : ''}
              {item.title === 'Deposit' ? `+${addCommasToDisplayValue(item.amount || '', 3)} ${item.assetId}` : ''}
              {item.title === 'Top Up' ? `+${addCommasToDisplayValue(item.amount || '', 3)} ${item.assetId}` : ''}
            </div>
            {item.status === 'FAILED' ? <div className={styles.listStrikethrough} /> : null}
          </>
        ) : null}

        {item.transactionType === TYPE_TXN_HISTORY.CARD ? (
          <div
            className={clsx(
              styles.listTextAmount,
              +item?.transactionAmount > 0 ? styles.listGreenColor : '',
              item.status === 'PENDING' ? styles.listPendingStyle : ''
            )}
          >
            {+item?.transactionAmount > 0 ? '+' : ''}
            {addCommasToDisplayValue(item.transactionAmount || '', 3)} {item.transactionCurrencyCode}
            {item.status === 'CANCELED' ? <div className={styles.listStrikethrough} /> : null}
          </div>
        ) : null}
      </>
    )
  }

  const renderItem = ({ item }: RenderItemType) => {
    return (
      <>
        {[TYPE_TXN_HISTORY.CRYPTO, TYPE_TXN_HISTORY.FIAT].includes(item.transactionType) ? (
          <div className={styles.listRow} onClick={() => handelSelectRow(item)}>
            <img className={styles.listIcon} alt='icon' src={item.icon} />
            <div className={styles.listRowTitleWrap}>
              <div className={styles.listRowTitle}>
                {item.title} {item.title === 'Exchange' ? `${item.fromAssetId} to ${item.toAssetId}` : ''}
              </div>
              <div className={styles.listTextDate}>
                {moment(item.time || item.rewardTime || item.transactionTime).format('MMMM DD, YYYY')}
              </div>
            </div>
            <div className={styles.flexGrow1} />
            <div className={styles.listTextAmountWrap}>
              <div className={styles.positionRelative}>{renderFormatAmount(item)}</div>
              {item.status ? (
                <div className={styles.listTextStatus}>
                  {item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {[TYPE_TXN_HISTORY.CASHBACK].includes(item.transactionType) ? (
          <div className={styles.listRow} onClick={() => handelSelectRow(item)}>
            <img className={styles.listIcon} alt='icon' src={item.icon} />
            <div className={styles.listRowTitleWrap}>
              <div className={styles.listRowTitle}>{item.title}</div>
              <div className={styles.listTextDate}>{moment(item.rewardTime).format('MMMM DD, YYYY hh:mm')}</div>
            </div>
            <div className={styles.flexGrow1} />
            <div className={styles.listTextAmountWrap}>
              <div className={styles.positionRelative}>
                <div className={clsx(styles.listTextAmount, styles.textAlignEnd)}>
                  +{addCommasToDisplayValue(item.amount || '', 3)} {item.assetId}
                </div>
              </div>

              <div className={styles.listTextStatus}>
                {addCommasToDisplayValue(
                  (item?.amount && item.exchangeRate ? +item.amount / +item.exchangeRate : '').toString(),
                  2
                )}{' '}
                EUR (1 EUR = {addCommasToDisplayValue(item.exchangeRate || '', 2)} {item.assetId})
              </div>
            </div>
          </div>
        ) : null}
      </>
    )
  }

  const renderSectionHeader = ({ section: { title } }: { section: { title: string } }) => (
    <div className={styles.listSectionTitle}>{title}</div>
  )

  const sections = processAndGroupData(data, currentMonth)

  return (
    <div className={styles.listContainer}>
      {sections.map((section, sectionIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={sectionIndex} className={styles.listSection}>
          {renderSectionHeader({ section })}
          {section.data.map((item, itemIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={itemIndex}>{renderItem({ item })}</div>
          ))}
        </div>
      ))}
    </div>
  )
}
