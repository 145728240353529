import { createEffect, createStore } from 'effector'

import { BasicCardInfo, CardService, OrderStatus } from 'wip/services'

export const $cardStatus = createStore<OrderStatus>({
  currentStep: 'NONE',
  nextStep: '',
  additionalInfo: {
    kyc: 'FAILED',
  },
})
export const getCardStatusFx = createEffect(async () => {
  try {
    return await CardService.getOrderStatus()
  } catch (error) {
    console.log('ERROR-getCardStatusFx', error)
  }
})
$cardStatus.on(getCardStatusFx.doneData, (_, repos) => repos)

//
export const $cardsData = createStore<BasicCardInfo[]>([])
export const getCardsDataFx = createEffect(async () => {
  try {
    return await CardService.getAllActiveCards()
  } catch (error) {
    console.log('ERROR-getAllActiveCards', error)
  }
})
$cardsData.on(getCardsDataFx.doneData, (_, repos) => repos)

//
export const $cardsBalance = createStore<string>('')
export const getCardsBalanceFx = createEffect(async () => {
  try {
    const cardsBalance = await CardService.getCardBalance()
    return cardsBalance.amount
  } catch (error) {
    console.log('ERROR-getCardBalance', error)
  }
})
$cardsBalance.on(getCardsBalanceFx.doneData, (_, repos) => repos)

export const $physicalCardRequested = createStore<boolean>(false)

export const getPhysicalCardRequestedFx = createEffect(async () => {
  try {
    const data = await CardService.getPhysicalCardRequested()
    return data.value
  } catch (error) {
    console.log('ERROR-getPhysicalCardRequestedFx', error)
    return false
  }
})

$physicalCardRequested
  .on(getPhysicalCardRequestedFx.doneData, (_, repos) => repos)
  .on(getPhysicalCardRequestedFx.failData, () => false)
