import { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { EVENT_NAMES, useAnalytics } from 'wip/services'

import { $assetsCefiExchangeRates } from '../../../model/cef-rates-exchange'
import { CombainedObject } from '../../../model/cefi-combain-assets-data'
import { Buy } from './buy'
import { Exchange } from './exchange'
import { Sell } from './sell'
import styles from './styles.module.scss'

const navItems = [{ name: 'Exchange' }, { name: 'Buy' }, { name: 'Sell' }]

export interface TradeAssetModal {
  asset: CombainedObject
}

export function TradeAssetModal({ asset, ...props }: TradeAssetModal) {
  const { myLogEvent } = useAnalytics()
  const ratesCeFi = useStore($assetsCefiExchangeRates)

  const eurAssetRate = ratesCeFi.find(rate => rate.fromAssetId === 'EUR' && rate.toAssetId === asset?.assetId)
  const assetRateToEUR = ratesCeFi.find(
    assetRaw => assetRaw.toAssetId === 'EUR' && assetRaw.fromAssetId === asset?.assetId
  )
  // TODO: off exchange list ??
  const assetRateToAny = ratesCeFi.filter(assetRaw => {
    return assetRaw.toAssetId !== 'EUR' && assetRaw.fromAssetId === asset?.assetId
  })

  const [activePanel, setActivePanel] = useState('Exchange')

  useEffect(() => {
    myLogEvent(EVENT_NAMES.WEB_TRADE_OPENED, { asset: asset?.assetId })
  }, [])

  return (
    <div className={styles.main}>
      <div className={styles.navBlock}>
        {navItems.map(navItem => {
          if (!eurAssetRate && navItem.name === 'Buy') return null
          if (!assetRateToEUR && navItem.name === 'Sell') return null
          return (
            <div
              key={navItem.name}
              onClick={() => setActivePanel(navItem.name)}
              className={clsx(styles.navItem, activePanel === navItem.name ? styles.navItemActive : '')}
            >
              {navItem.name}
            </div>
          )
        })}
      </div>
      <div className={styles.formWrap}>
        {activePanel === 'Exchange' && <Exchange asset={asset} />}
        {activePanel === 'Buy' && <Buy asset={asset} />}
        {activePanel === 'Sell' && <Sell asset={asset} />}
      </div>
    </div>
  )
}
