import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { pages } from 'constant'
import { SoonIcon } from 'icons'
import { $isMobileNavOpen, setIsMobileNavOpenEV } from 'model'
import { resetStoresEv } from 'model/auth-logout'

import styles from './styles.module.scss'

type Props = {
  path: string
  title: string
  Icon: React.FC<{ fill: string }>
  isSoon?: boolean
}

export function NavItem({ path, title, Icon, isSoon }: Props) {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobileNavOpen = useStore($isMobileNavOpen)

  return (
    <div
      onClick={() => {
        if (isSoon) {
          console.log('isSoon')
        } else if (title === pages.LOGOUT.name) {
          resetStoresEv()
          navigate(path)
        } else {
          navigate(path)
        }
        setIsMobileNavOpenEV(!isMobileNavOpen)
      }}
      className={clsx(styles.linkItem, location.pathname === path ? styles.active : '')}
    >
      <div className={styles.hideMobile} style={isSoon ? { opacity: 0.2 } : {}}>
        <Icon fill={location.pathname === path ? 'white' : 'var(--iconBackground)'} />
      </div>

      <p
        className={styles.title}
        style={{ color: location.pathname === path ? 'white' : '#445374', opacity: isSoon ? 0.2 : 1 }}
      >
        {title}
      </p>
      {isSoon && <SoonIcon className={styles.soonImg} />}
    </div>
  )
}
