import { HeaderTitle } from 'components'

import { ChangePassword } from './change-password'
import { Currency } from './currency-new'
import DeleteAccount from './delete-account'
import Referral from './referral-new'
import styles from './styles.module.scss'
import { TwoFactorAuthentication } from './two-factor-authentication'
import { UserEmail } from './user-email'
import { UserPhone } from './user-phone'
import Version from './version-new'

const Settings = () => {
  return (
    <div className={styles.settingsWrap}>
      <HeaderTitle hideUd headerTitle='Settings' />
      <div className={styles.settingsItemsWrap}>
        <Currency />
        <TwoFactorAuthentication />
        <ChangePassword />
        <Referral />
        {/* <FAQ /> */}
        {/* eslint-disable-next-line react/jsx-no-undef */}
        <UserEmail />
        <UserPhone />
        <DeleteAccount />
        <Version />
      </div>
    </div>
  )
}

export default Settings
