import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import snsWebSdk from '@sumsub/websdk'

import { AuthSnackbar, BackButton } from 'components'
import I18n from 'components/i18n'
import { pages } from 'constant'
import { errorDescriptionHandler, getToken, parseJwt } from 'utils'
import { AuthServiceV4 } from 'wip/services'

import { snackComponentChangedEv } from '../../../model/snackComponent'
import { getRefreshToken, saveRefreshToken, saveToken } from '../../../utils/local-storage'
import AuthLayout from '../auth-layout'
import AuthTitle from '../auth-title'
import styles from '../styles.module.scss'

export function KYC() {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [hideBtn, setHideBtn] = useState(false)

  useEffect(() => {
    const token = getToken()
    if (!token) navigate(pages.SignIn.path)
    const parsedToken = parseJwt(token)
    const scope = parsedToken?.scope
    // user?.status === 'KYCConfirmed'
    if (scope?.includes('KYC')) navigate(pages.Base.path)
    setHideBtn(false)
  }, [location.pathname])

  const getNewAccessToken = async (): Promise<string> => {
    try {
      const tokenResponse = await AuthServiceV4.getSumSubToken()
      return tokenResponse.token
    } catch (error: any) {
      console.log('ERROR-getNewAccessToken', error)
      snackComponentChangedEv(<AuthSnackbar title='ERROR' description={errorDescriptionHandler(error.code || '')} />)
    }
    return ''
  }

  const launchWebSdk = (accessToken: string): void => {
    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => getNewAccessToken())
      .withConf({
        lang: 'en',
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.onApplicantStatusChanged', payload => {
        // @ts-ignore
        if (payload?.reviewStatus === 'completed' && payload?.reviewResult?.reviewAnswer !== 'RED') {
          const rToken = getRefreshToken() || ''
          if (rToken) {
            AuthServiceV4.newTokensPair(rToken).then(response => {
              saveToken(response.accessToken)
              saveRefreshToken(response.refreshToken)
              navigate(pages.Base.path)
            })
          }
        }
      })
      .build()

    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }

  const onSubmit = async (): Promise<void> => {
    setLoading(true)

    try {
      const result = await AuthServiceV4.getSumSubToken()
      setHideBtn(true)
      launchWebSdk(result.token)
    } catch (error: any) {
      console.log('ERROR-KYCScreen', error)
      // TODO: title ?
      snackComponentChangedEv(<AuthSnackbar title='ERROR' description={errorDescriptionHandler(error.code || '')} />)
    }

    setLoading(false)
  }

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackButton backFn={() => navigate(pages.SignIn.path)} />

        {!hideBtn && (
          <div className={clsx(styles.formWrap)}>
            <AuthTitle title='kyc.title' description='kyc.description' />

            <button onClick={onSubmit} className='btn btn-primary' disabled={loading}>
              {loading ? <span className='spinner-border' /> : <I18n tKey='kyc.identity' />}
            </button>
          </div>
        )}

        <div className={styles.sumsub} id='sumsub-websdk-container' />
      </div>
    </AuthLayout>
  )
}
