import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { AuthSnackbar, BackButton, HintPasswordText } from 'components'
import I18n from 'components/i18n'
import i18n from 'components/i18n/localize'
import { pages, regex } from 'constant'
import AuthLayout from 'features/auth-new/auth-layout'
import AuthTitle from 'features/auth-new/auth-title'
import { errorDescriptionHandler } from 'utils'
import { AuthServiceV4, useAnalytics } from 'wip/services'
import { termsOfUseLink } from 'config'
import eye from 'assets/icons/eye.svg'
import eyeOff from 'assets/icons/eye-off.svg'

import { snackComponentChangedEv } from '../../../model/snackComponent'
import styles from '../styles.module.scss'

type Inputs = {
  email: string
  password: string
  repeatPassword: string
  checkBox: boolean
  referral: string
}

const defaultValues = {
  email: '',
  password: '',
  repeatPassword: '',
  checkBox: false,
  referral: '',
}

export function SignUp() {
  const { myLogEvent } = useAnalytics()
  const navigate = useNavigate()

  const { t } = i18n
  const methods = useForm<Inputs>({ defaultValues, mode: 'onChange' })
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    setValue,
  } = methods

  const [loading, setLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passwordIsFocus, setPasswordIsFocus] = useState(false)

  const hints = [
    t('inputError.password.min'),
    t('inputError.password.uppercase'),
    t('inputError.password.lowercase'),
    t('inputError.password.number'),
    t('inputError.password.sign'),
  ]

  const toggleShowPassword = (): void => {
    setShowPassword(prevState => !prevState)
  }

  const toggleShowConfirmPassword = (): void => {
    setShowConfirmPassword(prevState => !prevState)
  }

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true)

    try {
      const response = await AuthServiceV4.emailSignUp({
        password: data.password,
        email: data.email,
        referral: (data.referral || '').trim(),
      })
      console.log('res2', response)

      if (response.nextStep === 'VERIFY_EMAIL') {
        navigate(pages.ConfirmationCode.path, {
          state: {
            step: response.nextStep,
            sessionToken: response.sessionToken,
            email: data.email,
          },
        })
      }

      // const res = await AuthService.signIn({
      //   passwordHash: hash(data.password),
      //   username: data.email.trim(),
      //   deviceType: 'web',
      //   appVersion: env.npm_package_version ?? '',
      // })

      // saveToken(res.token)
      // twoFaChangedEv(res['2fa'])

      // myLogEvent(EVENT_NAMES.WEB_SIGN_UP_SUCCESS)

      // navigate(pages.ConfirmationCode.path, {
      //   state: { email: data.email.trim(), passwordHash: hash(data.password) },
      // })
    } catch (error: any) {
      console.log('ERROR-signIn', error)
      // TODO: title ?
      snackComponentChangedEv(
        <AuthSnackbar title='Email Incorrect.' description={errorDescriptionHandler(error.code || '')} />
      )
    }
    setLoading(false)
  }

  const isSamePassword = (repeatPassword: string): boolean => {
    const password = getValues('password')
    return repeatPassword === password
  }

  const passwordHintsClassName = (): string => {
    if (passwordIsFocus && errors.password) {
      return clsx(styles.hintPassword, errors.password?.message ? styles.fadeIn : styles.fadeOut)
    }
    return clsx(styles.hintPassword, styles.hidePasswordHint)
  }

  const validateHintPassword = (data: string): boolean | string => {
    const errorArray = []

    const regex8Characters = /^.{8,}$/
    const isMin8Characters = regex8Characters.test(data)
    if (!isMin8Characters) errorArray.push('Min 8 characters')

    const regexOneUppercase = /.*[A-Z].*/
    const isOneUppercase = regexOneUppercase.test(data)
    if (!isOneUppercase) errorArray.push('One Uppercase')

    const regexOneLowercase = /.*[a-z].*/
    const isOneLowercase = regexOneLowercase.test(data)
    if (!isOneLowercase) errorArray.push('One Lowercase')

    const regexOneNumber = /\d+/
    const isOneNumber = regexOneNumber.test(data)
    if (!isOneNumber) errorArray.push('One Number')

    const regexOneSign = /[\W_]/
    const isOneSign = regexOneSign.test(data)
    if (!isOneSign) errorArray.push('One Sign')

    const errorStrings = errorArray.join(',')

    return !errorArray.length ? true : errorStrings
  }

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackButton />

        <div className={styles.formWrap}>
          <AuthTitle title='signUp.title' description='signUp.description' />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='input-item-wrap'>
                <label htmlFor='email' className={`input-label ${errors.email ? 'text-error' : ''}`}>
                  {t('signIn.email')} {errors.email && errors.email.type === 'pattern' ? t('inputError.invalid') : ''}
                  {errors.email && errors.email.type === 'required' ? t('inputError.required') : ''}
                </label>
                <input
                  id='email'
                  type='text'
                  style={errors.email ? { outline: '1px solid red' } : {}}
                  className='input-form'
                  placeholder={t('signIn.email.placeholder') || ''}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: regex.email,
                      message: 'Invalid email address',
                    },
                    onChange(event) {
                      setValue('email', event.target.value?.toLowerCase()?.trim())
                    },
                  })}
                />
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='password' className={`input-label ${errors.password ? 'text-error' : ''}`}>
                  {t('signIn.password')}{' '}
                </label>
                <div className='input-password-wrap'>
                  <div className={passwordHintsClassName()}>
                    {hints.map(text => {
                      return <HintPasswordText key={text} text={text} errors={errors.password?.message || ''} />
                    })}
                  </div>
                  <input
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    className='input-form'
                    style={errors.password ? { border: '1px solid red' } : {}}
                    placeholder={t('signUp.password.placeholder') || ''}
                    onFocus={async () => {
                      setPasswordIsFocus(true)
                      await trigger('password')
                    }}
                    {...register('password', {
                      required: hints.join(''),
                      validate: validateHintPassword,
                      onBlur: () => setPasswordIsFocus(false),
                      onChange(event) {
                        setValue('password', event.target.value?.trim())
                      },
                    })}
                  />
                  <div onClick={toggleShowPassword}>
                    <img src={showPassword ? eyeOff : eye} alt='' className='icon-eye' />
                  </div>
                </div>
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='repeat-password' className={`input-label ${errors.repeatPassword ? 'text-error' : ''}`}>
                  {t('signUp.password.repeat')}{' '}
                  {errors.repeatPassword && errors.repeatPassword.type === 'required' ? t('inputError.required') : ''}
                  {errors.repeatPassword && errors.repeatPassword.type === 'validate'
                    ? t('inputError.samePassword')
                    : ''}
                </label>
                <div className='input-password-wrap'>
                  <input
                    id='repeat-password'
                    type={showConfirmPassword ? 'text' : 'password'}
                    className='input-form'
                    style={errors.repeatPassword ? { outline: '1px solid red' } : {}}
                    placeholder={t('signUp.password.placeholder.repeat') || ''}
                    {...register('repeatPassword', {
                      required: true,
                      min: 0,
                      validate: isSamePassword,
                      onChange(event) {
                        setValue('repeatPassword', event.target.value?.trim())
                      },
                    })}
                  />
                  <div onClick={toggleShowConfirmPassword}>
                    <img src={showConfirmPassword ? eyeOff : eye} alt='' className='icon-eye' />
                  </div>
                </div>
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='referral' className='input-label'>
                  <I18n tKey='signUp.referral' />
                </label>
                <input
                  id='referral'
                  type='text'
                  className='input-form'
                  placeholder={t('signUp.referral.placeholder') || ''}
                  {...register('referral', {
                    onChange(event) {
                      setValue('referral', event.target.value?.trim())
                    },
                  })}
                />
              </div>

              <label htmlFor='checkBox' className={clsx(styles.container)}>
                <I18n tKey='signUp.agree' />
                <input id='checkBox' type='checkbox' {...register('checkBox', { required: true })} />
                <span style={errors.checkBox ? { outline: '1px solid red' } : {}} className={styles.checkmark} />
                <NavLink to={termsOfUseLink()} target='_blank' className='input-label form-link'>
                  {t('signUp.termsConditions')}
                </NavLink>
              </label>

              <button type='submit' className='btn btn-primary' disabled={loading}>
                {loading ? <span className='spinner-border' /> : <I18n tKey='signUp.createAccount' />}
              </button>

              <div className='input-item-wrap text-under-button'>
                <p className='input-label text-center'>
                  {t('signUp.haveAccount')}
                  <NavLink to={pages.SignIn.path} className='input-label form-link'>
                    {t('signIn.title')}
                  </NavLink>
                </p>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  )
}
