import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'

import { AuthSnackbar, Modal } from 'components'
import I18n from 'components/i18n'
import i18n from 'components/i18n/localize'
import { pages, regex } from 'constant'
import { clearToken, errorDescriptionHandler, parseJwt, saveRefreshToken, saveToken } from 'utils'
import { clearRefreshToken } from 'utils/local-storage'
import { EVENT_NAMES, useAnalytics } from 'wip/services'
import { initApp } from 'wip/stores'
import { updateEarning } from 'wip/stores/init'
import { theme } from 'config'
import eye from 'assets/icons/eye.svg'
import eyeOff from 'assets/icons/eye-off.svg'

import { resetStoresEv } from '../../../model/auth-logout'
import { snackComponentChangedEv } from '../../../model/snackComponent'
import { AuthServiceV4 } from '../../../wip/services'
import AuthLayout from '../auth-layout'
import AuthTitle from '../auth-title'
import styles from '../styles.module.scss'

type Inputs = {
  email: string
  password: string
}

const defaultValues = {
  email: '',
  password: '',
}

export function SignIn() {
  const { myLogEvent } = useAnalytics()

  useEffect(() => {
    clearToken()
    clearRefreshToken()
    Modal.close()
    resetStoresEv()

    myLogEvent(EVENT_NAMES.WEB_SIGN_IN_FOCUSED)
  }, [])

  const { t } = i18n
  const navigate = useNavigate()
  const methods = useForm<Inputs>({ defaultValues })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const toggleShowPassword = (): void => {
    setShowPassword(prevState => !prevState)
  }

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true)

    try {
      const response = await AuthServiceV4.emailLogin({
        email: data.email,
        password: data.password,
      })

      if (['VERIFY_EMAIL', 'VERIFY_PHONE'].includes(response.nextStep)) {
        navigate(pages.ConfirmationCode.path, {
          state: {
            step: response.nextStep,
            sessionToken: response.sessionToken,
            email: data.email,
          },
        })
      }

      if (response.nextStep === 'MFA_VERIFY') {
        navigate(pages.TwoFactorAuthentication.path, {
          state: {
            sessionToken: response.sessionToken,
          },
        })
      }

      if (response.nextStep === null) {
        saveToken(response.accessToken)
        saveRefreshToken(response.refreshToken)

        if (!response?.accessToken) {
          navigate(pages.SignIn.path)
        }

        const parsedToken = parseJwt(response.accessToken)
        const scope = parsedToken?.scope

        await initApp()
        await updateEarning()

        // userSelf.status === UserStatus.KYCConfirmed
        if (scope && scope.length && scope.includes('KYC')) {
          navigate(pages.Base.path)
          // userSelf.status === UserStatus.Active
        } else if (scope && scope.length && !scope.includes('KYC')) {
          navigate(pages.KYC.path)
        }
      }
    } catch (error: any) {
      console.log('ERROR-signIn', error)
      snackComponentChangedEv(
        <AuthSnackbar title='Email or Password Incorrect.' description={errorDescriptionHandler(error.code || '')} />
      )
    }

    setLoading(false)
  }

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <div className={styles.formWrap}>
          <AuthTitle title='signIn.title' description='signIn.description' />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='input-item-wrap'>
                <label htmlFor='email' className={`input-label ${errors.email ? 'text-error' : ''}`}>
                  {t('signIn.email')} {errors.email && errors.email.type === 'pattern' ? t('inputError.invalid') : ''}
                  {errors.email && errors.email.type === 'required' ? t('inputError.required') : ''}
                </label>
                <input
                  id='email'
                  type='text'
                  className='input-form'
                  style={errors.email ? { border: '1px solid red' } : {}}
                  placeholder={t('signIn.email.placeholder') || ''}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: regex.email,
                      message: 'Invalid email address',
                    },
                    onChange(event) {
                      setValue('email', event.target.value?.toLowerCase()?.trim())
                    },
                  })}
                />
              </div>

              <div className='input-item-wrap'>
                <label htmlFor='password' className={`input-label ${errors.password ? 'text-error' : ''}`}>
                  {t('signIn.password')}{' '}
                  {errors.password && errors.password.type === 'required' ? t('inputError.required') : ''}
                </label>
                <div className='input-password-wrap'>
                  <input
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    className='input-form'
                    style={errors.password ? { border: '1px solid red' } : {}}
                    placeholder={t('signIn.password.placeholder') || ''}
                    {...register('password', {
                      required: true,
                      onChange(event) {
                        setValue('password', event.target.value?.trim())
                      },
                    })}
                  />
                  <div onClick={toggleShowPassword}>
                    <img src={showPassword ? eyeOff : eye} alt='' className='icon-eye' />
                  </div>
                </div>

                <NavLink to={pages.ForgotPassword.path} className='input-label form-link'>
                  <I18n tKey='signIn.forgotPassword' />
                </NavLink>
              </div>

              <button type='submit' className='btn btn-primary' disabled={loading}>
                {loading ? <span className='spinner-border' /> : <I18n tKey='signIn.title' />}
              </button>

              {theme === 'fideum' ? null : (
                <div className='input-item-wrap text-under-button'>
                  <p className='input-label text-center' style={{ display: 'inline' }}>
                    {t('signIn.notAccount')}
                    <NavLink to={pages.SignUp.path} className='input-label form-link'>
                      {t('signUp.title')}
                    </NavLink>
                  </p>
                </div>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  )
}
