export function LogoFiSvg(): JSX.Element {
  return (
    <svg width={163} height={163} fill='none'>
      <rect width={163} height={163} fill='url(#a)' rx={81.5} />
      <path
        fill='#9C88FD'
        d='M61.187 85.117h46.057c0 16.18-13.137 29.316-29.316 29.316H31.871c0-16.18 13.137-29.316 29.316-29.316ZM61.187 48.469h76.871c0 16.18-13.137 29.315-29.316 29.315h-76.87c0-16.179 13.136-29.315 29.315-29.315Z'
      />
      <defs>
        <linearGradient id='a' x1={218.274} x2={100.18} y1={225.953} y2={25.658} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#B0A4FF' />
          <stop offset={1} stopColor='#EBE8FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}
