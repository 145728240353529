import clsx from 'clsx'

import { FilterList } from './filter-list'
import styles from './styles.module.scss'

type Props = {
  data: string[]
  goBack: () => void
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  selectedItems: string[]
}

export const SearchAndList = ({ data, goBack, setSelectedItems, selectedItems }: Props) => {
  const handleDeselectAll = () => {
    setSelectedItems([])
  }

  const handleApply = () => {
    goBack()
  }

  const sortStringsAlphabetically = (arr: string[]): string[] => {
    return arr.sort((a, b) => a.localeCompare(b))
  }

  return (
    <>
      <FilterList
        data={sortStringsAlphabetically(data)}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />

      <div className={styles.flexGrow1} />

      <div className={styles.mainBtnGroup}>
        <button className={clsx('btn', 'btn-primary', styles.mainBtnClear)} onClick={handleDeselectAll}>
          Clear All
        </button>
        <button className={clsx('btn', 'btn-primary', styles.mainBtnSumbit)} onClick={handleApply}>
          Apply
        </button>
      </div>
    </>
  )
}
