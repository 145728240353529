import { env } from 'constant'
import fideumIco from 'assets/favicons/fideum64.png'
import kaizenIco from 'assets/favicons/kaizen64.png'
import bbank from 'assets/icons/bb-name.svg'
import bbankOnboardingLogo from 'assets/icons/bbankOnboardingLogo.svg'
import fideumLogo from 'assets/icons/fideumLogo.svg'
import fideumOnboardingLogo from 'assets/icons/fideumOnboardingLogo.svg'
import kaizenLogo from 'assets/icons/kaizenLogo.svg'
import kaizenOnboardingLogo from 'assets/icons/kaizenOnboardingLogo.svg'

// REACT_APP_CEFI_WHITE_LABEL=fideum  npx react-inject-env set
// REACT_APP_BACKEND_URL
// PROD is 'https://api2.blockbank.ai';   https://api.fideum.com/  https://api.kaizencx.com
// DEV is 'https://api.blockbank.dev'

// FIDEUM
// PROD https://api.fideum.com

// const API_BASE_URL = 'https://api.fideum.dev' // env.REACT_APP_BACKEND_URL ?? 'https://api2.blockbank.ai'
// const API_BASE_AUTH_URL = 'https://auth.fideum.dev'

// const API_BASE_URL = 'https://api-staging.fideum.dev' // STAGING
// const API_BASE_AUTH_URL = 'https://auth-staging.fideum.dev' // STAGING_AUTH
// const API_BASE_URL = env.REACT_APP_BACKEND_URL || 'https://api.fideum.group'
// const API_BASE_AUTH_URL = env.REACT_APP_BACKEND_AUTH_URL || 'https://auth.fideum.group'

const API_BASE_URL = env.REACT_APP_BACKEND_URL || 'https://api.fideum.group'
const API_BASE_AUTH_URL = env.REACT_APP_BACKEND_AUTH_URL || 'https://auth.fideum.group'

// TODO: index theme state for change UI
const theme = env.REACT_APP_CEFI_WHITE_LABEL ?? 'main'

const kycLevel = (): string => {
  if (theme === 'kaizen') {
    return 'KaizenCX'
  }
  return 'basic-kyc-level'
}

const favicon = (): string | null => {
  if (theme === 'fideum') {
    return fideumIco
  }
  if (theme === 'kaizen') {
    return kaizenIco
  }
  return fideumIco
}

const documentTitle = (): string => {
  if (theme === 'fideum') {
    return 'Fideum - Web App'
  }
  if (theme === 'kaizen') {
    return 'Kaizencx - Web App'
  }

  return 'Fideum - Web App'
}

const qrName = (): string => {
  if (theme === 'fideum') {
    return 'Fideum'
  }
  if (theme === 'kaizen') {
    return 'KaizenCX'
  }

  return 'Fideum'
}

const fideumMainColor = '#9C88FD'
const kaizenMainColor = '#003EF5'
const bbMainColor = '#9A95CE'

const themeLogo = (): string | undefined => {
  if (theme === 'fideum') {
    return fideumLogo
  }
  if (theme === 'kaizen') {
    return kaizenLogo
  }
  return bbank
}

const themeOnboardingLogo = (): string | undefined => {
  if (theme === 'fideum') {
    return fideumOnboardingLogo
  }
  if (theme === 'kaizen') {
    return kaizenOnboardingLogo
  }
  return bbankOnboardingLogo
}

const themeGlobalColor = (): string => {
  if (theme === 'fideum') {
    return fideumMainColor
  }
  if (theme === 'kaizen') {
    return kaizenMainColor
  }
  return fideumMainColor
}

const termsOfUseLink = (): string => {
  // @ts-ignore
  if (theme === 'kaizen') {
    return 'https://files.kaizencx.com/TERMS%20&%20CONDITIONS%20-%20KAIZENCX.pdf'
  }
  return 'https://blockbank.ai/terms-of-use/'
}

export {
  API_BASE_URL,
  API_BASE_AUTH_URL,
  documentTitle,
  favicon,
  qrName,
  theme,
  themeGlobalColor,
  themeLogo,
  themeOnboardingLogo,
  termsOfUseLink,
  kycLevel,
}
