import { useStore } from 'effector-react'
import clsx from 'clsx'

import { initApp } from 'wip/stores'

import { $currency, currencyChangedEv } from '../../model/currency'
import styles from './styles.module.scss'

export function Currency() {
  const currency = useStore($currency)

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div style={{ display: 'flex' }}>
          <div className={styles.settingsItemTitle}>Currency</div>
        </div>
        <div className={styles.settingsItemDescription}>
          You can easily switch your account currency easily. Just switch to the preferred currency
        </div>
        <div className={styles.currencySwitchBlock}>
          <div
            onClick={async () => {
              currencyChangedEv({
                symbol: '€',
                type: 'EUR',
              })
              await initApp()
            }}
            className={clsx(styles.currencyBtn, currency.type === 'EUR' ? styles.currencyBtnActive : '')}
          >
            EUR
          </div>
          <div
            onClick={async () => {
              currencyChangedEv({
                symbol: '$',
                type: 'USD',
              })
              await initApp()
            }}
            className={clsx(styles.currencyBtn, currency.type === 'USD' ? styles.currencyBtnActive : '')}
          >
            USD
          </div>
        </div>
      </div>
    </div>
  )
}
