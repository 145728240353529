import { useStore } from 'effector-react'

import { Modal } from 'components'
import { pages } from 'constant'
import { TwoFaSetup } from 'features/modals'
import { $twoFaStatus } from 'model/two-fa'

import styles from './styles.module.scss'

export function TwoFactorAuthentication() {
  const twoFaStatus = useStore($twoFaStatus)

  const handleTwoFa = () => {
    Modal.open(<TwoFaSetup />, {
      title: pages.SETTINGS.name,
      isFullScreen: true,
    })
  }

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>Two Factor Authentication</div>
          {!twoFaStatus && <div className={styles.recommended}>Recommended</div>}
        </div>

        <div className={styles.settingsItemDescription}>
          Protect your account by setting up Two Factor Authentication for your account.
        </div>

        {twoFaStatus ? (
          <div className={styles.activeBlock}>
            <div className={styles.activeBtn}>Active</div>
            <div onClick={() => handleTwoFa()} className={styles.deactivateBtn}>
              Turn Off 2FA
            </div>
          </div>
        ) : (
          <div onClick={() => handleTwoFa()} className={styles.twoFaBtn}>
            Setup Two Factor Authentication
          </div>
        )}
      </div>
    </div>
  )
}
