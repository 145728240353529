export const codeToGroupMapping: { [key: string]: string } = {
  '0742': '1',
  '0763': '2',
  '0780': '2',
  '1520': '2',
  '1711': '2',
  '1731': '2',
  '1740': '2',
  '1750': '2',
  '1761': '2',
  '1771': '2',
  '1799': '2',
  '2741': '2',
  '2791': '2',
  '2842': '2',
  '3000': '3',
  '3001': '3',
  '3002': '3',
  '3003': '3',
  '3004': '3',
  '3005': '3',
  '3006': '3',
  '3007': '3',
  '3008': '3',
  '3009': '3',
  '3010': '3',
  '3011': '3',
  '3012': '3',
  '3013': '3',
  '3014': '3',
  '3015': '3',
  '3016': '3',
  '3017': '3',
  '3018': '3',
  '3020': '3',
  '3021': '3',
  '3022': '3',
  '3023': '3',
  '3024': '3',
  '3025': '3',
  '3026': '3',
  '3027': '3',
  '3028': '3',
  '3029': '3',
  '3030': '3',
  '3031': '3',
  '3032': '3',
  '3033': '3',
  '3034': '3',
  '3035': '3',
  '3036': '3',
  '3037': '3',
  '3038': '3',
  '3039': '3',
  '3040': '3',
  '3041': '3',
  '3042': '3',
  '3043': '3',
  '3044': '3',
  '3045': '3',
  '3046': '3',
  '3047': '3',
  '3048': '3',
  '3049': '3',
  '3050': '3',
  '3051': '3',
  '3052': '3',
  '3053': '3',
  '3054': '3',
  '3055': '3',
  '3056': '3',
  '3057': '3',
  '3058': '3',
  '3059': '3',
  '3060': '3',
  '3061': '3',
  '3062': '3',
  '3063': '3',
  '3064': '3',
  '3065': '3',
  '3066': '3',
  '3068': '3',
  '3069': '3',
  '3070': '3',
  '3071': '3',
  '3072': '3',
  '3073': '3',
  '3075': '3',
  '3076': '3',
  '3077': '3',
  '3078': '3',
  '3079': '3',
  '3082': '3',
  '3083': '3',
  '3084': '3',
  '3085': '3',
  '3087': '3',
  '3089': '3',
  '3090': '3',
  '3094': '3',
  '3096': '3',
  '3097': '3',
  '3098': '3',
  '3099': '3',
  '3100': '3',
  '3102': '3',
  '3103': '3',
  '3105': '3',
  '3106': '3',
  '3111': '3',
  '3112': '3',
  '3117': '3',
  '3125': '3',
  '3127': '3',
  '3129': '3',
  '3130': '3',
  '3131': '3',
  '3132': '3',
  '3135': '3',
  '3136': '3',
  '3141': '3',
  '3144': '3',
  '3146': '3',
  '3148': '3',
  '3151': '3',
  '3156': '3',
  '3159': '3',
  '3161': '3',
  '3164': '3',
  '3167': '3',
  '3171': '3',
  '3172': '3',
  '3174': '3',
  '3175': '3',
  '3177': '3',
  '3178': '3',
  '3180': '3',
  '3181': '3',
  '3182': '3',
  '3183': '3',
  '3184': '3',
  '3185': '3',
  '3186': '3',
  '3187': '3',
  '3188': '3',
  '3190': '3',
  '3191': '3',
  '3193': '3',
  '3195': '3',
  '3196': '3',
  '3197': '3',
  '3198': '3',
  '3199': '3',
  '3200': '3',
  '3204': '3',
  '3206': '3',
  '3207': '3',
  '3211': '3',
  '3212': '3',
  '3213': '3',
  '3217': '3',
  '3219': '3',
  '3220': '3',
  '3221': '3',
  '3222': '3',
  '3223': '3',
  '3226': '3',
  '3228': '3',
  '3229': '3',
  '3231': '3',
  '3234': '3',
  '3236': '3',
  '3239': '3',
  '3240': '3',
  '3241': '3',
  '3242': '3',
  '3243': '3',
  '3245': '3',
  '3246': '3',
  '3247': '3',
  '3248': '3',
  '3252': '3',
  '3256': '3',
  '3260': '3',
  '3261': '3',
  '3263': '3',
  '3266': '3',
  '3267': '3',
  '3268': '3',
  '3275': '3',
  '3276': '3',
  '3277': '3',
  '3279': '3',
  '3280': '3',
  '3282': '3',
  '3285': '3',
  '3286': '3',
  '3287': '3',
  '3291': '3',
  '3292': '3',
  '3293': '3',
  '3294': '3',
  '3295': '3',
  '3296': '3',
  '3297': '3',
  '3298': '3',
  '3299': '3',
  '3351': '4',
  '3352': '4',
  '3353': '4',
  '3354': '4',
  '3355': '4',
  '3357': '4',
  '3359': '4',
  '3360': '4',
  '3361': '4',
  '3362': '4',
  '3364': '4',
  '3366': '4',
  '3368': '4',
  '3370': '4',
  '3374': '4',
  '3376': '4',
  '3380': '4',
  '3381': '4',
  '3385': '4',
  '3386': '4',
  '3387': '4',
  '3388': '4',
  '3389': '4',
  '3390': '4',
  '3391': '4',
  '3393': '4',
  '3394': '4',
  '3395': '4',
  '3396': '4',
  '3398': '4',
  '3400': '4',
  '3405': '4',
  '3409': '4',
  '3412': '4',
  '3420': '4',
  '3421': '4',
  '3423': '4',
  '3425': '4',
  '3427': '4',
  '3428': '4',
  '3429': '4',
  '3430': '4',
  '3431': '4',
  '3432': '4',
  '3433': '4',
  '3434': '4',
  '3435': '4',
  '3436': '4',
  '3438': '4',
  '3439': '4',
  '3441': '4',
  '3501': '5',
  '3502': '5',
  '3503': '5',
  '3504': '5',
  '3505': '5',
  '3506': '5',
  '3507': '5',
  '3508': '5',
  '3509': '5',
  '3510': '5',
  '3511': '5',
  '3512': '5',
  '3513': '5',
  '3514': '5',
  '3515': '5',
  '3516': '5',
  '3517': '5',
  '3518': '5',
  '3519': '5',
  '3520': '5',
  '3521': '5',
  '3522': '5',
  '3523': '5',
  '3524': '5',
  '3525': '5',
  '3526': '5',
  '3527': '5',
  '3528': '5',
  '3529': '5',
  '3530': '5',
  '3531': '5',
  '3532': '5',
  '3533': '5',
  '3534': '5',
  '3535': '5',
  '3536': '5',
  '3537': '5',
  '3538': '5',
  '3539': '5',
  '3540': '5',
  '3541': '5',
  '3542': '5',
  '3543': '5',
  '3544': '5',
  '3545': '5',
  '3546': '5',
  '3548': '5',
  '3549': '5',
  '3550': '5',
  '3551': '5',
  '3552': '5',
  '3553': '5',
  '3554': '5',
  '3555': '5',
  '3556': '5',
  '3557': '5',
  '3558': '5',
  '3559': '5',
  '3560': '5',
  '3561': '5',
  '3562': '5',
  '3563': '5',
  '3564': '5',
  '3565': '5',
  '3566': '5',
  '3567': '5',
  '3568': '5',
  '3569': '5',
  '3570': '5',
  '3571': '5',
  '3572': '5',
  '3573': '5',
  '3574': '5',
  '3575': '5',
  '3576': '5',
  '3577': '5',
  '3578': '5',
  '3579': '5',
  '3580': '5',
  '3581': '5',
  '3582': '5',
  '3583': '5',
  '3584': '5',
  '3585': '5',
  '3586': '5',
  '3587': '5',
  '3588': '5',
  '3589': '5',
  '3590': '5',
  '3591': '5',
  '3592': '5',
  '3593': '5',
  '3594': '5',
  '3595': '5',
  '3596': '5',
  '3597': '5',
  '3598': '5',
  '3599': '5',
  '3600': '5',
  '3601': '5',
  '3602': '5',
  '3603': '5',
  '3604': '5',
  '3605': '5',
  '3606': '5',
  '3607': '5',
  '3608': '5',
  '3609': '5',
  '3610': '5',
  '3611': '5',
  '3612': '5',
  '3613': '5',
  '3614': '5',
  '3615': '5',
  '3617': '5',
  '3618': '5',
  '3619': '5',
  '3620': '5',
  '3621': '5',
  '3622': '5',
  '3623': '5',
  '3624': '5',
  '3625': '5',
  '3626': '5',
  '3627': '5',
  '3628': '5',
  '3629': '5',
  '3630': '5',
  '3631': '5',
  '3632': '5',
  '3633': '5',
  '3634': '5',
  '3635': '5',
  '3636': '5',
  '3637': '5',
  '3638': '5',
  '3639': '5',
  '3640': '5',
  '3641': '5',
  '3642': '5',
  '3643': '5',
  '3644': '5',
  '3645': '5',
  '3646': '5',
  '3647': '5',
  '3648': '5',
  '3649': '5',
  '3650': '5',
  '3651': '5',
  '3652': '5',
  '3653': '5',
  '3654': '5',
  '3655': '5',
  '3656': '5',
  '3657': '5',
  '3658': '5',
  '3659': '5',
  '3660': '5',
  '3661': '5',
  '3662': '5',
  '3663': '5',
  '3664': '5',
  '3665': '5',
  '3666': '5',
  '3667': '5',
  '3668': '5',
  '3669': '5',
  '3670': '5',
  '3671': '5',
  '3672': '5',
  '3673': '5',
  '3674': '5',
  '3675': '5',
  '3676': '5',
  '3677': '5',
  '3678': '5',
  '3679': '5',
  '3680': '5',
  '3681': '5',
  '3682': '5',
  '3683': '5',
  '3684': '5',
  '3685': '5',
  '3686': '5',
  '3687': '5',
  '3688': '5',
  '3689': '5',
  '3690': '5',
  '3691': '5',
  '3692': '5',
  '3693': '5',
  '3694': '5',
  '3695': '5',
  '3696': '5',
  '3697': '5',
  '3698': '5',
  '3699': '5',
  '3700': '5',
  '3701': '5',
  '3702': '5',
  '3703': '5',
  '3704': '5',
  '3705': '5',
  '3706': '5',
  '3707': '5',
  '3708': '5',
  '3709': '5',
  '3710': '5',
  '3711': '5',
  '3712': '5',
  '3713': '5',
  '3714': '5',
  '3715': '5',
  '3716': '5',
  '3717': '5',
  '3718': '5',
  '3719': '5',
  '3720': '5',
  '3721': '5',
  '3722': '5',
  '3723': '5',
  '3724': '5',
  '3725': '5',
  '3726': '5',
  '3727': '5',
  '3728': '5',
  '3729': '5',
  '3730': '5',
  '3731': '5',
  '3732': '5',
  '3734': '5',
  '3735': '5',
  '3736': '5',
  '3737': '5',
  '3738': '5',
  '3739': '5',
  '3740': '5',
  '3741': '5',
  '3744': '5',
  '3745': '5',
  '3746': '5',
  '3747': '5',
  '3748': '5',
  '3749': '5',
  '3750': '5',
  '3751': '5',
  '3752': '5',
  '3753': '5',
  '3754': '5',
  '3755': '5',
  '3757': '5',
  '3758': '5',
  '3759': '5',
  '3760': '5',
  '3761': '5',
  '3762': '5',
  '3763': '5',
  '3764': '5',
  '3765': '5',
  '3766': '5',
  '3767': '5',
  '3768': '5',
  '3769': '5',
  '3770': '5',
  '3771': '5',
  '3772': '5',
  '3773': '5',
  '3774': '5',
  '3775': '5',
  '3776': '5',
  '3777': '5',
  '3778': '5',
  '3779': '5',
  '3780': '5',
  '3781': '5',
  '3782': '5',
  '3783': '5',
  '3784': '5',
  '3785': '5',
  '3786': '5',
  '3787': '5',
  '3788': '5',
  '3789': '5',
  '3790': '5',
  '3791': '5',
  '3792': '5',
  '3793': '5',
  '3794': '5',
  '3795': '5',
  '3796': '5',
  '3797': '5',
  '3798': '5',
  '3799': '5',
  '3800': '5',
  '3801': '5',
  '3802': '5',
  '3807': '5',
  '3808': '5',
  '3810': '5',
  '3811': '5',
  '3812': '5',
  '3813': '5',
  '3814': '5',
  '3815': '5',
  '3816': '5',
  '3817': '5',
  '3818': '5',
  '3819': '5',
  '3820': '5',
  '3821': '5',
  '3822': '5',
  '3823': '5',
  '3824': '5',
  '3825': '5',
  '3826': '5',
  '3827': '5',
  '3828': '5',
  '3829': '5',
  '3830': '5',
  '3831': '5',
  '4011': '5',
  '4111': '6',
  '4112': '6',
  '4119': '6',
  '4121': '6',
  '4131': '6',
  '4214': '6',
  '4215': '6',
  '4225': '6',
  '4411': '6',
  '4457': '6',
  '4468': '6',
  '4511': '6',
  '4582': '6',
  '4722': '6',
  '4723': '6',
  '4761': '6',
  '4784': '6',
  '4789': '7',
  '4812': '7',
  '4813': '7',
  '4814': '7',
  '4815': '7',
  '4816': '7',
  '4821': '7',
  '4829': '7',
  '4899': '7',
  '4900': '8',
  '5013': '999',
  '5021': '999',
  '5039': '2',
  '5044': '7',
  '5045': '7',
  '5046': '7',
  '5047': '1',
  '5051': '999',
  '5065': '999',
  '5072': '999',
  '5074': '999',
  '5085': '999',
  '5094': '999',
  '5099': '999',
  '5111': '999',
  '5122': '999',
  '5131': '999',
  '5137': '999',
  '5139': '999',
  '5169': '999',
  '5172': '999',
  '5192': '999',
  '5193': '999',
  '5198': '999',
  '5199': '999',
  '5200': '2',
  '5211': '2',
  '5231': '2',
  '5251': '2',
  '5261': '2',
  '5271': '2',
  '5300': '2',
  '5309': '999',
  '5310': '999',
  '5311': '2',
  '5331': '999',
  '5399': '999',
  '5411': '9',
  '5422': '9',
  '5441': '9',
  '5451': '9',
  '5462': '9',
  '5499': '9',
  '5511': '10',
  '5521': '10',
  '5532': '10',
  '5531': '10',
  '5533': '10',
  '5541': '10',
  '5542': '10',
  '5551': '10',
  '5561': '10',
  '5592': '10',
  '5598': '999',
  '5599': '10',
  '5611': '11',
  '5621': '11',
  '5631': '11',
  '5641': '11',
  '5651': '11',
  '5655': '11',
  '5661': '11',
  '5681': '11',
  '5691': '11',
  '5697': '11',
  '5698': '11',
  '5699': '11',
  '5712': '2',
  '5713': '2',
  '5714': '2',
  '5718': '2',
  '5719': '2',
  '5722': '7',
  '5732': '7',
  '5733': '12',
  '5734': '7',
  '5735': '7',
  '5811': '13',
  '5812': '13',
  '5813': '13',
  '5814': '13',
  '5815': '14',
  '5816': '14',
  '5817': '14',
  '5818': '14',
  '5912': '1',
  '5921': '13',
  '5931': '999',
  '5932': '999',
  '5933': '999',
  '5935': '999',
  '5937': '999',
  '5940': '15',
  '5941': '15',
  '5942': '12',
  '5943': '999',
  '5944': '999',
  '5945': '12',
  '5946': '12',
  '5947': '999',
  '5948': '11',
  '5949': '11',
  '5950': '999',
  '5960': '8',
  '5961': '999',
  '5962': '999',
  '5963': '999',
  '5964': '999',
  '5965': '999',
  '5966': '999',
  '5967': '7',
  '5968': '16',
  '5969': '16',
  '5970': '12',
  '5971': '12',
  '5972': '12',
  '5973': '12',
  '5975': '1',
  '5976': '1',
  '5977': '1',
  '5978': '999',
  '5983': '10',
  '5992': '2',
  '5994': '999',
  '5995': '13',
  '5996': '15',
  '5997': '999',
  '5998': '12',
  '5999': '999',
  '6010': '999',
  '6011': '999',
  '6012': '999',
  '6050': '999',
  '6211': '17',
  '6300': '17',
  '6381': '17',
  '6399': '17',
  '6513': '17',
  '6529': '17',
  '6530': '17',
  '6531': '17',
  '6532': '17',
  '6533': '17',
  '6534': '17',
  '6535': '17',
  '6536': '17',
  '6537': '17',
  '6538': '17',
  '6540': '17',
  '7011': '5',
  '7012': '5',
  '7032': '15',
  '7033': '5',
  '7210': '11',
  '7211': '11',
  '7216': '11',
  '7217': '11',
  '7221': '12',
  '7230': '18',
  '7251': '11',
  '7261': '999',
  '7276': '17',
  '7277': '999',
  '7278': '12',
  '7296': '11',
  '7297': '1',
  '7298': '1',
  '7299': '999',
  '7311': '999',
  '7321': '17',
  '7332': '7',
  '7333': '12',
  '7338': '7',
  '7339': '999',
  '7342': '2',
  '7349': '2',
  '7361': '2',
  '7372': '7',
  '7375': '7',
  '7379': '7',
  '7392': '999',
  '7393': '999',
  '7394': '999',
  '7395': '7',
  '7511': '10',
  '7512': '4',
  '7513': '4',
  '7519': '4',
  '7523': '10',
  '7524': '10',
  '7531': '10',
  '7534': '10',
  '7535': '10',
  '7538': '10',
  '7542': '10',
  '7549': '10',
  '7622': '7',
  '7623': '7',
  '7629': '7',
  '7631': '12',
  '7641': '999',
  '7692': '999',
  '7699': '999',
  '7778': '999',
  '7800': '999',
  '7801': '999',
  '7802': '999',
  '7829': '7',
  '7832': '7',
  '7841': '7',
  '7911': '12',
  '7922': '12',
  '7929': '12',
  '7932': '12',
  '7933': '12',
  '7941': '15',
  '7991': '5',
  '7992': '15',
  '7993': '14',
  '7994': '12',
  '7995': '12',
  '7996': '12',
  '7997': '15',
  '7998': '12',
  '7999': '1',
  '8011': '1',
  '8021': '1',
  '8031': '1',
  '8041': '1',
  '8042': '1',
  '8043': '1',
  '8049': '1',
  '8050': '1',
  '8062': '1',
  '8071': '1',
  '8099': '1',
  '8111': '999',
  '8211': '999',
  '8220': '999',
  '8241': '999',
  '8244': '999',
  '8249': '999',
  '8299': '999',
  '8351': '1',
  '8641': '999',
  '8651': '999',
  '8675': '999',
  '8699': '999',
  '8734': '1',
  '8911': '2',
  '8931': '17',
  '8999': '999',
  '9211': '999',
  '9222': '999',
  '9223': '999',
  '9311': '999',
  '9399': '999',
  '9401': '999',
  '9402': '999',
  '9405': '999',
  '9700': '999',
  '9701': '999',
  '9702': '999',
  '9751': '999',
  '9752': '999',
  '9753': '999',
  '9754': '999',
  '9950': '999',
}
