import { useEffect, useState } from 'react'

import { Spinner } from 'components'
import { HistoryResponseError } from 'components/history-response-error'
import { NoHistoryPlaceholder } from 'components/no-history-placeholder'
import { getToken } from 'utils'
import { CryptoAndFiatHistoryType } from 'model/cefi-transactions-history'
import { getTxnsHistoryCashbackFx } from 'model/cefi-transactions-history-cashback'

import { GroupedSectionList } from './grouped-section-list'
import styles from './styles.module.scss'

export function TransactionHistoryCashback() {
  const storeToken = getToken()
  const [txnHistoryResponse, setTxnHistoryResponse] = useState<CryptoAndFiatHistoryType[]>([])

  const [isLoading, setIsLoading] = useState(true)
  const [responseError, setResponseError] = useState('')

  const fetchData = async () => {
    setIsLoading(true)
    setResponseError('')

    if (storeToken) {
      try {
        const response = (await getTxnsHistoryCashbackFx()) as unknown as CryptoAndFiatHistoryType[]

        if (response) {
          setTxnHistoryResponse(response)
        }
      } catch (error: any) {
        setResponseError('error')
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 100)
      }
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.containerWrap}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>Cashback</div>
        </div>

        <div className={styles.horizontalLine}></div>

        {isLoading ? (
          <div className={styles.loadingWrap}>
            <Spinner />
          </div>
        ) : null}

        {!isLoading && !responseError && txnHistoryResponse?.length ? (
          <GroupedSectionList data={txnHistoryResponse} />
        ) : null}

        {!isLoading && !responseError && !txnHistoryResponse?.length ? <NoHistoryPlaceholder /> : null}
        {!isLoading && responseError ? <HistoryResponseError /> : null}
      </div>
    </div>
  )
}
