/* eslint-disable max-len */
import { SVGProps } from 'react'

export function BankingIcon({ fill = '#fff', stroke, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none' viewBox='0 0 21 20' {...props}>
      <path
        fill={fill}
        d='M14.524 19H5.491a5.633 5.633 0 01-4.034-1.413 5.333 5.333 0 01-1.45-3.933V6.338a5.32 5.32 0 011.449-3.929A5.641 5.641 0 015.491 1h9.03a5.64 5.64 0 014.034 1.409 5.32 5.32 0 011.452 3.929v.037h-4.238a3.54 3.54 0 00-3.57 3.482 3.42 3.42 0 001.044 2.467 3.6 3.6 0 002.527 1.024h4.237v.306a5.334 5.334 0 01-1.449 3.933A5.632 5.632 0 0114.524 19zM4.746 4.875a.759.759 0 00-.761.744.731.731 0 00.222.528.769.769 0 00.54.221h5.65a.77.77 0 00.705-.463.735.735 0 00.057-.287.759.759 0 00-.767-.743H4.746z'
        opacity='0.4'
      />
      <path
        fill={fill}
        d='M14.045 10.298a2.018 2.018 0 002 1.6h3.25a.726.726 0 00.713-.734V8.632a.728.728 0 00-.717-.734h-3.327a1.985 1.985 0 00-1.956 2.01c0 .131.012.262.037.39z'
      />
    </svg>
  )
}
