import { createEvent, createStore } from 'effector'
import persist from 'effector-localstorage'
// import { persist } from '@effector-storage/react-native-async-storage'

type CurrencyType = 'EUR' | 'USD'
export type LowercaseCurrencyType = Lowercase<CurrencyType>

const initCurrency = {
  symbol: '€', // € $
  type: 'EUR' as CurrencyType,
}

export const $currency = createStore(initCurrency)
export const currencyChangedEv = createEvent<Record<string, string>>()

$currency.on(currencyChangedEv, (s, p) => ({ ...s, ...p }))

persist({ store: $currency, key: '@bb_cefi_web_currency_store' })
