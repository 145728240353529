import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'

import { HeaderTitle, Spinner } from 'components'
import { TransactionHistoryCard } from 'features/transactions-history/transaction-history-card'
import {
  $cardsData,
  $cardStatus,
  getCardsBalanceFx,
  getCardsDataFx,
  getPhysicalCardRequestedFx,
} from 'model/cefi-banking'

import Cashback from './Cashback'
import { ManageCard } from './ManageCard'
import styles from './styles.module.scss'

export function Card() {
  const cardStatus = useStore($cardStatus)
  const cardsData = useStore($cardsData)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (cardStatus.currentStep === 'PROCESSED') {
      getCardsDataFx().then(r => setIsLoading(false))
      getCardsBalanceFx().then(r => null)
      getPhysicalCardRequestedFx().then(r => null)
    }
  }, [cardStatus])

  return (
    <div className={styles.cardWrap}>
      <HeaderTitle hideUd headerTitle='Card' />
      {isLoading || !cardsData.length ? (
        <div style={{ flexGrow: 1, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.contentCardWrap}>
          <div className={styles.balanceWrap}>
            <ManageCard />
            <div style={{ height: 24 }} />
            <Cashback />
            <div style={{ height: 24 }} />
          </div>

          <div className={styles.txnsHistoryCardWrap}>
            <TransactionHistoryCard />
          </div>
        </div>
      )}
    </div>
  )
}
