import React from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { ErrorRedBlock, Modal } from 'components'
import { CardOrderInfoModal } from 'features/modals'
import { CardService } from 'wip/services'
import { $cardStatus, getCardStatusFx } from 'model/cefi-banking'

import card from './card-hodl.svg'
import { ErrorCardBlock } from './error-card-block'
import { STEPS } from './order-card-flow'
import styles from './styles.module.scss'

type Props = {
  isSubmitted: boolean
  requestError: string
  setIsStartPage: React.Dispatch<React.SetStateAction<boolean>>
  isBlocked?: boolean
  isCountryBlock?: string
}

export function StartPage({ isSubmitted, requestError, setIsStartPage, isBlocked, isCountryBlock }: Props) {
  const cardStatus = useStore($cardStatus)

  return (
    <div className={styles.cardImageWrap}>
      <img className={styles.cardImg} src={card} alt='' />
      <div className={styles.freeHistory} style={isSubmitted ? { color: '#FFFFFF' } : {}}>
        {isBlocked ? (
          <div style={{ maxWidth: 675, marginTop: 184 }}>
            <ErrorCardBlock isCountryBlock={isCountryBlock} />
          </div>
        ) : isSubmitted ? (
          <div className={styles.freeHistorySubmitted}>Congrats! Your card order is submitted.</div>
        ) : (
          <div>
            Seamless Crypto Spending <br />
            Starts Here
          </div>
        )}
      </div>
      {isBlocked ? null : (
        <div className={styles.freeHistoryDescription} style={isSubmitted ? { color: '#FFFFFF' } : {}}>
          {isSubmitted ? (
            <div className={styles.freeHistoryDescriptionSubmitted}>
              We are processing your card order. You will receive a notification once your card is issued.
            </div>
          ) : (
            // eslint-disable-next-line max-len
            'Unleash the power of borderless banking with the blockbank HODL card. Spend your crypto easily and securely all around the world.'
          )}
        </div>
      )}
      {isSubmitted || isBlocked ? null : (
        <button
          onClick={e => {
            e.preventDefault()
            Modal.open(
              <CardOrderInfoModal
                goNext={async () => {
                  if (cardStatus?.additionalInfo?.kyc === 'OK') {
                    try {
                      await CardService.submitOrderStatus({
                        step: STEPS.KYC,
                        data: {},
                      })
                      await getCardStatusFx()
                    } catch (error: any) {
                      console.log('submitOrderStatus-KYC-error', error)
                    }
                  }
                  setIsStartPage(false)
                  Modal.close()
                }}
              />,
              {
                isFullScreen: true,
                className: 'miniModal',
              }
            )
          }}
          className={clsx('btn', 'btn-primary', styles.orderBtn)}
        >
          <div>Order My Card</div>
        </button>
      )}
      {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
    </div>
  )
}
