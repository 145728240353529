import styles from './styles.module.scss'

export function NoSuperchargeEvents() {
  return (
    <div className={styles.noSuperchargeContainer}>
      <div className={styles.emoji}>😢</div>
      <div className={styles.noSuperchargeTitle}>No Supercharge Events{'\n'}Currently Available</div>
      <div className={styles.noSuperchargeDescription}>
        At the moment, there are no active{'\n'}staking opportunities available.{'\n'}Please check back soon for new
        events.
      </div>
    </div>
  )
}
