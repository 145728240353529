import styles from './styles.module.scss'

export interface WithdrawalNotAvailable {
  openDeposit: () => void
}

export function WithdrawalNotAvailable({ openDeposit }: WithdrawalNotAvailable) {
  return (
    <div>
      <div className={styles.transfer}>Withdrawal Not Available</div>
      <div className={styles.transferDescription}>
        According to the Terms & Conditions to be able to withdraw your funds to any bank account, you must first make a
        deposit with the desired bank account. You can withdraw your funds to the bank accounts from which deposits have
        been made.
      </div>
      <button
        onClick={() => {
          openDeposit()
        }}
        className='btn btn-primary'
      >
        Deposit
      </button>
    </div>
  )
}
