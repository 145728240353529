import React from 'react'

import styles from '../../features/launchpad/styles.module.scss'

type Props = {
  status: string
}

// 'COMING_SOON' | 'ACTIVE' | 'FULLY_RAISED' | ' FINISHED' | ' NOT_RAISED' | ' CANCELED'

const statusColors: Record<string, string> = {
  COMING_SOON: 'rgba(255, 149, 0, 1)',
  ACTIVE: 'rgba(52, 199, 89, 1)',
  FULLY_RAISED: 'rgba(0, 40, 181, 1)',
  FINISHED: 'rgba(0, 40, 181, 1)',
  NOT_RAISED: 'rgba(0, 40, 181, 1)',
  CANCELED: 'rgba(255, 59, 48, 1)',
}

const backGroundStatusColors: Record<string, string> = {
  COMING_SOON: 'rgba(255, 149, 0, 0.1)',
  ACTIVE: 'rgba(52, 199, 89, 0.1)',
  FULLY_RAISED: 'rgba(0, 40, 181, 0.1)',
  FINISHED: 'rgba(0, 40, 181, 0.1)',
  NOT_RAISED: 'rgba(0, 40, 181, 0.1)',
  CANCELED: 'rgba(255, 59, 48, 0.1)',
}

export function LaunchpadStatus({ status }: Props) {
  const showedStatus = status
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase())
  const textColor = statusColors[status]
  const backgroundColor = backGroundStatusColors[status]

  return (
    <div
      style={{
        // marginLeft: 'auto',
        display: 'flex',
        alignSelf: 'self-start',
        alignItems: 'center',
        backgroundColor: backgroundColor || '#EBF9EE',
        padding: '6px 12px',
        borderRadius: 61,
        justifyContent: 'center',
        whiteSpace: 'nowrap',
      }}
    >
      <div
        style={{
          width: 7,
          height: 7,
          borderRadius: 7,
          backgroundColor: textColor || '#34C759',
          marginRight: 4,
        }}
      />
      <div
        style={{
          color: textColor || '#34C759',
          fontFamily: 'Inter',
          fontSize: 12,
          fontWeight: 700,
        }}
      >
        {showedStatus}
      </div>
    </div>
  )
}
