import { createEffect, createStore } from 'effector'

import { PhoneService } from 'wip/services/phone'

export const $userPhone = createStore('')

export const getUserPhoneFx = createEffect(async () => {
  try {
    const respopnse = await PhoneService.getPhone()
    return respopnse?.phone
  } catch (error) {
    console.log('ERROR-getUserPhoneFx', error)
  }
})

$userPhone.on(getUserPhoneFx.doneData, (s, p) => p)
