import { useState } from 'react'
import clsx from 'clsx'

import { TriangleIcon } from 'icons'

import styles from './styles.module.scss'

export interface AssetsDropdown {
  assets: any
  selectedData: any
  setSelectedData: React.Dispatch<any>
}

export function AssetsDropdown({ assets, selectedData, setSelectedData }: AssetsDropdown) {
  const [shouldShowContent, setShouldShowContent] = useState(false)

  return (
    <>
      <div
        className={styles.networksDropdownMain}
        style={shouldShowContent ? { borderColor: 'var(--mainBlue)' } : {}}
        onClick={() => setShouldShowContent(prev => !prev)}
      >
        <div
          className={styles.dropDownSelectedText}
          style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginLeft: 6 }}
        >
          <img className={styles.assetIcon} src={selectedData.icon} alt='' />
          {selectedData?.assetName}
        </div>
        <div
          className={styles.networksDropdownTriangle}
          style={shouldShowContent ? { transform: 'rotate(+90deg)' } : {}}
        >
          <TriangleIcon fill='var(--mainBlue)' />
        </div>
      </div>

      {shouldShowContent && (
        <div className={clsx(styles.networksDropdownContainer, styles.networksDropdownContainerTrade)}>
          {assets
            .filter((asset: any) => !asset.isFiat)
            .map((asset: any) => {
              return (
                <div
                  key={asset.id}
                  onClick={() => {
                    setSelectedData(asset)
                    setShouldShowContent(false)
                  }}
                  className={clsx(styles.dropDownSelectedText, styles.networksDropdownItem)}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginLeft: 0,
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}
                >
                  <img className={styles.assetIcon} src={asset.icon} alt='' />
                  {asset?.assetName}
                </div>
              )
            })}
        </div>
      )}
    </>
  )
}
