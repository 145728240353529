import { HeaderTitle, ProtectedRoute } from 'components'
import { HeaderCards } from 'features/header-cards'
import { Main } from 'features/main'
import Portfolio from 'features/portfolio'

import { getToken } from '../utils'
import styles from './styles.module.scss'

export function PortfolioPage() {
  const token = getToken()

  return (
    <ProtectedRoute
      type='guest'
      element={
        <Main>
          {/* REFACTOR move wrapper to layout */}
          <div className={styles.wrap}>
            {token ? <HeaderCards headerTitle='Portfolio' /> : <HeaderTitle hideUd headerTitle='Portfolio' />}
            <Portfolio />
          </div>
        </Main>
      }
    />
  )
}
