import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from 'effector-react'
import moment from 'moment'
import clsx from 'clsx'

import { HeaderTitle, Modal, Spinner } from 'components'
import { MODAL_TYPES, pages } from 'constant'
import { DepositAssetModal, TradeAssetModal, WithdrawAssetModal } from 'features/modals'
import { TransactionHistoryCrypto } from 'features/transactions-history/transaction-history-crypto'
import { getBalanceString, getToken } from 'utils'
import { CoingeckoService } from 'wip/services'
import { BackArrowIcon, DepositIcon, ExchangeIcon } from 'icons'
import { theme } from 'config'

import { $assetsCefiExchangeRates } from '../../model/cef-rates-exchange'
import { $assetsListData } from '../../model/cefi-combain-assets-data'
import { $currency, LowercaseCurrencyType } from '../../model/currency'
import { PriceChart } from './price-chart'
import styles from './styles.module.scss'

const unixTimestampNow = moment().unix()
const unixTimestampDay = moment().subtract(1, 'day').unix()
const unixTimestampWeak = moment().subtract(7, 'day').unix()
const unixTimestampMonth = moment().subtract(1, 'month').unix()
const unixTimestampYear = moment().subtract(1, 'year').unix()

export function IndividualToken() {
  const navigate = useNavigate()
  const { assetId } = useParams()
  const assets = useStore($assetsListData)
  const ratesCeFi = useStore($assetsCefiExchangeRates)
  const token = getToken()
  const currency = useStore($currency)
  const currencyType: 'eur' | 'usd' = (currency?.type?.toLowerCase() as LowercaseCurrencyType) || 'eur'

  const asset = assets.find(assetItem => assetItem.assetId === assetId)
  const [priceData, setPriceData] = useState([])
  const [timeLine, setTimeline] = useState(unixTimestampDay)
  const [isLoading, setIsLoading] = useState(false)
  const [heightControl, setHeightControl] = useState(0)

  const firstBlockRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (firstBlockRef.current) {
      setHeightControl(firstBlockRef.current.offsetHeight)
      setTimeout(() => firstBlockRef.current && setHeightControl(firstBlockRef.current.offsetHeight), 10)
    }
  }, [firstBlockRef?.current?.offsetHeight])

  const getAssetRatesData = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const pricesChart =
        asset &&
        (await CoingeckoService.rangeData({
          assetName: asset.coinGeckoId,
          currency: currencyType,
          from: timeLine,
          to: unixTimestampNow,
        }))
      setPriceData(pricesChart?.prices || [])
    } catch (e) {
      console.log('ERROR-rangeData', e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (!asset) {
      // navigate(pages.Base.path)
    }
    getAssetRatesData().then(() => null)
  }, [asset, timeLine])

  if (!assetId || !asset) return null

  const goToModal = (type: string): void => {
    if (type === MODAL_TYPES.DEPOSIT_ASSET) {
      Modal.open(<DepositAssetModal asset={asset} />, { title: pages.PORTFOLIO.name, isFullScreen: true })
    }
    if (type === MODAL_TYPES.WITHDRAW_ASSET) {
      Modal.open(<WithdrawAssetModal asset={asset} />, { title: pages.PORTFOLIO.name, isFullScreen: true })
    }
    if (type === MODAL_TYPES.TRADE_ASSET) {
      Modal.open(<TradeAssetModal asset={asset} />, { title: pages.PORTFOLIO.name, isFullScreen: true })
    }
  }

  // TODO: change theme state
  const isHideBlock = ['kaizen'].includes(theme)

  const tradeEnabled = ratesCeFi.filter(assetItem => {
    if (assetItem.toAssetId === asset.assetId) return true
    if (assetItem.fromAssetId === asset.assetId) return true
    return false
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 1582,
        maxWidth: 2700,
      }}
    >
      <div className={styles.headerTitle}>
        <HeaderTitle headerTitle={assetId} showBackBtn />
      </div>

      <div
        style={{
          display: 'flex',
          gap: '20px',
          padding: '25px',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
            overflowY: 'auto',
          }}
        >
          <div ref={firstBlockRef}>
            <div className={styles.chartBlock}>
              <div className={styles.chartBlockWrap}>
                <div className={styles.assetInfoWrap}>
                  <div className={styles.graphIcon}>
                    <img style={{ width: 64, height: 64, borderRadius: 5, marginRight: 22 }} src={asset.icon} alt='' />
                    <div>
                      <div className={styles.assetName}>{asset.assetName}</div>
                      <div className={styles.assetId}>{asset.assetId}</div>
                    </div>
                  </div>
                  <div className={styles.assetChangeWrap}>
                    <div className={styles.assetName}>
                      {currency.symbol}
                      {getBalanceString(+asset[currencyType].price, 6)}
                    </div>
                    <div
                      className={styles.assetChange}
                      style={{
                        color: +asset[currencyType].priceChangePercentage24h < 0 ? '#FF0000' : '#2B9A18',
                      }}
                    >
                      {+asset[currencyType].priceChangePercentage24h < 0 ? '' : '+'}
                      {+asset[currencyType].priceChangePercentage24h === 0 ||
                      asset[currencyType].priceChangePercentage24h
                        ? getBalanceString(+asset[currencyType].priceChangePercentage24h, 2)
                        : '--'}
                      %
                    </div>
                  </div>
                </div>
                <div className={styles.priceChartWrap}>
                  {priceData.length && !isLoading ? (
                    <PriceChart data={priceData} />
                  ) : (
                    <div className={styles.spinnerWrap}>
                      <Spinner />
                    </div>
                  )}
                </div>
                <div className={styles.timeLine}>
                  <div
                    onClick={() => {
                      if (!isLoading) setTimeline(unixTimestampDay)
                    }}
                    className={clsx(
                      styles.timeLineItem,
                      timeLine === unixTimestampDay ? styles.timeLineItemActive : ''
                    )}
                  >
                    1D
                  </div>
                  <div
                    onClick={() => {
                      if (!isLoading) setTimeline(unixTimestampWeak)
                    }}
                    className={clsx(
                      styles.timeLineItem,
                      timeLine === unixTimestampWeak ? styles.timeLineItemActive : ''
                    )}
                  >
                    1W
                  </div>
                  <div
                    onClick={() => {
                      if (!isLoading) setTimeline(unixTimestampMonth)
                    }}
                    className={clsx(
                      styles.timeLineItem,
                      timeLine === unixTimestampMonth ? styles.timeLineItemActive : ''
                    )}
                  >
                    1M
                  </div>
                  <div
                    onClick={() => {
                      if (!isLoading) setTimeline(unixTimestampYear)
                    }}
                    className={clsx(
                      styles.timeLineItem,
                      timeLine === unixTimestampYear ? styles.timeLineItemActive : ''
                    )}
                  >
                    1Y
                  </div>
                </div>

                <div className={styles.mobileBtnBlock}>
                  <div className={styles.totalBalanceTitle}>Total Balance</div>
                  <div className={styles.totalBalanceAmount}>
                    {getBalanceString(+asset.availableBalance, 8)} {asset.assetId}
                  </div>
                  <div className={styles.totalBalanceTitle}>
                    {currency.symbol}
                    {asset[currencyType]?.price
                      ? getBalanceString(+asset[currencyType].price * +asset.availableBalance, 2)
                      : '--'}
                  </div>

                  <div style={{ marginTop: 48 }}>
                    <div style={{ display: 'flex' }}>
                      <button
                        onClick={() => goToModal(MODAL_TYPES.DEPOSIT_ASSET)}
                        className='btn btn-primary'
                        style={{ backgroundColor: '#F5F4FA', color: 'black', height: 55 }}
                      >
                        <DepositIcon arrowColor='black' circleBg='#F5F4FA' fill='var(--mainBlue)' />
                        Deposit
                      </button>
                      <div style={{ minWidth: 12 }} />
                      <button
                        onClick={() => goToModal(MODAL_TYPES.WITHDRAW_ASSET)}
                        className='btn btn-primary'
                        style={{ backgroundColor: '#F5F4FA', color: 'black' }}
                      >
                        <div style={{ transform: 'rotate(+180deg)' }}>
                          <DepositIcon arrowColor='black' circleBg='#F5F4FA' fill='var(--mainBlue)' />
                        </div>
                        Withdraw
                      </button>
                    </div>
                    <div style={{ height: 12 }} />
                    <button
                      onClick={() => goToModal(MODAL_TYPES.TRADE_ASSET)}
                      className={clsx('btn', 'btn-primary', styles.btnTradeMobile)}
                    >
                      <ExchangeIcon arrowColor='white' fill='var(--mainBlue)' />
                      Trade
                    </button>
                  </div>
                </div>

                <div className={styles.graphText}>
                  The graph and the price shown are indicative only. The execution price may vary at the moment of the
                  transaction.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: heightControl || '100%',
            gap: 24,
          }}
        >
          <div
            style={{
              backgroundColor: '#e0e0e0',
              borderRadius: '10px',
              flexShrink: 0,
            }}
          >
            <div style={{ padding: '36px', backgroundColor: '#fff', borderRadius: '10px' }}>
              <div className={styles.totalBalanceTitle}>Total Balance</div>
              <div className={styles.totalBalanceAmount}>
                {currency.symbol}{' '}
                {asset[currencyType]?.price
                  ? getBalanceString(+asset[currencyType].price * +asset.availableBalance, 2)
                  : '--'}
              </div>
              <div className={styles.totalBalanceTitle}>
                {getBalanceString(+asset.availableBalance, 8)} {asset.assetId}
              </div>
              <div className={styles.divider} />
              <button
                style={asset.depositEnabled && token ? {} : { cursor: 'default', opacity: 0.2 }}
                onClick={() => {
                  if (!asset.depositEnabled || !token) return
                  goToModal(MODAL_TYPES.DEPOSIT_ASSET)
                }}
                className='btn btn-primary'
              >
                Deposit
              </button>
              <div style={{ height: 20 }} />
              <button
                style={tradeEnabled?.length && token ? {} : { cursor: 'default', opacity: 0.2 }}
                onClick={() => {
                  if (!tradeEnabled?.length || !token) return
                  goToModal(MODAL_TYPES.TRADE_ASSET)
                }}
                className='btn btn-primary'
              >
                Trade
              </button>
              <div style={{ height: 20 }} />
              {isHideBlock ? null : (
                <button
                  onClick={() => {
                    if (!token) return
                    navigate(pages.EARN.path)
                  }}
                  className='btn btn-primary'
                  style={
                    asset.withdrawalEnabled && token
                      ? { backgroundColor: 'black' }
                      : { cursor: 'default', opacity: 0.2 }
                  }
                >
                  Earn
                </button>
              )}

              <div style={{ height: 20 }} />
              <div style={{ display: 'flex' }}>
                <button
                  onClick={() => {
                    if (!asset.withdrawalEnabled || !token) return
                    goToModal(MODAL_TYPES.WITHDRAW_ASSET)
                  }}
                  className='btn btn-primary'
                  style={
                    asset.withdrawalEnabled && token
                      ? { backgroundColor: '#FFF', color: '#262832', border: '1px solid #262832' }
                      : { cursor: 'default', opacity: 0.2 }
                  }
                >
                  Withdraw
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              flexGrow: 1,
              backgroundColor: '#fff',
              borderRadius: '10px',
              overflowY: 'auto',
            }}
          >
            <TransactionHistoryCrypto filterAssetId={assetId} />
          </div>
        </div>
      </div>
    </div>
  )

  // TODO: old UI
  // return (
  //   <div className={styles.individualTokenWrap}>
  //     <div className={styles.headerTitle}>
  //       <HeaderTitle headerTitle={assetId} showBackBtn />
  //     </div>
  //     <div className={styles.mobBackTitle}>
  //       <div onClick={() => navigate(-1)} className={styles.mobBack}>
  //         <BackArrowIcon fill='var(--mainBlue)' />
  //       </div>
  //       <div className={styles.mobTitle}>
  //         <img style={{ width: 32, height: 32, borderRadius: 5, marginRight: 16 }} src={asset.icon} alt='' />
  //         {asset.assetName}
  //       </div>
  //       <div style={{ width: 32 }} />
  //     </div>
  //     <div className={styles.headerLine} />
  //     <div className={styles.chartActionBlock}>
  //       <div className={styles.chartBlock}>
  //         <div className={styles.chartBlockWrap}>
  //           <div className={styles.assetInfoWrap}>
  //             <div className={styles.graphIcon}>
  //               <img style={{ width: 64, height: 64, borderRadius: 5, marginRight: 22 }} src={asset.icon} alt='' />
  //               <div>
  //                 <div className={styles.assetName}>{asset.assetName}</div>
  //                 <div className={styles.assetId}>{asset.assetId}</div>
  //               </div>
  //             </div>
  //             <div className={styles.assetChangeWrap}>
  //               <div className={styles.assetName}>
  //                 {currency.symbol}
  //                 {getBalanceString(+asset[currencyType].price, 6)}
  //               </div>
  //               <div
  //                 className={styles.assetChange}
  //                 style={{
  //                   color: +asset[currencyType].priceChangePercentage24h < 0 ? '#FF0000' : '#2B9A18',
  //                 }}
  //               >
  //                 {+asset[currencyType].priceChangePercentage24h < 0 ? '' : '+'}
  //                 {+asset[currencyType].priceChangePercentage24h === 0 || asset[currencyType].priceChangePercentage24h
  //                   ? getBalanceString(+asset[currencyType].priceChangePercentage24h, 2)
  //                   : '--'}
  //                 %
  //               </div>
  //             </div>
  //           </div>
  //           <div className={styles.priceChartWrap}>
  //             {priceData.length && !isLoading ? (
  //               <PriceChart data={priceData} />
  //             ) : (
  //               <div className={styles.spinnerWrap}>
  //                 <Spinner />
  //               </div>
  //             )}
  //           </div>
  //           <div className={styles.timeLine}>
  //             <div
  //               onClick={() => {
  //                 if (!isLoading) setTimeline(unixTimestampDay)
  //               }}
  //               className={clsx(styles.timeLineItem, timeLine === unixTimestampDay ? styles.timeLineItemActive : '')}
  //             >
  //               1D
  //             </div>
  //             <div
  //               onClick={() => {
  //                 if (!isLoading) setTimeline(unixTimestampWeak)
  //               }}
  //               className={clsx(styles.timeLineItem, timeLine === unixTimestampWeak ? styles.timeLineItemActive : '')}
  //             >
  //               1W
  //             </div>
  //             <div
  //               onClick={() => {
  //                 if (!isLoading) setTimeline(unixTimestampMonth)
  //               }}
  //               className={clsx(styles.timeLineItem, timeLine === unixTimestampMonth ? styles.timeLineItemActive : '')}
  //             >
  //               1M
  //             </div>
  //             <div
  //               onClick={() => {
  //                 if (!isLoading) setTimeline(unixTimestampYear)
  //               }}
  //               className={clsx(styles.timeLineItem, timeLine === unixTimestampYear ? styles.timeLineItemActive : '')}
  //             >
  //               1Y
  //             </div>
  //           </div>
  //
  //           <div className={styles.mobileBtnBlock}>
  //             <div className={styles.totalBalanceTitle}>Total Balance</div>
  //             <div className={styles.totalBalanceAmount}>
  //               {getBalanceString(+asset.availableBalance, 8)} {asset.assetId}
  //             </div>
  //             <div className={styles.totalBalanceTitle}>
  //               {currency.symbol}
  //               {asset[currencyType]?.price
  //                 ? getBalanceString(+asset[currencyType].price * +asset.availableBalance, 2)
  //                 : '--'}
  //             </div>
  //
  //             <div style={{ marginTop: 48 }}>
  //               <div style={{ display: 'flex' }}>
  //                 <button
  //                   onClick={() => goToModal(MODAL_TYPES.DEPOSIT_ASSET)}
  //                   className='btn btn-primary'
  //                   style={{ backgroundColor: '#F5F4FA', color: 'black', height: 55 }}
  //                 >
  //                   <DepositIcon arrowColor='black' circleBg='#F5F4FA' fill='var(--mainBlue)' />
  //                   Deposit
  //                 </button>
  //                 <div style={{ minWidth: 12 }} />
  //                 <button
  //                   onClick={() => goToModal(MODAL_TYPES.WITHDRAW_ASSET)}
  //                   className='btn btn-primary'
  //                   style={{ backgroundColor: '#F5F4FA', color: 'black' }}
  //                 >
  //                   <div style={{ transform: 'rotate(+180deg)' }}>
  //                     <DepositIcon arrowColor='black' circleBg='#F5F4FA' fill='var(--mainBlue)' />
  //                   </div>
  //                   Withdraw
  //                 </button>
  //               </div>
  //               <div style={{ height: 12 }} />
  //               <button
  //                 onClick={() => goToModal(MODAL_TYPES.TRADE_ASSET)}
  //                 className={clsx('btn', 'btn-primary', styles.btnTradeMobile)}
  //               >
  //                 <ExchangeIcon arrowColor='white' fill='var(--mainBlue)' />
  //                 Trade
  //               </button>
  //             </div>
  //           </div>
  //
  //           <div className={styles.graphText}>
  //             The graph and the price shown are indicative only. The execution price may vary at the moment of the
  //             transaction.
  //           </div>
  //         </div>
  //       </div>
  //
  //       <div className={styles.actionsWrap}>
  //         <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
  //           <div style={{ padding: '36px', backgroundColor: '#fff', borderRadius: '10px', marginBottom: '24px' }}>
  //             <div className={styles.totalBalanceTitle}>Total Balance</div>
  //             <div className={styles.totalBalanceAmount}>
  //               {currency.symbol}{' '}
  //               {asset[currencyType]?.price
  //                 ? getBalanceString(+asset[currencyType].price * +asset.availableBalance, 2)
  //                 : '--'}
  //             </div>
  //             <div className={styles.totalBalanceTitle}>
  //               {getBalanceString(+asset.availableBalance, 8)} {asset.assetId}
  //             </div>
  //             <div className={styles.divider} />
  //             <button
  //               style={asset.depositEnabled && token ? {} : { cursor: 'default', opacity: 0.2 }}
  //               onClick={() => {
  //                 if (!asset.depositEnabled || !token) return
  //                 goToModal(MODAL_TYPES.DEPOSIT_ASSET)
  //               }}
  //               className='btn btn-primary'
  //             >
  //               Deposit
  //             </button>
  //             <div style={{ height: 20 }} />
  //             <button
  //               style={tradeEnabled?.length && token ? {} : { cursor: 'default', opacity: 0.2 }}
  //               onClick={() => {
  //                 if (!tradeEnabled?.length || !token) return
  //                 goToModal(MODAL_TYPES.TRADE_ASSET)
  //               }}
  //               className='btn btn-primary'
  //             >
  //               Trade
  //             </button>
  //             <div style={{ height: 20 }} />
  //             {isHideBlock ? null : (
  //               <button
  //                 onClick={() => {
  //                   if (!token) return
  //                   navigate(pages.EARN.path)
  //                 }}
  //                 className='btn btn-primary'
  //                 style={
  //                   asset.withdrawalEnabled && token
  //                     ? { backgroundColor: 'black' }
  //                     : { cursor: 'default', opacity: 0.2 }
  //                 }
  //               >
  //                 Earn
  //               </button>
  //             )}
  //
  //             <div style={{ height: 20 }} />
  //             <div style={{ display: 'flex' }}>
  //               {/*<button*/}
  //               {/*  style={{*/}
  //               {/*    backgroundColor: '#FFF',*/}
  //               {/*    color: 'rgba(154, 149, 206, 0.5)',*/}
  //               {/*    border: '1px solid rgba(154, 149, 206, 0.5)',*/}
  //               {/*    cursor: 'default',*/}
  //               {/*  }}*/}
  //               {/*  className='btn btn-primary'*/}
  //               {/*>*/}
  //               {/*  Setup A Recurring Buy*/}
  //               {/*</button>*/}
  //               {/*<div style={{ width: 12 }} />*/}
  //               <button
  //                 onClick={() => {
  //                   if (!asset.withdrawalEnabled || !token) return
  //                   goToModal(MODAL_TYPES.WITHDRAW_ASSET)
  //                 }}
  //                 className='btn btn-primary'
  //                 style={
  //                   asset.withdrawalEnabled && token
  //                     ? { backgroundColor: '#FFF', color: '#262832', border: '1px solid #262832' }
  //                     : { cursor: 'default', opacity: 0.2 }
  //                 }
  //               >
  //                 Withdraw
  //               </button>
  //             </div>
  //           </div>
  //           <div className={styles.transactionsBlock}>
  //             <TransactionHistoryCrypto filterAssetId={assetId} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}
