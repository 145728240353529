import { useEffect, useState } from 'react'
import Switch from 'react-switch'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Modal } from 'components'
import { pages } from 'constant'
import { DepositAssetModal, TradeAssetModal, WithdrawAssetModal } from 'features/modals'
import { getBalanceString, getToken, roundingBalance } from 'utils'
import { EVENT_NAMES, useAnalytics } from 'wip/services'
import { themeGlobalColor } from 'config'

import { $assetsCefiExchangeRates } from '../../../model/cef-rates-exchange'
import { $assetsListData } from '../../../model/cefi-combain-assets-data'
import { $currency, LowercaseCurrencyType } from '../../../model/currency'
import { addCommasToDisplayValue } from '../../../utils/add-commas-to-display-value'
import { Star } from './star'
import styles from './styles.module.scss'

export function Portfolio() {
  const [isHideZeroBalance, setIsHideZeroBalance] = useState(false)
  const assets = useStore($assetsListData)
  const ratesCeFi = useStore($assetsCefiExchangeRates)
  const currency = useStore($currency)
  const currencyType: 'eur' | 'usd' = (currency?.type?.toLowerCase() as LowercaseCurrencyType) || 'eur'
  const navigate = useNavigate()
  const { myLogEvent } = useAnalytics()

  const token = getToken()

  useEffect(() => {
    myLogEvent(EVENT_NAMES.WEB_PORTFOLIO_OPENED)
  }, [])

  const assetsList = [
    ...assets.filter(asset => asset.assetId === 'FI'),
    ...assets.filter(asset => asset.isFavourite && asset.assetId !== 'FI').sort((a, b) => +b.usd.cash - +a.usd.cash),
    ...assets
      .filter(asset => !asset.isFavourite && asset.assetId !== 'FI')
      .sort((a, b) => +b[currencyType].cash - +a[currencyType].cash),
  ]

  return (
    <>
      <div className={styles.tableTitleRow}>
        <div className={styles.tableTitle}>Assets</div>

        {token ? (
          <div className={styles.tableHideZeroWrap}>
            <div className={styles.tableHideZero}>Hide Zero Balances</div>
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              offColor='#DADDE3'
              onColor={themeGlobalColor()}
              offHandleColor='#445374'
              onHandleColor='#FFFFFF'
              height={23}
              width={42}
              handleDiameter={19}
              checked={isHideZeroBalance}
              onChange={() => setIsHideZeroBalance(prev => !prev)}
            />
          </div>
        ) : null}
      </div>

      <div className={styles.headerWrap}>
        <div className={clsx(styles.row, styles.header)}>
          <div className={clsx(styles.cell, styles.cellName)}>Name</div>
          {/* <div className={styles.cell}>Tag</div> */}
          <div className={clsx(styles.cell)}>Price</div>
          <div className={styles.cell}>Amount</div>
          <div className={clsx(styles.cell)}>Value</div>
          {/* <div className={styles.cell} /> */}
          <div className={clsx(styles.cell, styles.cell2)}>Actions</div>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.table}>
          {assetsList
            // .filter(asset => !asset.isFiat)
            .filter(asset => {
              if (isHideZeroBalance) {
                return +asset.availableBalance > 0
              }
              return true
            })
            .map(asset => {
              const tradeEnabled = ratesCeFi.filter(assetItem => {
                if (assetItem.toAssetId === asset.assetId) return true
                if (assetItem.fromAssetId === asset.assetId) return true
                return false
              })
              return (
                <div
                  key={asset.assetId}
                  className={styles.row}
                  onClick={() => navigate(`${pages.ASSET.path}/${asset.assetId}`)}
                >
                  <div className={clsx(styles.cell)}>
                    <img className={styles.assetIcon} src={asset.icon} alt='' />
                    <div className={styles.assetName}>
                      <div className={styles.assetTitle}>{asset.assetName}</div>
                      <div className={styles.assetSymbol}>{asset.assetId}</div>
                    </div>
                  </div>
                  <div className={clsx(styles.cell, styles.assetPrice, styles.hideCell)}>
                    <div>
                      {currency.symbol}
                      {roundingBalance(asset[currencyType]?.price.toString(), 5) || '0'}
                    </div>
                    <div
                      className={styles.assetChangeRateWrap}
                      style={{
                        color: +asset[currencyType].priceChangePercentage24h < 0 ? '#FF0000' : '#2B9A18',
                        backgroundColor: +asset[currencyType].priceChangePercentage24h < 0 ? '#FFCCCC' : '#D5EBD1',
                      }}
                    >
                      {+asset[currencyType].priceChangePercentage24h < 0 ? '' : '+'}
                      {+asset[currencyType]?.priceChangePercentage24h === 0 ||
                      asset[currencyType]?.priceChangePercentage24h
                        ? getBalanceString(+asset[currencyType].priceChangePercentage24h, 2)
                        : '--'}
                      %
                    </div>
                  </div>
                  <div className={clsx(styles.cell, styles.hideCell)}>
                    {token ? getBalanceString(+asset.availableBalance, 8) : '-'}
                  </div>
                  <div className={clsx(styles.cell, styles.assetPrice, styles.hideCell)}>
                    <div>
                      {token
                        ? `${currency.symbol}${addCommasToDisplayValue(
                            (
                              (+asset.availableBalance > 0.000001 ? +asset.availableBalance : 0) *
                              asset[currencyType].price
                            ).toString(),
                            4
                          )}`
                        : '-'}
                    </div>
                  </div>
                  <div className={clsx(styles.cell, styles.cell2, styles.hideCell)}>
                    <div
                      className={styles.actionBtn}
                      style={asset.depositEnabled && token ? {} : { cursor: 'default', opacity: 0.2 }}
                      onClick={event => {
                        event.stopPropagation()
                        if (!token) return
                        if (!asset.depositEnabled) return
                        Modal.open(<DepositAssetModal asset={asset} />, { title: pages.PORTFOLIO.name })
                      }}
                    >
                      Deposit
                    </div>
                    <div
                      style={asset.withdrawalEnabled && token ? {} : { cursor: 'default', opacity: 0.2 }}
                      onClick={event => {
                        event.stopPropagation()
                        if (!token) return
                        if (!asset.withdrawalEnabled) return
                        Modal.open(<WithdrawAssetModal asset={asset} />, { title: pages.PORTFOLIO.name })
                      }}
                      className={styles.actionBtn}
                    >
                      Withdraw
                    </div>
                    <div
                      style={tradeEnabled?.length && token ? {} : { cursor: 'default', opacity: 0.2 }}
                      onClick={event => {
                        event.stopPropagation()
                        if (!token) return
                        if (!tradeEnabled?.length) return
                        Modal.open(<TradeAssetModal asset={asset} />, { variant: 'right', title: pages.PORTFOLIO.name })
                      }}
                      className={styles.actionBtn}
                    >
                      Trade
                    </div>

                    <Star asset={asset} />
                  </div>

                  <div className={styles.mobileAmount}>
                    <div className={styles.mobAvailableBalance}>{getBalanceString(+asset.availableBalance, 8)}</div>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          color: +asset[currencyType].priceChangePercentage24h < 0 ? '#FF0000' : '#2B9A18',
                        }}
                      >
                        {+asset[currencyType].priceChangePercentage24h < 0 ? '' : '+'}
                        {+asset[currencyType].priceChangePercentage24h === 0 ||
                        asset[currencyType].priceChangePercentage24h
                          ? getBalanceString(+asset[currencyType].priceChangePercentage24h, 2)
                          : '--'}
                        %
                      </div>
                      <div style={{ width: 8 }} />
                      <div style={{ opacity: 0.5 }}>
                        {currency.symbol}
                        {asset[currencyType]?.price
                          ? getBalanceString(asset[currencyType].price * +asset.availableBalance, 8)
                          : '--'}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}
