import { request } from './base'

export interface UserPhoneResponse {
  phone: string
}

const getPhone = (): Promise<UserPhoneResponse> => request({ url: '/public/v3/phone', method: 'GET' })

export const PhoneService = {
  getPhone,
}
