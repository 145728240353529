import { createEffect, createEvent, createStore } from 'effector'

import { clearRefreshToken, clearToken } from '../utils'
import { AssetInfo, AssetsServices } from '../wip/services'

export const $assets = createStore<AssetInfo[]>([])
export const assetsChangedEv = createEvent<[]>()
export const assetsDataFx = createEffect(async () => {
  try {
    return await AssetsServices.getAssets()
  } catch (e: any) {
    console.log('ERROR-assetsDataFx', e)
    // TODO: clear token
    if (e.status === 401) {
      clearToken()
      clearRefreshToken()
    }
  }
})

$assets.on(assetsDataFx.doneData, (_, repos) => repos).on(assetsChangedEv, (s, p) => p)
