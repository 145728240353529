export const groupNameMapping: { [key: string]: string | string[] } = {
  'Card Usage Fee': 'CARD_USAGE_FIXED_FEE',
  'Declined Fee': 'AUTHORIZATION_DECLINED_FIXED_FEE',
  'Foreign Exchange Fee': 'AUTHORIZATION_FOREIGN_EXCHANGE_PERCENTAGE_FEE',
  'ATM Balance Inquiry Fee': 'AUTHORIZATION_ATM_BALANCE_INQUIRY_FIXED_FEE',
  'ATM Declined Fee': 'AUTHORIZATION_ATM_DECLINED_FIXED_FEE',
  'ATM Withdrawal Fee': [
    'AUTHORIZATION_ATM_WITHDRAWAL_COMPLEX_FEE',
    'AUTHORIZATION_ATM_WITHDRAWAL_EEA_COMPLEX_FEE',
    'AUTHORIZATION_ATM_WITHDRAWAL_FIXED_FEE',
    'AUTHORIZATION_ATM_WITHDRAWAL_INTERNATIONAL_COMPLEX_FEE',
    'AUTHORIZATION_ATM_WITHDRAWAL_PERCENTAGE_FEE',
  ],
  'Authorization EEA Fee': ['AUTHORIZATION_EEA_FIXED_FEE', 'AUTHORIZATION_FIXED_FEE'],
  'Authorization International Fee': 'AUTHORIZATION_INTERNATIONAL_FIXED_FEE',
  'Payment EEA Fee': ['PAYMENT_EEA_COMPLEX_FEE', 'PAYMENT_EEA_FIXED_FEE'],
  'Payment International Fee': [
    'PAYMENT_INTERNATIONAL_COMPLEX_FEE',
    'PAYMENT_INTERNATIONAL_FIXED_FEE',
    'PAYMENT_PERCENTAGE_FEE',
  ],
  'Remind 3DS Password Fee': 'Remind3DSPasswordFixedFee',
  'Remind PIN Fee': 'PIN_MANAGEMENT',
  'PIN Change Fee': 'PIN_CHANGE_FIXED_FEE',
  'Card Issuance Fee': 'CARD_ISSUANCE_FIXED_FEE',
  'Card Delivery Fee': 'CardDeliveryFixedFee',
  'Card Express Delivery Fee': 'CardExpressDeliveryFixedFee',
  'Card Renewal Fee': 'CardRenewalFixedFee',
  'Card Replacement Fee': 'CARD_REPLACEMENT_FIXED_FEE',
  'Additional Virtual Card Fee': 'ADDITIONAL_VIRTUAL_CARDS_FEE',
  'Top Up': 'DEPOSIT',
  'Internet Purchase': 'INTERNET_PURCHASE',
  Other: 'OTHER',
  'Pricing Plan Fee': 'PRICING_PLAN_FEE',
  Purchase: 'PURCHASE',
  Refund: 'REFUND',
  'Cash Withdrawal': 'WITHDRAW',
}
