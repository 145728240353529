import React, { useEffect, useRef, useState } from 'react'

import { CommonDropdown, Modal, RequestError, Spinner, Success } from 'components'
import { getCardsDataFx } from 'model/cefi-banking'

import { CardDispatchResponse, CardService } from '../../../wip/services'
import styles from './styles.module.scss'

const STEPS = {
  DELIVERY_ADDRESS: 'Delivery',
  SUMMARY: 'Summary',
}

export function FancyPhysicalCard() {
  const containerRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [step, setStep] = useState(STEPS.DELIVERY_ADDRESS)
  const [deliveryData, setDeliveryData] = useState<CardDispatchResponse | null>(null)
  const [selectedData, setSelectedData] = useState<any>({
    method: 'Select Delivery',
    description: '',
    price: '',
  })
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [requestError, setRequestError] = useState('')

  const handlePhysical = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const delivery = await CardService.getDelivery()

      setDeliveryData(delivery)
    } catch (e: any) {
      console.log('ERROR-handlePhysical', e)
      setRequestError(e.code || e.message)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handlePhysical()
  }, [])

  const itemComponentCurrency = (item: any) => {
    return (
      <div className={styles.itemText}>
        <span style={{ maxWidth: '90%' }}>{item.method.replaceAll('_', ' ')}</span> <span>{item.description}</span>
        <span>{item.price ? ` ${item.price}€` : ''}</span>
      </div>
    )
  }

  const disableBtn = (): boolean => {
    if (isLoading) return true
    return selectedData.method === 'Select Delivery'
  }

  const handleDelivery = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await CardService.setPhysicalCardRequested({ dispatchMethod: selectedData.method })
      await getCardsDataFx()

      setIsSuccessful(true)
    } catch (e: any) {
      console.log('ERROR-handlePhysical', e)
      setRequestError(e.code || e.message)
    }
    setIsLoading(false)
  }

  const handleNext = () => {
    setStep(STEPS.SUMMARY)
  }

  if (isSuccessful) {
    return <Success text='Card Ordered Successfully' />
  }

  return (
    <div id='withdrawContainer' ref={containerRef} className={styles.topUpCardWrap}>
      <div className={styles.container}>
        {step === STEPS.DELIVERY_ADDRESS && !isLoading ? (
          <div className={styles.section}>
            <div className={styles.title}>Delivery</div>
            <div>
              <div className='input-item-wrap'>
                <input
                  id='addressLine1'
                  type='text'
                  className='input-form'
                  disabled
                  value={deliveryData?.deliveryAddress?.address1}
                  placeholder='Address Line 1'
                />
              </div>
              <div className='input-item-wrap'>
                <input
                  id='addressLine1'
                  type='text'
                  className='input-form'
                  disabled
                  value={deliveryData?.deliveryAddress?.address2}
                  placeholder='Address Line 2'
                />
              </div>
              <div className='input-item-wrap'>
                <input
                  id='city'
                  type='text'
                  className='input-form'
                  disabled
                  value={deliveryData?.deliveryAddress?.city}
                  placeholder='City'
                />
              </div>
              <div className='input-item-wrap'>
                <input
                  id='zipCode'
                  type='text'
                  className='input-form'
                  disabled
                  value={deliveryData?.deliveryAddress?.postalCode}
                  placeholder='Zip Code'
                />
              </div>
              <div className={styles.countryDropDownWrap}>
                <CommonDropdown
                  containerRef={containerRef}
                  data={deliveryData?.dispatchMethods || []}
                  itemComponent={itemComponentCurrency}
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                />
              </div>
            </div>

            {requestError ? (
              <RequestError requestError={requestError} />
            ) : (
              <button
                type='button'
                className='btn btn-primary'
                disabled={disableBtn()}
                style={{ marginTop: 50, opacity: disableBtn() ? 0.5 : 1 }}
                onClick={handleNext}
              >
                {isLoading ? <span className='spinner-border' /> : 'Next'}
              </button>
            )}
          </div>
        ) : null}

        {step === STEPS.SUMMARY && !isLoading ? (
          <div className={styles.section}>
            <div>
              <div className={styles.title}>Card Order Summary</div>
              <div className={styles.description}>Make sure the following information is correct.</div>
            </div>

            <div className={styles.summaryContent}>
              {deliveryData ? (
                <div>
                  <div className={styles.summaryLabel}>Delivery address</div>
                  <div className={styles.summaryText}>
                    {deliveryData.deliveryAddress?.address1} {deliveryData.deliveryAddress?.address2}{' '}
                    {deliveryData.deliveryAddress?.city} {deliveryData.deliveryAddress?.countryCode}-
                    {deliveryData.deliveryAddress?.postalCode}
                  </div>
                </div>
              ) : null}

              <div>
                <div className={styles.summaryLabel}>Card</div>
                <div className={styles.summaryText}>Fideum Crypto Card</div>
              </div>
              <div>
                <div className={styles.summaryLabel}>Plastic Card Issuance Fee</div>
                <div className={styles.summaryText}>5 EUR</div>
              </div>
              <div>
                <div className={styles.summaryLabel}>Card Delivery Fee</div>
                <div className={styles.summaryText}>{selectedData.price} EUR</div>
              </div>
            </div>

            {requestError ? (
              <RequestError requestError={requestError} />
            ) : (
              <button
                type='submit'
                className='btn btn-primary'
                disabled={disableBtn()}
                style={{ marginTop: 30, opacity: disableBtn() ? 0.5 : 1 }}
                onClick={() => handleDelivery()}
              >
                {isLoading ? <span className='spinner-border' /> : 'Confirm & Order'}
              </button>
            )}
          </div>
        ) : null}

        {isLoading ? (
          <div className='justify-row-center'>
            <Spinner />
          </div>
        ) : null}
      </div>
    </div>
  )
}
