import { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Modal } from 'components'
import { pages } from 'constant'
import { LockBbankModal, NewEarningModal } from 'features/modals'
import { getBalanceString } from 'utils'
import { EVENT_NAMES, RollingLeveledStakingPlansResponse, StakingContract, useAnalytics } from 'wip/services'
import { $allStakingContracts, $campaignStakingContracts, $stakingPlans, $tierLevel } from 'model/cefi-stacking'

import { $isMobile } from '../../model'
import { $assetsListData } from '../../model/cefi-combain-assets-data'
import { NewSuperchargeEarningModal } from '../modals/new-supercharge-earning'
import { EarnRow } from './row'
import { EarnSuperchargeRow } from './row-supercharge'
import styles from './styles.module.scss'

export function Earn() {
  const assets = useStore($assetsListData)

  const tierLevel = useStore($tierLevel)
  const stakingPlans = useStore($stakingPlans)
  const stackingList = useStore($allStakingContracts)
  const isMobile = useStore($isMobile)
  const superChargeList = useStore($campaignStakingContracts)
  const { myLogEvent } = useAnalytics()

  const [inputAmount, setInputAmount] = useState('')
  const [inputBbankError, setInputBbankError] = useState(false)
  const [inputError, setInputError] = useState('')
  const [isSupercharge, setIsSupercharge] = useState(false)

  useEffect(() => {
    myLogEvent(EVENT_NAMES.WEB_EARN_PAGE_OPENED)
  }, [])

  const assetIdRollingLeveledPlans =
    stakingPlans.rollingLeveledPlans && stakingPlans.rollingLeveledPlans.length
      ? stakingPlans.rollingLeveledPlans[0].assetId
      : null

  const availableBalance = assets.find(asset => asset.assetId === assetIdRollingLeveledPlans)
  const rollingLeveledStakeAmount = stackingList
    .filter(stake => stake.assetId === assetIdRollingLeveledPlans)
    .reduce((acc, stake) => {
      return acc + +stake.amount
    }, 0)

  const rollingLeveledPlans = stakingPlans.rollingLeveledPlans
    .slice()
    .sort((a, b) => +a.minimalTargetPlanAmount - +b.minimalTargetPlanAmount)
  const nextPlan = rollingLeveledPlans.find(plan => rollingLeveledStakeAmount < +plan.minimalPlanAmount)
  const nextAmount = nextPlan ? nextPlan.minimalPlanAmount - rollingLeveledStakeAmount : 0

  const stakeRollingLeveled = stackingList.filter(stake => stake.isRollingLeveled)
  const currentStakingApyPercent = stakeRollingLeveled.length ? stakeRollingLeveled[0].stakingApyPercent : 0

  const maxOtherApiPercent = stakingPlans.simplePlans.reduce((max, plan) => {
    return plan.stakingApyPercent > max ? +plan.stakingApyPercent : max
  }, 0)

  const calcMinimalStakingAmount = (plans: RollingLeveledStakingPlansResponse[], contracts: StakingContract[]) => {
    const myPlan = contracts.find(stake => stake.assetId === 'FI')
    const myPlanId = myPlan?.planId

    const myPlanMinimalStakingAmount = plans.find(item => item.id === myPlanId)?.minimalStakingAmount

    if (myPlanMinimalStakingAmount) {
      return myPlanMinimalStakingAmount
    } else {
      const minStakingAmount = plans.length ? Math.min(...plans.map(plan => plan.minimalStakingAmount)) : undefined
      return minStakingAmount || 1
    }
  }

  const validateInputAmount = (value: string) => {
    const minimalStakingAmount = calcMinimalStakingAmount(stakingPlans.rollingLeveledPlans, stackingList)

    if (!Number(value)) {
      setInputBbankError(true)
      return false
    } else if (minimalStakingAmount > +value) {
      setInputError(`Minimal staking amount ${minimalStakingAmount}`)
      setInputBbankError(true)
      return false
    } else if (Number(availableBalance?.availableBalance || 0) < +value) {
      setInputError('Not enough balance')
      setInputBbankError(true)
      return false
    } else {
      setInputError('')
      setInputBbankError(false)
      return true
    }
  }

  const handleLockRollingLeveled = (): void => {
    const formattedAmount = inputAmount.replace(',', '.')
    const isValid = validateInputAmount(formattedAmount)

    if (isValid) {
      setInputBbankError(false)

      Modal.open(
        <LockBbankModal
          amount={formattedAmount}
          currentStakingApyPercent={currentStakingApyPercent}
          maxOtherApiPercent={maxOtherApiPercent}
          assetIdRollingLeveledPlans={assetIdRollingLeveledPlans}
        />,
        { title: pages.EARN.name, isFullScreen: true }
      )
    }
  }

  const handleNewEarning = (): void => {
    if (!stakingPlans.simplePlans?.length) {
      window.open('https://blockbank.ai/announcements/new-tier-system-and-referral-program/')
    } else {
      Modal.open(<NewEarningModal />, { title: pages.EARN.name, isFullScreen: true })
    }
  }

  const handleNewSupercharge = (): void => {
    Modal.open(<NewSuperchargeEarningModal setIsSupercharge={setIsSupercharge} />, {
      title: pages.EARN.name,
      isFullScreen: true,
    })
  }

  const handleChangeEarn = () => {
    setIsSupercharge(!isSupercharge)
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTitleRow}>
        <div className={styles.manageCardSwitchBlock}>
          <div
            onClick={handleChangeEarn}
            className={clsx(styles.manageCardBtn, !isSupercharge ? styles.manageCardBtnActive : '')}
          >
            Earnings
          </div>
          <div
            onClick={handleChangeEarn}
            className={clsx(styles.manageCardBtn, isSupercharge ? styles.manageCardBtnActive : '')}
          >
            Supercharge
          </div>
        </div>
        <button
          onClick={isSupercharge ? handleNewSupercharge : handleNewEarning}
          className={clsx(styles.newEarn, 'btn', 'btn-primary')}
          style={isSupercharge && !superChargeList.length ? { display: 'none' } : {}}
        >
          {isSupercharge ? '+ New Supercharge' : '+ New Earning'}
        </button>
      </div>
      {isSupercharge ? null : (
        <div className={styles.earnInfoBlock}>
          <div className={clsx(styles.earnInfoItem, styles.earnInfoItemOrder1)}>
            <div className={styles.earnInfoItemTitle}>Total Locked</div>
            <div className={styles.earnInfoData}>
              {getBalanceString(rollingLeveledStakeAmount, 4)} {assetIdRollingLeveledPlans}
            </div>
          </div>
          <div
            onClick={() => {
              handleNewEarning()
              // window.open('https://blockbank.ai/announcements/new-tier-system-and-referral-program/')
            }}
            className={clsx(styles.earnInfoItem, styles.earnInfoItemOrder3)}
            style={{ cursor: 'pointer' }}
          >
            <div className={styles.tierInfo}>
              <div className={styles.earnInfoItemTitle}>Assigned Tier</div>
              <div style={{ display: 'flex' }}>
                <div className={styles.dot} />
                <div className={styles.dot} />
                <div className={styles.dot} />
              </div>
            </div>
            <div className={styles.earnInfoData}>
              <div>Tier {tierLevel}</div>
              <div className={styles.dot2} />
              <div>
                {+currentStakingApyPercent}% APY {assetIdRollingLeveledPlans}
              </div>
              <div className={styles.dot2} />
              <div>{maxOtherApiPercent}% APY Other</div>
            </div>
          </div>
          <div className={clsx(styles.earnInfoItem, styles.earnInfoItemOrder2)}>
            <div className={styles.earnInfoItemTitle}>Lock to Reach Next Tier</div>
            {tierLevel === 4 ? (
              <div className={styles.earnInfoData}>Max Level Reached</div>
            ) : (
              <div className={styles.earnInfoData}>
                {getBalanceString(nextAmount, 4)} {assetIdRollingLeveledPlans}
              </div>
            )}
          </div>

          <div className={styles.earnLastInfoItem}>
            <div className={styles.lockInputWrap}>
              <input
                id='amount'
                type='number'
                inputMode='numeric'
                className={`${styles.lockInput} ${styles.noSpinners}`}
                style={inputBbankError ? { border: '1px solid red' } : {}}
                placeholder='Enter Amount to Lock'
                value={inputAmount}
                onChange={value => {
                  setInputAmount(value.target?.value)
                  setInputError('')
                  setInputBbankError(false)
                }}
              />

              <button onClick={handleLockRollingLeveled} className={clsx('btn', 'btn-primary', styles.lockInputBtn)}>
                Lock {assetIdRollingLeveledPlans}
              </button>
            </div>
            {inputError ? (
              <div className={styles.reachNextTier} style={{ color: 'red' }}>
                {inputError}
              </div>
            ) : (
              <div className={styles.reachNextTier}>
                {tierLevel === 4
                  ? ''
                  : `Lock ${getBalanceString(nextAmount, 8)} ${assetIdRollingLeveledPlans} to Reach Next Tier`}
              </div>
            )}
          </div>
        </div>
      )}

      <div style={{ height: 24 }} />
      <div className={styles.table}>
        {!isSupercharge && stackingList.length ? (
          <>
            <div className={clsx(styles.row, styles.header)}>
              <div className={clsx(styles.cell, styles.cell3)}>Name</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell1)}>Locked</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell4)}>Value</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell5)}>APY</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell3)}>Paid Interest</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell1)}>Total Duration</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell4)}>Days Left</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell2)}>Actions</div>
            </div>
            {stackingList
              .slice()
              .sort((a, b) => {
                if (a.assetId === 'FI' && a.assetId !== b.assetId) {
                  return -1
                }
                return 0
              })
              .map(stake => {
                return (
                  <div key={stake.id}>
                    <EarnRow stake={stake} />
                  </div>
                )
              })}
          </>
        ) : null}

        {!isSupercharge && !stackingList.length ? (
          <div className={styles.clearStakeWrap}>
            <div className={styles.clearStake}>
              <div className={styles.clearStakeWrapTitle}>Lock ${assetIdRollingLeveledPlans} to Unlock Earning</div>
              <div className={styles.clearStakeWrapDescription}>
                Lock ${assetIdRollingLeveledPlans} tokens to unlock earnings and get assigned a tier level to earn on
                your crypto.
                {/*<a*/}
                {/*  href='https://blockbank.ai/announcements/new-tier-system-and-referral-program/'*/}
                {/*  target='_blank'*/}
                {/*  style={{ fontWeight: 600, cursor: 'pointer' }}*/}
                {/*  rel='noreferrer'*/}
                {/*>*/}
                {/*  Learn More Here.*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        ) : null}

        {isSupercharge && !superChargeList.length ? (
          <div className={styles.superchargeClearWrap}>
            <div className={styles.superchargeTextWrap}>
              <div className={styles.superchargeTitle}>Join Supercharge Staking and Win Big!</div>
              <div className={styles.superchargeDescription}>
                Dive into our exclusive Supercharge Staking and amplify your earnings! Take control of your financial
                future and start staking today!
              </div>
              {isMobile ? (
                <div onClick={handleNewSupercharge} className={styles.getStarted}>
                  Get Started
                </div>
              ) : (
                <button
                  onClick={handleNewSupercharge}
                  className={clsx(styles.newEarn, 'btn', 'btn-primary')}
                  style={{ backgroundColor: 'white', color: 'var(--mainBlue)', marginTop: 54 }}
                >
                  Get Started
                </button>
              )}
            </div>
          </div>
        ) : null}

        {isSupercharge && superChargeList.length ? (
          <>
            <div className={clsx(styles.row, styles.header)}>
              <div className={clsx(styles.cell, styles.cell3)}>Name</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell1)}>Locked</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell6)}>Estimated Reward</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell3)}>Locked until:</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell1)}>Days Left</div>
              <div style={{ flexGrow: 1 }} />
              <div className={clsx(styles.cell, styles.cell2)}>Actions</div>
            </div>
            {superChargeList
              .slice()
              .sort((a, b) => {
                const today = new Date().getTime()
                const dateA = new Date(a.closeDate).getTime()
                const dateB = new Date(b.closeDate).getTime()
                const diffA = dateA - today
                const diffB = dateB - today

                if (diffA <= 0 && diffB <= 0) {
                  return diffA - diffB
                } else if (diffA <= 0) {
                  return -1
                } else if (diffB <= 0) {
                  return 1
                } else {
                  return diffA - diffB
                }
              })
              .map(stake => {
                return (
                  <div key={stake.id}>
                    <EarnSuperchargeRow setIsSupercharge={setIsSupercharge} stake={stake} />
                  </div>
                )
              })}
          </>
        ) : null}
      </div>
    </div>
  )
}
