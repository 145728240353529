import clsx from 'clsx'

import styles from './styles.module.scss'

type Props = {
  title: string
  action: () => void
  buttonActive: boolean
  disabled?: boolean
}

export const FilterButton = ({ title, action, buttonActive, disabled = false }: Props) => {
  return (
    <div
      className={clsx(
        disabled ? styles.filterButtonDisabled : styles.filterButton,
        buttonActive ? styles.filterButtonActive : ''
      )}
      onClick={disabled ? () => {} : action}
    >
      <div className={clsx(styles.filterButtonTitle, buttonActive ? styles.filterButtonTitleActive : '')}>{title}</div>
    </div>
  )
}
