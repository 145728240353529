import { useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Modal, ProgressBar } from '../../components'
import { LaunchpadStatus } from '../../components/launchpad-status'
import { pages } from '../../constant'
import { $assetsListData } from '../../model/cefi-combain-assets-data'
import { addCommasToDisplayValue } from '../../utils/add-commas-to-display-value'
import { formatDateOrTime } from '../../utils/formats'
import { getAssetIcon } from '../../utils/getAssetIcon'
import { ShortLaunchpadProject } from '../../wip/services/launchpads'
import { LaunchpadShareModal } from '../modals/launchpad-share'
import share from './share.svg'
import styles from './styles.module.scss'

type Props = {
  launchpad: ShortLaunchpadProject
}

export function LaunchpadEventItem({ launchpad }: Props) {
  const assets = useStore($assetsListData)

  const navigate = useNavigate()

  const goToAssetContract = (url: string) => {
    if (window) {
      window.open(url)
    }
  }

  const progress = () => {
    return 100 - (+launchpad.supplyRaisedAmount * 100) / +launchpad.supplyAmount
  }

  const handleShare = async () => {
    await navigator.clipboard.writeText(
      `${window?.location?.origin || 'app.fideum.group'}/launchpad/${launchpad.projectId}`
    )
    Modal.open(<LaunchpadShareModal />, {
      title: pages.PORTFOLIO.name,
      variant: 'center',
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          backgroundColor: '#fff',
          borderRadius: '15px',
          overflow: 'hidden',
          width: 755,
        }}
      >
        <div
          style={{
            padding: 35,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex' }}>
            <img
              style={{ width: 80, height: 80, borderRadius: 5, marginRight: 24 }}
              src={launchpad?.iconUrl || require('../../assets/images/crypto-icons/unknown.png')}
              alt=''
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 7,
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
                className={styles.titleText}
              >
                {launchpad.name}
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
                {launchpad.tags && launchpad.tags.length
                  ? launchpad.tags.map(tag => {
                      return (
                        <div key={tag.name} className={styles.launchpadItemBtn}>
                          {tag.name}
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <LaunchpadStatus status={launchpad?.status || ''} />
            </div>
          </div>
          <div style={{ margin: '25px 0', borderTop: '1px solid #F3F3F4' }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
              <div className={styles.descriptionTitle}>Description</div>
              <div
                className={styles.descriptionText}
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}
              >
                {launchpad.shortDescription}
              </div>
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
              <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Token Price</div>
                <div className={styles.infoBlockAmount}>
                  {addCommasToDisplayValue(launchpad.buyingAssetPrice || '0', 2)} {launchpad.buyingAssetId}
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Contracts</div>
                <div className={styles.infoBlockAmount} style={{ display: 'flex', gap: 9 }}>
                  {launchpad.contracts && launchpad.contracts.length
                    ? launchpad.contracts.map(contract => {
                        const nativeAsset = assets.find(assetItem => {
                          if (contract.type === 'BSC' && assetItem.symbol === 'BNB') return true
                          return assetItem.symbol === contract.type
                        })
                        const icon = getAssetIcon(nativeAsset?.symbol)
                        return (
                          <img
                            key={contract.type}
                            onClick={() => goToAssetContract(contract.url)}
                            style={{ width: 16, height: 16, borderRadius: 5, cursor: 'pointer' }}
                            src={icon}
                            alt=''
                          />
                        )
                      })
                    : null}
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.infoBlockTitle}>Launch Date</div>
                <div className={styles.infoBlockAmount}>{formatDateOrTime(launchpad.launchDate)}</div>
              </div>
            </div>
            <div style={{ gap: 12, display: 'flex', flexDirection: 'column' }}>
              <div className={styles.descriptionTitle}>Total raised</div>
              <div className={styles.infoBlockAmount}>
                {addCommasToDisplayValue(launchpad.supplyRaisedAmount, 2)} /{' '}
                {addCommasToDisplayValue(launchpad.supplyAmount, 2)} {launchpad.supplyAssetId}
              </div>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  flexGrow: 1,
                  marginLeft: -5,
                }}
              >
                <ProgressBar value={progress() || 0} />
              </div>
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
              <button
                type='submit'
                className={clsx('btn btn-primary')}
                style={{ maxWidth: 120, minHeight: 40, fontSize: 14 }}
                onClick={() => navigate(`${pages.LAUNCHPAD.path}/${launchpad.projectId}`)}
              >
                Learn More
              </button>
              <div
                onClick={handleShare}
                style={{
                  display: 'flex',
                  gap: 12,
                  borderRadius: 10,
                  border: '1px solid #FFF',
                  height: 40,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 116,
                  alignSelf: 'end',
                  cursor: 'pointer',
                  backgroundColor: 'rgba(156, 136, 253, 0.1)',
                }}
              >
                <img src={share} alt='' />
                <div className={styles.shareText}>Share</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
