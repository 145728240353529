import { useEffect } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Modal } from 'components'
import { pages } from 'constant'
import { ChangeEmailModal } from 'features/modals/change-email'
import { $userEmail, getUserEmailFx } from 'model/user-email'
import editSvg from 'assets/icons/edit.svg'

import styles from './styles.module.scss'

export function UserEmail() {
  const userEmail = useStore($userEmail)

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>User Email</div>
        </div>

        <div className={styles.settingsItemDescription}>
          Your account is registered with the following email. You can update your email address.
        </div>

        <div
          onClick={() =>
            Modal.open(<ChangeEmailModal />, {
              title: pages.SETTINGS.name,
              isFullScreen: true,
            })
          }
          className={clsx(styles.twoFaBtn, styles.copyBtn)}
          style={{ color: '#445374' }}
        >
          {userEmail}
          <div className={styles.btnIcon}>
            <img src={editSvg} alt='Edit' />
          </div>
        </div>
      </div>
    </div>
  )
}
