import { useState } from 'react'
import { useStore } from 'effector-react'

import { CheckedIcon, CopyIcon } from 'icons'
import { theme } from 'config'
import { $isMobile } from 'model'

import styles from './styles.module.scss'

const mockInstructions = [
  { name: 'Account Holder Name', value: 'UAB HODL Services' },
  { name: 'IBAN Account', value: 'LT61 3550 0200 0002 7329' },
  { name: 'Bank Name', value: 'UAB "Pervesk"' },
  { name: 'BIC / SWIFT Code', value: 'UAPELT22XXX' },
  { name: 'Bank Address', value: 'Gedimino pr. 5-3, LT-01103, Vilnius, Lithuania' },
]

const mockInstructionsFideum = [
  { name: 'Account Holder Name', value: 'UAB HODL Services' },
  { name: 'IBAN Account', value: 'LT98 3980 8100 0000 0128' },
  { name: 'Bank Name', value: 'UAB Alphapay' },
  { name: 'BIC / SWIFT Code', value: 'UANOLT22XXX' },
  { name: 'Bank Address', value: 'Naugarduko g. 68B, 03203, Vilnius, Lithuania' },
]

const mockInstructionsKaizen = [
  { name: 'Account Holder Name', value: 'UAB HODL Services' },
  { name: 'IBAN Account', value: 'LT59 3550 0200 0003 6289' },
  { name: 'Bank Name', value: 'UAB "Pervesk"' },
  { name: 'BIC / SWIFT Code', value: 'UAPELT22XXX' },
  { name: 'Bank Address', value: 'Gedimino pr. 5-3, LT-01103, Vilnius, Lithuania' },
]

export interface DepositBankingModal {
  codeForInvitation: string
}

const bankData =
  theme === 'fideum' ? mockInstructionsFideum : theme === 'kaizen' ? mockInstructionsKaizen : mockInstructions

export function DepositBankingModal({ codeForInvitation }: DepositBankingModal) {
  const [isCopied, setIsCopied] = useState(false)
  const isMobile = useStore($isMobile)

  return (
    <div className={styles.content}>
      <div className={styles.contentWrap}>
        <div className={styles.transfer}>Top Up via Wire Transfer</div>
        <div className={styles.transferDescription}>
          The bank account from which you are depositing must be under your full name. The minimum deposit amount is €50
          or it will not be credited. Please note that SWIFT deposits incur a €25 fee.
        </div>
        {bankData.map(item => {
          return (
            <div
              key={item.name}
              onClick={() => {
                navigator.clipboard.writeText(item.value).then(() => {
                  setIsCopied(true)
                  setTimeout(() => {
                    setIsCopied(false)
                  }, 2000)
                })
              }}
              className={styles.itemWrap}
            >
              <div>{item.name}</div>
              <div className={styles.copyString}>
                <div>{item.value}</div>
                <CopyIcon isMobile={isMobile} fill='var(--mainBlue)' />
              </div>
            </div>
          )
        })}
        {!codeForInvitation ? null : (
          <div
            onClick={() => {
              navigator.clipboard.writeText(codeForInvitation).then(() => {
                setIsCopied(true)
                setTimeout(() => {
                  setIsCopied(false)
                }, 2000)
              })
            }}
            className={styles.itemWrap}
          >
            <div>
              Your Reference Code <br />
              (Must be included in the description)
            </div>
            <div className={styles.copyString}>
              <div>{codeForInvitation}</div>
              <CopyIcon isMobile={isMobile} fill='var(--mainBlue)' />
            </div>
          </div>
        )}

        <div style={{ height: 100, alignItems: 'center', display: 'flex' }}>
          {isCopied && (
            <div
              style={{
                backgroundColor: '#262832',
                color: '#FFFFFF',
                height: 30,
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                padding: '0 11px',
                maxWidth: 100,
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              <div style={{ marginRight: 6, marginBottom: 4 }}>Copied</div>
              <CheckedIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
