import React from 'react'
import { useStore } from 'effector-react'

import { TYPE_TXN_HISTORY } from 'features/transactions-history/constant-type-transaction-history'

import { $assetsListData } from '../../../model/cefi-combain-assets-data'
import styles from './styles.module.scss'

type CustomRadioButtonType = {
  checked: boolean
}

const CustomRadioButton = ({ checked }: CustomRadioButtonType) => {
  return <div className={styles.radioButton}>{checked && <div className={styles.radioButtonInner} />}</div>
}

type ListRowType = {
  item: string
  selected: boolean
  onPress: () => void
  //TODO: Андрею проверить слияние по transactionType
  // transactionType: string
}
const ListRow = ({ item, selected, onPress }: ListRowType) => {
  const assets = useStore($assetsListData)

  const asset = assets.find(assetItem => assetItem.assetId === item)

  return (
    <div onClick={onPress} className={styles.listRowSelect}>
      <div className={styles.listRowSelectTitleWrap}>
        <img className={styles.listRowSelectIcon} alt='icon' src={asset?.icon} />
        <div>
          <div className={styles.listRowSelectTitle}>{item}</div>
        </div>
      </div>
      <CustomRadioButton checked={selected} />
    </div>
  )
}

type Props = {
  data: string[]
  selectedItems: string[]
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  // transactionType: string
}

export const FilterList = ({ data, selectedItems, setSelectedItems }: Props) => {
  const handleRowPress = (item: string) => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter(prevItem => prevItem !== item)
      } else {
        return [...prevSelectedItems, item]
      }
    })
  }

  return (
    <div className={styles.filterListWrap}>
      {data.map(item => (
        <ListRow
          key={item}
          item={item}
          selected={selectedItems.includes(item)}
          onPress={() => handleRowPress(item)}
          // transactionType={transactionType}
        />
      ))}
    </div>
  )
}
