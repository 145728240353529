import { useEffect, useState } from 'react'

import { Modal, Spinner } from 'components'
import { HistoryResponseError } from 'components/history-response-error'
import { NoHistoryPlaceholder } from 'components/no-history-placeholder'
import { NoResultsFilter } from 'components/no-results-filter'
import { FilterOptionsType, GroupedOptionsType, TransactionHistoryFilter } from 'features/modals/transaction-filter'
import { FilterBar } from 'features/modals/transaction-filter/filter-bar'
import { getToken } from 'utils'
import { CryptoAndFiatHistoryType, getTransactionsHistoryFx } from 'model/cefi-transactions-history'
import filterIcon from 'assets/icons/history/filter-icon.svg'

import { TYPE_TXN_HISTORY } from './constant-type-transaction-history'
import { GroupedSectionList } from './grouped-section-list'
import styles from './styles.module.scss'

type Props = {
  filterAssetId?: string
  rewardNameFilter?: string
}

export function TransactionHistoryCrypto({ filterAssetId, rewardNameFilter }: Props) {
  const storeToken = getToken()
  const [txnHistoryResponse, setTxnHistoryResponse] = useState<CryptoAndFiatHistoryType[]>([])

  const [historyData, setHistoryData] = useState<CryptoAndFiatHistoryType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType[]>([])
  const [responseError, setResponseError] = useState('')

  const filterData = (data: CryptoAndFiatHistoryType[], filterOptionsData: FilterOptionsType[]) => {
    if (!filterOptionsData.length) return data

    const groupedOptions = filterOptionsData.reduce<GroupedOptionsType>((acc, option) => {
      const field = option.field.toLowerCase()
      if (!acc[field]) {
        acc[field] = []
      }
      acc[field].push(option.value)
      return acc
    }, {})

    return data.filter(item => {
      const time = item.time || item.rewardTime || item.transactionTime || ''

      return Object.keys(groupedOptions).every(field => {
        const values = groupedOptions[field]

        if (field === 'asset_type') {
          return values.some(value => {
            const assetValue = value.startsWith('$') ? value.substring(1) : value
            return item.assetId === assetValue || item.toAssetId === assetValue || item.fromAssetId === assetValue
          })
        } else if (field === 'transaction_type') {
          return values.some(value => {
            const transactionValue = value === 'Reward' ? 'Earning Reward' : value
            return (item.title || '').toLowerCase().includes(transactionValue.toLowerCase())
          })
        } else if (field === 'time') {
          return values.some(value => {
            const [start, end] = value.split(' - ')
            return new Date(time) >= new Date(start) && new Date(time) <= new Date(end)
          })
        }
        return false
      })
    })
  }

  useEffect(() => {
    setHistoryData(filterData(txnHistoryResponse, filterOptions))
  }, [filterOptions, txnHistoryResponse])

  const fetchData = async () => {
    setIsLoading(true)
    setResponseError('')

    try {
      if (storeToken) {
        const response = (await getTransactionsHistoryFx()) as unknown as CryptoAndFiatHistoryType[]

        if (response) {
          setTxnHistoryResponse(
            //TODO refactor
            response
              .filter(historyAsset => {
                if (!rewardNameFilter) return true
                return historyAsset.rewardName === rewardNameFilter
              })
              .filter(historyAsset => {
                if (filterAssetId) {
                  return (
                    historyAsset.assetId === filterAssetId ||
                    historyAsset.fromAssetId === filterAssetId ||
                    historyAsset.toAssetId === filterAssetId
                  )
                }

                return true
              })
          )
        }
      }
    } catch (error: any) {
      setResponseError('error')
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 100)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancelFilter = (value: string) => {
    if (value === 'cancelAllFilter') {
      setFilterOptions([])
      return
    }
    const data = filterOptions.filter(option => option.value !== value)

    setFilterOptions(data)
  }

  const handleOpenFilter = () => {
    Modal.open(
      <TransactionHistoryFilter
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        transactionType={TYPE_TXN_HISTORY.CRYPTO}
        responseData={txnHistoryResponse}
        filterAssetId={filterAssetId}
        rewardNameFilter={rewardNameFilter}
      />,
      { title: '', variant: 'right', isFullScreen: true }
    )
  }

  const iconGroup = () => {
    return (
      <div className={styles.btnGroupWrap}>
        <div className={styles.btnGroupButton} onClick={handleOpenFilter}>
          <div className={styles.btnGroupButtonText}>Filters</div>
          <img className={styles.btnGroupIcon} alt='icon' src={filterIcon} />
        </div>

        {/* <div className={styles.btnGroupButton} onClick={() => {}}> //Get CSV
          <div className={styles.btnGroupButtonText}>Get CSV</div>
          <img className={styles.btnGroupIcon} alt='icon' src={downloadIcon} />
        </div> */}
      </div>
    )
  }

  return (
    <div className={styles.containerWrap}>
      {filterAssetId || rewardNameFilter ? null : <div className={styles.title}>Transactions</div>}
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>{rewardNameFilter ? 'Cashback' : 'Recent Transactions'}</div>
          {!isLoading && !responseError && txnHistoryResponse?.length ? iconGroup() : null}
        </div>

        <div className={styles.horizontalLine}></div>

        {!isLoading && !responseError && filterOptions.length ? (
          <FilterBar filterOptions={filterOptions} handleCancelFilter={handleCancelFilter} />
        ) : null}

        {isLoading ? (
          <div className={styles.loadingWrap}>
            <Spinner />
          </div>
        ) : null}
        {!isLoading && !responseError && historyData?.length ? <GroupedSectionList data={historyData} /> : null}
        {!isLoading && !responseError && !txnHistoryResponse?.length ? <NoHistoryPlaceholder /> : null}
        {!isLoading && !responseError && !historyData?.length && txnHistoryResponse?.length ? (
          <NoResultsFilter />
        ) : null}
        {!isLoading && responseError ? <HistoryResponseError /> : null}
      </div>
    </div>
  )
}
