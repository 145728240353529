import { localStorageKeys } from 'constant'

export const getToken = (): string => {
  return localStorage.getItem(localStorageKeys.token) || ''
}

export const saveToken = (token: string): void => {
  localStorage.setItem(localStorageKeys.token, token)
}

export const clearToken = (): void => {
  localStorage.removeItem(localStorageKeys.token)
}

export const getRefreshToken = (): null | string => {
  return localStorage.getItem(localStorageKeys.refreshToken)
}

export const saveRefreshToken = (token: string): void => {
  localStorage.setItem(localStorageKeys.refreshToken, token)
}

export const clearRefreshToken = (): void => {
  localStorage.removeItem(localStorageKeys.refreshToken)
}

// export const save2faStatus = (status: boolean): void => {
//   const stringStatus = status?.toString() || 'false'
//   localStorage.setItem(localStorageKeys.twoFAStatus, stringStatus)
// }

// export const get2faStatus = (): boolean => !!localStorage.getItem(localStorageKeys.twoFAStatus)

// export const saveCurrency = (currency: string): void => {
//   localStorage.setItem(localStorageKeys.currency, currency)
// }

// export const getCurrency = (): null | string => {
//   return localStorage.getItem(localStorageKeys.currency)
// }
