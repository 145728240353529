import QRCode from 'react-qr-code'
import { useStore } from 'effector-react'

import { DepositInfo } from 'wip/services'
import { CopyIcon } from 'icons'
import { $isMobile } from 'model'

import styles from './styles.module.scss'

type QRCodeBlock = {
  dataString: string
  isDeposit?: DepositInfo
  stringCode?: string
  copiedAction?: () => void
}

export function QRCodeBlock({ dataString, isDeposit, stringCode, copiedAction }: QRCodeBlock) {
  const isMobile = useStore($isMobile)

  const handleOnClickCopied = async () => {
    if (!stringCode) return

    try {
      await navigator.clipboard.writeText(stringCode)
      if (copiedAction) {
        copiedAction()
      }
    } catch (error) {
      console.error('Error copying text:', error)
    }
  }

  return (
    <div className={styles.qrWrap}>
      <div className={styles.qrContent}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={dataString}
          viewBox='0 0 256 256'
        />
      </div>

      <div className={styles.qrText}>
        <div className={styles.addressText}>{stringCode ?? dataString}</div>
        <div style={{ cursor: 'pointer' }} onClick={handleOnClickCopied}>
          <div style={{ marginRight: 15 }}>
            <CopyIcon isMobile={isMobile} fill='var(--mainBlue)' />
          </div>
        </div>
      </div>
      {isDeposit && (
        <div className={styles.note}>
          <div style={{ fontWeight: 700 }}>Note</div>: Please ensure you are depositing asset using{' '}
          {isDeposit.networkId} network.
        </div>
      )}
    </div>
  )
}
