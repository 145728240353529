import { combine } from 'effector'

import { AssetInfo, ExchangeRateRaw, UserAsset } from '../wip/services'
import { $assetsRates } from './cef-rates-coingecko'
import { $assets } from './cefi-assets-list'
import { $myAssets } from './cefi-my-assets-list'
import { $favouriteAssets } from './favourite-assets'

export interface CombainedObject extends AssetInfo, UserAsset {
  icon: string
  eur: {
    cash: number
    price: number
    priceChangePercentage24h: string
  }
  usd: {
    cash: number
    price: number
    priceChangePercentage24h: string
  }
  coinGeckoId: string
  assetId: string
  isFavourite?: boolean

  // //DEF
  // walletId?: string
  // type?: ECoinTypeNetworks
  // isNative?: boolean
  // assetWalletAddress?: string
  // decimals?: number
}

export const $assetsListData = combine(
  [$assets, $myAssets, $assetsRates, $favouriteAssets],
  ([assets, myAssets, assetsRates, favouriteAssets]) => {
    const result: CombainedObject[] = []

    // Объединяем данные из трех сторов
    assets.forEach(asset => {
      const assetId = asset.assetId

      if (assetId === 'EUR') return
      if (assetId === 'USD') return

      const myAsset = myAssets.find(myAsset => myAsset.assetId === assetId) || ({} as UserAsset)

      const assetRateUSD =
        assetsRates.find(rate => rate.fromAssetId === assetId && rate.toAssetId === 'USD') || ({} as ExchangeRateRaw)
      const assetRateEUR =
        assetsRates.find(rate => rate.fromAssetId === assetId && rate.toAssetId === 'EUR') || ({} as ExchangeRateRaw)

      const usdRate = assetRateUSD?.data || null

      const availableCashUsd = Number(myAsset?.availableBalance || 0) * (usdRate ? usdRate.currentPrice : 0)

      const eurRate = assetRateEUR?.data || null

      const availableCashEur = Number(myAsset?.availableBalance || 0) * (eurRate ? eurRate.currentPrice : 0)

      const symbolPriceChangePercentage = (rate: string) => (Number(rate) > 0 ? '+' : '')

      const combinedObject = {
        ...asset,
        ...myAsset,
        availableBalance: myAsset?.availableBalance || 0,
        icon: usdRate?.image || eurRate?.image,
        eur: {
          cash: availableCashEur,
          price: eurRate?.currentPrice || 0,
          priceChangePercentage24h: `${symbolPriceChangePercentage(
            eurRate?.priceChangePercentage24h?.toString() || '0'
          )}${Number(eurRate?.priceChangePercentage24h || 0).toFixed(2)}`,
        },
        usd: {
          cash: availableCashUsd,
          price: usdRate?.currentPrice || 0,
          priceChangePercentage24h: `${symbolPriceChangePercentage(
            usdRate?.priceChangePercentage24h?.toString() || '0'
          )}${Number(usdRate?.priceChangePercentage24h || 0).toFixed(2)}`,
        },
        coinGeckoId: usdRate?.id || eurRate?.id,
        isFavourite: favouriteAssets.includes(asset.assetId),
      }

      result.push(<CombainedObject>combinedObject)
    })
    // console.log('result',result);

    return result
  }
)

//EUR FIAT
export const $assetEurData = combine([$assets, $myAssets, $assetsRates], ([assets, myAssets, assetsRates]) => {
  const eurToUsdData = assetsRates.find(rate => rate.toAssetId === 'EUR' && rate.fromAssetId === 'USDT')
  const currentPriceUsd = 1 / Number(eurToUsdData?.data?.currentPrice || 0.925)

  const eurAsset = assets.find(asset => asset.assetId === 'EUR') || ({} as AssetInfo)
  const eurMyAsset = myAssets.find(asset => asset.assetId === 'EUR') || ({} as UserAsset)

  const combinedObject = {
    ...eurAsset,
    ...eurMyAsset,
    availableBalance: eurMyAsset?.availableBalance || 0,
    eur: {
      cash: eurMyAsset?.availableBalance || 0,
      price: 1,
      priceChangePercentage24h: `${(0).toFixed(2)}`,
    },
    usd: {
      cash: Number(eurMyAsset?.availableBalance || 0) * currentPriceUsd,
      price: currentPriceUsd || 1.1,
      priceChangePercentage24h: `${(0).toFixed(2)}`,
    },
  }

  return combinedObject
})
