import React, { FunctionComponent, SVGProps, useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from 'effector-react'
import moment from 'moment/moment'
import clsx from 'clsx'

import infoIcon from '../../assets/icons/info-icon.svg'
import { AuthSnackbar, HeaderTitle, Modal, ProgressBar, Spinner } from '../../components'
import { LaunchpadStatus } from '../../components/launchpad-status'
import { SimpleHint } from '../../components/simple-hint'
import { pages } from '../../constant'
import { $assetsListData } from '../../model/cefi-combain-assets-data'
import { $tierLevel } from '../../model/cefi-stacking'
import {
  $allocationLaunchpad,
  $currentLaunchpad,
  getAllocationLaunchpadFx,
  getLaunchpadFx,
} from '../../model/launchpads'
import { snackComponentChangedEv } from '../../model/snackComponent'
import { errorDescriptionHandler, getToken } from '../../utils'
import { addCommasToDisplayValue } from '../../utils/add-commas-to-display-value'
import { formatDateOrTime } from '../../utils/formats'
import { getAssetIcon } from '../../utils/getAssetIcon'
import { LaunchpadService } from '../../wip/services/launchpads'
import { LaunchpadPurchaseModal } from '../modals/launchpad-purchase'
import CHAT from './icons/CHAT.svg'
import DEFAULT from './icons/DEFAULT.svg'
import FACEBOOK from './icons/FACEBOOK.svg'
import LINKEDIN from './icons/LINKEDIN.svg'
import REDDIT from './icons/REDDIT.svg'
import SuccessIconGreen from './icons/successIconGreen.svg'
import TELEGRAM from './icons/TELEGRAM.svg'
import WEBSITE from './icons/WEBSITE.svg'
import WHITEPAPER from './icons/WHITEPAPER.svg'
import X from './icons/X.svg'
import YOUTUBE from './icons/YOUTUBE.svg'
import styles from './styles.module.scss'

const rightIcons: Record<string, any> = {
  WHITEPAPER: WHITEPAPER,
  WEBSITE: WEBSITE,
  CHAT: CHAT,
}

const bottomIcons: Record<string, any> = {
  FACEBOOK: FACEBOOK,
  DEFAULT: DEFAULT,
  LINKEDIN: LINKEDIN,
  REDDIT: REDDIT,
  TELEGRAM: TELEGRAM,
  X: X,
  YOUTUBE: YOUTUBE,
}

export function IndividualLaunchpad() {
  const { launchpadId } = useParams()
  const currentLaunchpad = useStore($currentLaunchpad)
  const assets = useStore($assetsListData)
  const userLevel = useStore($tierLevel)
  const allocationLaunchpad = useStore($allocationLaunchpad)
  const userLevelAllocation = currentLaunchpad?.userLevelAllocations.find(item => item.userLevel === userLevel)
  const token = getToken()

  const minUserLevelAllocation = Math.min(...(currentLaunchpad?.userLevelAllocations?.map(obj => obj.userLevel) || [0]))
  const unlimitedTierLevelAllocation = Math.min(
    ...(currentLaunchpad?.userLevelAllocations?.filter(obj => obj.isUnlimited).map(obj => obj.userLevel) || [5]) // max lv 4
  )

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (launchpadId) {
      getLaunchpadFx(launchpadId).finally(() => {
        setIsLoading(false)
      })
      if (token) {
        getAllocationLaunchpadFx(launchpadId)
      }
    }
  }, [launchpadId])

  if (!currentLaunchpad) {
    return (
      <div style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Spinner />
      </div>
    )
  }

  const progress = () => {
    return 100 - (+currentLaunchpad.supplyRaisedAmount * 100) / +currentLaunchpad.supplyAmount
  }

  const progressAllocation = () => {
    return (
      100 -
      (+(allocationLaunchpad?.totalPurchasedAmount || 0) * 100) / +(userLevelAllocation?.supplyAllocationSize || 0)
    )
  }

  const handlePurchase = () => {
    if (!token) {
      navigate(pages.SignIn.path)
      return
    }
    if (!(minUserLevelAllocation <= userLevel)) {
      navigate(pages.EARN.path)
      return
    }
    Modal.open(
      <LaunchpadPurchaseModal currentLaunchpad={currentLaunchpad} unlimitedLevel={unlimitedTierLevelAllocation} />,
      {
        title: pages.PORTFOLIO.name,
        variant: 'center',
      }
    )
  }

  const handleRefund = async () => {
    setIsLoading(true)
    try {
      await LaunchpadService.createRefundLaunchpad(currentLaunchpad.projectId)
    } catch (error: any) {
      console.log('handleRefund-error', error)
      snackComponentChangedEv(
        <AuthSnackbar title='Something went wrong..' description={errorDescriptionHandler(error.code || '')} />
      )
    }
    setIsLoading(false)
  }

  const handleClaim = async () => {
    setIsLoading(true)
    try {
      await LaunchpadService.createClaimLaunchpad(currentLaunchpad.projectId)
    } catch (error: any) {
      console.log('handleClaim-error', error)
      snackComponentChangedEv(
        <AuthSnackbar title='Something went wrong..' description={errorDescriptionHandler(error.code || '')} />
      )
    }
    setIsLoading(false)
  }

  const showResidualAllocation = () => {
    if (currentLaunchpad.status === 'COMING_SOON') return false
    if (currentLaunchpad.status === 'FULLY_RAISED' && unlimitedTierLevelAllocation) return false
    if (currentLaunchpad.status === 'NOT_RAISED' && userLevel < +(unlimitedTierLevelAllocation || 4)) return false
    if (userLevel >= unlimitedTierLevelAllocation) return false
    return true
  }

  const goToAssetContract = (url: string) => {
    if (window) {
      window.open(url)
    }
  }

  const combinedBlock = () => {
    if (!token) return null
    if (currentLaunchpad.status === 'FINISHED') return null
    if (currentLaunchpad.status === 'CANCELED') return null
    return (
      <>
        {token &&
        allocationLaunchpad &&
        minUserLevelAllocation <= userLevel &&
        currentLaunchpad.status !== 'COMING_SOON' ? (
          <>
            <div className={styles.divider} />
            {showResidualAllocation() ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                    <div className={styles.infoBlockTitle}>Your residual allocation</div>
                    <SimpleHint
                      text={`The amount of tokens you are allocated to buy based on your tier level on Fideum Earnings.`}
                    />
                  </div>
                </div>

                <div className={styles.infoBlockAmount}>
                  {addCommasToDisplayValue(userLevelAllocation?.supplyAllocationSize || '0', 2)} $
                  {currentLaunchpad.supplyAssetId}
                </div>
              </div>
            ) : null}

            <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                  <div className={styles.infoBlockTitle}>Purchased</div>
                  <SimpleHint text={`The amount you have purchased.`} />
                </div>
              </div>

              <div className={styles.infoBlockAmount}>
                {addCommasToDisplayValue(allocationLaunchpad.totalPurchasedAmount || '0', 2)} $
                {currentLaunchpad.supplyAssetId}
              </div>
              {userLevel >= unlimitedTierLevelAllocation ? null : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    flexGrow: 1,
                    marginLeft: -5,
                  }}
                >
                  <ProgressBar value={progressAllocation() || 0} />
                </div>
              )}
              {progressAllocation() <= 0 && unlimitedTierLevelAllocation > userLevel ? (
                <div className={styles.infoBlockTitle} style={{ maxWidth: 310, marginTop: 20 }}>
                  <span style={{ fontWeight: 700 }}>You’ve reached your residual allocation amount.</span> You can still
                  continue purchasing via upgrading your Tier Level
                </div>
              ) : null}
            </div>

            {currentLaunchpad.status === 'FULLY_RAISED' &&
            unlimitedTierLevelAllocation &&
            userLevel >= unlimitedTierLevelAllocation ? (
              <div className={styles.infoBlockTitle} style={{ maxWidth: 252 }}>
                The project has reached its raising goal.{' '}
                <span style={{ fontWeight: 700 }}>
                  You can still buy more, since you are on tier{' '}
                  {userLevel >= unlimitedTierLevelAllocation ? userLevel : unlimitedTierLevelAllocation} level.
                </span>
              </div>
            ) : null}
            {currentLaunchpad.status === 'FULLY_RAISED' &&
            unlimitedTierLevelAllocation &&
            userLevel < unlimitedTierLevelAllocation ? (
              <div className={styles.infoBlockTitle} style={{ maxWidth: 252 }}>
                The project has reached its raising goal.{' '}
                <span style={{ fontWeight: 700 }}>
                  Only Tier {unlimitedTierLevelAllocation} Users can purchase more
                </span>
              </div>
            ) : null}
          </>
        ) : (
          <div
            className={styles.activeText}
            style={{
              fontWeight: 400,
              textAlign: 'center',
              display: currentLaunchpad.status === 'COMING_SOON' ? 'none' : 'flex',
            }}
          >
            Project available only for Tier {minUserLevelAllocation} users and above.
          </div>
        )}
        {currentLaunchpad.status === 'COMING_SOON' ? (
          <div className={styles.activeText} style={{ textAlign: 'center' }}>
            This project has not started the raising period.{' '}
          </div>
        ) : currentLaunchpad.status === 'NOT_RAISED' && userLevel < +(unlimitedTierLevelAllocation || 5) ? (
          <div className={styles.infoBlockTitle}>The project has not reached its raising goal.</div>
        ) : (
          <button onClick={() => handlePurchase()} className={clsx('btn btn-primary')} style={{ minHeight: 40 }}>
            {isLoading ? (
              <span className='spinner-border' />
            ) : (
              <div className={styles.btnTitle}>
                {minUserLevelAllocation <= userLevel
                  ? 'Purchase Allocation'
                  : `Go to “Earn” to upgrade to Tier ${minUserLevelAllocation}`}
              </div>
            )}
          </button>
        )}
      </>
    )
  }

  const finishBlock = (isClaimed?: boolean) => {
    if (!token) return null
    if (currentLaunchpad.status !== 'FINISHED') return null

    return (
      <>
        <div className={styles.divider} />
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: 7, alignItems: isClaimed ? 'center' : 'flex-start' }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
              <div className={styles.infoBlockTitle}>Purchased</div>
              <SimpleHint text={`The amount you have purchased.`} />
            </div>
          </div>

          <div className={styles.infoBlockAmount}>
            {addCommasToDisplayValue(allocationLaunchpad?.totalPurchasedAmount || '0', 2)} $
            {currentLaunchpad.supplyAssetId}
          </div>
          {isClaimed ? (
            <>
              <div className={styles.divider} style={{ width: '100%', margin: '30px 0' }} />
              <img src={SuccessIconGreen} alt={''} />
              <div style={{ height: 30 }} />
              <div className={styles.infoBlockTitle}>Tokens have been successfully claimed.</div>
            </>
          ) : (
            <>
              <div className={styles.infoBlockTitle} style={{ fontWeight: 700, maxWidth: 310 }}>
                This project has successfully reached its goal and is not available for purchase anymore. Claim date is{' '}
                {new Date(currentLaunchpad.claimAvailableFromDate).toLocaleString()}
              </div>

              <button onClick={() => handleClaim()} className={clsx('btn btn-primary')} style={{ minHeight: 40 }}>
                {isLoading ? <span className='spinner-border' /> : <div className={styles.btnTitle}>Claim Tokens</div>}
              </button>
            </>
          )}
        </div>
      </>
    )
  }

  const canceledBlock = (isClaimed?: boolean) => {
    if (!token) return null
    if (currentLaunchpad.status !== 'CANCELED') return null
    return (
      <>
        <div className={styles.divider} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
              <div className={styles.infoBlockTitle}>Purchased</div>
              <SimpleHint text={`The amount you have purchased.`} />
            </div>
          </div>

          <div className={styles.infoBlockAmount}>
            {addCommasToDisplayValue(allocationLaunchpad?.totalPurchasedAmount || '0', 2)} $
            {currentLaunchpad.supplyAssetId}
          </div>
          <div className={styles.infoBlockTitle} style={{ fontWeight: 700, maxWidth: 310 }}>
            The project has been terminated due to violation of our terms and conditions. All purchases will be refunded
            to the investors.
          </div>
          {isClaimed ? (
            <>
              <div className={styles.divider} style={{ width: '100%', margin: '30px 0' }} />
              <div className={styles.infoBlockTitle}>Refund claimed.</div>
            </>
          ) : (
            <button
              onClick={() => handleRefund()}
              className={clsx('btn btn-primary')}
              style={{ minHeight: 40, backgroundColor: '#FF3B30' }}
            >
              {isLoading ? <span className='spinner-border' /> : <div className={styles.btnTitle}>Claim Refund</div>}
            </button>
          )}
        </div>
      </>
    )
  }

  return (
    <div className={styles.individualTokenWrap}>
      <div className={styles.headerTitle}>
        <HeaderTitle headerTitle={currentLaunchpad.name} showBackBtn />
      </div>
      <div className={styles.launchpadInfoWrap}>
        <div className={styles.launchpadInfo}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: 35, alignItems: 'center' }}>
              <img
                style={{ width: 124, height: 124, borderRadius: 5 }}
                src={currentLaunchpad?.iconUrl || require('../../assets/images/crypto-icons/unknown.png')}
                alt=''
              />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 24 }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 8 }}>
                  <div className={styles.titleText}>{currentLaunchpad.name}</div>
                  <div style={{ display: 'flex', gap: 8 }}>
                    {currentLaunchpad.tags && currentLaunchpad.tags.length
                      ? currentLaunchpad.tags.map(tag => {
                          return (
                            <div key={tag.name} className={styles.launchpadItemBtn}>
                              {tag.name}
                            </div>
                          )
                        })
                      : null}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: 16 }}>
                  {currentLaunchpad.icons && currentLaunchpad.icons.length
                    ? currentLaunchpad.icons.map(icon => {
                        // TODO: bottom
                        if (Object.keys(rightIcons).includes(icon.iconType)) return null
                        return (
                          <div onClick={() => window.open(icon.url)} style={{ cursor: 'pointer' }} key={icon.iconType}>
                            <SimpleHint
                              text={icon.iconType.charAt(0) + icon.iconType.slice(1).toLowerCase()}
                              icon={bottomIcons[icon.iconType] || DEFAULT}
                            />
                          </div>
                        )
                      })
                    : null}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: 16 }}>
              {currentLaunchpad.icons && currentLaunchpad.icons.length
                ? currentLaunchpad.icons.map(icon => {
                    // TODO: right
                    if (!Object.keys(rightIcons).includes(icon.iconType)) return null
                    return (
                      <div onClick={() => window.open(icon.url)} style={{ cursor: 'pointer' }} key={icon.iconType}>
                        <SimpleHint
                          text={icon.iconType.charAt(0) + icon.iconType.slice(1).toLowerCase()}
                          icon={rightIcons[icon.iconType] || DEFAULT}
                        />
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
          <div className={styles.divider} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 64 }}>
            <div>
              <Markdown>{currentLaunchpad.fullDescription}</Markdown>
            </div>
          </div>
        </div>
        <div className={styles.actionsWrap}>
          <div style={{ flexGrow: 1 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#FFF',
                gap: 29,
                borderRadius: 10,
                padding: 30,
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: 38 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <LaunchpadStatus status={currentLaunchpad?.status || ''} />
                  {+currentLaunchpad.supplyRaisedAmount >= +currentLaunchpad.supplyAmount * 0.8 &&
                  currentLaunchpad.status === 'ACTIVE' ? (
                    <div className={styles.finalCall}>Final Call</div>
                  ) : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                  <div className={styles.activeText}>Raising</div>
                  <div className={styles.infoBlockAmount}>
                    {addCommasToDisplayValue(currentLaunchpad.supplyRaisedAmount, 2)} /{' '}
                    {addCommasToDisplayValue(currentLaunchpad.supplyAmount, 2)} {currentLaunchpad.supplyAssetId}
                  </div>
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      flexGrow: 1,
                      marginLeft: -5,
                    }}
                  >
                    <ProgressBar
                      customColor={currentLaunchpad.status === 'CANCELED' ? 'red' : ''}
                      value={progress() || 0}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <div className={styles.infoBlock}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                      <div className={styles.infoBlockTitle}>Supply</div>
                      <SimpleHint text={`Available Supply for purchase\n through the Fideum launchpad.`} />
                    </div>
                    <div className={styles.infoBlockAmount}>
                      {addCommasToDisplayValue(currentLaunchpad.supplyAmount, 2)} ${currentLaunchpad.supplyAssetId}
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                      <div className={styles.infoBlockTitle}>Token Price</div>
                      <SimpleHint text={`Fixed price of each token during raising period.`} />
                    </div>
                    <div className={styles.infoBlockAmount}>
                      {addCommasToDisplayValue(currentLaunchpad.buyingAssetPrice || '0', 2)}{' '}
                      {currentLaunchpad.buyingAssetId}
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>Contract address</div>
                    <div className={styles.infoBlockAmount} style={{ display: 'flex', gap: 9 }}>
                      {currentLaunchpad.contracts && currentLaunchpad.contracts.length
                        ? currentLaunchpad.contracts.map(contract => {
                            const nativeAsset = assets.find(assetItem => {
                              if (contract.type === 'BSC' && assetItem.symbol === 'BNB') return true
                              return assetItem.symbol === contract.type
                            })
                            const icon = getAssetIcon(nativeAsset?.symbol)
                            return (
                              <img
                                key={contract.type}
                                onClick={() => goToAssetContract(contract.url)}
                                style={{ width: 16, height: 16, borderRadius: 5, cursor: 'pointer' }}
                                src={icon}
                                alt=''
                              />
                            )
                          })
                        : null}
                    </div>
                  </div>
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>Launch Date</div>
                    <div className={styles.infoBlockAmount}>{formatDateOrTime(currentLaunchpad.launchDate)}</div>
                  </div>
                </div>
              </div>
              {combinedBlock()}
              {finishBlock(allocationLaunchpad?.status === 'CLAIMED')}
              {canceledBlock(allocationLaunchpad?.status === 'CLAIMED')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
