const AssetsIcons: { [key: string]: string } = {
  adk: require('../assets/images/crypto-icons/adk.png'),
  bnx: require('../assets/images/crypto-icons/bnx.png'),
  eur: require('../assets/images/crypto-icons/eur.png'),
  usd: require('../assets/images/crypto-icons/usd.png'),
  aave: require('../assets/images/crypto-icons/aave.png'),
  key: require('../assets/images/crypto-icons/key.png'),
  lym: require('../assets/images/crypto-icons/lym.png'),
  ripple: require('../assets/images/crypto-icons/ripple.png'),
  bbank: require('../assets/images/crypto-icons/bbank.webp'),
  okb: require('../assets/images/crypto-icons/okb.png'),
  wtl: require('../assets/images/crypto-icons/wtl.png'),
  xrp: require('../assets/images/crypto-icons/xrp.png'),
  yfi: require('../assets/images/crypto-icons/yfi.png'),
  uni: require('../assets/images/crypto-icons/uni.png'),
  comp: require('../assets/images/crypto-icons/comp.png'),
  pax: require('../assets/images/crypto-icons/pax.png'),
  paxg: require('../assets/images/crypto-icons/paxg.png'),
  sai: require('../assets/images/crypto-icons/sai.png'),
  orca: require('../assets/images/crypto-icons/orca.png'),
  fly: require('../assets/images/crypto-icons/fly.png'),
  swace: require('../assets/images/crypto-icons/swace.png'),
  npxs: require('../assets/images/crypto-icons/npxs.png'),
  mkr: require('../assets/images/crypto-icons/mkr.png'),
  sushi: require('../assets/images/crypto-icons/sushi.png'),
  dot: require('../assets/images/crypto-icons/dot.png'),
  bnb: require('../assets/images/crypto-icons/bnb.webp'),
  gcr: require('../assets/images/crypto-icons/gcr.png'),
  eth: require('../assets/images/crypto-icons/eth.webp'),
  eth_test: require('../assets/images/crypto-icons/eth.webp'),
  flt: require('../assets/images/crypto-icons/flt.png'),
  btc: require('../assets/images/crypto-icons/btc.png'),
  btc_test: require('../assets/images/crypto-icons/btc.png'),
  enj: require('../assets/images/crypto-icons/enj.png'),
  dai: require('../assets/images/crypto-icons/dai.png'),
  usdt: require('../assets/images/crypto-icons/usdt.webp'),
  usdc: require('../assets/images/crypto-icons/usdc.webp'),
  api3: require('../assets/images/crypto-icons/api3.jpg'),
  link: require('../assets/images/crypto-icons/link.png'),
  om: require('../assets/images/crypto-icons/om.png'),
  mod: require('../assets/images/crypto-icons/mod.png'),
  shld: require('../assets/images/crypto-icons/shld.png'),
  sxp: require('../assets/images/crypto-icons/sxp.png'),
  busd: require('../assets/images/crypto-icons/busd.png'),
  xvs: require('../assets/images/crypto-icons/xvs.png'),
  tko: require('../assets/images/crypto-icons/tko.png'),
  eps: require('../assets/images/crypto-icons/eps.png'),
  bmi: require('../assets/images/crypto-icons/bmi.png'),
  shib: require('../assets/images/crypto-icons/shiba.png'),
  cake: require('../assets/images/crypto-icons/pancakeswap-cake.webp'),
  ohm: require('../assets/images/crypto-icons/olympus.png'),
  mana: require('../assets/images/crypto-icons/decentraland-mana.png'),
  safemoon: require('../assets/images/crypto-icons/safemoon.png'),
  omg: require('../assets/images/crypto-icons/omisego.jpg'),
  elon: require('../assets/images/crypto-icons/dogelon-mars.jpg'),
  crv: require('../assets/images/crypto-icons/curve-dao-token.png'),
  sand: require('../assets/images/crypto-icons/the-sandbox.jpg'),
  orion: require('../assets/images/crypto-icons/orion-money.png'),
  mrcr: require('../assets/images/crypto-icons/mercor-finance.png'),
  dvdx: require('../assets/images/crypto-icons/derived.png'),
  ufi: require('../assets/images/crypto-icons/purefi.png'),
  avax: require('../assets/images/crypto-icons/avax.png'),
  fi: require('../assets/images/crypto-icons/fi.webp'),
  trx: require('../assets/images/crypto-icons/trx.webp'),
  matic: require('../assets/images/crypto-icons/matic.webp'),
  '1inch': require('../assets/images/crypto-icons/1inch.webp'),
  mbc: require('../assets/images/crypto-icons/mbc.png'),
  luchow: require('../assets/images/crypto-icons/luchow.jpg'),
  mtv: require('../assets/images/crypto-icons/mtv.webp'),
  doge: require('../assets/images/crypto-icons/doge.webp'),
  ada: require('../assets/images/crypto-icons/ada.webp'),
  sol: require('../assets/images/crypto-icons/sol.webp'),
  ltc: require('../assets/images/crypto-icons/ltc.webp'),
  near: require('../assets/images/crypto-icons/near.webp'),
  xtz: require('../assets/images/crypto-icons/xtz.webp'),

  unknown: require('../assets/images/crypto-icons/unknown.png'),
}

export default AssetsIcons
