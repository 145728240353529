import React, { ReactElement, RefObject, useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { CommonDropdown, ErrorRedBlock } from 'components'
import { countriesFormatted } from 'constant'
import { CardService } from 'wip/services'
import { $cardStatus, getCardStatusFx } from 'model/cefi-banking'

import hodlCard from './card-hodl.svg'
import styles from './styles.module.scss'

export type TCountries = {
  name: string
  code: string
}

type Props = {
  containerRef: RefObject<HTMLDivElement>
}

export function StepResidency({ containerRef }: Props) {
  const cardStatus = useStore($cardStatus)

  const [selectedCountry, setSelectedCountry] = useState<TCountries>({ name: 'Select Country', code: 'OTHER' })

  const [requestError, setRequestError] = useState('')
  const [loading, setLoading] = useState(false)

  const itemComponentCurrency = (currency: TCountries): ReactElement => {
    return <div className={styles.bankName}>{currency.name}</div>
  }

  const countries: TCountries[] = ((cardStatus?.additionalInfo?.countryCodes || []) as string[]) // permittedCountries
    // @ts-ignore
    .map(item => ({ code: item, name: countriesFormatted[item] }))
    .sort((a, b) => {
      if (b.code === 'OTHER') return -1
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

  return (
    <div className={styles.stepContentContainer}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img className={clsx(styles.cardImg, styles.cardImgTwo)} src={hodlCard} alt='' />
      </div>
      <div className={clsx(styles.enterAmount, styles.enterAmountTwo)}>Choose Your Country</div>

      <div className={styles.countryDropDownWrap}>
        <CommonDropdown
          containerRef={containerRef}
          data={countries}
          itemComponent={itemComponentCurrency}
          setSelectedData={setSelectedCountry}
          selectedData={selectedCountry}
        />
      </div>

      <button
        style={selectedCountry.name === 'Select Country' ? { opacity: 0.5 } : {}}
        onClick={async e => {
          e.preventDefault()
          if (selectedCountry.name === 'Select Country') return
          setLoading(true)
          try {
            const stepData = await CardService.submitOrderStatus({
              step: cardStatus.nextStep,
              data: {
                countryCode: selectedCountry?.code || 'OTHER',
              },
            })

            await getCardStatusFx()
          } catch (error: any) {
            setRequestError(error.code || 'RESIDENCY Error')
            console.log('submitOrderStatus(RESIDENCY)-ERROR', error)
          }
          setLoading(false)
        }}
        className='btn btn-primary'
      >
        {loading ? <span className='spinner-border' /> : <div>Next</div>}
      </button>

      {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
    </div>
  )
}
