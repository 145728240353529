import { useEffect, useRef, useState } from 'react'

import { Spinner } from 'components'
import { BankAddressResponse, FiatService } from 'wip/services'

import { WithdrawalFiat } from './fiat'
import { WithdrawalNotAvailable } from './not-available'

export interface WithdrawBankingModal {
  openDeposit: () => void
}

export function WithdrawBankingModal({ openDeposit }: WithdrawBankingModal) {
  const [isLoading, setIsLoading] = useState(true)
  const [bankAddresses, setBankAddresses] = useState<BankAddressResponse[]>([])

  const containerRef = useRef<HTMLInputElement>(null)

  const getBankAddress = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const address = await FiatService.getAllBankAddress()
      if (address.content.length > 0) {
        setBankAddresses(address.content)
      }
    } catch (e) {
      console.log('getAllBankAddress-ERROR', e)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getBankAddress().catch(e => console.log('getAllBankAddress-ERROR', e))
  }, [])

  return (
    <div ref={containerRef} id='withdrawContainer'>
      {isLoading ? (
        <div className='justify-row-center'>
          <Spinner />
        </div>
      ) : bankAddresses.length ? (
        <WithdrawalFiat containerRef={containerRef} bankAddresses={bankAddresses} />
      ) : (
        <WithdrawalNotAvailable openDeposit={openDeposit} />
      )}
    </div>
  )
}
