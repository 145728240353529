import { Navigate } from 'react-router-dom'

import { pages } from 'constant'
import { getToken } from 'utils'

const protectionType = {
  user: 'user',
  guest: 'guest',
  multiple: 'multiple',
} as const

type ProtectionType = keyof typeof protectionType

interface ProtectedRoute {
  element: JSX.Element
  type: ProtectionType
}

export function ProtectedRoute({ element, type }: ProtectedRoute) {
  const token = getToken()

  if (type === protectionType.multiple) {
    return element
  }

  if (!token && type === protectionType.user) {
    return <Navigate to={pages.SignIn.path} replace />
  }

  return element
}
