import clsx from 'clsx'

import packageJson from '../../../package.json'
import styles from './styles.module.scss'

const Version: React.FC = () => {
  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>Web App Version</div>
        </div>

        <div className={styles.settingsItemDescription}>
          You are currently using the latest version of our web based application.
        </div>

        <div className={clsx(styles.twoFaBtn, styles.copyBtn)} style={{ color: '#445374' }}>
          Version {packageJson.version}
        </div>
      </div>
    </div>
  )
}

export default Version
