import { createEvent } from 'effector'

import { $cardHistoryByFilter } from './card-history'
import { $cardsBalance, $cardsData, $cardStatus } from './cefi-banking'
import { $allStakingContracts, $stakingPlans, $tierLevel } from './cefi-stacking'
import { $transactionsHistory } from './cefi-transactions-history'
import { $txnsHistoryCashback } from './cefi-transactions-history-cashback'
import { $transactionsHistoryFiat } from './cefi-transactions-history-fiat'
import { $twoFaStatus } from './two-fa'
import { $userEmail } from './user-email'
import { $userPhone } from './user-phone'

export const resetStoresEv = createEvent()
$cardStatus.reset(resetStoresEv)
$cardsData.reset(resetStoresEv)
$cardsBalance.reset(resetStoresEv)
$allStakingContracts.reset(resetStoresEv)
$tierLevel.reset(resetStoresEv)
$stakingPlans.reset(resetStoresEv)
$transactionsHistory.reset(resetStoresEv)
$txnsHistoryCashback.reset(resetStoresEv)
$cardHistoryByFilter.reset(resetStoresEv)
$transactionsHistoryFiat.reset(resetStoresEv)
$userEmail.reset(resetStoresEv)
$userPhone.reset(resetStoresEv)
$twoFaStatus.reset(resetStoresEv)
