import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Spinner, Success } from 'components'
import { regex } from 'constant'
import { StepControllerComponent } from 'features/step-controller'
import { saveRefreshToken, saveToken } from 'utils'
import { AuthResponse, AuthServiceV4, MFAAddAuthResponse, StepUpAuthResponse } from 'wip/services'
import { $userPhone, getUserPhoneFx } from 'model/user-phone'

import styles from './styles.module.scss'

type Inputs = {
  newPhone: string
}

const defaultValues = {
  newPhone: '+',
}

export function AddOrChangePhoneModal() {
  const userPhone = useStore($userPhone)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<Inputs>({ defaultValues })

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [responseError, setResponseError] = useState('')
  const [response, setResponse] = useState<AuthResponse | StepUpAuthResponse | MFAAddAuthResponse>()
  const [successfulText, setSuccessfulText] = useState<string>('')

  const inputValue = watch('newPhone')

  const onSubmit = async (formData: Inputs) => {
    setIsLoading(true)
    const newPhoneValue = formData.newPhone.replace(/[^+\d]/g, '')
    setSuccessfulText(userPhone ? 'Updated' : 'Added')

    try {
      const phoneRes = userPhone
        ? await AuthServiceV4.changePhone({ newPhone: newPhoneValue })
        : await AuthServiceV4.addPhone({ phone: newPhoneValue })
      setResponse(phoneRes)
    } catch (error: any) {
      console.log('ERROR-changePhone or ERROR-addPhone', error)
      setResponseError(error.code || error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFinalAction = (responseData: AuthResponse | StepUpAuthResponse | MFAAddAuthResponse) => {
    setIsSuccessful(true)
    saveToken(responseData.accessToken)
    saveRefreshToken(responseData.refreshToken)
    getUserPhoneFx()
  }

  if (isLoading) {
    return (
      <div className={styles.isLoadingWrap}>
        <Spinner />
      </div>
    )
  }

  if (isSuccessful) {
    return <Success text={`Phone Number Successfully ${successfulText}.`} />
  }

  return (
    <div className={styles.container}>
      <div className={clsx(styles.title, styles.titleMainMobile)}>
        {userPhone ? 'Change Phone Number' : 'Add Phone Number'}
      </div>

      {!response && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrap}>
          <div>
            <div className={styles.description}>
              {`To ${userPhone ? 'change' : 'add'} your phone number please follow`} <br />
              the steps specified.
            </div>
          </div>
          <div style={{ width: '100%' }} className='input-item-wrap'>
            <label htmlFor='code' className={'input-label'}>
              {`Enter your ${userPhone ? 'new' : ''} phone numbe`}
            </label>

            <input
              type='text'
              className='input-form'
              style={errors.newPhone ? { outline: '1px solid red' } : {}}
              placeholder={`Enter your ${userPhone ? 'new' : ''} phone numbe`}
              disabled={isLoading}
              {...register('newPhone', {
                required: true,
                pattern: {
                  value: regex.phone,
                  message: 'Invalid phone number',
                },
                validate: value => {
                  const trimmedValue = value.replace(/[^+\d]/g, '')
                  if (trimmedValue.length < 8 || trimmedValue.length > 16) {
                    return 'Phone number must be between 8 and 16 characters long.'
                  }
                },
                onChange: event => {
                  let cleanedValue = event.target.value.replace(/[^0-9()\s_-]+/g, '')
                  if (cleanedValue.charAt(0) !== '+') {
                    cleanedValue = '+' + cleanedValue
                  }
                  setValue('newPhone', cleanedValue)
                },
              })}
            />

            <div className={styles.errorWrap}>
              {errors.newPhone || responseError ? (
                <div className={styles.errorText}>{errors.newPhone?.message || responseError}</div>
              ) : null}
            </div>
          </div>
          <div className={styles.button}>
            <div></div>
            <button
              type='submit'
              className={clsx('btn btn-primary', ['', '+'].includes(inputValue) ? styles.buttonDisable : {})}
              disabled={isLoading || ['', '+'].includes(inputValue)}
            >
              {isLoading ? <span className='spinner-border' /> : 'Next'}
            </button>
          </div>
        </form>
      )}

      {response && (
        <StepControllerComponent
          nextStepResponse={response}
          finalAction={handleFinalAction}
          dataProps={{ phone: inputValue }}
        />
      )}
    </div>
  )
}
