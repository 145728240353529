import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import i18n from 'i18next'
import clsx from 'clsx'

import { ErrorRedBlock } from 'components'
import { CardService } from 'wip/services'
import { $cardStatus, getCardStatusFx } from 'model/cefi-banking'

import styles from './styles.module.scss'

type Inputs = {
  addressLine1: string
  addressLine2: string
  city: string
  zipCode: string
}

const defaultValues = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  zipCode: '',
}

export function StepAddress() {
  const cardStatus = useStore($cardStatus)
  const { t } = i18n

  const methods = useForm<Inputs>({ defaultValues })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods

  const watchAddressLine1 = watch('addressLine1')
  const watchAddressLine2 = watch('addressLine2')
  const watchCity = watch('city')
  const watchZipCode = watch('zipCode')

  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState('')

  const onSubmit: SubmitHandler<Inputs> = async () => {
    setLoading(true)
    try {
      const addressStep = await CardService.submitOrderStatus({
        step: cardStatus.nextStep,
        data: {
          address1: watchAddressLine1,
          address2: watchAddressLine2,
          city: watchCity,
          postalCode: watchZipCode,
        },
      })

      await getCardStatusFx()
    } catch (e: any) {
      console.log('ERROR-PhoneService. | ADDRESS', e)
      setRequestError(e.code || 'submitOrderStatus(ADDRESS)-ERROR')
    }
    setLoading(false)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={clsx(styles.freeHistoryDescription, styles.freeHistoryDescriptionThree)}>
          Please fill in or confirm your address details below. This is important, as we&#39;ll use this information to
          securely send your HODL Card.
        </div>
        <div className={styles.stepContentContainer}>
          <div className='input-item-wrap'>
            <label htmlFor='addressLine1' className={`input-label ${errors.addressLine1 ? 'text-error' : ''}`}>
              Address Line 1{' '}
              {errors.addressLine1 && errors.addressLine1.type === 'required' ? t('inputError.required') : ''}
            </label>
            <input
              id='addressLine1'
              type='text'
              className='input-form'
              style={errors.addressLine1 ? { outline: '1px solid red' } : {}}
              placeholder='Address Line 1'
              {...register('addressLine1', { required: true })}
            />
          </div>
          <div className='input-item-wrap'>
            <label htmlFor='addressLine1' className='input-label'>
              Address Line 2{' '}
            </label>
            <input
              id='addressLine2'
              type='text'
              className='input-form'
              style={errors.addressLine2 ? { outline: '1px solid red' } : {}}
              placeholder='Address Line 2'
              {...register('addressLine2')}
            />
          </div>
          <div className='input-item-wrap'>
            <label htmlFor='city' className={`input-label ${errors.city ? 'text-error' : ''}`}>
              City {errors.city && errors.city.type === 'required' ? t('inputError.required') : ''}
            </label>
            <input
              id='city'
              type='text'
              className='input-form'
              style={errors.city ? { outline: '1px solid red' } : {}}
              placeholder='City'
              {...register('city', { required: true })}
            />
          </div>
          <div className='input-item-wrap'>
            <label htmlFor='zipCode' className={`input-label ${errors.zipCode ? 'text-error' : ''}`}>
              Zip Code {errors.zipCode && errors.zipCode.type === 'required' ? t('inputError.required') : ''}
            </label>
            <input
              id='zipCode'
              type='text'
              className='input-form'
              style={errors.zipCode ? { outline: '1px solid red' } : {}}
              placeholder='Zip Code'
              {...register('zipCode', { required: true })}
            />
          </div>
          <div className={styles.stepThreeBtnWrap}>
            <button type='submit' className='btn btn-primary'>
              {loading ? <span className='spinner-border' /> : <div>Next</div>}
            </button>

            {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
