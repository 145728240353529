import { Dispatch, SetStateAction, useState } from 'react'
import { useStore } from 'effector-react'
import moment from 'moment'
import clsx from 'clsx'

import { AuthSnackbar, Modal, ProgressBar } from 'components'
import { pages } from 'constant'
import { IndividualEarnModal } from 'features/modals'
import { convertTimestampToISO, errorDescriptionHandler, getBalanceString } from 'utils'
import { StakingCampaignContractResponse, StakingContract, StakingServices } from 'wip/services'
import { updateEarning } from 'wip/stores/init'

import { $assetsListData } from '../../model/cefi-combain-assets-data'
import { $currency } from '../../model/currency'
import { snackComponentChangedEv } from '../../model/snackComponent'
import { addCommasToDisplayValue } from '../../utils/add-commas-to-display-value'
import { NewSuperchargeEarningModal } from '../modals/new-supercharge-earning'
import Hint from './hint-new'
import styles from './styles.module.scss'
import { calculateTimeLeft } from './timeLeftHelper'

export interface EarnRow {
  stake: StakingCampaignContractResponse
  setIsSupercharge: Dispatch<SetStateAction<boolean>>
}

export function EarnSuperchargeRow({ stake, setIsSupercharge }: EarnRow) {
  const assets = useStore($assetsListData)
  const currency = useStore($currency)
  const currencyType = currency.type.toLowerCase() as 'eur' | 'usd'
  const asset = assets.find(assetItem => assetItem.assetId === stake.assetId)

  const [isLoading, setIsLoading] = useState(false)

  const preparedExpectedCloseDate = convertTimestampToISO(stake.closeDate)
  const preparedOpenDate = convertTimestampToISO(stake.openDate)

  const stakeDays = moment(preparedExpectedCloseDate).diff(preparedOpenDate, 'days')
  const daysDiffToday = moment(preparedExpectedCloseDate).diff(moment(), 'days')
  const daysLeft = daysDiffToday >= 0 ? daysDiffToday : 0
  const daysLeftPercent = daysDiffToday >= 0 ? (daysDiffToday / stakeDays) * 100 : 0
  const currentDate = new Date().toISOString()
  const currentDateObj = new Date(currentDate)

  const handleClaim = async (stakeItem: StakingCampaignContractResponse): Promise<void> => {
    setIsLoading(true)
    try {
      await StakingServices.campaignClaim(stakeItem.id)
      await updateEarning()
    } catch (e: any) {
      console.log('ERROR-handleClaim', e)
      snackComponentChangedEv(
        <AuthSnackbar title='Something went wrong.' description={errorDescriptionHandler(e.code || '')} />
      )
    }
    setIsLoading(false)
  }

  const handleAddSupercharge = async (stakeItem: StakingCampaignContractResponse): Promise<void> => {
    setIsLoading(true)
    try {
      Modal.open(<NewSuperchargeEarningModal setIsSupercharge={setIsSupercharge} stakeItem={stakeItem} />, {
        title: pages.EARN.name,
        isFullScreen: true,
      })
    } catch (error) {
      console.log('ERROR-handleAddSupercharge', error)
    }
    setIsLoading(false)
  }

  const [isOpenHint, setIsOpenHint] = useState(false)

  const handleOpenHint = (): void => {
    setIsOpenHint(true)
    setTimeout(() => {
      setIsOpenHint(false)
    }, 3000)
  }

  // const goToEarnPage = () => {
  //   Modal.open(
  //     <IndividualEarnModal
  //       stake={stake}
  //       daysLeftPercent={daysLeftPercent}
  //       stakeDays={stakeDays}
  //       daysLeft={daysLeft}
  //       isLoading={isLoading}
  //       handleClaim={handleClaim}
  //     />,
  //     { title: pages.EARN.name, isFullScreen: true }
  //   )
  // }

  const estimatedReward = () => {
    const value = addCommasToDisplayValue(stake.estimatedRewardAmount, 5)
    if (!+value) {
      return '>0.00001'
    }
    return value
  }

  // TODO: mobile screens not show
  return (
    <>
      {/*<div onClick={goToEarnPage} className={styles.rowMobile}>*/}
      {/*  <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>*/}
      {/*    <img style={{ width: 48, height: 48, borderRadius: 5, marginRight: 11 }} src={asset?.icon} alt='' />*/}
      {/*    <div*/}
      {/*      style={{*/}
      {/*        height: '100%',*/}
      {/*        display: 'flex',*/}
      {/*        flexDirection: 'column',*/}
      {/*        justifyContent: 'space-around',*/}
      {/*        flexGrow: 1,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <div className={styles.assetName}>{stake.assetId}</div>*/}
      {/*      <ProgressBar value={daysLeftPercent} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      display: 'flex',*/}
      {/*      flexDirection: 'column',*/}
      {/*      justifyContent: 'space-around',*/}
      {/*      alignItems: 'end',*/}
      {/*      marginLeft: 10,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div>{getBalanceString(+stake.userStakingAmount, 8)}</div>*/}
      {/*    <div style={{ fontSize: 11, color: '#858EAA', fontWeight: 400 }}>*/}
      {/*      Interest Paid {getBalanceString(+stake.payedRewardAmount, 2)}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={clsx(styles.row)}>
        <div className={clsx(styles.cell, styles.cell3)}>
          <img style={{ width: 46, height: 46, borderRadius: 5 }} src={asset?.icon} alt='' />
          <div className={styles.assetName}>{stake.assetId}</div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={clsx(styles.cell, styles.cell1)}>
          {addCommasToDisplayValue(stake.userStakingAmount, 6)} {stake.assetId}
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={clsx(styles.cell, styles.cell6)}>
          {estimatedReward()} {stake.payedAssetId}
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={clsx(styles.cell, styles.cell3)}>{new Date(stake.closeDate).toLocaleString()}</div>
        <div style={{ flexGrow: 1 }} />
        <div className={clsx(styles.cell, styles.cell1)}>
          <div style={{ width: 110 }}>{calculateTimeLeft(stake.closeDate)}</div>
          <ProgressBar value={daysLeftPercent} />
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={clsx(styles.cell, styles.cell2)}>
          <div
            onMouseEnter={() => {
              return daysLeft ? handleOpenHint() : null
            }}
            onMouseLeave={() => {
              return daysLeft ? setIsOpenHint(false) : null
            }}
            className={clsx(styles.actionBtn, styles.superchargeActionBtn)}
            style={daysLeft ? { backgroundColor: '#ECECED', cursor: 'default' } : {}}
            onClick={daysLeft ? () => handleOpenHint() : () => handleClaim(stake)}
          >
            {isLoading ? <span className='spinner-border' /> : 'Unlock & Claim'}
            {isOpenHint ? <Hint /> : null}
          </div>
          {currentDateObj < new Date(stake.closeDate) ? (
            <div
              className={clsx(styles.actionBtn, styles.superchargeActionBtn)}
              onClick={() => handleAddSupercharge(stake)}
            >
              {isLoading ? <span className='spinner-border' /> : 'Add more'}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
