import { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import { Spinner, Success } from 'components'
import { regex } from 'constant'
import { StepControllerComponent } from 'features/step-controller'
import { saveRefreshToken, saveToken } from 'utils'
import { AuthResponse, AuthServiceV4, MFAAddAuthResponse, StepUpAuthResponse } from 'wip/services'
import { getUserEmailFx } from 'model/user-email'

import styles from './styles.module.scss'

type Inputs = {
  newEmail: string
}

const defaultValues = {
  newEmail: '',
}

export function ChangeEmailModal() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>({ defaultValues })

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [responseError, setResponseError] = useState('')
  const [response, setResponse] = useState<AuthResponse | StepUpAuthResponse | MFAAddAuthResponse>()

  const inputValue = watch('newEmail')

  const onSubmit = async (formData: Inputs) => {
    setIsLoading(true)
    const newEmailValue = formData.newEmail.toLowerCase()?.trim()

    const data = { newEmail: newEmailValue }

    try {
      const emailRes = await AuthServiceV4.changeEmail(data)
      setResponse(emailRes)
    } catch (error: any) {
      console.log('ERROR-changeEmail', error)
      setResponseError(error.code || error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleFinalAction = (responseData: AuthResponse | StepUpAuthResponse | MFAAddAuthResponse) => {
    saveToken(responseData.accessToken)
    saveRefreshToken(responseData.refreshToken)
    getUserEmailFx()
    setIsSuccessful(true)
  }

  if (isLoading) {
    return (
      <div className={styles.isLoadingWrap}>
        <Spinner />
      </div>
    )
  }

  if (isSuccessful) {
    return <Success text='Email Address Successfully Updated' />
  }

  return (
    <div className={styles.container}>
      <div className={clsx(styles.title, styles.titleMainMobile)}>Change Email</div>

      {!response && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrap}>
          <div>
            <div className={styles.description}>
              To change your email address please follow <br />
              the steps specified.
            </div>
          </div>
          <div style={{ width: '100%' }} className='input-item-wrap'>
            <label htmlFor='code' className={'input-label'}>
              Enter your new email
            </label>
            <input
              type='text'
              className='input-form'
              style={errors.newEmail ? { outline: '1px solid red' } : {}}
              placeholder='Enter your email'
              {...register('newEmail', {
                required: true,
                pattern: {
                  value: regex.email,
                  message: 'Invalid email address',
                },
              })}
              disabled={isLoading}
            />

            <div className={styles.errorWrap}>
              {errors.newEmail || responseError ? (
                <div className={styles.errorText}>{errors.newEmail?.message || responseError}</div>
              ) : null}
            </div>
          </div>
          <div className={styles.button}>
            <div></div>
            <button
              type='submit'
              className={clsx('btn btn-primary', !inputValue ? styles.buttonDisable : {})}
              disabled={isLoading || !inputValue}
            >
              {isLoading ? <span className='spinner-border' /> : 'Next'}
            </button>
          </div>
        </form>
      )}

      {response && (
        <StepControllerComponent
          nextStepResponse={response}
          finalAction={handleFinalAction}
          dataProps={{ email: inputValue }}
        />
      )}
    </div>
  )
}
