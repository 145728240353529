import clsx from 'clsx'

import styles from './styles.module.scss'

type Props = {
  value: string
  action: () => void
  icon?: string
  iconLocation?: 'left' | 'right'
  isActive?: boolean
}

export const NavButton = ({ value, action, icon, iconLocation = 'left', isActive }: Props) => {
  return (
    <div className={clsx(styles.navButton, isActive ? styles.navButtonActive : '')} onClick={action}>
      {icon && iconLocation === 'left' ? <img className={styles.listIcon} alt='icon' src={icon} /> : null}
      <div className={clsx(styles.navButtonTitle, isActive ? styles.navButtonTitleActive : '')}>{value}</div>
      {icon && iconLocation === 'right' ? <img className={styles.listIcon} alt='icon' src={icon} /> : null}
    </div>
  )
}
