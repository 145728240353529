/* eslint-disable max-len */
import * as React from 'react'

type Props = {
  fill: string
}

const SvgComponent: React.FC<Props> = ({ fill }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='62' height='62' fill='none' viewBox='0 0 62 62'>
    <rect width='62' height='62' fill={fill || '#3D5CF5'} fillOpacity='0.1' rx='31' />
    <path
      fill={fill || '#3D5CF5'}
      d='M33.564 41.134l-.002-7.569 7.557.009a2.637 2.637 0 00.01-5.274l-7.567-.003.002-7.572a2.634 2.634 0 10-5.268 0V28.3l-7.556-.002a2.637 2.637 0 00.005 5.273l7.55-.007.003 7.573a2.633 2.633 0 105.266-.002z'
    />
  </svg>
)
export default SvgComponent
