import { getToken } from 'utils'
import { allStakingContractsDataFx, stakingPlansFx, tierLevelFx } from 'model/cefi-stacking'
import { $userEmail, getUserEmailFx } from 'model/user-email'

import { theme } from '../../config'
import { assetsRatesFx } from '../../model/cef-rates-coingecko'
import { assetsCefiExchangeRatesFx } from '../../model/cef-rates-exchange'
import { assetsDataFx } from '../../model/cefi-assets-list'
import {
  $cardStatus,
  getCardsBalanceFx,
  getCardsDataFx,
  getCardStatusFx,
  getPhysicalCardRequestedFx,
} from '../../model/cefi-banking'
import { myAssetsFx } from '../../model/cefi-my-assets-list'
import { getFavouriteAssetsFx } from '../../model/favourite-assets'
import { getAllocationsLaunchpadsFx, getLaunchpadsFx } from '../../model/launchpads'
import { mainLoaderChangedEv } from '../../model/mainLoader'

export const updateEarning = async () => {
  allStakingContractsDataFx()
  stakingPlansFx()
  tierLevelFx()
}

// Without token
export const updateAvailableAssets = () => {
  assetsDataFx()
  assetsRatesFx() // coingecko rates
  // TODO: off launchpad
  // getLaunchpadsFx({ page: '0', size: '20' })
}

export async function initApp() {
  console.log('initApp')
  const token = getToken()
  const userEmail = $userEmail.getState()

  updateAvailableAssets() // assets list

  if (token) {
    // new state
    myAssetsFx()
    assetsCefiExchangeRatesFx()
    getFavouriteAssetsFx()
    // TODO: off launchpad
    // getAllocationsLaunchpadsFx({ page: '0', size: '20' })

    try {
      await updateEarning()
    } catch (e) {
      console.log('ERROR-get-stakingContracts', e)
    }

    try {
      // get card data only bbank
      if (theme === 'main') {
        await getCardStatusFx()

        const status = $cardStatus.getState()
        if (status.currentStep === 'PROCESSED') {
          await getCardsDataFx()
          await getCardsBalanceFx()
          await getPhysicalCardRequestedFx()
        }
      }
    } catch (e) {
      console.log('ERROR-get-stakingContracts', e)
    }

    //user email
    try {
      if (!userEmail) {
        await getUserEmailFx()
      }
    } catch (error) {
      console.log('ERROR-getUserEmailFx', error)
    }
  }
  mainLoaderChangedEv(false)
}
