import { HTMLAttributes } from 'react'

import { HeaderTitle } from 'components'

import { InfoCards } from './components/info-cards'
import styles from './styles.module.scss'

export interface HeaderCards extends HTMLAttributes<HTMLDivElement> {
  headerTitle: string
  hideUd?: boolean
}

export function HeaderCards({ headerTitle, hideUd = false, ...props }: HeaderCards) {
  return (
    <div className={styles.headerCards} {...props}>
      <HeaderTitle headerTitle={headerTitle} hideUd={hideUd} />
      <InfoCards />
    </div>
  )
}
