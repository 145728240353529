import { useState } from 'react'
import { useStore } from 'effector-react'

import { BasicCardInfo } from 'wip/services'
import { $isMobile } from 'model'
import copySvg from 'assets/icons/copy.svg'
import CopySvgComponent from 'assets/icons/copy-svg-component'

import styles from './styles.module.scss'

type Props = {
  cardData: BasicCardInfo | undefined
}
export function CardtTracking({ cardData }: Props) {
  const isMobile = useStore($isMobile)

  const [isCopied, setIsCopied] = useState(false)

  const handleCopied = (data: string): void => {
    navigator.clipboard.writeText(data).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    })
  }

  return (
    <div className={styles.cardViewWrap}>
      <div className={styles.contentWrap}>
        <div className={styles.title}>
          <div>Tracking</div>
        </div>

        <div className={styles.viewDetailsItemWrap}>
          <div className={styles.row}>
            <div className={styles.viewDetailsTitle}>Tracking Number</div>
            <div className={styles.viewDetailsDescription}>
              {['DPD_EXPRESS', 'DHL_EXPRESS'].includes(cardData?.deliveryAddress?.dispatchMethod || '')
                ? cardData?.deliveryAddress?.trackingNumber
                : 'Not available yet'}
            </div>
          </div>
          {['DPD_EXPRESS', 'DHL_EXPRESS'].includes(cardData?.deliveryAddress?.dispatchMethod || '') ? (
            <div
              onClick={() => handleCopied(cardData?.deliveryAddress?.trackingNumber || '')}
              style={{ position: 'relative', cursor: 'pointer' }}
            >
              {isMobile ? null : <CopySvgComponent fill='var(--mainBlue)' />}

              {isCopied ? (
                <div className={styles.copiedWrap}>
                  <img src={copySvg} alt='' />
                </div>
              ) : isMobile ? (
                <CopySvgComponent fill='var(--mainBlue)' />
              ) : null}
            </div>
          ) : null}
        </div>

        <div className={styles.viewDetailsItemWrap}>
          <div className={styles.row}>
            <div className={styles.viewDetailsTitle}>Delivery Address</div>
            <div className={styles.viewDetailsDescription}>
              {cardData?.deliveryAddress?.address1}
              {cardData?.deliveryAddress?.address2 ? `${', '}${cardData?.deliveryAddress?.address2}, ` : ', '}
              {cardData?.deliveryAddress?.city}, {cardData?.deliveryAddress?.postalCode}
            </div>
          </div>
        </div>

        <div className={styles.viewDetailsItemWrap}>
          <div className={styles.row}>
            <div className={styles.viewDetailsTitle}>Delivery Method</div>
            <div className={styles.viewDetailsDescription}>
              {cardData?.deliveryAddress?.dispatchMethod.replaceAll('_', ' ')}
            </div>
          </div>
        </div>

        <div className={styles.viewDetailsItemWrap}>
          <div className={styles.row}>
            <div className={styles.viewDetailsTitle}>Status</div>
            <div className={styles.viewDetailsDescription}>{cardData?.status?.replaceAll('_', ' ') || '****'}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
