import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import { LaunchpadProject } from '../../wip/services/launchpads'
import styles from './styles.module.scss'

type Inputs = {
  amount: string
}

type PropsLaunchpadInput = {
  currentLaunchpad: LaunchpadProject
  formMethods: UseFormReturn<Inputs, any, undefined>
  maxValue: number
}

export function LaunchpadInput({ currentLaunchpad, formMethods, maxValue }: PropsLaunchpadInput) {
  const {
    register,
    formState: { errors },
    setValue,
  } = formMethods

  const handleMaxClick = () => {
    setValue('amount', maxValue.toString())
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          display: 'flex',
        }}
      >
        <input
          id='amount'
          inputMode='decimal'
          pattern='[0-9]*'
          className={styles.input}
          style={errors.amount ? { border: '1px solid red' } : {}}
          placeholder={'0.00'}
          {...register('amount', {
            required: true,
            onChange(event) {
              const value = event.target.value.replace(/[^0-9.]/g, '')
              setValue('amount', value.trim())
            },
          })}
          onKeyDown={e => {
            if (
              !/[0-9.]/.test(e.key) &&
              e.key !== 'Backspace' &&
              e.key !== 'ArrowLeft' &&
              e.key !== 'ArrowRight' &&
              e.key !== 'Delete' &&
              e.key !== 'Tab'
            ) {
              e.preventDefault()
            }
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 6,
            right: 2,
            backgroundColor: '#F9F8FF',
            display: 'flex',
            padding: 19,
            overflow: 'hidden',
            boxSizing: 'border-box',
            borderRadius: 20,
            gap: 15,
          }}
        >
          <div onClick={handleMaxClick} style={{ color: '#9C88FD', cursor: 'pointer' }} className={styles.btnTitle}>
            Max
          </div>
          <div className={styles.btnTitle}>${currentLaunchpad.supplyAssetId}</div>
        </div>
      </div>
    </div>
  )
}
