import { useStore } from 'effector-react'

import { $isMobile } from 'model'

import { ModalCenterResponseLayout } from './modal-center-response'
import { ModalDefaultLayout } from './modal-default'
import { ModalMobileFullscreenLayout } from './modal-mobile'
import { ModalRightLayout } from './modal-right'
import { ModalLayoutProps } from './types'

export const ModalLayout = (props: ModalLayoutProps) => {
  const isMobile = useStore($isMobile)

  if (props.isFullScreen && isMobile) return <ModalMobileFullscreenLayout {...props} />
  if (props.variant === 'center') return <ModalCenterResponseLayout {...props} />
  if (props.variant === 'right') return <ModalRightLayout {...props} />
  return <ModalDefaultLayout {...props} />
}
