import React, { useState } from 'react'
import { useStore } from 'effector-react'

import { BackButton, Modal } from 'components'
import { $isMobile } from 'model'

// import close from 'assets/icons/close.svg'
import Password from './3DPassword'
import CardLimits from './CardLimits'
import { SettingsStep } from './SettingsStep'
import styles from './styles.module.scss'

type Props = {
  cardUuid: string
}

export function CardSettingsModal({ cardUuid }: Props) {
  const isMobileScreens = useStore($isMobile)
  const [step, setStep] = useState('setting')

  return (
    <div className={styles.cardSettingsWrap}>
      <div style={{ justifyContent: 'end', display: isMobileScreens ? 'none' : 'flex' }}>
        {step !== 'setting' ? <BackButton backFn={() => setStep('setting')} /> : null}

        {/*<div className={styles.closeWrap} onClick={() => Modal.close()}>*/}
        {/*  <img className={styles.closeIcon} alt='' src={close} />*/}
        {/*</div>*/}
      </div>

      <div className={styles.contentWrap}>
        {step === 'setting' ? <SettingsStep setStep={setStep} cardUuid={cardUuid} /> : null}
        {step === 'cardLimits' ? <CardLimits cardUuid={cardUuid} /> : null}
        {step === '3DPassword' ? <Password cardUuid={cardUuid} /> : null}
        {step === 'pin' ? <Password isPin cardUuid={cardUuid} /> : null}
      </div>
    </div>
  )
}
