export const pages = {
  Base: {
    name: '/',
    path: '/',
  },
  SignIn: {
    name: 'SignIn',
    path: '/sign-in',
  },
  SignUp: {
    name: 'SignUp',
    path: '/sign-up',
  },
  ForgotPassword: {
    name: 'ForgotPassword',
    path: '/forgot-password',
  },
  ConfirmationCode: {
    name: 'ConfirmationCode',
    path: '/confirmation-code',
  },
  TwoFactorAuthentication: {
    name: 'TwoFactorAuthentication',
    path: '/two-factor-authentication',
  },
  KYC: {
    name: 'KYC',
    path: '/k-y-c',
  },
  EARN: {
    name: 'Earn',
    path: '/earn',
  },
  BANKING: {
    name: 'Banking',
    path: '/banking',
  },
  CARD: {
    name: 'Card',
    path: '/card',
  },
  PORTFOLIO: {
    name: 'Portfolio',
    path: '/portfolio',
  },
  SUPPORT: {
    name: 'Support',
    path: '/support',
  },
  SETTINGS: {
    name: 'Settings',
    path: '/settings',
  },
  LOGOUT: {
    name: 'Log Out',
    path: null,
  },
  ISMOBILE: {
    name: 'IsMobile',
    path: '/mobile',
  },
  ASSET: {
    name: 'Asset',
    path: '/asset',
  },
  OTC: {
    name: 'OTC',
    path: '/otc',
  },
  STAKING: {
    name: 'Staking',
    path: '/staking',
  },
  DELETE_ACCOUNT: {
    name: 'Delete Account',
    path: '/delete-account',
  },
  TRANSACTIONS_HISTORY: {
    name: 'Transactions History',
    path: '/transactions-history',
  },
  LAUNCHPAD: {
    name: 'Launchpad',
    path: '/launchpad',
  },
  INDIVIDUAL_LAUNCHPAD: {
    name: 'Launchpad',
    path: '/launchpad',
  },
} as const
