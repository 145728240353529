import { HTMLAttributes, ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { pages } from 'constant'
import { roundingBalance } from 'utils'
import { ArrowIcon, SoonIcon } from 'icons'

import { $currency } from '../../../../model/currency'
import styles from './styles.module.scss'

export interface CardItem extends HTMLAttributes<HTMLDivElement> {
  title: string
  amount: string
  changeRate: string
  isSoon?: boolean
  path?: string
  icon?: ReactNode
}

export function CardItem({ title, amount, changeRate, isSoon, path, icon, ...props }: CardItem) {
  const currency = useStore($currency)
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className={styles.cardWrap} {...props}>
      <div className={styles.cardIcon}>{icon}</div>
      <div className={styles.cardTitleAmount}>
        <div className={styles.cardAmount}>
          {currency.symbol}
          {roundingBalance(amount, 2)}
        </div>
        <div className={styles.cardTitle}>{title}</div>
      </div>

      <div className={styles.cardChangeRate}>
        {path === pages.BANKING.path ? (
          <div />
        ) : (
          <div className={clsx(styles.cardChangeRateWrap, +changeRate >= 0 ? {} : styles.cardChangeRateMinus)}>
            {path !== pages.BANKING.path && +changeRate >= 0 ? '+' : ''}
            {roundingBalance(changeRate, 2)} %{isSoon && <SoonIcon style={{ marginLeft: 'auto' }} />}
          </div>
        )}

        {path && !isSoon && location.pathname !== path && (
          <div className={styles.arrow} onClick={() => navigate(path)}>
            <ArrowIcon className={styles.arrowIcon} />
          </div>
        )}
      </div>
    </div>
  )
}
