import { useStore } from 'effector-react'

import { theme, themeOnboardingLogo } from 'config'
import { $isMobile } from 'model'
import kaizenBg from 'assets/images/bg_kaizen.png'

import styles from './styles.module.scss'

type Props = {
  children: JSX.Element
}

const authBackground = theme === 'kaizen' ? 'inherit' : 'var(--mainBlue)'

const AuthLayout: React.FC<Props> = ({ children }) => {
  const isMobile = useStore($isMobile)
  return (
    <div
      className={styles.main}
      style={
        theme === 'kaizen'
          ? {
              backgroundImage: `url(${kaizenBg})`,
            }
          : {}
      }
    >
      {themeOnboardingLogo() ? (
        <div
          style={{
            // backgroundColor: 'var(--mainBlue)',
            backgroundColor: authBackground,
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {isMobile ? null : <img width={302} src={themeOnboardingLogo()} alt='' />}
        </div>
      ) : (
        <div />
      )}
      {children}
    </div>
  )
}

export default AuthLayout
