import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { AuthSnackbar, CommonDropdown, QRCodeBlock, Spinner } from 'components'
import { errorDescriptionHandler } from 'utils'
import { AssetsServices, DepositInfo, EVENT_NAMES, useAnalytics } from 'wip/services'
import { CheckedIcon } from 'icons'

import { CombainedObject } from '../../../model/cefi-combain-assets-data'
import { snackComponentChangedEv } from '../../../model/snackComponent'
import styles from './styles.module.scss'

export interface DepositAssetModal {
  asset: CombainedObject
}

export function DepositAssetModal({ asset }: DepositAssetModal) {
  const { myLogEvent } = useAnalytics()

  const containerRef = useRef<HTMLInputElement | null>(null)

  const [isLoading, setIsLoading] = useState(true)
  const [availableNetworks, setAvailableNetworks] = useState<DepositInfo[]>([])
  const [selectedNetwork, setSelectedNetwork] = useState<DepositInfo | null>(null)
  const [isCopied, setIsCopied] = useState(false)

  const getCoinAddress = useCallback(
    async (assetId: string) => {
      setIsLoading(true)

      try {
        const depositInfo = await AssetsServices.getDepositInfo(assetId)

        /* error for an empty array of addresses and networks */
        if (!depositInfo.length) throw new Error('backendErrors:FAILED_CODE_GENERATION')

        for (const depositInfoElement of depositInfo) {
          if (!depositInfoElement.depositAddress) {
            const newAddress = await AssetsServices.createDepositAddress(assetId, depositInfoElement.networkId)
            depositInfoElement.depositAddress = newAddress.depositAddress
          }
        }

        if (depositInfo && depositInfo.length === 1) setSelectedNetwork(depositInfo[0])
        setAvailableNetworks(depositInfo)
      } catch (error: any) {
        console.log('ERROR-getCoinAddress', error)
        // TODO: title ?
        snackComponentChangedEv(<AuthSnackbar title='ERROR' description={errorDescriptionHandler(error.code || '')} />)
      }
      setIsLoading(false)
    },
    [asset]
  )

  useEffect(() => {
    if (asset) {
      getCoinAddress(asset.assetId).then(() => {})
    }
  }, [asset])

  useEffect(() => {
    if (selectedNetwork) {
      myLogEvent(EVENT_NAMES.WEB_DEPOSIT, {
        asset: asset?.assetId,
        address: selectedNetwork.depositAddress,
        networkId: selectedNetwork.networkId,
      })
    }
  }, [selectedNetwork])

  const handleCopy = (): void => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 3000)
  }

  const itemComponentAddresses = (selectedItemAddress: DepositInfo): ReactElement => {
    if (!selectedItemAddress) return <div className={styles.bankName}>Choose Network</div>
    return (
      <div>
        <div className={styles.bankName}>{selectedItemAddress?.networkId}</div>
      </div>
    )
  }

  return (
    <div ref={containerRef} id='withdrawContainer' style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={styles.title}>Deposit {asset?.assetName}</div>
      {isLoading ? (
        <div style={{ alignSelf: 'center' }}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.content}>
          {availableNetworks.length > 1 ? (
            <>
              <div className={styles.chooseNetwork}>Choose Network</div>
              <CommonDropdown
                containerRef={containerRef}
                data={availableNetworks}
                itemComponent={itemComponentAddresses}
                setSelectedData={setSelectedNetwork}
                selectedData={selectedNetwork}
              />
            </>
          ) : null}
          {selectedNetwork && (
            <QRCodeBlock
              stringCode={selectedNetwork?.depositAddress || ''}
              copiedAction={handleCopy}
              dataString={selectedNetwork?.depositAddress || ''}
              isDeposit={selectedNetwork}
            />
          )}
          {isCopied && (
            <div
              style={{
                backgroundColor: '#262832',
                color: '#FFFFFF',
                height: 32,
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                padding: '0 11px',
                justifyContent: 'center',
                width: 136,
                margin: '30px auto',
              }}
            >
              <div style={{ marginRight: 6, marginBottom: 4 }}>Address Copied</div>
              <CheckedIcon />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
