import { useStore } from 'effector-react'
import clsx from 'clsx'

import { pages } from 'constant'
import { calculateCefiPortfolioValueChange, getBalanceString } from 'utils'
import { Currencies } from 'wip/stores'
import FiatSVG from 'icons/wip/FiatSVG'
import RewardsSVG from 'icons/wip/RewardsSVG'
import StakingSVG from 'icons/wip/StakingSVG'
import TotalSVG from 'icons/wip/TotalSVG'
import { theme } from 'config'
import { $allStakingContracts, $campaignStakingContracts } from 'model/cefi-stacking'

import { $assetsRates } from '../../../../model/cef-rates-coingecko'
import { $assetEurData, $assetsListData } from '../../../../model/cefi-combain-assets-data'
import { $currency } from '../../../../model/currency'
import { CardItem } from '../card-item'
import styles from './styles.module.scss'

export function InfoCards() {
  const ratesRaw = useStore($assetsRates)
  const currency = useStore($currency)
  const currencyType = currency.type.toLowerCase() as 'eur' | 'usd'
  const assets = useStore($assetsListData)
  const stackingList = useStore($allStakingContracts)
  const superchargeList = useStore($campaignStakingContracts)

  const totalCurrencyAmount = assets.reduce((acc, asset) => {
    return acc + asset[currencyType].cash
  }, 0)

  const fiatAsset = useStore($assetEurData)

  const assetsWithChange = assets?.filter(item => item[currencyType].cash !== 0)
  const avgAssetChange = calculateCefiPortfolioValueChange(assetsWithChange)

  const currencySymbol = currency.symbol === Currencies.USD ? 'USD' : 'EUR'

  const stackingLocked = stackingList.reduce((acc, stackItem) => {
    const stackItemRate = ratesRaw.find(
      rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stackItem.assetId
    )
    return acc + stackItem.amount * Number(stackItemRate?.data?.currentPrice ?? 0)
  }, 0)

  const superchargeLocked = superchargeList.reduce((acc, stackItem) => {
    const stackItemRate = ratesRaw.find(
      rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stackItem.assetId
    )
    return acc + +stackItem.userStakingAmount * Number(stackItemRate?.data?.currentPrice ?? 0)
  }, 0)

  const allLocked = stackingLocked + superchargeLocked

  const stackingPayedRewardAmount = stackingList.reduce((acc, stackItem) => {
    const stackItemRate = ratesRaw.find(
      rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stackItem.assetId
    )
    return acc + stackItem.payedRewardAmount * Number(stackItemRate?.data?.currentPrice ?? 0)
  }, 0)

  const superchargePayedRewardAmount = superchargeList.reduce((acc, stackItem) => {
    const stackItemRate = ratesRaw.find(
      rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stackItem.assetId
    )
    return acc + +stackItem.payedRewardAmount * Number(stackItemRate?.data?.currentPrice ?? 0)
  }, 0)

  const allPayedRewardAmount = stackingPayedRewardAmount + superchargePayedRewardAmount

  const avgEarnChange = (): number => {
    const stacksChange = stackingList
      .filter(stack => +stack.amount)
      .map(stack => {
        const asset = assets.find(asset => asset.assetId === stack.assetId)
        const stackItemRate = ratesRaw.find(
          rate => rate.toAssetId === currencySymbol && rate.fromAssetId === stack.assetId
        )
        return {
          ...asset,
          availableBalance: stack.amount,
          [currencyType]: {
            cash: stack.amount * (stackItemRate?.data.currentPrice || 0),
            price: stackItemRate?.data.currentPrice ?? 0,
            priceChangePercentage24h: stackItemRate?.data.priceChangePercentage24h ?? 0,
          },
        }
      })
    if (!stacksChange.length) return 0
    // TODO something
    return calculateCefiPortfolioValueChange(stacksChange as any) ?? 0
  }

  const isHideBlock = ['fideum', 'kaizen'].includes(theme)

  return (
    <div className={styles.infoCards}>
      <div className={styles.infoCardsItemWrap}>
        <CardItem
          amount={getBalanceString(totalCurrencyAmount, 2) || '0'}
          changeRate={avgAssetChange ? avgAssetChange?.toString() : '0'}
          title='Total Portfolio'
          path={pages.Base.path}
          icon={<TotalSVG fill='var(--mainBlue)' />}
        />
      </div>
      <div className={styles.infoCardsItemWrap}>
        <CardItem
          amount={fiatAsset[currencyType].cash.toString() ?? '0'}
          changeRate={fiatAsset[currencyType].priceChangePercentage24h.toString() ?? '0'}
          title='Fiat Balance'
          path={pages.BANKING.path}
          icon={<FiatSVG fill='var(--mainBlue)' />}
        />
      </div>
      <div
        className={clsx(styles.infoCardsItemWrap, isHideBlock ? styles.hideBlock : {})}
        style={{ visibility: isHideBlock ? 'hidden' : 'visible' }}
      >
        <CardItem
          amount={getBalanceString(allLocked, 2)}
          changeRate={avgEarnChange().toString()}
          title='Locked'
          path={pages.EARN.path}
          icon={<StakingSVG fill='var(--mainBlue)' />}
        />
      </div>
      <div
        className={clsx(styles.infoCardsItemWrap, isHideBlock ? styles.hideBlock : {})}
        style={{ visibility: isHideBlock ? 'hidden' : 'visible', marginRight: 0 }}
      >
        <CardItem
          amount={allPayedRewardAmount.toString()}
          changeRate={avgEarnChange().toString()}
          title='Earning Rewards'
          path={pages.EARN.path}
          icon={<RewardsSVG fill='var(--mainBlue)' />}
        />
      </div>
    </div>
  )
}
