import { createEffect, createStore } from 'effector'

import { AssetsServices } from '../wip/services'

export const $favouriteAssets = createStore<string[]>([])

export const getFavouriteAssetsFx = createEffect(async () => {
  try {
    return await AssetsServices.getFavouriteAssets()
  } catch (error) {
    console.log('ERROR-getFavouriteAssetsFx', error)
  }
})

$favouriteAssets.on(getFavouriteAssetsFx.doneData, (s, p) => p)
