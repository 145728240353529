import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { AuthSnackbar, BackButton } from 'components'
import I18n from 'components/i18n'
import i18n from 'components/i18n/localize'
import { pages } from 'constant'
import { errorDescriptionHandler, parseJwt, saveRefreshToken, saveToken } from 'utils'
import { AuthResponse, StepController } from 'wip/services'
import { initApp } from 'wip/stores'
import { updateEarning } from 'wip/stores/init'
import MessageSendSvgComponent from 'assets/icons/MessageSendSvgComponent'

import { snackComponentChangedEv } from '../../../model/snackComponent'
import AuthLayout from '../auth-layout'
import AuthTitle from '../auth-title'
import styles from '../styles.module.scss'

type Inputs = {
  confirmationCode: string
}

const defaultValues = {
  confirmationCode: '',
}

const STEPS = {
  VERIFY_PHONE: 'VERIFY_PHONE',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  MFA_VERIFY: 'MFA_VERIFY',
}

export function ConfirmationCode() {
  const { t } = i18n
  const location = useLocation()
  const sessionToken = location.state?.sessionToken
  const step = location.state?.step
  const navigate = useNavigate()
  const methods = useForm<Inputs>({ defaultValues })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const [loading, setLoading] = useState<boolean>(false)

  const handleConfirmation: SubmitHandler<Inputs> = async data => {
    setLoading(true)

    try {
      let res: AuthResponse | undefined
      if (step === STEPS.VERIFY_PHONE) {
        res = await StepController.verifyPhone({ code: data.confirmationCode }, sessionToken)
      }

      if (step === STEPS.VERIFY_EMAIL) {
        res = await StepController.verifyEmail({ code: data.confirmationCode }, sessionToken)
      }

      if (res) {
        if (res.nextStep === STEPS.MFA_VERIFY) {
          navigate(pages.TwoFactorAuthentication.path, {
            state: { sessionToken: res.sessionToken },
          })
        }

        if (res.nextStep === null) {
          saveToken(res.accessToken)
          saveRefreshToken(res.refreshToken)

          if (!res?.accessToken) {
            navigate(pages.SignIn.path)
          }

          const parsedToken = parseJwt(res.accessToken)
          const scope = parsedToken?.scope

          await initApp()
          await updateEarning()

          if (scope && scope.length) {
            navigate(scope.includes('KYC') ? pages.Base.path : pages.KYC.path)
          }
        }
      }
    } catch (error: any) {
      console.log('ERROR-handleConfirmation', error)
      snackComponentChangedEv(
        <AuthSnackbar title='Code is Incorrect.' description={errorDescriptionHandler(error.code || '')} />
      )
    } finally {
      setLoading(false)
    }
  }

  const handleResend = async () => {
    setLoading(true)

    try {
      if (step === STEPS.VERIFY_PHONE) await StepController.phoneSessionCodeResend(sessionToken)
      if (step === STEPS.VERIFY_EMAIL) await StepController.emailSessionCodeResend(sessionToken)
    } catch (error: any) {
      console.log('handleResend-ERROR', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout>
      <div className={styles.rightModule}>
        <BackButton backFn={() => navigate(pages.SignIn.path)} />

        <div className={styles.formWrap}>
          <div className={styles.iconMessage}>
            <MessageSendSvgComponent fill='var(--mainBlue)' />
          </div>
          <AuthTitle
            title={step === STEPS.VERIFY_PHONE ? 'Check Your Phone' : 'ConfirmationCode.confirm.title'}
            description={
              step === STEPS.VERIFY_PHONE
                ? 'A confirmation code has been sent to your phone'
                : 'ConfirmationCode.confirm.description'
            }
          />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleConfirmation)}>
              <div style={{ marginTop: '-2rem' }} className='input-item-wrap'>
                <input
                  type='text'
                  className='input-form'
                  style={errors.confirmationCode ? { outline: '1px solid red' } : {}}
                  placeholder={t('ConfirmationCode.confirmationCode') || ''}
                  {...register('confirmationCode', { required: true })}
                />
                <div style={{ height: '2rem' }} />
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {loading ? <span className='spinner-border' /> : <I18n tKey='ConfirmationCode.confirm' />}
                </button>

                <div className='input-item-wrap text-under-button'>
                  <p className='input-label text-center'>
                    {t('ConfirmationCode.notReceive')}
                    <span
                      onClick={async () => {
                        await handleResend()
                      }}
                      className='input-label form-link'
                    >
                      {t('ConfirmationCode.sendAgain')}
                    </span>
                  </p>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </AuthLayout>
  )
}
