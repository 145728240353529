import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import moment from 'moment'
import clsx from 'clsx'

import { Modal } from 'components'
import { TYPE_TXN_HISTORY } from 'features/transactions-history/constant-type-transaction-history'
import { TriangleIcon } from 'icons'
import { $cardHistoryByFilter } from 'model/card-history'
import { $transactionsHistory, CryptoAndFiatHistoryType, TITLE_TXN_HISTORY } from 'model/cefi-transactions-history'
import { $transactionsHistoryFiat } from 'model/cefi-transactions-history-fiat'

import { FilterButton } from './filter-button'
import { SearchAndList } from './search-and-list'
import styles from './styles.module.scss'

export type FilterOptionsType = {
  field: string //ASSET_TYPE or MERCHANT_TYPE, TIME, TRANSACTION_TYPE
  value: string
}
export type GroupedOptionsType = {
  [key: string]: string[]
}

type Props = {
  filterOptions: FilterOptionsType[]
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptionsType[]>>
  transactionType: string
  responseData?: any[]
  filterAssetId?: string
  rewardNameFilter?: string
}

const STEP = {
  FILTER: 'FILTER',
  LIST: 'LIST',
}

export const TransactionHistoryFilter = ({
  filterOptions,
  setFilterOptions,
  transactionType = TYPE_TXN_HISTORY.CRYPTO,
  responseData,
  filterAssetId,
  rewardNameFilter,
}: Props) => {
  const transactionsHistoryCrypto = useStore($transactionsHistory)
  const transactionsHistoryFiat = useStore($transactionsHistoryFiat)
  const cardHistoryByFilter = useStore($cardHistoryByFilter)

  let transactionsHistory = []

  if (responseData) {
    transactionsHistory = responseData
  } else {
    switch (transactionType) {
      case TYPE_TXN_HISTORY.CRYPTO:
        transactionsHistory = transactionsHistoryCrypto
        break
      case TYPE_TXN_HISTORY.FIAT:
        transactionsHistory = transactionsHistoryFiat
        break
      case TYPE_TXN_HISTORY.CARD:
        transactionsHistory = cardHistoryByFilter
        break
      default:
        transactionsHistory = []
        break
    }
  }

  const [step, setStep] = useState(STEP.FILTER)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [dateRange, setDateRange] = useState({ from: '', to: '' })
  const [selectedTransactionType, setSelectedTransactionType] = useState<string[]>([])
  const [selectedMerchantType, setSelectedMerchantType] = useState<string[]>([])
  const [selectedDate, setSelectedDate] = useState('')

  const initializeFilterOptions = () => {
    const assetTypes: string[] = []
    const merchantType: string[] = []
    let timeFrom = ''
    let timeTo = ''
    const transactionTypes: string[] = []

    filterOptions.forEach(option => {
      switch (option.field) {
        case 'ASSET_TYPE': {
          assetTypes.push(option.value)
          break
        }
        case 'MERCHANT_TYPE': {
          merchantType.push(option.value)
          break
        }
        case 'TIME': {
          const [from, to] = option.value.split(' - ')
          timeFrom = moment(from).format('DD/MM/YY')
          timeTo = moment(to).format('DD/MM/YY')
          break
        }
        case 'TRANSACTION_TYPE': {
          transactionTypes.push(option.value)
          break
        }
        default:
          break
      }
    })

    setSelectedItems(assetTypes)
    setDateRange({ from: timeFrom, to: timeTo })
    setSelectedTransactionType(transactionTypes) // tut
    setSelectedMerchantType(merchantType)
  }

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear()).slice(-2)
    return `${day}/${month}/${year}`
  }

  const checkDateButton = (from: string, to: string) => {
    const now = new Date()
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())
    const lastQuarter = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate())
    const lastYear = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())

    if (from === formatDate(lastMonth) && to === formatDate(now)) {
      setSelectedDate('Last month')
    } else if (from === formatDate(lastQuarter) && to === formatDate(now)) {
      setSelectedDate('Last quarter')
    } else if (from === formatDate(lastYear) && to === formatDate(now)) {
      setSelectedDate('Last year')
    } else {
      setSelectedDate('')
    }
  }

  useEffect(() => {
    initializeFilterOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkDateButton(dateRange.from, dateRange.to)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  const handleDateButtonPress = (label: string) => {
    setSelectedDate(prev => (prev === label ? '' : label))

    if (selectedDate === label) {
      setDateRange({ from: '', to: '' })
    } else {
      const now = new Date()
      let fromDate
      const toDate = now

      switch (label) {
        case 'Last month':
          fromDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())
          break
        case 'Last quarter':
          fromDate = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate())
          break
        case 'Last year':
          fromDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())
          break
        default:
          fromDate = toDate
          break
      }

      if (fromDate) {
        const formattedFrom = formatDate(fromDate)
        const formattedTo = formatDate(toDate)
        setDateRange({ from: formattedFrom, to: formattedTo })
      } else {
        setDateRange({ from: '', to: '' })
      }
    }
  }

  const handleDateInput = (value: string, type: string) => {
    let cleaned = value.replace(/[^0-9]/g, '')

    if (cleaned.length > 2) {
      cleaned = `${cleaned.slice(0, 2)}/${cleaned.slice(2)}`
    }
    if (cleaned.length > 5) {
      cleaned = `${cleaned.slice(0, 5)}/${cleaned.slice(5)}`
    }

    const parts = cleaned.split('/')

    if (parts[0] && parseInt(parts[0], 10) > 31) {
      parts[0] = '31'
    }

    if (parts[1] && parseInt(parts[1], 10) > 12) {
      parts[1] = '12'
    }

    if (parts[2] && parts[2].length > 2) {
      parts[2] = parts[2].slice(0, 2)
    }

    cleaned = parts.join('/')

    setDateRange(prev => ({ ...prev, [type]: cleaned }))
  }

  const handleTransactionButtonPress = (label: string) => {
    setSelectedTransactionType(prev => (prev.includes(label) ? prev.filter(item => item !== label) : [...prev, label]))
  }
  const handleMerchantTypeButtonPress = (label: string) => {
    setSelectedMerchantType(prev => (prev.includes(label) ? prev.filter(item => item !== label) : [...prev, label]))
  }

  const handleOpenList = () => {
    setStep(prev => (prev === STEP.LIST ? STEP.FILTER : STEP.LIST))
  }

  const handleCloseFilterList = () => {
    setStep(STEP.FILTER)
  }

  function formatDateFilterToISO(obj: { from: string; to: string }) {
    if (!obj.from && !obj.to) return ''
    const fromDate = obj.from ? moment(obj.from, 'DD/MM/YY') : moment()
    const toDate = obj.to ? moment(obj.to, 'DD/MM/YY') : moment()

    const isoFromDate = fromDate.toISOString()
    const isoToDate = toDate.toISOString()

    return `${isoFromDate} - ${isoToDate}`
  }

  const handleCloseFilter = () => {
    Modal.close()
  }

  const handleApply = () => {
    const options: FilterOptionsType[] = []

    selectedItems.length &&
      selectedItems.forEach(asset => {
        options.push({ field: 'ASSET_TYPE', value: asset })
      })

    const dateString = formatDateFilterToISO(dateRange)
    dateString && options.push({ field: 'TIME', value: dateString })

    selectedTransactionType.length &&
      selectedTransactionType.forEach(name => {
        options.push({ field: 'TRANSACTION_TYPE', value: name })
      })

    selectedMerchantType.length &&
      selectedMerchantType.forEach(name => {
        options.push({ field: 'MERCHANT_TYPE', value: name })
      })

    setFilterOptions(options)
    handleCloseFilter()
  }

  const handleDeselectAll = () => {
    setFilterOptions([])
    setSelectedItems([])
    setSelectedDate('')
    setSelectedTransactionType([])
    setSelectedMerchantType([])
    setDateRange({ from: '', to: '' })
  }

  const getAssetIds = (data: CryptoAndFiatHistoryType[]) => {
    const result = new Set()
    data.forEach(item => {
      if (item.assetId) {
        result.add(item.assetId)
      }
      if (item.toAssetId) {
        result.add(item.toAssetId)
      }
      if (item.fromAssetId) {
        result.add(item.fromAssetId)
      }
    })
    return Array.from(result) as string[]
  }

  const getTransactionTypeButton = (type: string) => {
    if (type === TYPE_TXN_HISTORY.CRYPTO) {
      return ['Exchange', 'Reward', 'Deposit', 'Withdrawal', 'Cashback']
    } else if (type === TYPE_TXN_HISTORY.FIAT) {
      return ['Top Up', 'Withdrawal']
    } else if (type === TYPE_TXN_HISTORY.CARD) {
      return ['Card Transaction', 'Top Up', 'Fee']
    } else {
      return []
    }
  }

  const merchantTypeButtons = [
    'Groceries',
    'Shopping',
    'Restaurants',
    'Transport',
    'Travel',
    'Entertainment',
    'Health',
    'Services',
    'Utilities',
  ]

  return (
    <div className={styles.container}>
      <div className={styles.title}>Filters</div>

      {[TYPE_TXN_HISTORY.CRYPTO].includes(transactionType) && !filterAssetId ? (
        <div className={clsx(styles.section, step === STEP.LIST ? styles.fixSectionForSearchAndList : '')}>
          <div className={styles.sectionTitle}>Asset type</div>

          <div className={styles.horizontalLine}></div>

          <div className={styles.assetInputWrap} onClick={handleOpenList}>
            {selectedItems.length ? (
              <div className={styles.assetInputText}>{selectedItems.join(', ')}</div>
            ) : (
              <div className={styles.assetInputPlaceholder}>
                Choose an {transactionType === TYPE_TXN_HISTORY.CARD ? 'merchant' : 'asset'} type
              </div>
            )}
            <div className={clsx(styles.assetInputIcon, step === STEP.LIST ? styles.iconTransform : '')}>
              <TriangleIcon fill='var(--mainBlue)' />
            </div>
          </div>
        </div>
      ) : null}

      {[TYPE_TXN_HISTORY.CARD].includes(transactionType) ? (
        <div className={clsx(styles.section, step === STEP.LIST ? styles.fixSectionForSearchAndList : '')}>
          <div className={styles.sectionTitle}>Merchant type</div>

          <div className={styles.horizontalLine}></div>

          <div className={styles.buttonGroupFilter}>
            {merchantTypeButtons.map(title => (
              <FilterButton
                key={title}
                title={title}
                action={() => handleMerchantTypeButtonPress(title)}
                buttonActive={selectedMerchantType.includes(title)}
              />
            ))}
          </div>
        </div>
      ) : null}

      {step === STEP.FILTER ? (
        <>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Date</div>

            <div className={styles.horizontalLine}></div>

            <div className={styles.buttonGroupFilter}>
              {['Last month', 'Last quarter', 'Last year'].map(title => (
                <FilterButton
                  key={title}
                  title={title}
                  action={() => handleDateButtonPress(title)}
                  buttonActive={selectedDate === title}
                />
              ))}
            </div>

            <div className={styles.inputDateGroup}>
              <div style={{ flexGrow: 1 }}>
                <div className={styles.inputDateLabel}>From</div>
                <input
                  type='text'
                  placeholder='dd/mm/year'
                  onChange={e => handleDateInput(e.target.value, 'from')}
                  value={dateRange.from}
                  className={styles.inputDate}
                  maxLength={8}
                />
              </div>
              <div style={{ flexGrow: 1 }}>
                <div className={styles.inputDateLabel}>To</div>
                <input
                  type='text'
                  placeholder='dd/mm/year'
                  onChange={e => handleDateInput(e.target.value, 'to')}
                  value={dateRange.to}
                  className={styles.inputDate}
                  maxLength={8}
                />
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>Transaction type</div>

            <div className={styles.horizontalLine}></div>

            <div className={styles.buttonGroupFilter}>
              {getTransactionTypeButton(transactionType).map(title => (
                <FilterButton
                  key={title}
                  title={title}
                  action={() => handleTransactionButtonPress(title)}
                  buttonActive={selectedTransactionType.includes(title)}
                  disabled={
                    title === TITLE_TXN_HISTORY.CASHBACK
                      ? !selectedItems.includes('FI') && !!selectedItems.length
                      : false
                  }
                />
              ))}
            </div>
          </div>

          <div className={styles.flexGrow1} />

          <div className={styles.mainBtnGroup}>
            <button className={clsx('btn', 'btn-primary', styles.mainBtnClear)} onClick={handleDeselectAll}>
              Clear All
            </button>
            <button className={clsx('btn', 'btn-primary', styles.mainBtnSumbit)} onClick={handleApply}>
              Apply
            </button>
          </div>
        </>
      ) : null}

      {step === STEP.LIST ? (
        <SearchAndList
          data={getAssetIds(transactionsHistory)}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          goBack={handleCloseFilterList}
        />
      ) : null}
    </div>
  )
}
