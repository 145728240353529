import { useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import i18n from 'components/i18n/localize'
import { getBalanceString, roundingBalance } from 'utils'
import { Currencies } from 'wip/stores'
import ChangeIcon from 'assets/icons/ChangeIcon'

import { $currency } from '../../model/currency'
import styles from './styles.module.scss'

export interface InputAmount {
  errors: any
  asset: any
  register: any
  assetRate: any
  setValue: any
  watchAmount: any
  isCurrency: any
  setIsCurrency: any
  clearErrors: any
}

export function InputAmount({
  errors,
  asset,
  register,
  assetRate,
  setValue,
  watchAmount,
  isCurrency,
  setIsCurrency,
  clearErrors,
}: InputAmount) {
  const { t } = i18n
  const currency = useStore($currency)

  const [isFocusAmount, setIsFocusAmount] = useState(false)

  const validateAmountCurrency = (data: string): boolean | string => {
    const amount = data.replace(',', '.')
    const currentPrice = assetRate?.data.currentPrice || 0
    if (isNaN(+amount)) return 'Incorrect value'
    if (+amount <= +asset.availableBalance * currentPrice) return true

    return 'Not Enough Balance'
  }

  const validateAmountCrypto = (data: string): boolean | string => {
    const amount = data.replace(',', '.')
    if (isNaN(+amount)) return 'Incorrect value'
    if (+amount <= +asset.availableBalance) return true
    return 'Not Enough Balance'
  }

  const handleMaxBtn = (): void => {
    clearErrors()
    const newAmount = isCurrency
      ? (assetRate?.data.currentPrice || 0) * +roundingBalance(asset.availableBalance, 8)
      : roundingBalance(asset.availableBalance, 8)
    setValue('amount', newAmount.toString())
  }

  const changeAmountCurrency = (): void => {
    let newAmount = 0
    if (isCurrency && assetRate) {
      newAmount = +watchAmount / assetRate.data.currentPrice
    } else if (!isCurrency && assetRate) {
      newAmount = +watchAmount * assetRate.data.currentPrice
    }
    setValue('amount', newAmount.toString())

    setIsCurrency((prev: any) => !prev)
  }

  return (
    <>
      <div className={styles.enterAmount} style={errors.amount ? { color: 'red' } : {}}>
        {errors.amount && errors.amount.type === 'required' ? t('inputError.required') : ''}
        {errors.amount && errors.amount.type === 'validate' ? errors.amount.message : ''}
        {!errors.amount && 'Enter Amount'}
      </div>
      <div
        className={clsx(styles.withdrawInput, isFocusAmount ? styles.focusAmount : '')}
        style={errors.amount ? { border: '1px solid red' } : {}}
      >
        <div className={styles.assetNameWrap}>
          <img src={asset.icon} className={styles.assetIcon} alt='' />
          <div className={styles.assetName}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isCurrency ? (currency.symbol === Currencies.USD ? 'USD' : 'EUR') : asset.assetName}
          </div>
        </div>
        <div className={styles.inputBlock}>
          {isCurrency ? (
            <>
              <div onClick={changeAmountCurrency} className={styles.currencyBlock}>
                ≈ {asset.id}{' '}
                {assetRate && watchAmount ? getBalanceString(+watchAmount / assetRate.data.currentPrice, 8) : '00'}
                &nbsp;
                <ChangeIcon fill='var(--mainBlue)' width={9} height={9} />{' '}
              </div>
              <input
                placeholder='00.00'
                className={styles.input}
                onFocus={() => setIsFocusAmount(true)}
                {...register('amount', {
                  required: true,
                  validate: validateAmountCurrency,
                  onBlur: () => setIsFocusAmount(false),
                })}
              />
            </>
          ) : (
            <>
              <input
                placeholder='00.00'
                className={styles.input}
                onFocus={() => setIsFocusAmount(true)}
                {...register('amount', {
                  required: true,
                  validate: validateAmountCrypto,
                  onBlur: () => setIsFocusAmount(false),
                })}
              />
              <div onClick={changeAmountCurrency} className={styles.currencyBlock}>
                ≈ {currency.symbol}{' '}
                {assetRate && watchAmount ? getBalanceString(+watchAmount * assetRate.data.currentPrice, 2) : '00'}
                &nbsp;
                <ChangeIcon fill='var(--mainBlue)' width={9} height={9} />{' '}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.balanceWrap}>
        <div className={styles.balance}>
          Balance: {getBalanceString(+asset.availableBalance, 8)} {asset.id}
        </div>
        <div onClick={handleMaxBtn} className={styles.maxBtn}>
          MAX
        </div>
      </div>
    </>
  )
}
