import { createEffect, createStore } from 'effector'

import { AuthServiceV4 } from 'wip/services'

export const $userEmail = createStore('')

export const getUserEmailFx = createEffect(async () => {
  try {
    const { email } = await AuthServiceV4.getEmail()
    return email
  } catch (error) {
    console.log('ERROR-getCardStatusFx', error)
  }
})

$userEmail.on(getUserEmailFx.doneData, (s, p) => p)
