import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useStore } from 'effector-react'

import { Modal } from 'components'
import { Layout } from 'features/layout'
import { Router } from 'router'
import { getToken, updateFavicon } from 'utils'
import { initApp } from 'wip/stores'
import { favicon, theme } from 'config'
import { $isMobile, setIsMobileEV } from 'model'
import { $userEmail } from 'model/user-email'
import { $userPhone, getUserPhoneFx } from 'model/user-phone'

import { MainSnackbar } from '../components/main-snackbar'
import { MobileAppLanding } from './mobile-app-landing'

export function App() {
  const mediaQueryList = window.matchMedia('(max-width: 767px)')
  const userEmail = useStore($userEmail)
  const userPhone = useStore($userPhone)
  const token = getToken()
  const isMobileScreens = useStore($isMobile)

  const handleChange = (mq: MediaQueryListEvent | MediaQueryList) => {
    setIsMobileEV(mq.matches)
  }

  useEffect(() => {
    handleChange(mediaQueryList)
    mediaQueryList.addEventListener('change', handleChange)
    return () => mediaQueryList.removeEventListener('change', handleChange)
  }, [])

  const updateData = () => {
    initApp()
      .then(() => {
        setTimeout(() => {
          updateData()
        }, 40000)
      })
      .catch(error => console.error('ERROR-initApp', error))
  }

  useEffect(() => {
    if (favicon()) updateFavicon()
    updateData()
    // refreshToken()
  }, [])

  useEffect(() => {
    !userPhone && token && getUserPhoneFx()
  }, [userEmail])

  return (
    <div className='app' data-theme={theme}>
      <BrowserRouter>
        <Modal.portal />
        <MainSnackbar />
        <Layout>{isMobileScreens ? <MobileAppLanding /> : <Router />}</Layout>
        {/*<Layout>*/}
        {/*  <Router />*/}
        {/*</Layout>*/}
      </BrowserRouter>
    </div>
  )
}
