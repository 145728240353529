import { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { CompleteIconBlur, RequestError, Spinner } from 'components'
import { StepControllerComponent } from 'features/step-controller'
import { saveRefreshToken, saveToken } from 'utils'
import { AuthResponse, AuthServiceV4, MFAAddAuthResponse, StepUpAuthResponse } from 'wip/services'
import { $isMobile } from 'model'
import { $twoFaStatus, setTwoFaStatusEv } from 'model/two-fa'

import styles from './styles.module.scss'

export function TwoFaSetup() {
  const isMobile = useStore($isMobile)
  const twoFa = useStore($twoFaStatus)

  const [isLoading, setIsLoading] = useState(false)
  const [responseError, setResponseError] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const [response, setResponse] = useState<AuthResponse | StepUpAuthResponse | MFAAddAuthResponse>()

  const deleteMfa = async () => {
    setIsLoading(true)

    setResponseError('')
    try {
      const deleteMfaRes = await AuthServiceV4.deleteMfa()

      setResponse(deleteMfaRes)
    } catch (error: any) {
      console.log('ERROR-deleteMfa', error)
      setResponseError(error?.code || error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (twoFa) {
      deleteMfa()
    }
  }, [])

  const handleOffOnSetup = async () => {
    setIsLoading(true)

    setResponseError('')
    try {
      if (!twoFa) {
        const mfaSetupData = await AuthServiceV4.mfaSetup()
        setResponse(mfaSetupData)
      }

      if (twoFa) {
        const deleteMfaRes = await AuthServiceV4.deleteMfa()
        setResponse(deleteMfaRes)
      }
    } catch (error: any) {
      console.log('ERROR-OffOnSetup', error)
      setResponseError(error?.code || error.message)
    }
    setIsLoading(false)
  }

  const handleFinalAction = (responseData: AuthResponse | StepUpAuthResponse | MFAAddAuthResponse) => {
    setIsSuccess(true)
    saveToken(responseData.accessToken)
    saveRefreshToken(responseData.refreshToken)
    setTwoFaStatusEv(!twoFa) // TODO можно распарсить токен и получить MFA
  }

  if (isLoading) {
    return (
      <div className={styles.isLoadingWrap}>
        <Spinner />
      </div>
    )
  }

  if (isSuccess) {
    return (
      <>
        <div className={styles.isSuccessIconWrap}>
          <CompleteIconBlur isMobile={isMobile} />
        </div>
        <div className={styles.title}>
          {!twoFa ? 'Turning off the 2FA successfully' : 'Two Factor Authentication'}
          <br />
          {twoFa && 'Setup Successfully'}
        </div>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <div className={clsx(styles.title, styles.titleMainMobile)}>Two Factor Authentication</div>

      {!twoFa && !response ? (
        <>
          <div>
            <div className={styles.description}>
              To setup Two Factor Authentication please <br /> follow the instructions.
            </div>
          </div>
          <div className={styles.btnWrap}>
            <button type='button' className='btn btn-primary' disabled={isLoading} onClick={handleOffOnSetup}>
              {isLoading ? <span className='spinner-border' /> : 'Start Setup'}
            </button>
          </div>
        </>
      ) : null}

      {response && <StepControllerComponent nextStepResponse={response} finalAction={handleFinalAction} />}

      {responseError && <RequestError requestError={responseError} />}
    </div>
  )
}
