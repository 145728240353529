import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { CommonDropdown, InputAmount, Modal, Spinner, Success } from 'components'
import i18n from 'components/i18n/localize'
import { pages } from 'constant'
import { StepControllerComponent } from 'features/step-controller'
import { getBalanceString, getToken, parseJwt, roundingBalance, validateAddress } from 'utils'
import {
  AssetsServices,
  AuthResponse,
  AuthServiceV4,
  DepositInfo,
  EVENT_NAMES,
  MFAAddAuthResponse,
  StepUpAuthResponse,
  useAnalytics,
  WithdrawalInfo,
} from 'wip/services'
import { initApp } from 'wip/stores'
import { DangerIcon } from 'icons'

import { $assetsRates } from '../../../model/cef-rates-coingecko'
import { CombainedObject } from '../../../model/cefi-combain-assets-data'
import { $currency } from '../../../model/currency'
import styles from './styles.module.scss'

type Inputs = {
  amount: string
  address: string
  twoFaCode: string
  emailCode: string
  phoneCode: string
}

const defaultValues = {
  amount: '',
  address: '',
  twoFaCode: '',
  emailCode: '',
  phoneCode: '',
}

export interface WithdrawAssetModal {
  asset: CombainedObject
}

export interface VerificationStep {
  nextStep: string
  sessionToken: string
  refreshToken: string
  accessToken: string
  oneTimeAccessToken?: string
}

export function WithdrawAssetModal({ asset }: WithdrawAssetModal) {
  const ratesRaw = useStore($assetsRates)
  const currency = useStore($currency)
  const { myLogEvent } = useAnalytics()
  const navigate = useNavigate()
  const containerRef = useRef<HTMLInputElement | null>(null)
  const { t } = i18n
  const methods = useForm<Inputs>({ defaultValues })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
  } = methods

  const assetRate = ratesRaw.find(
    assetRateRaw => assetRateRaw.fromAssetId === asset?.assetId && assetRateRaw.toAssetId === currency.type
  )

  const [isLoading, setIsLoading] = useState(true)
  const [availableNetworks, setAvailableNetworks] = useState<DepositInfo[]>([])
  const [selectedNetwork, setSelectedNetwork] = useState<DepositInfo | null>(null)
  const [isCurrency, setIsCurrency] = useState(false)
  const [summaryInfo, setSummaryInfo] = useState<WithdrawalInfo | null>(null)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [address, setAddress] = useState('')
  const [responseError, setResponseError] = useState('')
  const [is2FaOff, setIs2FaOff] = useState(false)

  const [response, setResponse] = useState<AuthResponse | StepUpAuthResponse | MFAAddAuthResponse>()

  const watchAmount = watch('amount').replace(',', '.')
  const watchAddress = watch('address')

  const storageToken = getToken()
  const parsedToken = parseJwt(storageToken)
  const scope = parsedToken?.scope || []

  const twoFaStatus: boolean = scope.includes('MFA')

  const getCoinAddress = useCallback(
    async (assetId: string) => {
      setIsLoading(true)

      try {
        const depositInfo = await AssetsServices.getDepositInfo(assetId)

        /* error for an empty array of addresses and networks */
        if (!depositInfo.length) throw new Error('backendErrors:FAILED_CODE_GENERATION')

        setSelectedNetwork(depositInfo[0])
        setAvailableNetworks(depositInfo)
      } catch (error: any) {
        console.log('ERROR-getCoinAddress', error)
        setResponseError(error.code)
      }
      setIsLoading(false)
    },
    [asset]
  )

  useEffect(() => {
    if (asset) {
      getCoinAddress(asset.assetId)
      myLogEvent(EVENT_NAMES.WEB_WITHDRAW_OPENED, { asset: asset.assetId })
    }
  }, [asset])

  const getWithdrawalInfo = async (isValidAddress: string): Promise<void> => {
    // Check deposit wallet for withdrawal
    const depositInfo = await AssetsServices.getDepositInfo(asset?.assetId ?? '')
    const [{ depositAddress }] = depositInfo.filter(info => info.networkId === selectedNetwork?.networkId)

    if (!depositAddress && selectedNetwork)
      await AssetsServices.createDepositAddress(asset?.assetId ?? '', selectedNetwork?.networkId)

    const withdrawalInfo = await AssetsServices.getWithdrawalInfo({
      address: isValidAddress,
      networkId: selectedNetwork?.networkId ?? '',
      amount: isCurrency ? +watchAmount / (assetRate?.data.currentPrice ?? 1) : +watchAmount,
      assetId: asset?.assetId ?? '',
    })
    setSummaryInfo(withdrawalInfo)
  }

  const handleFinalAction = async (responseData: AuthResponse | StepUpAuthResponse | MFAAddAuthResponse) => {
    const data = {
      address,
      networkId: selectedNetwork?.networkId ?? '',
      amount: isCurrency ? +watchAmount / (assetRate?.data.currentPrice || 1) : +watchAmount,
      info: summaryInfo as WithdrawalInfo,
    }

    try {
      setIsLoading(true)
      if ((responseData as StepUpAuthResponse).oneTimeAccessToken) {
        await AssetsServices.withdrawalAsset(data, (responseData as StepUpAuthResponse).oneTimeAccessToken)
        myLogEvent(EVENT_NAMES.WEB_CEFI_WITHDRAWAL, { amount: data.amount, assetId: asset?.assetId })
        await initApp()
        setIsSuccessful(true)
      }
    } catch (error: any) {
      console.log('lastStepWithdrawal-ERROR', error)
      setResponseError(error.code)
    } finally {
      setIsLoading(false)
    }
  }

  const startWithdrawal = async () => {
    setIsLoading(true)
    try {
      const stepUpRes = await AuthServiceV4.stepUp({ scope: 'STEP_UP_WITHDRAWAL' })

      setResponse(stepUpRes)
    } catch (error: any) {
      console.log('startWithdrawal-ERROR', error)
      setResponseError(error.code)
    } finally {
      setIsLoading(false)
    }
  }

  const handleWithdrawal = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const isValidAddress = selectedNetwork ? await validateAddress(watchAddress, selectedNetwork?.networkId) : ''
      if (!isValidAddress) {
        setError('address', {
          type: 'validate',
          message: 'Invalid',
        })
        throw new Error('Invalid')
      } else {
        setAddress(isValidAddress)
      }

      if (summaryInfo) {
        if (twoFaStatus) {
          await startWithdrawal()
        } else {
          setIs2FaOff(true)
        }
      } else {
        await getWithdrawalInfo(isValidAddress)
      }
      setResponseError('')
    } catch (error: any) {
      console.log('handleWithdraw-ERROR', error)
      setResponseError(error.code)
    }
    setIsLoading(false)
  }

  const itemComponentAddresses = (selectedItemAddress: DepositInfo): ReactElement => {
    return (
      <div>
        <div className={styles.bankName}>{selectedItemAddress.networkId}</div>
      </div>
    )
  }

  if (isSuccessful) {
    return <Success text='Withdrawal Successfully Requested' />
  }

  if (is2FaOff && !twoFaStatus) {
    return (
      <div>
        <div className={styles.title}>Two Factor Authentication</div>
        <div className={styles.description} style={{ maxWidth: 440 }}>
          For security reasons, a 2FA setup is required. Please follow the instructions.
        </div>
        <button
          onClick={() => {
            navigate(pages.SETTINGS.path)
            Modal.close()
          }}
          className='btn btn-primary'
          style={{ maxWidth: 440 }}
        >
          Go to Settings
        </button>
      </div>
    )
  }

  return (
    <>
      <div ref={containerRef} id='withdrawContainer' className={styles.container}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {
              <div className={styles.title}>
                {summaryInfo && !response ? 'Summary' : `Withdraw ${asset?.assetName}`}
              </div>
            }
            {!response && (
              <div className={styles.content}>
                <FormProvider {...methods}>
                  <form style={{ position: 'relative' }} onSubmit={handleSubmit(handleWithdrawal)}>
                    {summaryInfo ? (
                      <>
                        <div className={styles.summaryBlockTitle}>Amount</div>
                        <div className={styles.cryptoAmount}>
                          {`${asset?.symbol} ${
                            isCurrency
                              ? getBalanceString(+watchAmount / (assetRate?.data.currentPrice ?? 1), 8)
                              : roundingBalance(watchAmount, 8)
                          }`}
                        </div>
                        <div className={styles.currencyAmount}>
                          {`${currency.symbol} ${
                            isCurrency
                              ? watchAmount
                              : getBalanceString(+watchAmount * (assetRate?.data.currentPrice || 1), 2)
                          }`}
                        </div>
                        <div className={styles.conversion}>
                          Conversion Rate: 1 {asset?.symbol} = {currency.symbol} {assetRate?.data.currentPrice}
                        </div>
                        <div className={styles.conversion}>
                          Transaction Fee: {roundingBalance(summaryInfo?.totalFeeAmount.toString())} {asset?.symbol}
                        </div>
                        <div className={styles.divider} />
                        <div className={styles.summaryBlockTitle}>Network</div>
                        <div className={styles.cryptoAmount}>{selectedNetwork?.networkId}</div>
                        <div className={styles.divider} />
                        <div className={styles.summaryBlockTitle}>Address</div>
                        <div className={styles.cryptoAddress}>{watchAddress}</div>
                      </>
                    ) : (
                      <>
                        <InputAmount
                          errors={errors}
                          asset={asset}
                          register={register}
                          assetRate={assetRate}
                          setValue={setValue}
                          watchAmount={watchAmount}
                          isCurrency={isCurrency}
                          setIsCurrency={setIsCurrency}
                          clearErrors={clearErrors}
                        />

                        {availableNetworks.length > 1 && (
                          <>
                            <div className={styles.enterAmount}>Choose Network</div>
                            <CommonDropdown
                              containerRef={containerRef}
                              data={availableNetworks}
                              itemComponent={itemComponentAddresses}
                              setSelectedData={setSelectedNetwork}
                              selectedData={selectedNetwork}
                            />
                          </>
                        )}

                        <div className='input-item-wrap' style={{ marginTop: 22 }}>
                          <label
                            htmlFor='address'
                            className={`input-label ${errors.address ? 'text-error' : ''}`}
                            // style={{fontSize: '1rem'}}
                          >
                            Enter Wallet Address{' '}
                            {errors.address && errors.address?.type === 'required' ? t('inputError.required') : ''}
                            {errors.address && errors.address?.type === 'validate' ? t('inputError.invalid') : ''}
                          </label>
                          <input
                            style={errors.address ? { outline: '1px solid red' } : {}}
                            id='address'
                            type='text'
                            className='input-form'
                            placeholder='Enter wallet address here'
                            {...register('address', { required: true })}
                          />
                        </div>
                        <div className={styles.note}>
                          <div style={{ fontWeight: 700 }}>Note</div>: Please ensure you are depositing asset using{' '}
                          {selectedNetwork ? selectedNetwork?.networkId : ''} network.
                        </div>
                      </>
                    )}

                    {responseError ? (
                      <div
                        style={{
                          height: 78,
                          backgroundColor: 'rgba(255, 0, 0, 0.1)',
                          margin: '24px 0',
                          borderRadius: 10,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DangerIcon style={{ height: 24, margin: '0 24px' }} />
                        <div style={{ marginRight: 24 }}>{responseError}</div>
                      </div>
                    ) : (
                      <div style={{ height: 78, margin: '24px 0' }} />
                    )}

                    <button type='submit' className='btn btn-primary' disabled={isLoading}>
                      {isLoading ? <span className='spinner-border' /> : summaryInfo ? 'Confirm' : 'Next'}
                    </button>

                    {summaryInfo && (
                      <button
                        className={clsx('btn', 'btn-primary' /* styles.backBtn */)}
                        onClick={e => {
                          e.preventDefault()
                          setSummaryInfo(null)
                          setResponseError('')
                        }}
                      >
                        Back
                      </button>
                    )}
                  </form>
                </FormProvider>
              </div>
            )}
          </>
        )}
        {response && <StepControllerComponent nextStepResponse={response} finalAction={handleFinalAction} />}
      </div>
    </>
  )
}
