import { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { getToken, parseJwt } from 'utils'
import { AssetsServices, AuthServiceV4 } from 'wip/services'
import { CheckedIcon, CopyIcon } from 'icons'
import { $isMobile } from 'model'

import styles from './styles.module.scss'

const Referral: React.FC = () => {
  const [codeForInvitation, setCodeForInvitation] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const isMobile = useStore($isMobile)

  useEffect(() => {
    const accessToken = getToken() || ''
    const tokenData = parseJwt(accessToken)
    // TODO: refactor .getReferralCode() to state
    AuthServiceV4.getReferralCode()
      .then(res => {
        setCodeForInvitation(res.referralCode)
      })
      .catch(() => setCodeForInvitation(''))
  }, [])
  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap} style={{ height: 32 }}>
          <div className={styles.settingsItemTitle}>Referral</div>
          {isCopied && (
            <div
              style={{
                backgroundColor: '#262832',
                color: '#FFFFFF',
                height: 32,
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                padding: '0 11px',
              }}
            >
              <div style={{ marginRight: 6, marginBottom: 4 }}>Copied</div>
              <CheckedIcon />
            </div>
          )}
        </div>

        <div className={styles.settingsItemDescription}>
          You are automatically enrolled into our referral program. Share your referral code to start earning.
        </div>

        <div
          onClick={() => {
            navigator.clipboard.writeText(codeForInvitation).then(() => {
              setIsCopied(true)
              setTimeout(() => {
                setIsCopied(false)
              }, 2000)
            })
          }}
          className={clsx(styles.disableBtn, styles.copyBtn)}
        >
          {codeForInvitation}
          <div style={{ margin: 6 }} />
          <CopyIcon isMobile={isMobile} fill='var(--mainBlue)' />
        </div>
      </div>
    </div>
  )
}

export default Referral
