import React, { useEffect, useState } from 'react'
import { useStore } from 'effector-react'

import { $cardStatus } from 'model/cefi-banking'

import styles from './styles.module.scss'

const steps = [
  {
    label: 'Country',
    step: 1,
    value: 'RESIDENCY',
  },
  {
    label: 'KYC',
    step: 2,
    value: 'KYC',
  },
  {
    label: 'Deposit',
    step: 3,
    value: 'DEPOSIT',
  },
  {
    label: 'Address',
    step: 4,
    value: 'ADDRESS',
  },
  {
    label: 'Mobile',
    step: 5,
    value: 'PHONE',
  },
  {
    label: 'Terms',
    step: 6,
    value: 'TERMS',
  },
  {
    label: 'Terms',
    step: 6,
    value: 'SUBMITTED',
  },
]

export function StepContainer() {
  const cardStatus = useStore($cardStatus)

  const totalSteps = steps.length - 1
  const [activeStep, setActiveStep] = useState(5)

  const width = `${(99 / (totalSteps - 1)) * (+activeStep - 1)}%`

  useEffect(() => {
    const active = steps.find(step => step.value === cardStatus?.nextStep)
    setActiveStep(active?.step || 0)
  }, [cardStatus])

  if (cardStatus.currentStep === 'COUNTRY_BLOCKED') {
    return null
  }

  return (
    <div className={styles.mainStepContainer}>
      <div className={styles.stepContainer}>
        <div className={styles.activeLine} style={{ width }} />
        {steps.map(({ step, label, value }) => {
          if (value === 'SUBMITTED') return null
          return (
            <div className={styles.stepWrapper} key={value}>
              <div
                className={styles.stepStyle}
                style={{
                  backgroundColor: `${activeStep >= step ? 'var(--mainBlue)' : '#EBEAF5'}`,
                }}
              />
              <div className={styles.stepsLabelContainer}>
                <div className={activeStep >= step ? styles.stepLabelActive : styles.stepLabel} key={value}>
                  {label}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
