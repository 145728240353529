import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios'
import i18n from 'i18next'

import { getToken, parseJwt } from 'utils'
import { getRefreshToken, saveRefreshToken, saveToken } from 'utils/local-storage'
import { API_BASE_URL } from 'config'

import { AuthServiceV4 } from './auth-v4'

interface IRequest {
  method?: Method
  url: string
  data?: any
  contentType?: string
  baseURL?: string
  token?: string
  customHeaders?: Record<string, string> | null
}

interface RequestError {
  status: number
  message: string
  data: any
  code: string
  codeMessage: string
}

const getTokenStore = () => {
  return getToken()
}

const saveTokensStore = (accessToken: string, refreshToken: string) => {
  saveToken(accessToken)
  saveRefreshToken(refreshToken)
}

const logoutOnRefreshError = () => {
  // TODO add logout fn
}

const refreshAccessToken = async () => {
  try {
    const refreshTokenStore = getRefreshToken()
    const response = await AuthServiceV4.newTokensPair(refreshTokenStore || '')
    console.log('@@@ getRefreshToken @@@')

    const { accessToken, refreshToken } = response

    saveTokensStore(accessToken, refreshToken)

    return accessToken
  } catch (error: any) {
    console.log('ERROR - getRefreshToken', error)

    // if (['JWT_NOT_FOUND', 'INTERNAL_SERVER_ERROR'].includes(error.message)) {
    //   saveTokensStore('', '')
    // }
    throw error
  }
}

const checkTokenExpiration = (token: string): boolean => {
  const tokenPayload: { exp: number } | null = parseJwt(token)

  if (!tokenPayload) {
    return false
  }

  const currentTimeInSeconds = Math.floor(Date.now() / 1000)
  const timeUntilExpirationInSeconds = tokenPayload.exp - currentTimeInSeconds

  return timeUntilExpirationInSeconds <= 120 // 2 min
}

const getUserAgentInfo = () => {
  const userAgent = navigator.userAgent
  // console.log('userAgent', userAgent)
  const platform = navigator.platform
  // console.log('platform', platform)

  return {
    os: platform, // Информация об операционной системе
    browser: userAgent, // Информация о браузере
  }
}

const errorHandler = (axiosError: AxiosError): RequestError => {
  const error: RequestError = {
    status: 0,
    message: '',
    data: null,
    code: '',
    codeMessage: '',
  }

  if (axiosError.response) {
    error.data = axiosError.response.data || null
    error.status = axiosError.response.status
    const code = error.data?.code || ''

    if (code) {
      error.code = code
    }

    error.message = error.data?.code || ''

    if (error.status === 500) {
      error.code = error.code + '\nIssue code:\n' + axiosError?.response?.headers['x-trace-id']
    }
  } else {
    error.status = 600
  }
  return error
}

const handleRequest = async function (config: AxiosRequestConfig) {
  try {
    const response = await axios(config)
    return response.data
  } catch (error: any) {
    throw errorHandler(error)
  }
}

let isRefreshing = false

export const request = async <T>({
  method = 'POST',
  url = '',
  data = null,
  contentType = 'application/json',
  baseURL = API_BASE_URL,
  token = '',
  customHeaders = null,
}: IRequest): Promise<T> => {
  const { t } = i18n

  const deviceInfo = getUserAgentInfo()

  const options: AxiosRequestConfig = {
    method,
    baseURL,
    url,
    // headers: { 'content-type': contentType },
    timeout: 20000,
    headers: {
      'content-type': contentType,
      'X-DEVICE-OPERATION_SYSTEM': deviceInfo.os,
      'X-DEVICE-BROWSER': deviceInfo.browser,
    },
  }

  const newToken = token || getTokenStore()

  if (newToken && options.headers) options.headers.Authorization = `Bearer ${newToken}`
  // if (customHeaders) options.headers = customHeaders
  if (customHeaders) options.headers = { ...options.headers, ...customHeaders }

  if (data && method === 'GET') {
    options.params = data
  } else if (data) {
    options.data = data
  }

  if (checkTokenExpiration(newToken) && !isRefreshing) {
    isRefreshing = true
    try {
      const newAccessToken = await refreshAccessToken()
      options.headers!.Authorization = `Bearer ${newAccessToken}`

      return await handleRequest(options)
    } catch (refreshError) {
      logoutOnRefreshError()

      throw refreshError
    } finally {
      isRefreshing = false
    }
  }

  try {
    return await handleRequest(options)
  } catch (error: any) {
    if ((error.status === 401 || error.data?.message === 'Expired JWT') && !isRefreshing) {
      isRefreshing = true

      try {
        const newAccessToken = await refreshAccessToken()
        options.headers!.Authorization = `Bearer ${newAccessToken}`

        return await handleRequest(options)
      } catch (refreshError) {
        logoutOnRefreshError()

        throw refreshError
      } finally {
        isRefreshing = false
      }
    } else {
      throw error
    }
  }
}
