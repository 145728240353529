import { useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { HeaderTitle, Modal } from 'components'
import { MODAL_TYPES } from 'constant'
import { DepositBankingModal, WithdrawBankingModal } from 'features/modals'
import { TransactionHistoryFiat } from 'features/transactions-history/transaction-history-fiat'
import { getBalanceString, getToken, parseJwt, roundingBalance } from 'utils'
import { AuthServiceV4, EVENT_NAMES, useAnalytics } from 'wip/services'
import { Currencies } from 'wip/stores'

import { $assetEurData } from '../../model/cefi-combain-assets-data'
import { OrderCardFlow } from './order-card-flow'
import styles from './styles.module.scss'

export function Banking() {
  const { myLogEvent } = useAnalytics()
  const fiatAsset = useStore($assetEurData)
  const [codeForInvitation, setCodeForInvitation] = useState('')
  const goToModal = (type: string) => {
    if (type === MODAL_TYPES.TOP_UP_BANKING) {
      Modal.open(<DepositBankingModal codeForInvitation={codeForInvitation} />, {
        variant: 'right',
        title: 'Banking',
        isFullScreen: true,
      })
    }

    if (type === MODAL_TYPES.WITHDRAW_BANKING)
      Modal.open(<WithdrawBankingModal openDeposit={() => goToModal(MODAL_TYPES.TOP_UP_BANKING)} />, {
        variant: 'right',
        title: 'Banking',
        isFullScreen: true,
      })
  }

  useEffect(() => {
    const accessToken = getToken() || ''
    const tokenData = parseJwt(accessToken)
    AuthServiceV4.getReferralCode()
      .then(res => {
        setCodeForInvitation(res.referralCode)
      })
      .catch(() => setCodeForInvitation(''))
      .finally(() => {
        myLogEvent(EVENT_NAMES.WEB_BANKING_OPENED)
      })
  }, [])

  return (
    <div className={styles.bankingWrap}>
      <HeaderTitle hideUd headerTitle='Banking' />
      <div className={styles.mainContent}>
        <div className={styles.balanceWrap}>
          <div className={styles.fiatBalanceCard}>
            <div className={styles.balanceTitle}>Fiat Balance</div>
            <div className={styles.balance}>
              {Currencies.EUR}
              {getBalanceString(+roundingBalance(fiatAsset?.availableBalance?.toString() || '0', 2))}
            </div>
            <div className='justify-row-center'>
              <button
                onClick={() => goToModal(MODAL_TYPES.TOP_UP_BANKING)}
                className={clsx('btn', 'btn-primary', styles.fiatBtn)}
              >
                <div className={styles.btnTitle}>Top Up</div>
              </button>
              <button
                onClick={() => goToModal(MODAL_TYPES.WITHDRAW_BANKING)}
                className={clsx('btn', 'btn-primary', styles.fiatBtn)}
                style={{ backgroundColor: '#FFF', color: '#262832', border: '1px solid #262832', marginLeft: 12 }}
              >
                <div className={styles.btnTitle}>Withdraw</div>
              </button>
            </div>
          </div>
          <div className={styles.fiatTransactionWeb}>
            <TransactionHistoryFiat />
          </div>
        </div>

        <OrderCardFlow goToTopUp={() => goToModal(MODAL_TYPES.TOP_UP_BANKING)} />
        {/*<StartPageCard goToTopUp={() => goToModal(MODAL_TYPES.TOP_UP_BANKING)} />*/}
      </div>
    </div>
  )
}
