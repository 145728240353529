import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import i18n from 'i18next'

import { Modal, RequestError } from 'components'
import { pages } from 'constant'
import { roundingBalance } from 'utils'
import { AuthServiceV4, CardService } from 'wip/services'
import { Currencies, initApp } from 'wip/stores'

import { $assetEurData, $assetsListData } from '../../../model/cefi-combain-assets-data'
import { DepositBankingModal } from '../deposit-banking'
import { TradeAssetModal } from '../trade-asset'
import styles from './styles.module.scss'

type Inputs = {
  amount: string
}

const defaultValues = {
  amount: '',
}

export function TopUpCard() {
  const assets = useStore($assetsListData)
  const asset = assets.find(assetItem => assetItem.assetId === 'FI')!
  const { t } = i18n

  const methods = useForm<Inputs>({ defaultValues })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods

  const watchAmount = watch('amount').replace(',', '.')

  const [isLoading, setIsLoading] = useState(false)
  const [requestError, setRequestError] = useState('')
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [codeForInvitation, setCodeForInvitation] = useState('')

  const fiatAsset = useStore($assetEurData) // assets.find(assetItem => assetItem.isFiat)

  useEffect(() => {
    // const accessToken = getToken() || ''
    // const tokenData = parseJwt(accessToken)
    AuthServiceV4.getReferralCode()
      .then(res => {
        setCodeForInvitation(res.referralCode)
      })
      .catch(() => setCodeForInvitation(''))
  }, [])

  const validateAmount = (data: string): boolean | string => {
    setRequestError('')
    const amount = Number(data.replace(',', '.'))
    if (isNaN(amount)) return 'Incorrect value'
    if (Number(fiatAsset?.availableBalance || 0) < amount) return false
    return !!amount
  }

  const handleTopUpCard = async (data: any): Promise<void> => {
    setRequestError('')
    setIsLoading(true)
    try {
      await CardService.topUpCard({
        assetId: 'EUR',
        amount: data.amount,
      })
      await initApp()
      setIsSuccessful(true)
    } catch (e: any) {
      console.log('ERROR-handleTopUpCard', e)
      setRequestError(e.codeMessage)
    }
    setIsLoading(false)
  }

  // const goToModal = (type: string): void => {
  //   const options = {
  //     title: Pages.BANKING,
  //     codeForInvitation,
  //     asset: fiatAsset,
  //   }
  //   if (isMobileScreens) {
  //     setModalStore(options)
  //     navigate(`${APP_ROUTES[Pages.BANKING]}/${type}`)
  //   } else {
  //     showModal(type, options)
  //   }
  // }

  const handleSellCrypto = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    // goToModal(MODAL_TYPES.TRADE_ASSET)
    Modal.open(<TradeAssetModal asset={asset} />, { title: pages.PORTFOLIO.name, isFullScreen: true })
  }

  const handleToDeposit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    // goToModal(MODAL_TYPES.TOP_UP_BANKING)
    Modal.open(<DepositBankingModal codeForInvitation={codeForInvitation} />, {
      variant: 'right',
      title: 'Banking',
      isFullScreen: true,
    })
  }

  if (isSuccessful) {
    return (
      <div className={styles.topUpCardWrap}>
        {/*<div className={styles.closeWrap} onClick={() => Modal.close()}>*/}
        {/*  <img className={styles.closeIcon} alt='' src={close} />*/}
        {/*</div>*/}
        <div className={styles.successWrap}>
          {/*<CompleteIconBlurComponent />*/}
          <div className={styles.title}>
            <div>Card Top Up Completed Successfully</div>
          </div>
          <div style={{ height: 30 }} />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.topUpCardWrap}>
      {/*<div className={styles.closeWrap} onClick={() => Modal.close()}>*/}
      {/*  <img className={styles.closeIcon} alt='' src={close} />*/}
      {/*</div>*/}

      <FormProvider {...methods}>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(handleTopUpCard)}>
          <div className={styles.contentWrap}>
            <div className={styles.title}>
              <div>Top Up Card</div>
            </div>

            <div className={styles.description}>
              <div>
                To top up card enter the top up amount and then this amount will be deducted from your fiat balance.
              </div>
            </div>

            <div className='input-item-wrap' style={{ marginTop: 22, marginBottom: '1rem' }}>
              <label htmlFor='amount' className={`input-label ${errors.amount ? 'text-error' : ''}`}>
                Top Up Amount {errors.amount && errors.amount?.type === 'required' ? t('inputError.required') : ''}
                {errors.amount && errors.amount?.type === 'validate' ? t('inputError.invalid') : ''}
              </label>
              <input
                style={errors.amount ? { border: '1px solid red' } : {}}
                id='amount'
                type='text'
                className='input-form'
                placeholder='EUR'
                {...register('amount', { required: true, validate: validateAmount })}
              />
            </div>
            <div className={styles.fiatBalanceAmount}>
              EUR Balance{' '}
              <span
                style={{
                  color: Number(fiatAsset?.availableBalance || 0) >= +watchAmount ? '#2B9A18' : 'red',
                }}
              >
                {Currencies.EUR}
                {roundingBalance(fiatAsset?.availableBalance?.toString() || '0', 2)}
              </span>{' '}
            </div>

            {Number(fiatAsset?.availableBalance || 0) >= +watchAmount ? (
              <button
                type='submit'
                className='btn btn-primary'
                disabled={isLoading}
                style={{ marginTop: 30, marginBottom: 60 }}
              >
                {isLoading ? <span className='spinner-border' /> : 'Top Up Now'}
              </button>
            ) : (
              <div style={{ display: 'flex' }}>
                <button
                  className='btn btn-primary'
                  style={{ marginTop: 30, marginBottom: 60 }}
                  onClick={handleSellCrypto}
                >
                  Sell Crypto
                </button>
                <div style={{ width: 20 }} />
                <button
                  className='btn btn-primary'
                  disabled={isLoading}
                  style={{
                    marginTop: 30,
                    marginBottom: 60,
                    backgroundColor: '#FFFFFF',
                    color: 'var(--mainBlue)',
                    border: '2px solid var(--mainBlue)',
                  }}
                  onClick={handleToDeposit}
                >
                  Deposit EUR
                </button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>

      {requestError ? <RequestError requestError={requestError} /> : null}
    </div>
  )
}
