import { useNavigate } from 'react-router-dom'

import { pages } from 'constant'

import styles from './styles.module.scss'

const DeleteAccount: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.settingsItem}>
      <div className={styles.settingsItemContent}>
        <div className={styles.twoFaTitleWrap}>
          <div className={styles.settingsItemTitle}>Delete Account</div>
        </div>

        <div className={styles.settingsItemDescription}>
          We will be sad to see you go, but you always have the option to break our hearts....
        </div>

        <div
          onClick={() => {
            navigate(pages.DELETE_ACCOUNT.path)
          }}
          className={styles.twoFaBtn}
        >
          Delete Account
        </div>
      </div>
    </div>
  )
}

export default DeleteAccount
