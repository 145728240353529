import React, { useState } from 'react'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { ErrorRedBlock } from 'components'
import { countriesFormatted } from 'constant'
import { CardService } from 'wip/services'
import { $cardStatus, getCardStatusFx } from 'model/cefi-banking'

import styles from './styles.module.scss'

type Props = {
  setIsSubmitted: React.Dispatch<any>
}

export function StepTerms({ setIsSubmitted }: Props) {
  const cardStatus = useStore($cardStatus)

  const errors = { checkBox: false }

  const [terms, setTerms] = useState([
    {
      title: 'I am not a politically exposed person',
      isChecked: false,
    },
    {
      title: 'I am not represented by someone else',
      isChecked: false,
    },
    {
      title: 'I am the beneficial owner of the card',
      isChecked: false,
    },
  ])

  const [isSummary, setIsSummary] = useState(
    Object.keys(cardStatus?.additionalInfo).length ? cardStatus?.additionalInfo : null
  )
  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState('')

  const allTermsIsTrue = !!isSummary || terms.every(term => term.isChecked) //! !cardStatus?.additionalInfo ||

  return (
    <div className={styles.stepContentContainer}>
      <div className={clsx(styles.freeHistoryDescription, styles.freeHistoryDescriptionFive)}>
        {isSummary
          ? 'Please review your details and submit your application.'
          : "We're all about transparency. Please review and agree to our terms before we proceed."}
      </div>

      {isSummary ? (
        <>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Deposit</div>
            <div className={styles.summaryData}>
              {isSummary.amount} {isSummary.assetId} - <span className={styles.summaryConfirmed}>Confirmed</span>
            </div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Country</div>
            {/*// @ts-ignore*/}
            <div className={styles.summaryData}>{countriesFormatted[isSummary.countryCode]}</div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Address</div>
            <div className={styles.summaryData}>
              {isSummary.address1}
              {isSummary.address2 ? `${isSummary.address2}, ` : ', '}
              {isSummary.city}, {isSummary.postalCode}
            </div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Mobile</div>
            <div className={styles.summaryData}>{isSummary.phone}</div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Self-Check</div>
            <div className={styles.summaryData}>Completed</div>
          </div>
          <div className={styles.summaryBlock}>
            <div className={styles.summaryTitle}>Virtual Card Issuance Fee</div>
            <div className={styles.summaryData}>1 EUR</div>
          </div>
        </>
      ) : (
        terms.map(term => {
          return (
            <label key={term.title} htmlFor={term.title} className={clsx(styles.container)}>
              {term.title}
              <input
                checked={term.isChecked}
                id={term.title}
                type='checkbox'
                onChange={() => {
                  const newState = terms.map(item => {
                    if (item.title === term.title) {
                      return { ...item, isChecked: !term.isChecked }
                    }
                    return item
                  })
                  setTerms(newState)
                }}
              />
              <span style={errors.checkBox ? { outline: '1px solid red' } : {}} className={styles.checkmark} />
            </label>
          )
        })
      )}

      <button
        style={{ marginTop: 80, opacity: allTermsIsTrue ? 1 : 0.5 }}
        onClick={async e => {
          e.preventDefault()
          setLoading(true)
          if (isSummary) {
            try {
              await CardService.submitOrderStatus({
                step: 'SUBMITTED',
                data: {},
              })
              await getCardStatusFx()
              setIsSubmitted(true)
            } catch (error: any) {
              setRequestError(error.code || 'SUBMITTED Error')
              console.log('submitOrderStatus(TERMS)-SUBMITTED', error)
            }
          } else {
            try {
              const step5 = await CardService.submitOrderStatus({
                step: cardStatus.nextStep,
                data: {
                  isRepresentedBySomeoneElse: false,
                  isBeneficialOwner: true,
                  isPoliticallyExposedPerson: false,
                },
              })
              setIsSummary(step5.additionalInfo)
              await getCardStatusFx()
            } catch (error: any) {
              setRequestError(error.code || 'TERMS Error')
              console.log('submitOrderStatus(TERMS)-ERROR', error)
            }
          }

          setLoading(false)
        }}
        className='btn btn-primary'
        disabled={!allTermsIsTrue}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? <span className='spinner-border' /> : isSummary ? 'Submit' : <div>Next</div>}
      </button>
      {requestError ? <ErrorRedBlock requestError={requestError} /> : null}
    </div>
  )
}
