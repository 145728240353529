import React, { useState } from 'react'
import Switch from 'react-switch'
import { useStore } from 'effector-react'

import { RequestError } from 'components'
import { CardService } from 'wip/services'
import { themeGlobalColor } from 'config'
import { $cardsData, getCardsDataFx } from 'model/cefi-banking'
import eye from 'assets/icons/eye.svg'
import triangle from 'assets/icons/triangle.svg'

import styles from './styles.module.scss'

const SwitchComponent: React.FC<any> = ({ checked, changeChecked, isDisabled }) => {
  return (
    <div>
      <Switch
        uncheckedIcon={false}
        checkedIcon={false}
        offColor='#DADDE3'
        onColor={themeGlobalColor()}
        offHandleColor='#445374'
        onHandleColor='#FFFFFF'
        height={23}
        width={42}
        handleDiameter={19}
        checked={checked}
        onChange={changeChecked}
        disabled={isDisabled}
      />
    </div>
  )
}

type Props = {
  setStep: any
  cardUuid: string
}

export function SettingsStep({ setStep, cardUuid }: Props) {
  const cards = useStore($cardsData)
  const security = cards.find(card => card.cardUuid === cardUuid)?.security
  const isPhysical = cards.find(card => card.cardUuid === cardUuid)?.type === 'CHIP_AND_PIN'

  const [isLoading, setIsLoading] = useState(false)
  const [requestError, setRequestError] = useState('')

  const handleSecurity = async (type: string): Promise<void> => {
    setIsLoading(true)
    try {
      await CardService.updateSecurity({
        cardUuid,
        // @ts-ignore
        securityData: { ...security, [type]: !security[type] },
      })
      await getCardsDataFx()
    } catch (e: any) {
      console.log('ERROR-handleSecurity', e)
      setRequestError(e.code)
    }
    setIsLoading(false)
  }

  const CardSettingsFields = [
    {
      title: '3DS Password',
      description: 'View your card 3DS Password',
      icon: (
        <div onClick={() => setStep('3DPassword')} style={{ cursor: 'pointer' }}>
          <img src={eye} alt='' className='icon-eye' />
        </div>
      ),
    },
    {
      title: 'Limits',
      description: 'View card spending limits',
      icon: (
        <div onClick={() => setStep('cardLimits')} style={{ cursor: 'pointer' }}>
          <img style={{ rotate: '180deg' }} src={triangle} alt='' className='icon-eye' />
        </div>
      ),
    },
    {
      title: 'Contactless Payment',
      description: 'Turn on/off contactless payments',
      icon: (
        <SwitchComponent
          isDisabled={isLoading}
          checked={security?.contactlessEnabled}
          changeChecked={() => handleSecurity('contactlessEnabled')}
        />
      ),
    },
    {
      title: 'ATM Withdrawals',
      description: 'Allow/Deny ATM withdrawals',
      icon: (
        <SwitchComponent
          isDisabled={isLoading}
          checked={security?.withdrawalEnabled}
          changeChecked={() => handleSecurity('withdrawalEnabled')}
        />
      ),
    },
    {
      title: 'Online Transactions',
      description: 'Allow/Deny online transactions',
      icon: (
        <SwitchComponent
          isDisabled={isLoading}
          checked={security?.internetPurchaseEnabled}
          changeChecked={() => handleSecurity('internetPurchaseEnabled')}
        />
      ),
    },
  ]

  if (isPhysical) {
    CardSettingsFields.unshift({
      title: 'PIN Code',
      description: 'View your card pin code',
      icon: (
        <div onClick={() => setStep('pin')} style={{ cursor: 'pointer' }}>
          <img src={eye} alt='' className='icon-eye' />
        </div>
      ),
    })
  }

  return (
    <>
      <div className={styles.title}>
        <div>Settings</div>
      </div>

      {CardSettingsFields.map(cardSettingsItem => {
        return (
          <div key={cardSettingsItem.title} className={styles.cardSettingsItemWrap}>
            <div>
              <div className={styles.cardSettingsItemTitle}>{cardSettingsItem.title}</div>
              <div className={styles.cardSettingsItemDescription}>{cardSettingsItem.description}</div>
            </div>
            <div>{cardSettingsItem.icon}</div>
          </div>
        )
      })}

      <div style={{ height: 77 }} />

      {requestError ? <RequestError requestError={requestError} /> : null}
    </>
  )
}
