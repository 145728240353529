import React, { Dispatch, SetStateAction } from 'react'

import { FilterButton } from '../modals/transaction-filter/filter-button'
import { STATUSES_FILTER } from './index'
import styles from './styles.module.scss'

type Props = {
  setStatusFilter: Dispatch<SetStateAction<string>>
  statusFilter: string
  list: string[]
}

export function LaunchpadFilters({ statusFilter, setStatusFilter, list }: Props) {
  return (
    <div className={styles.launchpadWrap}>
      <div className={styles.buttonGroupFilter}>
        {list.map(title => {
          return (
            <FilterButton
              key={title}
              title={title}
              action={() => setStatusFilter(title)}
              buttonActive={statusFilter === title}
            />
          )
        })}
      </div>
    </div>
  )
}
