import { useState } from 'react'

import { AssetsServices, TAsset } from 'wip/services'
import { initApp } from 'wip/stores'
import { StarIcon } from 'icons'

import { CombainedObject } from '../../../model/cefi-combain-assets-data'
import { getToken } from '../../../utils'

export interface Star {
  asset: CombainedObject
}

export function Star({ asset }: Star) {
  const token = getToken()

  const [isLoading, setIsLoading] = useState(false)
  const updateFavourite = async (assetItem: CombainedObject): Promise<void> => {
    setIsLoading(true)
    try {
      await AssetsServices.setFavouriteAssetAddRemove({
        assetId: assetItem.assetId,
        isFavorite: !assetItem.isFavourite,
      })
      await initApp()
    } catch (e) {
      console.log('ERROR-setFavouriteAssetAddRemove', e)
    }

    setIsLoading(false)
  }

  return (
    <div
      style={{ position: 'relative' }}
      onClick={async event => {
        event.stopPropagation()
        if (!token) return
        await updateFavourite(asset)
      }}
    >
      {isLoading && (
        <div style={{ position: 'absolute', left: 12, top: 11 }}>
          <span className='spinner-border' />
        </div>
      )}

      <StarIcon fill={asset.isFavourite ? '#E7A600' : 'white'} stroke={asset.isFavourite ? '#E7A600' : '#E1E1E1'} />
    </div>
  )
}
