import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { ErrorRedBlock } from 'components'
import { regex } from 'constant'
import { saveRefreshToken, saveToken } from 'utils'
import { AuthServiceV4, CardService, StepController } from 'wip/services'
import { $cardStatus, getCardStatusFx } from 'model/cefi-banking'

import styles from './styles.module.scss'

type Inputs = {
  phone: string
  code: string
}

export function StepPhone() {
  const cardStatus = useStore($cardStatus)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      phone: cardStatus.additionalInfo?.phone || '+',
      code: '',
    },
  })

  const [isSent, setIsSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [requestError, setRequestError] = useState('')
  const [sessionToken, setSessionToken] = useState('')

  const blockPhone = !!cardStatus?.additionalInfo?.phone

  const nextStepToTerms = async (): Promise<void> => {
    try {
      await CardService.submitOrderStatus({
        step: cardStatus.nextStep,
        data: {},
      })
      await getCardStatusFx()
    } catch (error: any) {
      setRequestError(error.code || 'Phone Service Error')
    }
  }

  const handleButtonClick = async (data: Inputs) => {
    setLoading(true)
    setRequestError('')

    if (blockPhone) {
      return await nextStepToTerms()
    }

    const phone = data.phone.replace(/[^+\d]/g, '')
    const code = data.code.trim()

    if (isSent) {
      // check data.
      try {
        const resVerifyPhone = await StepController.verifyPhone({ code }, sessionToken)
        if (!resVerifyPhone.nextStep) {
          resVerifyPhone.accessToken && saveToken(resVerifyPhone.accessToken)
          resVerifyPhone.refreshToken && saveRefreshToken(resVerifyPhone.refreshToken)
        }

        await nextStepToTerms()
      } catch (error: any) {
        setRequestError(error.code || 'Phone Service Error')
        console.log('ERROR-PhoneService', error)
      }
    } else if (phone) {
      try {
        // send phone code
        const res = await AuthServiceV4.addPhone({ phone })
        if (res.sessionToken) {
          setSessionToken(res.sessionToken)
        }
        setIsSent(true)
      } catch (error: any) {
        console.log('ERROR-PhoneService', error)
        setRequestError(error.code || 'Phone Service Error')
      }
    }

    setLoading(false)
  }

  const handleResendPhoneCode = async () => {
    setRequestError('')
    setLoading(true)
    try {
      await AuthServiceV4.resendPhoneCode()
    } catch (error: any) {
      console.log('ERROR-ResendPhoneCode', error)
      setRequestError(error.code || 'Error Resend Phone Code')
    } finally {
      setLoading(false)
    }
  }

  const handleBackBtn = () => {
    setIsSent(false)
    setValue('code', '')
  }

  return (
    <div className={styles.stepContentContainer}>
      <div className={styles.freeHistoryDescription} style={{ textAlign: 'center', marginTop: 90 }}>
        {isSent
          ? 'Just type in the code we sent you. This helps us keep your account safe and secure.'
          : "A quick step for added security. We'll send you a verification code to ensure it's really you."}
      </div>
      <div className='input-item-wrap'>
        <label htmlFor='phone' className='input-label'>
          {isSent ? 'Enter Code Received' : 'Phone Number'}
        </label>

        {isSent && (
          <input
            id='code'
            type='text'
            className='input-form'
            style={errors.code ? { outline: '1px solid red' } : {}}
            placeholder='Enter Code Received'
            {...register('code', {
              required: true,
            })}
          />
        )}

        {!isSent && (
          <input
            id='phone'
            type='text'
            className='input-form'
            style={errors.phone ? { outline: '1px solid red' } : {}}
            placeholder='Phone Number'
            {...register('phone', {
              required: true,
              pattern: {
                value: regex.phone,
                message: 'Invalid phone',
              },
              validate: value => {
                const trimmedValue = value.replace(/[^+\d]/g, '')
                if (trimmedValue.length < 8 || trimmedValue.length > 16) {
                  return 'Phone number must be between 8 and 16 characters long.'
                }
              },
              onChange(event) {
                let cleanedValue = event.target.value?.replace(/[^0-9()\s_-]+/g, '')
                if (cleanedValue.charAt(0) !== '+') {
                  cleanedValue = '+' + cleanedValue
                }
                setValue('phone', cleanedValue)
              },
              disabled: blockPhone,
            })}
          />
        )}

        {isSent ? (
          <div style={{ marginLeft: '.5rem', marginTop: 11 }} className={styles.stepLabel}>
            Didn’t receive the code?
            <span className={clsx('input-label form-link')} style={{ fontSize: 12 }} onClick={handleResendPhoneCode}>
              Send Again
            </span>
          </div>
        ) : (
          <div style={{ marginLeft: '.5rem', marginTop: 11 }} className={styles.stepLabel}>
            Include “+” in the beginning, country code and number without spaces.
          </div>
        )}
      </div>

      <div className={styles.stepFourBtnWrap}>
        {isSent && (
          <button onClick={handleBackBtn} className='btn btn-secondary'>
            Back
          </button>
        )}
        <button onClick={handleSubmit(handleButtonClick)} className='btn btn-primary'>
          {loading ? <span className='spinner-border' /> : 'Next'}
        </button>
      </div>

      {requestError ? (
        <ErrorRedBlock requestError={requestError} />
      ) : (
        <div style={{ height: 'auto', margin: '24px 0' }} />
      )}
    </div>
  )
}
