import { API_BASE_AUTH_URL, kycLevel } from 'config'

import { request } from './base'
import { MFAAddAuthResponse } from './step-controller'

export interface LoginByEmailRequest {
  email: string
  password: string
}
export interface AddPhoneRequest {
  phone: string
}
export interface SignUpByEmailRequest extends LoginByEmailRequest {
  referral: string
}

export interface AuthResponse {
  nextStep: string
  sessionToken: string
  refreshToken: string
  accessToken: string
}

export interface ReferralCodeResponse {
  referralCode: string
}

export interface UserEmailResponse {
  email: string
}

export interface ChangeUserPasswordRequest {
  oldPassword: string
  newPassword: string
}

export type KycUserAccessTokenResponse = {
  token: string
}

export type EmailChangeRequest = {
  newEmail: string
}

export type PhoneChangeRequest = {
  newPhone: string
}

export type StepUpRequest = {
  scope: string
}

export interface StepUpAuthResponse extends AuthResponse {
  oneTimeAccessToken: string
}

const emailSignUp = (data: SignUpByEmailRequest): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/email-sign-up', baseURL: API_BASE_AUTH_URL, data })

const emailLogin = (data: LoginByEmailRequest): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/email-login', baseURL: API_BASE_AUTH_URL, data })

const getReferralCode = (): Promise<ReferralCodeResponse> =>
  request({
    url: `/public/auth/v4/own-referral-code`,
    method: 'GET',
    baseURL: API_BASE_AUTH_URL,
  })

const newTokensPair = (token: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/refresh', baseURL: API_BASE_AUTH_URL, token })

const mfaSetup = (): Promise<AuthResponse> => request({ url: '/public/auth/v4/mfa-setup', baseURL: API_BASE_AUTH_URL })

const getEmail = (): Promise<UserEmailResponse> =>
  request({ url: '/public/auth/v4/email', baseURL: API_BASE_AUTH_URL, method: 'GET' })

const deleteMfa = (): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/delete-mfa', baseURL: API_BASE_AUTH_URL })

const addPhone = (data: AddPhoneRequest, token?: string): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/phone', baseURL: API_BASE_AUTH_URL, data, token })

const resendPhoneCode = (): Promise<unknown> =>
  request({ url: '/public/auth/v4/phone-code-resend', baseURL: API_BASE_AUTH_URL })

const resetPassword = (data: { email: string }): Promise<void> =>
  request({ url: '/public/auth/v4/reset-password', baseURL: API_BASE_AUTH_URL, data })

const changePassword = (data: ChangeUserPasswordRequest): Promise<void> =>
  request({ url: '/public/auth/v4/change-password', baseURL: API_BASE_AUTH_URL, data })

const getSumSubToken = (level = kycLevel()): Promise<KycUserAccessTokenResponse> =>
  request({ url: `/public/v3/kyc/accessToken?level=${level || 'basic-kyc-level'}`, method: 'GET' })

const changeEmail = (data: EmailChangeRequest): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/change-email', baseURL: API_BASE_AUTH_URL, data })

const changePhone = (data: PhoneChangeRequest): Promise<AuthResponse> =>
  request({ url: '/public/auth/v4/change-phone', baseURL: API_BASE_AUTH_URL, data })

const stepUp = (data: StepUpRequest): Promise<AuthResponse | MFAAddAuthResponse | StepUpAuthResponse> =>
  request({ url: '/public/auth/v4/step-up', baseURL: API_BASE_AUTH_URL, data })

export const AuthServiceV4 = {
  emailSignUp,
  emailLogin,
  newTokensPair,
  getReferralCode,
  mfaSetup,
  getEmail,
  deleteMfa,
  addPhone,
  resendPhoneCode,
  resetPassword,
  changePassword,
  getSumSubToken,
  changeEmail,
  changePhone,
  stepUp,
}
