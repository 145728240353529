import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { QRCodeBlock } from 'components'
import { CheckedIcon } from 'icons'
import { qrName } from 'config'
import { $userEmail } from 'model/user-email'

import { ErrorView } from './error-view'
import styles from './styles.module.scss'

type Inputs = {
  code: string
}
const defaultValues = {
  code: '',
}

type Props = {
  action: (confirmationCode: string) => Promise<void>
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  response: any
}

export const MfaConfirmView = ({ isLoading, response, action, errorMessage, setErrorMessage }: Props) => {
  const userEmail = useStore($userEmail)

  const { handleSubmit, watch, register, reset } = useForm<Inputs>({ defaultValues })

  const [isCopied, setIsCopied] = useState(false)
  const [isQrCodeScreen, setIsQrCodeScreen] = useState<boolean>(true)

  const inputValue = watch('code')

  useEffect(() => {
    !!errorMessage && setErrorMessage('')
  }, [inputValue])

  const handleNext = () => {
    setIsQrCodeScreen(false)
  }

  const handleCopy = (): void => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 3000)
  }

  const handleAction = (inputData: Inputs) => {
    action(inputData.code)
  }

  return (
    <>
      {isQrCodeScreen ? (
        <div className={clsx(styles.contentQrCode, styles.formMobileContent)}>
          <div className={styles.description} style={{ marginBottom: 10 }}>
            Please enter the following address to an <br />
            authenticator of your choice.
          </div>
          <QRCodeBlock
            stringCode={response.secret || ''}
            dataString={`otpauth://totp/${userEmail}?secret=${response.secret || ''}&issuer=${qrName()}`}
            copiedAction={handleCopy}
          />
          {isCopied ? (
            <div
              style={{
                backgroundColor: '#262832',
                color: '#FFFFFF',
                height: 32,
                marginTop: 9,
                marginBottom: 13,
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                padding: '0 11px',
              }}
            >
              <div style={{ marginRight: 6 }}>Copied</div>
              <CheckedIcon />
            </div>
          ) : (
            <div style={{ height: 54 }} />
          )}

          <button type='button' className='btn btn-primary' disabled={isLoading} onClick={handleNext}>
            {isLoading ? <span className='spinner-border' /> : 'Next'}
          </button>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit(handleAction)} className={styles.formWrap}>
            <div>
              <div className={styles.description}>Please enter the code from your authenticator.</div>
            </div>

            <div style={{ width: '100%' }} className='input-item-wrap'>
              <label htmlFor='code' className={'input-label'}>
                2FA Code
              </label>

              <input
                type='text'
                className='input-form'
                style={errorMessage ? { outline: '1px solid red', marginBottom: '0.7rem' } : { marginBottom: '0.7rem' }}
                placeholder='Enter your code here'
                {...register('code', { required: true })}
              />
              {errorMessage ? null : <div className={styles.height30} />}
              <ErrorView errorMessage={errorMessage} />
            </div>

            <div className={styles.button}>
              <div></div>
              <button
                type='submit'
                className={clsx('btn btn-primary', !inputValue ? styles.buttonDisable : {})}
                disabled={isLoading || !inputValue}
              >
                {isLoading ? <span className='spinner-border' /> : 'Submit'}
              </button>
            </div>
          </form>
        </>
      )}
    </>
  )
}
