import { HTMLAttributes, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'effector-react'
import clsx from 'clsx'

import { Modal } from 'components'
import { pages } from 'constant'
// import { TransactionsModal } from 'features/modals'
import { $isMobileNavOpen, setIsMobileNavOpenEV } from 'model/gui'
import menu from 'assets/icons/menu.svg'
import transactions from 'assets/icons/transactions.svg'

import { $unstoppableDomains } from '../../model/unstoppableDomains'
import { getToken } from '../../utils'
import addUser from './Add-User.svg'
import styles from './styles.module.scss'

export interface HeaderTitle extends HTMLAttributes<HTMLDivElement> {
  headerTitle: string
  showBackBtn?: boolean
  hideUd?: boolean
}

export function HeaderTitle({ headerTitle, showBackBtn, hideUd, ...props }: HeaderTitle) {
  const unstoppableDomains = useStore($unstoppableDomains)
  const isMobileNavOpen = useStore($isMobileNavOpen)
  const navigate = useNavigate()
  const location = useLocation()
  const isLaunchpad = location.pathname.includes(pages.LAUNCHPAD.path)
  const [isCopied, setIsCopied] = useState(false)
  const transactionsShow: Array<string> = [pages.Base.path, pages.EARN.path, pages.PORTFOLIO.path]
  const token = getToken()

  const toggleMobileNavigation = () => {
    setIsMobileNavOpenEV(!isMobileNavOpen)
  }

  const launchProject = () => {
    return (
      <div
        onClick={() => window.open('https://form.typeform.com/to/IOV9EFa9?typeform-source=70y3ggn7l5c.typeform.com')}
        className={styles.launchBtnTitle}
      >
        Launch a project
      </div>
    )
  }

  return (
    <div className={styles.headerTitleWrap} {...props}>
      {showBackBtn ? (
        <div onClick={() => navigate(-1)} className={styles.backBtnWrap}>
          <div className={styles.backBtn}>
            {`<`} {isLaunchpad ? 'Launchpad' : 'Portfolio'} /{' '}
            <div className={styles.assetName}>&nbsp;{headerTitle}</div>
          </div>
        </div>
      ) : (
        <div className={styles.titleWrap}>
          <p className={styles.title}>{headerTitle}</p>

          {/*{!hideUd && !theme && unstoppableDomains && unstoppableDomains.meta && unstoppableDomains.meta.domain && (*/}
          {/*  <div*/}
          {/*    onClick={() => {*/}
          {/*      setIsCopied(true)*/}
          {/*      navigator.clipboard.writeText(unstoppableDomains.meta.domain).then(() => null)*/}
          {/*      setTimeout(() => setIsCopied(false), 1000)*/}
          {/*    }}*/}
          {/*    className={styles.unstoppable}*/}
          {/*  >*/}
          {/*    {isCopied ? (*/}
          {/*      <div className={styles.copiedUd}>*/}
          {/*        <Copied />*/}
          {/*      </div>*/}
          {/*    ) : (*/}
          {/*      <div className={styles.unstoppableName}>*/}
          {/*        <div>{unstoppableDomains.meta.domain}</div>*/}
          {/*        <div>*/}
          {/*          <img className={styles.copyWhiteIcon} alt='' src={copyWhite} />*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      )}

      {transactionsShow.includes(location.pathname) && token ? (
        <div style={{ display: 'flex' }}>
          <div onClick={() => navigate(pages.TRANSACTIONS_HISTORY.path)} className={styles.transactionsBtn}>
            <img style={{ marginRight: 12 }} src={transactions} alt='' />
            <div>Transactions</div>
          </div>
        </div>
      ) : null}

      {!token ? (
        <div style={{ display: 'flex', margin: '0 24px', alignItems: 'center' }}>
          <button
            // onClick={() => navigate(pages.LAUNCHPAD.path)}
            className={clsx('btn', 'btn-primary', styles.launchBtn)}
            style={{ backgroundColor: '#FFF', color: '#262832', marginLeft: 12 }}
          >
            {launchProject()}
          </button>
          <button
            onClick={() => navigate(pages.SignIn.path)}
            className={clsx('btn', 'btn-primary', styles.launchBtn)}
            style={{ backgroundColor: '#FFF', color: '#262832', marginLeft: 12, minWidth: 120 }}
          >
            <img src={addUser} alt='' style={{ marginRight: 12 }} />
            <div className={styles.launchBtnTitle}>Sign In</div>
          </button>
        </div>
      ) : null}

      {location.pathname === pages.LAUNCHPAD.path && token ? (
        <div style={{ display: 'flex', margin: '0 24px', alignItems: 'center' }}>
          <button
            // onClick={() => navigate(pages.LAUNCHPAD.path)}
            className={clsx('btn', 'btn-primary', styles.launchBtn)}
            style={{ backgroundColor: '#FFF', color: '#262832', marginLeft: 12 }}
          >
            {launchProject()}
          </button>
        </div>
      ) : null}

      <div onClick={toggleMobileNavigation} className={styles.navMenu}>
        <img src={menu} alt='' />
      </div>
    </div>
  )
}
