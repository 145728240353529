import React, { useState } from 'react'

import infoIcon from '../../assets/icons/info-icon.svg'
import styles from './styles.module.scss'

export function SimpleHint({ text, icon }: { text: string; icon?: any }) {
  const [isShowHint, setIsShowHint] = useState(false)
  return (
    <div
      onMouseLeave={() => setIsShowHint(false)}
      onMouseEnter={() => setIsShowHint(true)}
      style={{ position: 'relative', cursor: 'pointer' }}
    >
      <img src={icon || infoIcon} alt={''} />
      {isShowHint ? (
        <div
          className={styles.hint}
          style={
            icon
              ? {
                  width: 55,
                  left: -27,
                  top: '40%',
                  textAlign: 'center',
                  wordWrap: 'break-word',
                }
              : {}
          }
        >
          <div>{text}</div>
        </div>
      ) : null}
    </div>
  )
}
