import { ProtectedRoute } from 'components'
import { HeaderCards } from 'features/header-cards'
import { Main } from 'features/main'

import { Earn } from '../features/earn-new'
import styles from './styles.module.scss'

export function EarnPage() {
  return (
    <ProtectedRoute
      type='user'
      element={
        <Main>
          {/* REFACTOR move wrapper to layout */}
          <div className={styles.wrap}>
            <HeaderCards headerTitle='Earn' hideUd />
            <Earn />
          </div>
        </Main>
      }
    />
  )
}
