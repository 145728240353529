export type CountriesFormatted = keyof typeof countriesFormatted

export const countriesFormatted = {
  JOR: 'Jordan',
  MNP: 'Northern Mariana Islands',
  SRB: 'Serbia',
  AND: 'Andorra',
  TCA: 'Turks and Caicos Islands',
  BMU: 'Bermuda',
  ATA: 'Antarctica',
  BOL: 'Bolivia',
  LBY: 'Libya',
  MLI: 'Mali',
  ARM: 'Armenia',
  MUS: 'Mauritius',
  MDV: 'Maldives',
  ASM: 'American Samoa',
  MKD: 'North Macedonia',
  ETH: 'Ethiopia',
  GRL: 'Greenland',
  IRQ: 'Iraq',
  GTM: 'Guatemala',
  TTO: 'Trinidad and Tobago',
  PER: 'Peru',
  SUR: 'Suriname',
  SWE: 'Sweden',
  NCL: 'New Caledonia',
  PYF: 'French Polynesia',
  BEN: 'Benin',
  EST: 'Estonia',
  FLK: 'Falkland Islands',
  GUM: 'Guam',
  ZWE: 'Zimbabwe',
  SVK: 'Slovakia',
  NLD: 'Netherlands',
  ECU: 'Ecuador',
  SAU: 'Saudi Arabia',
  ARE: 'United Arab Emirates',
  AFG: 'Afghanistan',
  UMI: 'United States Minor Outlying Islands',
  CAF: 'Central African Republic',
  PAN: 'Panama',
  VAT: 'Vatican City',
  SYR: 'Syria',
  VUT: 'Vanuatu',
  HND: 'Honduras',
  KIR: 'Kiribati',
  CHL: 'Chile',
  BFA: 'Burkina Faso',
  KNA: 'Saint Kitts and Nevis',
  BLM: 'Saint Barthélemy',
  MEX: 'Mexico',
  CHN: 'China',
  TLS: 'Timor-Leste',
  IOT: 'British Indian Ocean Territory',
  SSD: 'South Sudan',
  SWZ: 'Eswatini',
  UZB: 'Uzbekistan',
  IDN: 'Indonesia',
  PRY: 'Paraguay',
  MCO: 'Monaco',
  GRD: 'Grenada',
  HRV: 'Croatia',
  POL: 'Poland',
  BIH: 'Bosnia and Herzegovina',
  CAN: 'Canada',
  PRT: 'Portugal',
  BRA: 'Brazil',
  MRT: 'Mauritania',
  ISR: 'Israel',
  CUW: 'Curaçao',
  BRN: 'Brunei',
  AGO: 'Angola',
  MLT: 'Malta',
  BLR: 'Belarus',
  TUR: 'Turkey',
  FIN: 'Finland',
  GIB: 'Gibraltar',
  BTN: 'Bhutan',
  ESP: 'Spain',
  VEN: 'Venezuela',
  QAT: 'Qatar',
  CZE: 'Czechia',
  KWT: 'Kuwait',
  MNE: 'Montenegro',
  BVT: 'Bouvet Island',
  IND: 'India',
  NZL: 'New Zealand',
  JAM: 'Jamaica',
  CYM: 'Cayman Islands',
  SMR: 'San Marino',
  COG: 'Republic of the Congo',
  PAK: 'Pakistan',
  FRA: 'France',
  KAZ: 'Kazakhstan',
  BHR: 'Bahrain',
  FJI: 'Fiji',
  ISL: 'Iceland',
  MMR: 'Myanmar',
  BGD: 'Bangladesh',
  PHL: 'Philippines',
  GNQ: 'Equatorial Guinea',
  IRL: 'Ireland',
  NPL: 'Nepal',
  YEM: 'Yemen',
  KOR: 'South Korea',
  DNK: 'Denmark',
  OMN: 'Oman',
  VCT: 'Saint Vincent and the Grenadines',
  ERI: 'Eritrea',
  AUS: 'Australia',
  IRN: 'Iran',
  SLV: 'El Salvador',
  TZA: 'Tanzania',
  GLP: 'Guadeloupe',
  SLB: 'Solomon Islands',
  KEN: 'Kenya',
  DOM: 'Dominican Republic',
  GRC: 'Greece',
  GGY: 'Guernsey',
  RWA: 'Rwanda',
  SPM: 'Saint Pierre and Miquelon',
  TUV: 'Tuvalu',
  TWN: 'Taiwan',
  GUY: 'Guyana',
  SYC: 'Seychelles',
  PRK: 'North Korea',
  BWA: 'Botswana',
  KHM: 'Cambodia',
  BRB: 'Barbados',
  COL: 'Colombia',
  UKR: 'Ukraine',
  CIV: 'Ivory Coast',
  NRU: 'Nauru',
  NAM: 'Namibia',
  TCD: 'Chad',
  TON: 'Tonga',
  ARG: 'Argentina',
  CXR: 'Christmas Island',
  NER: 'Niger',
  MHL: 'Marshall Islands',
  CRI: 'Costa Rica',
  GHA: 'Ghana',
  AUT: 'Austria',
  SHN: 'Saint Helena, Ascension and Tristan da Cunha',
  PSE: 'Palestine',
  CUB: 'Cuba',
  HUN: 'Hungary',
  FSM: 'Micronesia',
  BLZ: 'Belize',
  BHS: 'Bahamas',
  ABW: 'Aruba',
  STP: 'São Tomé and Príncipe',
  RUS: 'Russia',
  LUX: 'Luxembourg',
  FRO: 'Faroe Islands',
  NIC: 'Nicaragua',
  TUN: 'Tunisia',
  COD: 'DR Congo',
  SJM: 'Svalbard and Jan Mayen',
  ZAF: 'South Africa',
  SLE: 'Sierra Leone',
  LSO: 'Lesotho',
  SGS: 'South Georgia',
  ITA: 'Italy',
  DJI: 'Djibouti',
  HMD: 'Heard Island and McDonald Islands',
  LKA: 'Sri Lanka',
  LCA: 'Saint Lucia',
  WSM: 'Samoa',
  CCK: 'Cocos (Keeling) Islands',
  PRI: 'Puerto Rico',
  GAB: 'Gabon',
  TKM: 'Turkmenistan',
  LVA: 'Latvia',
  SEN: 'Senegal',
  BEL: 'Belgium',
  MDA: 'Moldova',
  LIE: 'Liechtenstein',
  MWI: 'Malawi',
  LBN: 'Lebanon',
  MNG: 'Mongolia',
  NOR: 'Norway',
  VGB: 'British Virgin Islands',
  CMR: 'Cameroon',
  THA: 'Thailand',
  NGA: 'Nigeria',
  REU: 'Réunion',
  CPV: 'Cape Verde',
  JEY: 'Jersey',
  DZA: 'Algeria',
  LAO: 'Laos',
  BES: 'Caribbean Netherlands',
  AZE: 'Azerbaijan',
  SXM: 'Sint Maarten',
  MAR: 'Morocco',
  ALA: 'Åland Islands',
  BGR: 'Bulgaria',
  BDI: 'Burundi',
  UGA: 'Uganda',
  UNK: 'Kosovo',
  MOZ: 'Mozambique',
  GEO: 'Georgia',
  GBR: 'United Kingdom',
  WLF: 'Wallis and Futuna',
  PCN: 'Pitcairn Islands',
  ROU: 'Romania',
  VNM: 'Vietnam',
  ATF: 'French Southern and Antarctic Lands',
  ESH: 'Western Sahara',
  JPN: 'Japan',
  EGY: 'Egypt',
  LBR: 'Liberia',
  LTU: 'Lithuania',
  HTI: 'Haiti',
  VIR: 'United States Virgin Islands',
  PNG: 'Papua New Guinea',
  HKG: 'Hong Kong',
  KGZ: 'Kyrgyzstan',
  PLW: 'Palau',
  MDG: 'Madagascar',
  GMB: 'Gambia',
  TGO: 'Togo',
  TKL: 'Tokelau',
  SVN: 'Slovenia',
  SGP: 'Singapore',
  GUF: 'French Guiana',
  USA: 'United States',
  MSR: 'Montserrat',
  MYS: 'Malaysia',
  DEU: 'Germany',
  ATG: 'Antigua and Barbuda',
  MAF: 'Saint Martin',
  SOM: 'Somalia',
  MAC: 'Macau',
  ALB: 'Albania',
  MYT: 'Mayotte',
  DMA: 'Dominica',
  ZMB: 'Zambia',
  AIA: 'Anguilla',
  COK: 'Cook Islands',
  MTQ: 'Martinique',
  GIN: 'Guinea',
  COM: 'Comoros',
  NIU: 'Niue',
  CHE: 'Switzerland',
  NFK: 'Norfolk Island',
  IMN: 'Isle of Man',
  SDN: 'Sudan',
  TJK: 'Tajikistan',
  URY: 'Uruguay',
  CYP: 'Cyprus',
  GNB: 'Guinea-Bissau',
  UK: 'United Kingdom (UK)',
  UAE: 'United Arab Emirates (UAE)',
  XXK: 'Kosovo',
  CAR: 'Central African Republic (CAR)',
  OTHER: 'Other',
} as const

// export const countriesFormatted = countries
//   .map(item => {
//     return {[item.cca3]: item.name.common};
//   })
//   .reduce((acc, obj) => ({...acc, ...obj}), {});
