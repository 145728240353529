import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { pages } from 'constant'

export function RedirectHome() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(pages.Base.path)
  }, [])

  return null
}
